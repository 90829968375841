<template>
  <!-- 杠杆弹框 -->
  <el-dialog
    :title="$t('m.调整杠杆')"
    :visible.sync="leverVisible.value == 1"
    width="400px"
    :before-close="handleClose"
    class="leverBox"
    v-if="leverVisible.value == 1"
  >
    <div class="leverTop">
      <div class="title">{{ $t("m.杠杆") }}</div>
      <div class="leverBtn">
        <div class="lever-input">
          {{ leverMultiple ? leverMultiple[leverVisibleValue] + "X" : 1 + "X" }}
        </div>
        <span class="lever-remove" @click="reduceLever()">-</span>
        <span class="lever-add" @click="addLever()">+</span>
      </div>
    </div>
    <el-slider
      v-model="leverVisibleValue"
      :show-tooltip="false"
      :step="1"
      :max="leverList ? leverMultiple.length - 1 : 1"
      show-stops
      class="leverSliderStyle"
      :marks="leverList"
      @change="leverChange"
    >
    </el-slider>
    <span slot="footer" class="dialog-footer">
      <div class="clickBtn" @click="leverVisibleClick()">
        {{ $t("m.确定") }}
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["leverVisible", "leverList", "leverMultiple", "leverValue"],
  data() {
    return {
      leverVisibleValue: this.leverValue.value ? this.leverValue.value : 2,
    };
  },
  methods: {
    ///////////*/*********///弹框杠杆*//////////////////////////
    //滑块Change
    leverChange(v) {
      this.leverVisibleValue = v;
    },
    //加号
    addLever() {
      this.leverVisibleValue = this.leverVisibleValue + 1;
    },
    //减号
    reduceLever() {
      this.leverVisibleValue = this.leverVisibleValue - 1;
    },
    //确定按钮
    leverVisibleClick() {
      this.leverValue.value = this.leverVisibleValue;
      this.leverVisible.value = 0;
    },
    handleClose() {
      this.leverVisible.value = 0;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
// 杠杆弹框样式

.leverBox {
  /deep/.el-dialog__body {
    padding: 15px 20px;
  }
  .leverTop {
    margin-bottom: 20px;
    .title {
      @include themeify {
        color: themed("secondaryColor") !important;
      }
      font-size: 16px;
      margin-bottom: 10px;
    }
    .leverBtn {
      position: relative;
      height: 36px;
      .lever-input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: 1px solid #e6e5f2;
        border-radius: 4px;
      }
      .lever-remove {
        position: absolute;
        top: 0;
        left: 15px;
        font-size: 30px;
        height: 32px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .lever-add {
        position: absolute;
        top: 0;
        right: 15px;
        font-size: 24px;
        height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 40px 20px 30px;
  }
}
.leverSliderStyle /deep/.el-slider__bar {
  @include themeify {
    background: themed("btnBackGreen") !important;
  }
}
.leverSliderStyle /deep/.el-slider__button {
  @include themeify {
    color: themed("btnBackGreen") !important;
    border: 3px solid themed("btnBackGreen") !important;
  }
  width: 0px;
  border-radius: 4px;
}
/deep/.el-slider__marks-text {
  width: 25px;
}
/deep/.el-slider__button-wrapper {
  z-index: 1 !important;
}
</style>