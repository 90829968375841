<template>
  <div class="grid">
    <TopNavigation 
    seoMetaTitle="Grid Trading Bot - Vtrading  AI  trading  platform" 
    seoKeyWords="Grid Trading, grid strategy, grid bot,dogecoin bot, Ethereum, Bitcoin, Altcoin" 
    seoDescription="Vtrading Automated cryptocurrency  AI trading platform - provide cryptocurrency investors with  quantitative strategies such as DCA,  Martingale strategies,  grid trading, etc.,and uses the intelligent tools and trade bots on the platform to make trading Bitcoin, Ethereum and altcoins easier and more efficient intelligent."/>
    
    <component v-if="current"
    :is="'Grid'+current" 

    :one="one" :two="two" :three="three" 
    :four="four" :five="five"/>

    <Bottom />
  </div>
</template>

<script>

import {getShowPage, isLogin} from "@/utils/function.js"

import TopNavigation from "@/components/TopNavigation";
import Bottom from "@/components/Bottom";
import GridPc from "./gridPc.vue"
import GridH5 from "./gridH5.vue"

export default {

  components: {
    TopNavigation: TopNavigation,
    Bottom: Bottom,
    GridPc: GridPc,
    GridH5: GridH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      one: {
        'span1': '什么是网格交易？',
        'span2': '网格交易是一种量化交易策略，即在震荡行情中通过一定的价格区间构造出一系列的买卖价位, 不断的执行低买高卖, 从而获得波段收益的一种交易方法。',
        'span3': '具体来说，网格交易是以一个价格为基础，在该价格的上下划分多个区间或者网格，并在每条网格上预先设定相应的买入和卖出订单。',
        'src1': require('@/assets/image/bot/grid_1.png'),
      },
      
      two: {
        'span1': '网格交易适用于什么行情？',
        'span2': '网格交易更合适在持续震荡的行情下交易获利。',
        'span3': '但如果行情上涨，您可以通过调整网格策略的参数从而将利润最大化',
        'src1': require('@/assets/image/bot/grid_2.png'),
      },

      three: {
        'span1':'Vtrading 网格机器人的优势',
        'span2':'Vtrading平台的网格机器人兼顾各种行情，无论是上涨、下跌还是震荡都可以获利。',
        'span3':'下跌行情使用币本位机器人套利赚币，上涨行情增加资金投入，震荡行情又可以调整网格区间',
        'src1': require('@/assets/image/bot/grid_3.png'),
        'cells':[
          {
            'span1':'灵活',
            'span2':'网格参数随用随改，及时生效',
            'src1': require('@/assets/image/bot/grid_3_1.png')
          },
          {
            'span1':'持续稳定',
            'span2':'网格区间灵活调整，从容面对上涨或下跌行情',
            'src1': require('@/assets/image/bot/grid_3_2.png')
          },
          {
            'span1':'风险可控',
            'span2':'通过参数来调整限制订单数量，从而应对突发行情，对于资金仓位的管控有非常好的帮助',
            'src1': require('@/assets/image/bot/grid_3_3.png')
          }
        ]
      },

      four: {
        'src1': require('@/assets/image/bot/grid_4_1.png'),
        'span1': '观看网格讲解视频',
        'video':'https://www.youtube.com/embed/-gKY_hpvjtw?si=2DgVAJ9yUJk47d7s',
        'but1': {
          'txt':'播放',
          'click':'toPlay'
        }
      },

      five: {
        'span1':'如何启动网格交易策略？',
        'cells':[
          {
            'src1': require('@/assets/image/bot/grid_5_1.png'),
            'span1':'通过API链接您的交易所'
          },
          {
            'src1': require('@/assets/image/bot/grid_5_2.png'),
            'span1':'选择网格策略'
          },
          {
            'src1': require('@/assets/image/bot/grid_5_3.png'),
            'span1':'选择模式开启策略',
          }
        ]
      }
    };
  },

  created() {
    this.initView();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.grid {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cbai60 {color: rgba($color: #ffffff, $alpha: 0.6);}
.but {cursor: pointer;}
</style>
