<template>
  <div class="cellNewsH5" @click="cellClick(item)">
    <el-image class="img-cellNewsH5" :src="item.artPath" fit="cover"  />
    <span class="font18 fontc ch1 mb10 text-line2">{{item.artTitle}}</span>
    <span class="font16 ch3 text-line3 mb4">{{item.artIntroduce}}</span>
    <span class="font10 ch5">{{(lang == 'en' ? item.timeEn  : item.timeCn)}}</span>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: {
    item: Object,
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState(['lang']),  //语言
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {

    /******************************************** 点击 */
    //cell点击
    cellClick() {
      if (!this.item) return;
      this.$emit('click', this.item);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.cellNewsH5 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  .img-cellNewsH5 {
    overflow: hidden;
    width: 100%;
    height: 190px;
    border-radius: 4px;
    border: 1px solid #F3F7FF;
    margin-bottom: 8px;
  }
}
</style>
