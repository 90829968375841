<template>
  <div class="dcaFuGrid">
    <TopNavigation 
    seoMetaTitle="DCA Futures Trading Bot - Vtrading  AI  trading  platform" 
    seoKeyWords="Futures Trading, DCA Futures, DCA strategy, Futures bot, Bitcoin, Altcoin Futures bot" 
    seoDescription="Vtrading Automated Cryptocurrency AI Trading Platform - Provides DCA trading futures tools for cryptocurrency investors. Whether the market is falling or rising, you can assist you with Vtrading's DCA futures crypto trading robot. Unlock your trading potential."/>
    
    <component v-if="current"
    :is="'Grid'+current" 

    :one="one" :two="two" :three="three" 
    :four="four" :five="five"

    @tapClick="tapClick"/>

    <Bottom />
  </div>
</template>

<script>

import {getShowPage, isLogin} from "@/utils/function.js"

import TopNavigation from "@/components/TopNavigation";
import Bottom from "@/components/Bottom";
import GridPc from "./dcaFuGridPc.vue"
import GridH5 from "./dcaFuGridH5.vue"

export default {

  components: {
    TopNavigation: TopNavigation,
    Bottom: Bottom,
    GridPc: GridPc,
    GridH5: GridH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      one: {
        'span1':'什么是DCA策略？',
        'span2':'DCA是一种美元平均成本策略，通过分批买入建仓从而降低买入成本，同时将DCA的策略运用到期货机器人上更能有效的控制风险，借助期货的杠杆特性有效的利用资金放大账户收益。',
        'but1':{'txt':'立即交易', 'click':'toExchange'},
        'src1': require('@/assets/image/bot/dcaFuGrid_1.png')
      },

      two: {

        'span1':'选择您的策略',
        'span2':'无论市场是下跌还是上涨，都可以通过 Vtrading 的DCA期货加密交易机器人增强您的交易游戏并实现您的目标。',
        'cells':[
          {
            'span1':'利润增强',
            'span2':'当价格上涨时',
            'span3':'DCA策略期货多头',
            'span4':'将您的资金分批投入以获得更好的平均价格。 最大化您的回报并最小化波动影响。',
          },
          {
            'span1':'赚取加密货币',
            'span2':'当市场下跌时',
            'span3':'DCA期货空头',
            'span4':'分批建立空头头寸，从而控制市场风险，避免账户持仓均价建立在行情底部，同时追踪下跌行情来交易获利。',
          }
        ]
      },

      three: {
        'span1': 'DCA策略+信号触发组合机器人',
        'span2': '最大化您的交易利润',
        'span3': '通过Vtrading提供的信号触发功能，可以设置价格突破或跌破策略，也可以自定义设置 TradingView 启动和停止信号，以帮助交易者寻找更好的入场时机，从而增强策略的盈利能力',
        'but1': {'txt':'创建机器人', 'click':'toAddBot'},
        'src1': require('@/assets/image/bot/dcaFuGrid_3.png')
      },
      
      four: {
        'span1': '只需 5个简单步骤即可启动 DCA 合约策略',
        'span2': '通过 Vtrading 开始交易，释放您的交易潜力',
        'cells':[
          {
            'src1': require('@/assets/image/bot/dcaFuGrid_4_1.png'),
            'span1':'通过API连接您的交易所'
          },
          {
            'src1': require('@/assets/image/bot/dcaFuGrid_4_2.png'),
            'span1':'选择DCA期货机器人'
          },
          {
            'src1': require('@/assets/image/bot/dcaFuGrid_4_3.png'),
            'span1':'选择交易方向并启动策略'
          },
          {
            'src1': require('@/assets/image/bot/dcaFuGrid_4_4.png'),
            'span1':'选交易标的'
          },
          {
            'src1': require('@/assets/image/bot/dcaFuGrid_4_5.png'),
            'span1':'运行机器人并观察其盈利'
          }
        ],
        'but1': {'txt':'开始交易', 'click':'toIndex'},
      },

      five: {
        'src1': require('@/assets/image/v3/jiantoushang_hui.png'),
        'src2': require('@/assets/image/v3/jiantouxia_hui.png'),
        'faqs':[
          {'title':'设置机器人需要多长时间？',
          'label':'只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。',
          'show':false},
          {'title':'启动交易机器人需要多少钱？',
          'label':'没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。',
          'show':false},
          {'title':'Vtrading可以在哪些类型的市场进行交易？',
          'label':'Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。',
          'show':false},
          {'title':'使用 vtrading 机器人进行交易安全吗？',
          'label':'Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。',
          'show':false},
          {'title':'我可以随时取款吗？',
          'label':'Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。',
          'show':false},
        ]
      }
    };
  },

  created() {
    this.initView();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    /******************************************** 点击 */
    //点击事件(PC、H5)
    tapClick(name, param) {
      this[name](param);
    },

    //交易策略-我的交易所
    toExchange() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/myExchange" });
    },

    //创建机器人
    toAddBot() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/robotList/createStrategy" });
    },

    //交易策略-首页
    toIndex() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/transac" });
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.dcaFuGrid {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cbai60 {color: rgba($color: #ffffff, $alpha: 0.6);}
.but {cursor: pointer;}
</style>
