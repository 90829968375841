<template>
  <div class="earlyBird">
    <div class="banner">
      <h1>兵马未动，早鸟先行！</h1>
      <div class="text">参与早鸟活动，抢无限机器人实例位</div>
      <div class="img-warp" v-if="accInfo">
        <img class="img1"  :src="accInfo.avatar" alt="" />
        <img class="img2" src="../assets/icon/img-top.png" alt="">
      </div>
      <div v-if="accInfo" class="email">{{ accInfo.email }}</div>
      <span v-if="accInfo" class="time">早鸟计划2024-11-19 到期</span>
      <div class="btn" v-if="accInfo" @click="toInviteFriend()">去邀请</div>
      <div class="btn login" v-else @click="toLogin()">去登录</div>
    </div>
    <div class="content-warp">
      <div class="label">
        <img src="../assets/icon/vip.svg" alt="" />
        <span>早鸟特权</span>
      </div>
      <div class="content">
        <div class="img-warp">
          <div class="box">
            <img src="../assets/image/1.svg" alt="" />
            <span>合约机器人</span>
          </div>
          <div class="box">
            <img src="../assets/image/2.svg" alt="" />
            <span>无限实例</span>
          </div>
          <div class="box">
            <img src="../assets/image/3.svg" alt="" />
            <span>新功能优先体验</span>
          </div>
        </div>

        <div class="rule">
          <h3 class="label">活动规则</h3>
          <div>
            1.活动期间注册Vtrading
            并成功绑定交易所API，即可获得有效期为3个月的早鸟计划时长。
          </div>
          <div>
            2.每次成功邀请1个好友注册Vtrading
            并开启交易，即可获得有效期5天的早鸟计划时长，最高可获得90天早鸟计划时长。
          </div>
        </div>

        <div class="rule">
          <h3 class="label">活动说明</h3>
          <div>
            1.在早鸟计划时长内可以不受数量限制使用平台的任何策略和任何品种；
          </div>
          <div>
            2.成功邀请好友使用机器人开启任意1次交易，才能被计入有效邀请；
          </div>
          <div>
            3.本次活动仅限于Vtrading 平台，活动时间：x月x日-x月x日；
          </div>
          <div>
            4.活动期间，如有恶意刷量、批量注册等作弊行为，平台将取消其参与资格；
          </div>
          <div>5.活动解释权归Vtrading 所有</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      accInfo: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    toLogin(){
      this.$router.push({ path: "/login" })
    },

    toInviteFriend(){
      this.$router.push({ path: "/inviteFriend" });
    }
  },
  created() {},
  mounted() {
    let accInfo = JSON.parse(localStorage.getItem("accInfo"));
    if (accInfo) {
      this.accInfo = accInfo;
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.earlyBird {
  width: 100%;
  height: 100%;
  .banner {
    width: 100%;
    height: 526px;
    background: url("../assets/image/banner.png") no-repeat;
    // /* 背景图垂直、水平均居中 */
    background-position: center center;
    // /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    background-attachment: fixed;
    // /* 让背景图基于容器大小伸缩 */
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    h1 {
      font-size: 56px;
      margin-top: 60px;
    }
    .text {
      margin: 12px 0 62px 0;
      font-size: 20px;
    }
    .img-warp {
        position: relative;
      .img1 {
        width: 82px;
        height: 82px;
        border-radius: 50%;
      }
      .img2{
          width: 20px;
          width: 20px;
          position: absolute;
          top: 0;
          right: 0;
      }
    }

    .email {
      margin: 20px 0 6px 0;
    }
    .btn {
      width: 280px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(90deg, #2670FA 0%, #0c5ffa 100%);
      border-radius: 26px;
      font-size: 22px;
      color: #fff;
      margin-top: 30px;
      cursor: pointer;
    }
    .login{
      margin-top: 60px;
    }
  }
  .content-warp {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    .label {
      width: 1000px;
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #162447;
      padding-top: 60px;
      img {
        margin-right: 10px;
      }
    }
    .content {
      width: 1000px;
      padding: 0 40px 80px 40px;
      .img-warp {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding: 0 10px;
        .box {
          width: 246px;
          height: 264px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 22px;
          background: #ffffff;
          box-shadow: 4px 4px 10px 0px rgba(55, 99, 170, 0.1);
          border-radius: 24px;
          border: 1px solid #dee2e6;
          color: #162447;
          img {
            width: 141px;
            height: 96px;
            margin: 49px 0 59px 0;
          }
        }
      }
      .rule {
        font-size: 18px;
        color: #162447;
        margin-top: 60px;
        .label {
          font-size: 26px;
          margin-bottom: 16px;
        }
        div {
          line-height: 35px;
        }
      }
    }
  }
}
</style>