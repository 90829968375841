<template>
  <div class="loginpc">
    <div class="loginpc-warp">

      <template v-if="isCode">

        <div class="menupc">
          <img class="img-menupc-left" src="@/assets/image/v3/signin_menu_left.png">
          
          <div class="menupc-yes">
            <span class="font20 fontc clan">{{$t("m.扫码登录")}}</span>
          </div>
          
          <RichBut class="font18 fontc" styleType="lhei" @click="isCode=false">
            <div class="menupc-no">
              <span>{{$t("m.邮箱登录")}}</span>
            </div>
          </RichBut>
        </div>
        
        <div class="contentpc-code">
          

          <div class="codepc" v-if="qrcode && downTime">
            <qrcode-vue  
              :value="qrcode"
              :size="qrcodeSize"
              background="#fff"
              foreground="#000"
              level="H"/>
              <img class="img-logopc" src="@/assets/image/v3/logo_code.png">
          </div>
          <img v-else class="codepc-no" src="@/assets/image/v3/codeno.png">
          

          <span class="font16 fontc ch1 mb4" v-if="downTime">{{$t('m.二维码有效期')+' '+downTime+'s'}}</span>
          <div class="row acenter mb4" v-else>
            <span class="font14 ch7">{{$t('m.二维码过期')}}</span>
            
            <RichBut class="font14" styleType="llan" 
            @click="httpGetTemporaryToken()">
              <span>{{' '+$t('m.重新获取')}}</span>
            </RichBut>
          </div>
          <span class="font14 ch5">{{$t('m.打开VtradingAPP扫一扫登录')}}</span>
          
          <div class="all"></div>
          <div class="row jcenter">
            <span class="font14 ch7 mr2">{{$t('m.没有账号')}}</span>
            
            <RichBut class="font14" styleType="llan" @click="signupClick">
              <span>{{' '+$t('m.立即注册')}}</span>
            </RichBut>
          </div>
        </div>
      </template>

      <template v-else>

        <div class="menupc">
          <img class="img-menupc-right" src="@/assets/image/v3/signin_menu_right.png">
          
            
          <RichBut class="font18 fontc" styleType="lhei" @click="isCode=true">
            <div class="menupc-no">
                <span>{{$t("m.扫码登录")}}</span>
            </div>
          </RichBut>
          <div class="menupc-yes">
            <span class="font20 fontc clan">{{$t("m.邮箱登录")}}</span>
          </div>
        </div>
        
        <div class="contentpc-user">

          <RichInput class="mb20"
          v-model="user"
          :placeholder="$t('m.请输入邮箱')"
          :imgLeft="require('@/assets/image/v3/signin_user.png')"
          :imgLeftSelect="require('@/assets/image/v3/signin_user_.png')" />

          
          <RichInput class="mb14"
          v-model="paw"
          :password="true"
          :placeholder="$t('m.请输入密码')"
          :imgLeft="require('@/assets/image/v3/signin_lock.png')"
          :imgLeftSelect="require('@/assets/image/v3/signin_lock_.png')" />

          <div class="row acenter jend mb32">
            
            <RichBut class="font14" styleType="llan" @click="resetPawClick">
              <span>{{$t('m.忘记密码')}}</span>
            </RichBut>
          </div>

          <RichBut class="butpc-ok" styleType="blan" @click="loginClick()">
            <span class="font16 fontc">{{$t('m.登录')}}</span>
          </RichBut>

          <div class="all"></div>
          <div class="row jcenter">
            <span class="font14 ch7 mr2">{{$t('m.没有账号')}}</span>
            
            <RichBut class="font14" styleType="llan" @click="signupClick">
              <span>{{' '+$t('m.立即注册')}}</span>
            </RichBut>
          </div>
        </div>
      </template>


      <!-- 安全验证 -->
      <el-dialog
      :title="$t('m.安全验证')"
      :visible.sync="verifyMap.verifyShow"
      width="30%"
      :close-on-click-modal="false">
        <!-- 安全验证 -->
        <template>
          <div class="dialog-warp">

            <div class="inp-warp">
                <el-dropdown trigger="click" v-if="isBandGa">
                  
                  <RichBut styleType='lhei'>
                    <span class="el-dropdown-link" style="cursor: pointer">
                      {{verifyMap.verifyType ? $t("m.谷歌验证") : $t("m.邮箱验证") }}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                  </RichBut>

                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="verifyMap.verifyType=true">
                      {{ $t("m.谷歌验证") }}
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="verifyMap.verifyType=false">
                      {{ $t("m.邮箱验证") }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
            </div>

            <div class="inp-warp">

              <el-input v-if="verifyMap.verifyType" v-model="gaCode" :placeholder="$t('m.请输入谷歌验证码')" />
              <el-input v-else v-model="emailCode" :placeholder="$t('m.请输入邮箱验证码')">
                <i slot="suffix">
                  
                  <RichBut v-if="downTimeEmail == 0" styleType='llan'
                  @click="$emit('httpSendEmail', user)" >
                    <div class="get-code">{{ $t("m.获取验证码") }}</div>
                  </RichBut>
                  
                  <div v-else class="get-code clan">
                    <span>{{ downTimeEmail + " s "}}</span>
                  </div>
                </i>
              </el-input>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            
            <RichBut class="butpc-ok" styleType="blan" @click="verifyClick()">
              <span class="font16 fontc">{{$t('m.提交')}}</span>
            </RichBut>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {
  getTemporaryToken,
  scanCodeLogin} from "@/request/api";
import {getFitterValue} from "@/utils/function.js"

import QrcodeVue from "qrcode.vue";
import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue"
export default {
  components: {
    QrcodeVue,
    RichInput,
    RichBut,
  },
  
  props: {
    
    downTimeEmail: { //邮箱验证码倒计时
      type: Number,
      default :0
    },
    
    isBandGa: true, //true 绑定了GA验证
    verifyMap: { 
      type: Object,
      default :{
        verifyType: true, //true 谷歌验证
        verifyShow: false, //true 显示登录验证
      }
    }
  },

  data() {
    return {
      isCode: true, //true 扫码登录
      user: '', //账户
      paw: '', //密码

      timeout: undefined, //扫码登录-定时器
      downTime: 90, //扫码登录-倒计时 
      qrcodeSize:120, //二维码大小
      qrcode:'', //二维码
      temporaryToken: '', //临时Token，用于检查扫码登录状态

      verifyType: false, //验证模式：true GA验证 flase邮箱验证
      emailCode: '', //邮箱验证码
      gaCode: '', //GA验证码
    };
  },
  
  created() {
    this.handleResize();
    this.httpGetTemporaryToken();
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    if(this.timeout) clearTimeout(this.timeout);
  },

  methods: {

    // 当窗口大小变化时，这个函数会被调用
    handleResize() {
      //适配二维码大小
      this.qrcodeSize = getFitterValue(120);
    },

    //更新倒计时-扫码登录
    upDownTime(downTime) {
      if(downTime) this.downTime = downTime;
      if (this.downTime < 1) return;
      
      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {

        this.downTime--;
        
        //间隔5秒坚持一次扫码登录
        if (this.downTime % 5) {
          this.httpScanCodeLogin();
        }

        this.upDownTime();
      }, 1000);
    },

    /******************************* https */
    //http获取登录二维码
    httpGetTemporaryToken() {

      getTemporaryToken().then((res) => {

        if (res.status == 200) {

          this.upDownTime(90)
          var data = res.data;
          this.temporaryToken = data.temporaryToken;
          this.qrcode = JSON.stringify(data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    //http检查扫码登录
    httpScanCodeLogin() {
      
      if (!this.temporaryToken) return;

      var param = {'temporaryToken': this.temporaryToken}
      scanCodeLogin(param).then((res) => {
        
        var data = res.data;
        if (res.status == 200 && data && data.state == true) {
          var userToken = data.token;
          var userInfo = data.user;
          this.$emit('loginOk', userToken, userInfo);
        }
      });
    },

    /******************************* 点击 */
    //登录
    loginClick() {
      this.$emit('login', this.user, this.paw);
    },

    //验证提交点击
    verifyClick() {
      var code = this.verifyMap.verifyType ? this.gaCode : this.emailCode;
      this.$emit('verify', this.user, code);
    },

    //注册点击
    signupClick() {
      this.$emit('signup');
    },

    //忘记密码点击
    resetPawClick() {
      this.$emit('resetPaw');
    }
  },
};
</script>

<style lang='scss' scoped>
.loginpc {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F5F8FA;
  .loginpc-warp {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    height: 477px;
    border-radius: 16px;
    overflow: hidden;
    .menupc {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 80px;
      background: #F0F2F6;
      border-radius: 16px 16px 0px 0px;
      .menupc-yes{
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 100%;
        // background: #fff;
      }
      .menupc-no{
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 100%;
      }
      .img-menupc-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 244px;
        height: 80px;
      }
      .img-menupc-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 244px;
        height: 80px;
      }
    }

    .contentpc-code {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 0 48px 0;
      background: #FFFFFF;
      border-radius: 0px 16px 16px 16px;
      .codepc {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        border-radius: 6px;
        border: 1px solid #E4E6EF;
        padding-top: 6px;
        margin-bottom: 20px;
        .img-logopc {
          position: absolute;
          width: 32px;
          height: 32px;
        }
      }
      .codepc-no {
        width: 140px;
        height: 140px;
        margin-bottom: 20px;
      }
    }

    .contentpc-user {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 48px;
      background: #FFFFFF;
      border-radius: 0px 16px 16px 16px;
    }
  }
}

.butpc-ok {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
}
</style>

<style lang='scss' scoped>
  .dialog-warp {
    .inp-warp {
      color: #6782a4;
      margin: 10px 0;

      ::v-deep.el-input__suffix {
        height: 40px !important;
        line-height: 40px !important;
      }
      .get-code {
        height: 40px;
      }
    }
  }
  /deep/.el-dialog__body {
    padding-top: 0px !important;
  }
  /deep/.el-form-item__error {
    position: relative;
  }
  /deep/.el-input__inner {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    height: 40px !important;
    line-height: 40px !important;
    border-radius: 4px;
    padding: 0 !important;
  }
</style>