<template>
  <div class="lineCharts">
    <div class="title-warp">
      <span class="charts-title">{{ label }}</span>
      <div v-if="time">
        <span class="time-label">{{ $t("m.日期范围") + " : " }}</span>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="—"
          :start-placeholder="$t('m.开始日期')"
          :end-placeholder="$t('m.结束日期')"
          @change="timeChange"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
    </div>
    <div
      :id="chartsId"
      class="charts"
      style="margin-top: 8px"
      :style="{ height: height }"
      name="name"
    ></div>
  </div>
</template>

<script>
import moment from "moment";
import * as echarts from "echarts";
export default {
  props: {
    //高度
    height: {
      type: String,
      default: "280px",
    },
    // //标题
    label: {
      type: String,
    },

    time: {
      type: Boolean,
    },
    chartsId: {
      type: String,
    },
    // 图表数据
    data: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
    },
    number: {},
    createTime: {},
  },
  components: {},
  data() {
    return {
      timeValue: null,
      clearableShow: true,
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      leftText: null,
      sortTime: false,
      data1: null,
      data2: null,
      copyData: null,
      scortStartShow: false,
      scortEndShow: false,
    };
  },
  computed: {},
  watch: {
    data(New, old) {
      this.myCharts(New);
    },
  },
  methods: {
    // 图表
    myCharts() {
      var myChart = echarts.init(document.getElementById(this.chartsId));
      var name = this.name;
      var xData = [];
      var yData = [];
      if (this.copyData) {
        let list = this.copyData;
        for (let key of list) {
          yData.push(this.Public.toMathNum(key.value));
          xData.push(moment(key.time).format("YYYY-MM-DD"));
        }
      } else {
        if (JSON.stringify(this.data) != "[]" && this.data) {
          let list = JSON.parse(JSON.stringify(this.data));
          xData = [];
          yData = [];
          if (list.length < 14 && !this.scortStartShow) {
            yData.push(0);
            xData.push(moment(list[0].time - 86400000).format("YYYY-MM-DD"));
            if (!this.data1) {
              this.data1 = {
                value: 0,
                time: list[0].time - 86400000,
              };
            }
          }
          for (let key in list) {
            if (list.length == 15 && key == "0") {
            } else {
              yData.push(this.Public.toMathNum(list[key].value));
              xData.push(moment(list[key].time).format("YYYY-MM-DD"));
            }
          }
          if (this.number && !this.scortEndShow) {
            yData.push(this.number);
            xData.push(
              moment(list[list.length - 1].time + 86400000).format("YYYY-MM-DD")
            );
            if (!this.data2) {
              this.data2 = {
                value: this.number,
                time: list[list.length - 1].time + 86400000,
              };
            }
          }
        } else {
          yData.push(0);
          xData.push(
            moment(new Date().getTime() - 86400000).format("YYYY-MM-DD")
          );
          if (this.number) {
            yData.push(this.number);
            xData.push(moment(new Date().getTime()).format("YYYY-MM-DD"));
          }
        }
      }

      // 指定图表的配置项和数据
      var options = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          // top: 70,
          bottom: 40,
          right: 35,
          left: 80,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          nameTextStyle: {
            width: 200,
          },
        },
        yAxis: {
          type: "value",
          splitLine: { show: false },
        },
        series: [
          {
            name: name,
            type: "line",
            stack: "Total",
            data: yData,
            lineStyle: {
              color: "#25A5FF",
            },
            color: "#25A5FF",
          },
        ],
      };
      myChart.setOption(options);
    },
    // 时间选择
    timeChange(v) {
      if (!v) {
        this.$emit("childFn", this.label);
        return;
      }
      let startTime = new Date(v[0]).getTime();
      let endTime = new Date(v[1]).getTime();
      let data = JSON.parse(JSON.stringify(this.data));
      this.scortEndShow = false;
      this.scortStartShow = false;

      if (this.data1) {
        data.unshift(this.data1);
      }
      if (this.data2) {
        data.push(this.data2);
      }
      if (this.copyData) {
        this.copyData = null;
      }
      if (startTime >= this.createTime) {
        this.copyData = null;
        this.scortStartShow = true;
        if (endTime !== data[data.length - 1].time) {
          this.scortEndShow = true;
        }
        return this.$emit("childFn", this.label, startTime, endTime);
      } else if (endTime !== data[data.length - 1].time) {
        this.copyData = null;
        this.scortEndShow = true;
        this.scortStartShow = true;
        return this.$emit("childFn", this.label, startTime, endTime);
      }
      // this.copyData = JSON.parse(JSON.stringify(data));
      let startIdx = null;
      let endIdx = null;
      let copyData = [];
      data.forEach((e, idx) => {
        if (startTime == endTime) {
          if (startTime == e.time) {
            copyData.push(e);
          }
        } else {
          if (e.time == startTime) {
            startIdx = idx;
          } else if (e.time == endTime) {
            endIdx = idx;
          }
        }
      });
      if (startTime == endTime) {
        this.copyData = copyData;
      } else {
        this.copyData = data.slice(startIdx, endIdx + 1);
      }
      this.myCharts();
      return;
      this.$emit("childFn", this.label, startTime, endTime);
    },
    disabledDate(time) {
      return time.getTime() > Date.now() || time.getTime() < this.createTime;
    },
  },
  created() {},
  mounted() {
    this.myCharts();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.lineCharts {
  // margin-top: 20px;
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  .title-warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 20px 20px 0 20px;
    @include themeify {
      color: themed("primaryColor") !important;
    }
    .charts-title {
      font-size: 20px;
    }
    .time-label {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .charts {
    width: 100%;
    height: 300px;
    margin-top: -20px;
  }
}
</style>
