<template>
  <div class="apiList">
    <div class="my-acc">
      <!-- <div class="acc-label">
        <span>我的交易所</span>
        <div class="clickBtn add">
          <img src="@/assets/icon/tianjia.svg" alt="" />
          <span>连接交易所</span>
        </div>
      </div> -->
      <div class="acc-list" v-if="apiList">
        <div
          class="acc-box"
          :class="{ 'acc-box-waidth': apiList.length == 1 }"
          style="cursor: pointer"
          v-for="(item, idx) in apiList"
          :key="idx"
          @click="toApiDetail(item)"
        >
          <div class="box-title">
            <div class="title-item">
              <img
                v-if="item.apiName.split('-')[0].indexOf('Okex') > -1"
                src="@/assets/icon/OKX@2x.png"
                alt=""
                style="width: 24px; margin-right: 8px"
              />
              <img
                v-else-if="item.apiName.split('-')[0].indexOf('Binance') > -1"
                src="@/assets/icon/bianlogo@2x.png"
                style="width: 24px; margin-right: 8px"
                alt=""
              />
              <img
                v-else
                src="@/assets/icon/cointr@2x.png"
                style="width: 24px; margin-right: 8px"
                alt=""
              />
              <span>{{ item.apiName.split("-")[1] }}</span>
            </div>
            <div class="title-right">
              <img
                @click.stop="editClick(item)"
                src="@/assets/icon/bianji@2x.png"
                alt=""
              />
              <img
                @click.stop="deleteApiBtn(item.apiId)"
                src="@/assets/icon/shanchu@2x.png"
                alt=""
              />
              <img
                @click.stop="refreshApi(item)"
                src="@/assets/icon/shuaxin@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="box-cont">
            <div class="box-left">
              <div class="assets">
                <span class="title">{{ $t("m.资产折合") }}</span>
                <p>{{ Public.toMathNum(item.assets) + " USDT" }}</p>
                <!-- <p class="text">
                  {{ "≈" + Public.toMathNum(item.assets) + "USDT" }}
                </p> -->
              </div>
              <div class="earnings">
                <span class="title">{{ $t("m.今日资产变动") }}</span>
                <!-- <span class="title">{{ $t("m.今日收益") }}</span> -->
                <p
                  class="text"
                  :class="{
                    green: Public.toMathNum(item.todayProfit) > 0,
                    red: Public.toMathNum(item.todayProfit) < 0,
                  }"
                >
                  {{
                    (item.todayProfit > 0 ? "+" : "") +
                    Public.toMathNum(item.todayProfit) +
                    " USDT"
                  }}
                </p>
              </div>
              <div class="seven-earnings">
                <span class="title">{{ $t("m.近7天资产变动") }}</span>
                <!-- <span class="title">{{ $t("m.近7天累计收益") }}</span> -->
                <p
                  class="text"
                  :class="{
                    green: Public.toMathNum(item.sevendayProfit) > 0,
                    red: Public.toMathNum(item.sevendayProfit) < 0,
                  }"
                >
                  {{
                    (item.sevendayProfit > 0 ? "+" : "") +
                    Public.toMathNum(item.sevendayProfit) +
                    " USDT"
                  }}
                </p>
              </div>
            </div>
            <div class="box-right" style="margin-top: 8px">
              <LineCharts
                :name="$t('m.资产折合') + ' ( USDT )'"
                height="210px"
                :chartsId="'line' + idx"
                style="margin-top: -9px"
                :data="item.iconData"
                :number="Public.toMathNum(item.assets)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 删除当前api -->
      <el-dialog
        :title="$t('m.删除账户')"
        :visible.sync="delVisible"
        width="366px"
        class="openBox"
      >
        <span>{{ $t("m.确定要删除当前账户") + "？" }}</span>
        <div slot="footer" class="open-footer">
          <div
            class="closeBtn"
            @click="
              delVisible = false;
              apiId = null;
            "
          >
            {{ $t("m.取消") }}
          </div>
          <div class="clickBtn" @click="deleteApi()">{{ $t("m.确定") }}</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryApiListIconData,
  deleteApi,
  refreshApiIconData,
  synchronizationApi,
} from "@/request/api";
import LineCharts from "@/components/lineCharts.vue";
import Vuex from "vuex";
export default {
  components: {
    LineCharts,
  },
  data() {
    return {
      apiList: null,
      apiCurrent: null, //当前刷新的api
      delVisible: false,
      apiId: null,
      synchronizationShow: false,
    };
  },
  computed: {},
  methods: {
    //获取api列表
    queryApiListIconData(item) {
      this.apiList = null;
      if (!item) {
        this.$store.commit("setIsSkeleton", true);
      }
      if (this.synchronizationShow) {
        this.$store.commit("setIsSkeleton", false);
      }
      let payload = {
        token: localStorage.getItem("token"),
      };
      if (item) {
        payload.apiId = item.apiId;
      }
      queryApiListIconData(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data)
          let list = JSON.parse(JSON.stringify(res.data.apiList));
          let apiData = [];

          if (list) {
            for (let key of list) {
              apiData.push(key.apiId);
              key.iconData = JSON.parse(key.iconData);
              key.iconData.sort(function (a, b) {
                return a.time - b.time;
              });
              for (let item of key.iconData) {
                item.value = Number(item.sumAssets);
              }
            }
            localStorage.setItem("apiData", JSON.stringify(apiData));
            if (this.apiCurrent) {
              this.apiList.forEach((e) => {
                if (e.apiId == list[0].apiId) {
                  e = list[0];
                  return;
                }
              });
            } else {
              this.apiList = list;
            }

            if (this.apiList.length >= 1) {
              this.$emit("IsApi", true);
              localStorage.setItem("isApi", true);
            } else {
              this.$emit("IsApi", false);
              localStorage.removeItem("isApi");
            }
          } else {
            this.$emit("IsApi", false);
            localStorage.removeItem("isApi");
          }
          setTimeout(() => {
            this.$store.commit("setIsSkeleton", false);
          }, 500);

          // 接口调完才能获取apiData,在同步
          if (!this.synchronizationShow) {
            let payload = {
              token: localStorage.getItem("token"),
              apiData: apiData,
            };
            synchronizationApi(payload).then((res) => {
              if (res.status == 200) {
                this.synchronizationShow = true;
                this.queryApiListIconData();
              }
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toApiDetail(item) {
      localStorage.setItem("exchangeId", item.exchangeId);
      localStorage.setItem("ApiId", item.apiId);
      setTimeout(() => {
        this.$router.push({ path: "/myExchange/exchangeDetail" });
      });
    },
    //删除按钮
    deleteApiBtn(v) {
      this.apiId = v;
      this.delVisible = true;
    },
    //删除当前api
    deleteApi() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiId,
      };
      deleteApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.删除成功"));
          this.delVisible = false;
          this.synchronizationShow = false;
          this.queryApiListIconData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    refreshApi(v) {
      let payload = {
        apiId: v.apiId,
        token: localStorage.getItem("token"),
      };
      refreshApiIconData(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.刷新成功"));
          let apiData = res.data.apiData;
          this.apiList.forEach((e, idx) => {
            if (e.apiId == apiData.apiId) {
              e.todayProfit = apiData.todayProfit;
              e.sevendayProfit = apiData.sevendayProfit;
              e.assets = apiData.assets;
              this.$set(this.apiList, idx, e);
            }
          });
          this.$forceUpdate();
        } else {
          this.$message.success(this.$t("m.刷新失败"));
        }
      });
      // this.queryApiListIconData();
      // this.$message.success(this.$t("m.刷新成功"));
    },
    editClick(v) {

      let url = window.location.href;
      if (url == "transac") {
        let editItem = {
          exchangeName: v.exchangeName,
          apiKey: v.apiKey,
          apiName: v.apiName,
          apiId: v.apiId,
          accountId: v.accountId,
        };
        localStorage.setItem("editItem", JSON.stringify(editItem));
        this.$router.push({ path: "myExchange" });
      } else {
        this.$emit(
          "editApi",
          v.exchangeName,
          v.apiKey,
          v.apiName,
          v.apiId,
          v.accountId
        );
      }
    },
  },
  created() {},
  mounted() {
    this.queryApiListIconData();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.apiList {
  // padding: 20px 0 70px 0;
  padding-bottom: 10px;
  .my-acc {
    .acc-label {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding-left: 20px;
      padding-bottom: 10px;
      .add {
        margin-left: 20px;
        height: 24px;
        font-size: 10px;
        padding: 2px 8px;
        // width: 85px;
        img {
          margin-right: 4px;
        }
      }
    }
    .acc-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .acc-box-waidth {
        width: 100% !important;
      }
      .acc-box {
        width: 49%;
        min-width: 550px;
        height: 280px;
        @include themeify {
          background: themed("secondaryBackColor") !important;
          box-shadow: themed("shadow") !important;
        }
        border-radius: 8px;
        padding: 20px;
        .box-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          margin-bottom: 1px;
          @include themeify {
            border-bottom: 1px solid themed("splitline") !important;
          }
          .title-item {
            display: flex;
            align-items: center;
            @include themeify {
              color: themed("primaryColor") !important;
            }
          }
          .title-right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #2670FA;
            border-radius: 4px;
            width: 116px;
            height: 28px;
            img {
              height: 100%;
              padding: 5px 11px;
              cursor: pointer;
              border-right: 1px solid #2670FA;
            }
            :last-child {
              border: 0;
            }
          }
        }
        .box-cont {
          display: flex;
          .box-left {
            width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 16px;
            line-height: 22px;
            @include themeify {
              color: themed("primaryColor") !important;
            }
            .title {
              font-size: 12px;
            }
            .assets {
              margin-top: 10px;
              .text {
                font-size: 12px;
              }
            }
            .earnings {
              margin-top: 20px;
            }
            .seven-earnings {
              margin-top: 20px;
            }
          }
          .box-right {
            width: calc(100% - 110px);
            height: 200px;
            margin-top: 0px;
            .charts {
              width: 100%;
              height: 260px;
            }
          }
        }
      }
    }
  }
}
.openBox {
  /deep/.el-dialog__body {
    padding: 20px 20px 35px;
  }
  span {
    color: #999fb4;
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>