import { render, staticRenderFns } from "./CreateStrategy.vue?vue&type=template&id=d44db640&scoped=true"
import script from "./CreateStrategy.js?vue&type=script&lang=js&external"
export * from "./CreateStrategy.js?vue&type=script&lang=js&external"
import style0 from "./CreateStrategy.vue?vue&type=style&index=0&id=d44db640&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d44db640",
  null
  
)

export default component.exports