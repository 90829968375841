<template>
  <div class="dcaFuGridH5">
    <div class="warph5">
      
      <!--1 什么是DCA策略？ -->
      <div class="oneh5" v-if="one">
          
        <span class="font24 fontx ch0 mb10">{{$t('m.'+one.span1)}}</span>
        <span class="font14 ch1 mb20">{{$t('m.'+one.span2)}}</span>
    
        <img class="img-icqh5" :src="one.src1">
      </div>
      
      <!--2 选择您的策略 -->
      <div class="twoh5" v-if="two">

        <span class="font24 fontx ch0 mb10">{{$t('m.'+two.span1)}}</span>
        <span class="font14 ch1 mb20">{{$t('m.'+two.span2)}}</span>

        <div class="cellh5" :class="['cellh5'+index]" v-for="(item, index) in two.cells" :key="'two'+index">
          <span class="font18 fontx ch0">{{$t('m.'+item.span1)}}</span>
          <span class="font18 fontx ch0">{{$t('m.'+item.span2)}}</span>
          <span class="font18 fontx ch0 mb10">{{$t('m.'+item.span3)}}</span>
          <span class="font12 ch1">{{$t('m.'+item.span4)}}</span>
        </div>
      </div>
   
      <!--3 DCA策略+信号触发组合机器人-->
      <div class="threeh5" v-if="three">
          
        <span class="font24 fontx ch0 mb10">{{$t('m.'+three.span1)}}</span>
        <span class="font14 ch1">{{$t('m.'+three.span2)}}</span>
    
        <img class="img-icqh5" :src="three.src1">
      </div>

      <!--4 选择您的策略 -->
      <div class="fourh5" v-if="four">
          
        <span class="font24 fontx ch0 mb10">{{$t('m.'+four.span1)}}</span>
        <span class="font14 ch1">{{$t('m.'+four.span2)}}</span>


        <div class="cellsh5">
          <template v-for="(item, index) in four.cells">
            <div class="cellh5"  :key="'four'+index">
              <img :src="item.src1">
              <span class="font18 fontx ch1">{{$t('m.'+item.span1)}}</span>
            </div>
            <img v-if="index<four.cells.length-1" class="img-arrowh5" src="@/assets/image/bot/dcaFuGrid_4_arrow_d.png" :key="'four_arrow'+index">
          </template>
        </div>
      </div>

      <!-- 5 FAQ-->
      <div class="five-h5" v-if="five">
        
        <div class="row jbetween">
          <img class="img-faq-h5" src="@/assets/image/v3/home_9_faq_h5.png">
          <img class="img-icq-h5" src="@/assets/image/v3/home_9_icq_h5.png">
        </div>

        <div class="home-line-h5"></div>

        <div class="column">
          <div class="cell-h5"  
          @click="item.show=!item.show" 
          v-for="(item, index) in five.faqs" :key="index">

            <div class="column all">
              <h3 class="font16 fontc ch1">{{$t('m.'+item.title)}}</h3>
      
              <span v-if="item.show" 
              class="text-h5 font14 ch5">{{$t('m.'+item.label)}}</span>
            </div>
            
            <img class="img-arrow-h5" v-if="item.show" :src="five.src1" alt="">
            <img class="img-arrow-h5" v-else :src="five.src2" alt="">
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },

  data() {
    return {
      isVideo: false,
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.dcaFuGridH5 {
  display: flex;
  flex-direction: column;
  .warph5 {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
  }

  /**** 1 什么是DCA策略？*/
  .oneh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 295px;
      height: auto;
      margin-left: 24px;
    }
  }

  /**** 2 选择您的策略*/
  .twoh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;

    .cellh5 {
      display: flex;
      flex-direction: column;
      padding:20px;
      margin-bottom: 10px;

      border-radius: 20px;
      border: 1px solid #E3ECFF;

      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */
    }
    .cellh50 {
      background-image: url('../../assets/image/bot/dcaFuGrid_2_bg1.png'); /* 背景图片路径 */
    }
    .cellh51 {
      background-image: url('../../assets/image/bot/dcaFuGrid_2_bg2.png'); /* 背景图片路径 */
    }
    .cellh5:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 3 DCA策略+信号触发组合机器人*/
  .threeh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 295px;
      height: auto;
      margin-left: 24px;
    }
  }
  
  
  /**** 4 只需 5个简单步骤即可启动 DCA 合约策略*/
  .fourh5 {

    .cellsh5 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .cellh5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
      }
    }
    .cellh5:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
    .img-arrowh5 {
      width: 34px;
      height: 34px;
      margin: 20px 0;
    }
  }

  /**** 5 FAQ*/
  .five-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .img-faq-h5 {
      width: 63px;
      height: 36px;
      margin-top: 40px;
    }
    .img-icq-h5 {
      width: 165px;
      height: 140px;
    }
    .cell-h5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #E4E6EF;
      .text-h5 {
        margin-top:8px;
      }
      .img-arrow-h5 {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }
}
.mrf_50 {margin-right: -50px}
</style>
