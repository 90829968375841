<template>
  <div class="downloadH5">
    <div class="warph5">
      
      <!--1 什么是网格交易？ -->
      <div class="oneh5" v-if="one">
          
        <div class="column">
          <div class="mb12">
            <span class="font24 fontx ch0">{{$t('m.'+one.span1)}}</span>
            <span class="font24 fontx clan">{{' '+$t('m.'+one.span2)}}</span>
          </div>
          <span class="font14 ch1">{{$t('m.'+one.span3)}}</span>
        </div>

        <div class="row jcenter">
          <img class="img-icqh5" :src="one.src1">
        </div>

        <RichBut @click="tapClick(one.but1.click)" 
        class="buth5" styleType="bhei">
        {{$t('m.'+one.but1.txt)}}
        </RichBut>
      </div>

      <!--2 网格交易适用于什么行情？ -->
      <div class="twoh5" v-if="two">
        <div class="column">
          <span class="font24 fontx ch0 mb12">{{$t('m.'+two.span1)}}</span>
          <span class="font14 ch1 mb12">{{$t('m.'+two.span2)}}</span>
          <span class="font14 ch1">{{$t('m.'+two.span3)}}</span>
        </div>
        <div class="row jcenter">
          <img class="img-icqh5" :src="two.src1">
        </div>
      </div>

      <!--3 Vtrading 网格机器人的优势 -->
      <div class="threeh5" v-if="three">
        <div class="column">
          <span class="font24 fontx ch0 mb12">{{$t('m.'+three.span1)}}</span>
          <span class="font14 ch1 mb12">{{$t('m.'+three.span2)}}</span>
          <span class="font14 ch1">{{$t('m.'+three.span3)}}</span>
        </div>
        <div class="row jcenter">
          <img class="img-icqh5" :src="three.src1">
        </div>
      </div>

      <!--4 观看网格讲解视频 -->
      <div class="fourh5" v-if="four">
        <div class="column">
          <span class="font24 fontx ch0 mb12">{{$t('m.'+four.span1)}}</span>
          <span class="font14 ch1 mb12">{{$t('m.'+four.span2)}}</span>
        </div>
        <div class="row jcenter">
          <img class="img-icqh5" :src="four.src1">
        </div>
      </div>

      <!--5 如何启动网格交易策略？ -->
      <div class="fiveh5" v-if="five">
         
        <div class="column">
          <span class="font24 fontx ch0 mb12">{{$t('m.'+five.span1)}}</span>
          <span class="font14 ch1 mb12">{{$t('m.'+five.span2)}}</span>
        </div>

        <div class="row jcenter">
          <img class="img-icqh5" :src="five.src1">
        </div>

        <RichBut @click="tapClick(five.but1.click)" 
        class="buth5" styleType="bhei">
        {{$t('m.'+five.but1.txt)}}
        </RichBut>
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },
  
  data() {
    return {
      isVideo: false,
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},
  methods:  {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.downloadH5 {
  display: flex;
  flex-direction: column;
  .warph5 {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
  }

  /**** 1 什么是网格交易？*/
  .oneh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width:  357px;
      height: 240px;
    }
  }
  
  /**** 2 网格交易适用于什么行情？*/
  .twoh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 240px;
      height: 240px;
    }
  }
  
  /**** 3 Vtrading 网格机器人的优势*/
  .threeh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 240px;
      height: 240px;
    }
  }

  /**** 4 观看网格讲解视频*/
  .fourh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 240px;
      height: 240px;
    }
  }

  /**** 5 如何启动网格交易策略？*/
  .fiveh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 240px;
      height: 240px;
      margin-bottom: 12px;
    }
  }

  .buth5 {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 44px;
    border-radius: 6px;
  }
}
</style>
