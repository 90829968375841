<template>
  <div class="slider">
    <div class="slider-title">
      {{ $store.state.lang == 'ch' ? dataList.name : dataList.enName }}
      <img
        v-if="dataList && dataList.explain"
        src="@/assets/icon/explain.svg"
        class="explain"
        @click="openAlert()"
        alt=""
      />
    </div>
    <div class="inputBox">
      <input
        class="slider-input inpStyle"
        v-model.number="dataValue"
        @blur="inputChange(dataValue)"
        :placeholder="
          $store.state.lang == 'ch'
            ? dataList.placeholder
            : dataList.enPlaceholder
        "
        :class="dataList.redBorderStyle ? 'redBorderStyle' : ''"
      />
      <span class="input-text" v-if="dataList.inpUnit">
        {{
          dataList.inpUnit == "currency"
            ? "USDT"
            : dataList.inpUnit == "multiple"
            ? $t("m.倍")
            : dataList.inpUnit == "single"
            ? $t("m.单")
            : dataList.inpUnit == "percentage"
            ? "%"
            : dataList.inpUnit == "time"
            ? $t("m.分")
            : "USDT"
        }}
      </span>
    </div>
    <div v-if="dataList.redBorderStyle" style="color: red">
      {{ $t("m.不能为空") }}
    </div>
    <!-- :format-tooltip="(val) => `${val}${dataList.price}`" -->
    <el-slider
      v-model="dataList.value"
      :format-tooltip="(val) => `${val}`"
      :max="dataList.slideMax == 'Infinity' ? 999999 : dataList.slideMax"
      :min="dataList.slideMin"
      class="sliderStyle"
      :step="
        dataList.name.indexOf('单') > -1 ||
        dataList.name.indexOf('网格数') > -1 ||
        dataList.name.indexOf('开仓等待时长') > -1
          ? 1
          : 0.1
      "
      @change="sliderChange(dataList.value)"
    ></el-slider>
    <div class="slider-text">
      <span style="margin-left: -4px">{{ dataList.slideMin }}</span>
      <span>
        {{ dataList.slideMax == "Infinity" ? 999999 : dataList.slideMax }}
      </span>
    </div>
    <div class="box"></div>
  </div>
</template>

<script>
export default {
  props: ["dataList"],
  watch: {
    dataValue() {
      if (
        this.dataValue == null ||
        typeof this.dataValue == "string" ||
        typeof this.dataValue == "object"
      ) {
        if (
          typeof this.dataValue == "string" ||
          typeof this.dataValue == "object"
        ) {
          this.dataList.redBorderStyle = true;
        }
        if (this.dataList.name == "止盈阈值") {
          this.dataList.copyValue = null;
        }
        return;
      }
      this.dataList.redBorderStyle = false;
      if (isNaN(Number(this.dataValue))) {
        this.dataValue = this.dataList.slideMin;
        return;
      }
      this.dataValue = this.dataValue.toString();
      if (
        this.dataValue.indexOf(".") > -1 &&
        this.dataValue.length - this.dataValue.indexOf(".") >
          this.dataList.accuracy
      ) {
        this.dataValue = this.dataValue.slice(
          0,
          this.dataValue.indexOf(".") + this.dataList.accuracy + 1
        );
      }
      if (this.dataList.name == "止盈阈值") {
        this.dataList.copyValue = this.dataValue;
      }
      if (this.dataList.name == "补仓间隔比例" && Number(this.dataValue) == 0) {
        this.dataValue = 0;
        return;
      }
      this.dataValue = Number(this.dataValue);
      this.dataList.value = this.dataValue;
    },
    "dataList.value"() {
      if (this.dataList.name == "止盈阈值") {
        return;
      }
      this.dataValue = this.dataList.value;
    },
  },
  data() {
    return {
      dataValue: null,
    };
  },
  methods: {
    //解释文案
    openAlert() {
      this.$alert(
        this.$store.state.lang == 'ch'
          ? this.dataList.explain
          : this.dataList.enExplain,
        "",
        {
          confirmButtonText: this.$t("m.确定"),
          callback: (action) => {},
        }
      );
    },
    inputChange() {
      console.log(
        this.dataValue == null,
        typeof this.dataValue == "string",
        typeof this.dataValue
      );
      if (
        this.dataValue == null ||
        typeof this.dataValue == "string" ||
        typeof this.dataValue == "object"
      ) {
        this.dataList.value = null;
        this.dataList.redBorderStyle = true;
        return;
      }
      if (
        Number(this.dataList.value).toString().indexOf(".") > -1 &&
        this.dataList.accuracy == 0
      ) {
        this.dataList.value = this.dataList.value
          .toString()
          .slice(
            0,
            Number(this.dataList.value).toString().indexOf(".") +
              this.dataList.accuracy +
              1
          );
      }
      if (this.dataValue < this.dataList.slideMin) {
        this.dataList.value = this.dataList.slideMin;
        this.dataValue = this.dataList.slideMin;
      } else if (this.dataValue > this.dataList.slideMax) {
        this.dataValue = this.dataList.slideMax;
        this.dataList.value = this.dataList.slideMax;
      }
      this.dataList.value = Number(this.dataList.value);
    },
    sliderChange() {
      if (this.dataList.value < this.dataList.slideMin) {
        this.dataList.value = this.dataList.slideMin;
      }
      this.dataValue = this.dataList.value;
    },
  },
  created() {
    this.dataValue = this.dataList.value;
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex: 1;
  @include themeify {
    color: themed("secondaryColor") !important;
  }
  .slider-title {
    display: flex;
    align-items: center;
    @include themeify {
      color: themed("tabColor") !important;
    }
    .explain {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .slider-text {
    display: flex;
    justify-content: space-between;
  }
  .inputBox {
    position: relative;
    .input-text {
      position: absolute;
      right: 8px;
      top: 20px;
    }
    .slider-input {
      margin-top: 10px;
    }
  }
}
.sliderStyle /deep/.el-slider__bar {
  @include themeify {
    background: themed("btnBackGreen") !important;
  }
}
.sliderStyle /deep/.el-slider__button {
  @include themeify {
    color: themed("btnBackGreen") !important;
    border: 3px solid themed("btnBackGreen") !important;
  }
  width: 0px;
  border-radius: 4px;
}
.redBorderStyle {
  border: 1px solid red !important;
}
</style>