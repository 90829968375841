<template>
  <div class="dcafugridpc">
    <div class="warppc">
      
      <!--1 什么是DCA策略？ -->
      <div class="contentpc onepc" v-if="one">
          
        <div class="column">

          <span class="font48 fontx ch0 mb10">{{$t('m.'+one.span1)}}</span>
          <span class="font18 ch1 mb30">{{$t('m.'+one.span2)}}</span>
          
          <RichBut @click="tapClick(one.but1.click)"
          class="butpc" styleType="blan">
            <span>{{$t('m.'+one.but1.txt)}}</span>
          </RichBut>
        </div>

        <img class="img-icqpc" :src="one.src1">
      </div>

      <!--2 选择您的策略 -->
      <div class="contentpc twopc" v-if="two">
        
        <div class="title">
          <span class="font48 fontx ch0 mb20">{{$t('m.'+two.span1)}}</span>
          <span class="font18 ch1">{{$t('m.'+two.span2)}}</span>
        </div>

        <div class="row jbetween">
          <div class="cellpc" :class="['cellpc'+index]" v-for="(item, index) in two.cells" :key="'two'+index">
            
            <span class="font28 fontx ch1">{{$t('m.'+item.span1)}}</span>
            <span class="font28 fontx ch1">{{$t('m.'+item.span2)}}</span>
            <span class="font28 fontx ch1 mb20">{{$t('m.'+item.span3)}}</span>
            <span class="font18 ch1">{{$t('m.'+item.span4)}}</span>
          </div>
        </div>
      </div>
      
      <!--3 DCA策略+信号触发组合机器人 -->
      <div class="contentpc threepc" v-if="three">
          
        <div class="column">

          <span class="font32 fontx ch0">{{$t('m.'+three.span1)}}</span>
          <span class="font32 fontx ch0 mb10">{{$t('m.'+three.span2)}}</span>
          <span class="font18 ch1 mb30">{{$t('m.'+three.span3)}}</span>
          
          <RichBut @click="tapClick(three.but1.click)"
          class="butpc" styleType="blan">
            <span>{{$t('m.'+three.but1.txt)}}</span>
          </RichBut>
        </div>

        <img class="img-icqpc" :src="three.src1">
      </div>

      <!--4 选择您的策略 -->
      <div class="contentpc fourpc" v-if="four">

        <span class="font48 fontx ch0 mb20">{{$t('m.'+four.span1)}}</span>
        <span class="font18 ch1 mb50">{{$t('m.'+four.span2)}}</span>

        <div class="cellspc">
          <template v-for="(item, index) in four.cells">
            <div class="cellpc"  :key="'four'+index">
              <img :src="item.src1">
              <span class="font18 fontx ch1">{{$t('m.'+item.span1)}}</span>
            </div>
            <img v-if="index<four.cells.length-1" class="img-arrowpc" src="@/assets/image/bot/dcaFuGrid_4_arrow.png" :key="'four_arrow'+index">
          </template>
        </div>

        <div class="row jcenter">
          <RichBut @click="tapClick(three.but1.click)"
          class="butpc" styleType="blan">
            <span>{{$t('m.'+three.but1.txt)}}</span>
          </RichBut>
        </div>
      </div>


      <!-- 5 FAQ-->
      <div class="contentpc fivepc" v-if="five">
        
        <img class="img-faqpc" src="@/assets/image/v3/home_9_faq.png">

        <div class="column">
          <div class="cellpc"  
          @click="item.show=!item.show" 
          v-for="(item, index) in five.faqs" :key="index">
            <div class="row jbetween">
              <h3 class="font24 fontc ch1">{{$t('m.'+item.title)}}</h3>
              <img class="img-arrowpc" v-if="item.show" :src="five.src1">
              <img class="img-arrowpc" v-else :src="five.src2">
            </div>
            <span v-if="item.show" 
            class="textpc font18 ch5">{{$t('m.'+item.label)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },

  data() {
    return {
      isVideo: false,
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.dcafugridpc {
  display: flex;
  flex-direction: column;

  .warppc {
    display: flex;
    flex-direction: column;
    align-items: center;
    .line-fenge {
      width: 1200px;
      height: 1px;
      background: #E4E6EF;
      margin-bottom: 120px;
    }
  }
  
  /************************************* 内容 */
  .contentpc {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin-bottom: 120px;
  }

  /**** 1 什么是DCA策略？*/
  .onepc {
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    .img-icqpc {
      width: 440px;
      height: 379px;
      margin-left: 100px;
    }
    .butpc {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 213px;
      height: 60px;
      border-radius: 6px;
    }
  }
  
  /**** 2 选择您的策略*/
  .twopc {

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 200px;
      margin-bottom: 50px;
    }

    .cellpc {
      display: flex;
      flex-direction: column;
      padding: 50px 40px;

      width: 590px;
      height: 350px;
      border-radius: 20px;
      border: 1px solid #E3ECFF;

      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */
    }
    .cellpc0 {
      background-image: url('../../assets/image/bot/dcaFuGrid_2_bg1.png'); /* 背景图片路径 */
    }
    .cellpc1 {
      background-image: url('../../assets/image/bot/dcaFuGrid_2_bg2.png'); /* 背景图片路径 */
    }
    .cellpc:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 3 DCA策略+信号触发组合机器人*/
  .threepc {
    flex-direction: row;
    align-items: center;
    .img-icqpc {
      width: 440px;
      height: 379px;
      margin-left: 100px;
    }
    .butpc {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 213px;
      height: 60px;
      border-radius: 6px;
    }
  }

  /**** 4 只需 5个简单步骤即可启动 DCA 合约策略*/
  .fourpc {

    .cellspc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 30px;
    }
    .cellpc {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 160px;
      margin-bottom: 50px;

      img {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
      }
    }
    .cellpc:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
    .img-arrowpc {
      width: 34px;
      height: 34px;
      margin-top: 43px;
    }
    .butpc {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 213px;
      height: 60px;
      border-radius: 6px;
    }
  }

  /**** 5 FAQ*/
  .fivepc {
    flex-direction: row;
    justify-content: space-between;
    
    .img-faqpc {
      width: 292px;
      height: 358px;
    }
    .cellpc {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 720px;
      padding: 25px 0;
      border-bottom: 1px solid #D8D8D8;
      .textpc {
        margin-top:12px;
      }
      .img-arrowpc {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.mrf_50 {margin-right: -50px}
</style>
