import GridDelegation from "./childModular/gridDelegation.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import InputIconBox from "@/components/InputIconBox";
import TabSwitchClick from "@/components/TabSwitchClick";
import Slider from "@/components/Slider";
import Menu from "@/components/menu.vue";
import moment from "moment";
import Transfer from "@/components/Transfer.vue";
import LeverDialog from "@/components/leverDialog.vue";
import publicData from "@/components/public.js";
import StartegyTradeHistory from "@/views/transaction/modular/childModular/startegyTradeHistory.vue";
import {
  getContractPairList,
  getUserApiList,
  queryStratgyParam,
  synchronSingelCloudApi,
  querylastIncrease,
  getLeverMultiple,
  addRobot,
  updataRobot,
  startRobot,
  qureyExecuteStrategyList,
  robotEnd,
  qureyHistoryStrategyList,
  deleteHistoryStrategy,
  restartRobot,
  suspendRobot,
  qureyExecuteStrategyById,
  queryRobotDetails,
  queryCandlesticks,
  queryContractPrecision,
} from "@/request/api";
export default {
  components: {
    TopNavigation,
    Menu,
    InputIconBox,
    TabSwitchClick,
    Slider,
    Transfer,
    LeverDialog,
    GridDelegation,
    StartegyTradeHistory,
  },
  watch: {
    "intervalTab.value"() {
      this.intervalTabValue = { name: 1 };
    },
    "modelTab.value"() {
      this.queryStratgyParam();
      setTimeout(() => {
        this.RightHeight = this.$refs.rightRef.offsetHeight;
      }, 500);
    },
    "publicData.transferShow.value"() {
      if (this.publicData.transferShow.value == 0) {
        this.synchronSingelCloudApi();
      }
    },
    'publicData.maxBond.value'() {
      this.amountBtn()
    },
    'publicData.leverVisible.value'() {
      if (this.publicData.leverVisible.value == 0) {
        this.amountBtn()
      }
    },
    'intervalTab.value'() {
      if (this.intervalTab.value == 0) {
        this.intervalTabValue1.redBorderStyle = false;
      } else {
        this.intervalTabValue2.redBorderStyle = false;
      }
    }
  },
  data() {
    return {
      moment,
      publicData,
      apiList: [],
      RightHeight: null, //右侧高度
      apiCurrentName: null,
      copyType: null,
      historyStrategyList: null,
      executeStrategyList: null,
      endVisible: false, //终止机器人弹框
      tabIndex: 0,
      proposalInvest: null, // 建议投入
      minInvest: null, // 最小投入
      assets: null, // 当前账户信息
      pairCurrent: null, // 当前币对信息
      result: null, // 当前币对行情价
      leverMultiple: null, // 当前杠杆倍数
      leverValue: { value: 1 },
      pairList: null, // 币对列表
      pairCoin: null, // 当前币对
      transactionType: "contract_usdt",

      intervalTabValue1: {
        placeholder: "请输入百分比",
        enPlaceholder: "Enter a percentage",
        accuracy: 2,
        valueMin: 0.1,
        value: null,
        inpUnit: "percentage",
        inputType: "required",
        redBorderStyle: false,
      },
      intervalTabValue2: {
        placeholder: "请输入价差",
        enPlaceholder: "The text in the tooltip Input the spread",
        valueMin: 0,
        value: null,
        inputType: "required",
        redBorderStyle: false,
        inpUnit: 'currency',
        title: '网格间隔等差'
      },
      advancedShow: false,
      // 自定义模式
      modelTab: {
        radioName: ["AI模式", "自定义模式"],
        enRadioName: ["AI mode", "Custom mode"],
        value: 0,
      },
      // 网格间隔
      intervalTab: {
        radioName: ["等比", "等差"],
        enRadioName: ["Geometric", "Arithmetric"],
        value: 0,
        inpUnit: "percentage",
        name: "网格间隔",
        enName: "Grid Interval",
        explain:
          "等比网格，每格开仓间距按固定百分比排列；等差网格，每格开仓间距按固定价差排列。",
        enExplain:
          "Geometric grid, the interval of each grid is arranged by a fixed percentage;Arithmetic grid, the interval of each grid is arranged by a fixed spread.",
      },
      // 单格投入
      investment: {
        placeholder: "请输入数量",
        enPlaceholder: "Input the amount",
        name: "单格投入",
        enName: "Funding per",
        explain: "单个网格开仓的合约数量。",
        enExplain: "The number of futures positions opend per grid",
        value: null,
        inpUnit: "currency",
        pairUsdtSize: null,
        redBorderStyle: false,
      },
      // 开仓下限
      openPositionMin: {
        name: "开仓上下限",
        placeholder: "最低价选填",
        enName: "Upper/Lower price",
        enPlaceholder: "Lower price",
        inpUnit: "currency",
        explain:
          "下限:低于此价格机器人将暂停开仓操作。上限:高于此价格机器人将暂停开仓操作。",
        enExplain:
          "Lower limit:The bot will suspend to open a position when under this price。Upper limit:The bot will suspend to open a position when beyond this price。",
        value: null,
        pairUsdtSize: null,
      },
      // 开仓上限
      openPositionMax: {
        placeholder: "最高价选填",
        enPlaceholder: "Highest price",
        inpUnit: "currency",
        value: null,
        pairUsdtSize: null,
        // explain: "高于此价格机器人将暂停开仓操作。",
      },
      // 终止下限
      terminationMin: {
        // name: "终止上下限",
        placeholder: "最低价选填",
        // enName: "Terminate upper and lower limits",
        enPlaceholder: "Lower price",
        inpUnit: "currency",
        // explain:
        //   "下限:低于此价格机器人将终止运行。上限:高于此价格机器人将终止运行。",
        // enExplain:
        //   "Lower limit:The bot will stop running when below this price。Upper limit:The bot will stop running when beyond this price。",
        pairUsdtSize: null,
        value: null
      },
      // 终止上限
      terminationMax: {
        placeholder: "最高价选填",
        enPlaceholder: "Highest price",
        inpUnit: "currency",
        pairUsdtSize: null,
        value: null
        // explain: "高于此价格机器人将终止运行。",
      },
      gridShow: false,
      executeId: null, //传递的executeId
      copyStrategy: null,
      qureyStrategyOrderShow: false, //成交记录
      total: 0,
      pageNo: 1,
      pageSize: 50,

      // 最大做多网格数
      maxLong: {
        name: "最大做多网格数",
        enName: "Maximum long grids",
        slideMin: 0,
        slideMax: 100,
        accuracy: 0,
        value: 1,
        inpUnit: "single",
        placeholder: '请输入网格数',
        enPlaceholder: 'Input the number of grids',
        explain:
          "允许连续做多开仓的次数，达到次数后，只等待平仓而不再进行开仓。当设置为0时，即不进行任何做多开仓委托。",
        enExplain:
          "The number of consecutive long positions to be opened. When reaching the highest number, only wait to close out and no position opened. When it is set to 0, no long position order will be made。",
      },
      // 最大做多活跃数
      maxLongNumber: {
        name: "最大活跃开多单数",
        enName: "Maximum active open long positions",
        slideMin: 0,
        slideMax: 100,
        accuracy: 0,
        value: 3,
        inpUnit: "single",
        placeholder: '请输入订单数',
        enPlaceholder: 'Input the number of grids',
        explain:
          "允许机器人在交易所委托簿中同时存在的做多开仓数量。此值受最大做多网格数约束。",
        enExplain:
          "The number of open long positions that the bot is allowed to simultaneously exist in the exchange order book. This value is constrained by the maximum number of long grids。",
      },
      // 最大做空网格数
      maxEmpty: {
        name: "最大做空网格数",
        enName: "Maximum number of short grids",
        slideMin: 0,
        slideMax: 100,
        accuracy: 0,
        value: 1,
        inpUnit: "single",
        placeholder: '请输入网格数',
        enPlaceholder: 'Input the number of grids',
        explain:
          "允许连续做空开仓的次数，达到次数后，只等待平仓而不再进行开仓。当设置为0时，即不进行任何做空开仓委托。",
        enExplain:
          "The number of consecutive short positions allowed to be opened. After reaching the highest number, just wait for closing the position and no position opened. When it is set to 0, no short position order will be made。",
      },
      // 最大做空活跃数
      maxEmptyNumber: {
        name: "最大活跃开空单数",
        enName: "Maximum number of long grids",
        slideMin: 0,
        slideMax: 100,
        accuracy: 0,
        value: 3,
        inpUnit: "single",
        placeholder: '请输入订单数',
        enPlaceholder: 'Input the number of grids',
        explain:
          "允许机器人在交易所委托簿中同时存在的做空开仓数量。此值受最大做空网格数约束。",
        enExplain:
          "The number of open short positions that the bot is allowed to simultaneously exist in the exchange order book. This value is constrained by the maximum number of short grids。",
      },


      openMax: null,//开仓上限
      openMin: null,// 开仓下限
      gridInterval: null,//网格间隔
      gridNum: null,// 网格数
      longNum: null,// 最大网格数
      shortNum: null,// 最大做空网格数

      timeShow: 0, // 时间排序
      priceShow: 0, // 总收益排序
      contractPrecision: null, //当前币种保留位数
      copyAmount: null, //单格投入
    };
  },
  methods: {
    
    //保证金模式
    marginChange(index, back) {
      if (index!=1) return;
      this.$message.warning("暂时只支持全仓模式");
      back();
    },
    
    handleClose() {
      this.qureyStrategyOrderShow = false;
    },

    handleSizeChange(val) {
      this.pageSize = val;
      if (this.tabIndex == 0) {
        this.qureyExecuteStrategyList();
      } else {
        this.qureyHistoryStrategyList();
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      let leftBox = document.getElementById("leftRef");
      leftBox.scrollTop = 0
      if (this.tabIndex == 0) {
        this.qureyExecuteStrategyList();
      } else {
        this.qureyHistoryStrategyList();
      }
    },
    // 活跃历史按钮
    tabIndexBtn(e) {
      if (e == this.tabIndex) {
        return;
      }
      this.timeShow = 0;
      this.priceShow = 0
      this.tabIndex = e;
      this.pageNo = 1;
      this.total = 0;
      this.pageSize = 50;
      if (e == 0) {
        this.qureyExecuteStrategyList();
      } else {
        this.qureyHistoryStrategyList();
      }
    },
    apiChange(v) {
      this.apiCurrentName = v.apiName;
      this.apiCurrent = v;
      if (this.transactionType == "contract_usdt") {
        this.getContractPairList();
      } else {
        this.getSpotPairList();
      }
      this.getLeverMultiple();
    },
    leverBtn() {
      if (this.copyStrategy && this.copyStrategy.disabled) {
        return;
      }
      this.publicData.leverVisible.value = 1;
    },
    // 币对change
    pairChange(v) {
      this.pairCoin = v.counterCoin + "-" + v.baseCoin;
      this.pairCurrent = v;
      this.querylastIncrease();
      this.queryContractPrecision()
      let exchangeName = v.exchangeName;
      if (exchangeName == "okexv5") {
        exchangeName = "okex";
      }
      this.showChart(exchangeName, v.counterCoin, v.baseCoin);

      this.queryCandlesticks(this.pairCurrent.exchangeName, this.pairCurrent.contractId)
    },
    // 获取交易所杠杆
    getLeverMultiple() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeId: this.apiCurrent ? this.apiCurrent.exchangeId : 4,
      };
      getLeverMultiple(payload).then((res) => {
        if (res.status == 200) {
          let leverMultipleMap = res.data.leverMultipleMap;
          let leverMultiple = eval(leverMultipleMap.leverMultiple);
          let leverList = {};
          for (let i in leverMultiple) {
            leverList[i] = leverMultiple[i] + "X";
          }

          this.leverList = leverList;
          this.leverMultiple = leverMultiple;
        }
      });
    },
    // 获取用户api列表
    getUserApiList() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      getUserApiList(payload).then((res) => {
        if (res.status == 200) {

          var datas = res.data.apiList;
          var apiList = [];
          datas.forEach((item)=>{
            item.exchangeId!=30 && apiList.push(item);
          });
          if (apiList.length == 0) {
            this.$message.warning("当前未录入API，请先录入");
            this.$emit("childFn", "2", "2-1");
            return;
          }
          this.apiCurrentName = apiList[0].apiName;
          this.apiCurrent = apiList[0];

          this.getLeverMultiple();
          this.getContractPairList();
          this.apiList = apiList;
        }
      });
    },
    // 获取合约交易对
    getContractPairList(type) {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeId: this.apiCurrent.exchangeId,
      };
      if (!this.pairCurrent) {
        payload.counterCoin = 'BTC'
        payload.baseCoin = 'USDT'
      }
      getContractPairList(payload).then((res) => {
        if (res.status == 200) {
          let contractPairList = res.data.contractPairList;
          let list = [];
          contractPairList.forEach((e) => {
            if (e.baseCoin == "USDT") {
              list.push(e);
            }
          });
          if (this.pairCurrent) {
            list.forEach((e) => {
              if (
                e.counterCoin == this.pairCurrent.counterCoin &&
                e.baseCoin == this.pairCurrent.baseCoin
              ) {
                this.pairCurrent = e;
              }
            });
          } else {
            list.forEach((e) => {
              if (e.counterCoin == "BTC" && e.baseCoin == "USDT") {
                this.pairCurrent = e;
                this.pairCoin = e.counterCoin + "-" + e.baseCoin;
              }
            });
          }
          if (type) {
            this.pairList = list;
            return
          }
          let exchangeName = this.pairCurrent.exchangeName;
          if (exchangeName == "okexv5") {
            exchangeName = "okex";
          }
          this.showChart(
            exchangeName,
            this.pairCurrent.counterCoin,
            this.pairCurrent.baseCoin
          );
          this.queryStratgyParam();
          this.querylastIncrease();
          this.pairList = list;
          this.queryContractPrecision()
        }
      });
    },

    // 获取币对涨跌幅
    querylastIncrease() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeName: this.apiCurrent.exchangeId == "4" ? "Binance" : "OkexV5",
        transactionType: "contract_usdt",
        counterCoin: this.pairCurrent.counterCoin,
        baseCoin: this.pairCurrent.baseCoin,
      };
      querylastIncrease(payload).then((res) => {
        if (res.status == 200) {
          let result = res.data.result;
          result = JSON.parse(result);
          this.result = result;
          this.synchronSingelCloudApi();
        }
      });
    },
    // 同步资产
    synchronSingelCloudApi() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiCurrent.apiId,
      };
      synchronSingelCloudApi(payload).then((res) => {
        if (res.status == 200) {
          let dataList = null;
          for (let item in res.data) {
            dataList = res.data[item];
          }
          if (dataList.isExpire) {
            this.$message.warning(this.$t('m.API已过期,请重新录入'))
            return
          }
          if (this.transactionType == "contract_usdt") {
            let linerSwapAssets = dataList.linerSwapAssets;
            let assets = null;
            if (linerSwapAssets && linerSwapAssets.length > 0) {
              if (
                this.apiCurrent.exchangeId == "29" ||
                this.apiCurrent.exchangeName == "OkexV5"
              ) {
                assets = linerSwapAssets[0];
                assets.available = this.Public.toMathNum(assets.available);
                assets.currency = "USDT";
              } else {
                linerSwapAssets.forEach((e) => {
                  if (e.currency == this.pairCurrent.baseCoin) {
                    if (e.available.toString().indexOf(".") > -1) {
                      e.available = this.Public.toMathNum(e.available);
                    }
                    assets = e;
                  }
                });
              }
            }
            this.assets = assets;
          } else {
            let accountAssets = dataList.accountAssets;
            let assets = null;
            if (accountAssets && accountAssets.length > 0) {
              accountAssets.forEach((e) => {
                if (e.currency == this.pairCurrent.baseCoin) {
                  if (e.available.toString().indexOf(".") > -1) {
                    e.available = e.available
                      .toString()
                      .slice(0, e.available.toString().indexOf(".") + 7);
                  }
                  assets = e;
                }
              });
            }
            this.assets = assets;
          }
          this.queryCandlesticks(this.pairCurrent.exchangeName, this.pairCurrent.contractId)
        }
      });
    },
    // 获取网格策略参数
    queryStratgyParam() {
      let payload = {
        token: localStorage.getItem("token"),
        strategyId: "WL202207311426369634",
        startType:
          this.modelTab.value == 0
            ? "ai"
            : this.modelTab.value == 1
              ? "define"
              : "manyPair",
      };
      queryStratgyParam(payload).then((res) => {
        if (res.status == 200) {
          let stratgyParamMap = res.data.stratgyParamMap;
          let strategyConfig = JSON.parse(stratgyParamMap.strategyConfig);
          this.strategyConfig = strategyConfig;
          this.strategyName = stratgyParamMap.strategyName;
          let investLimit = JSON.parse(stratgyParamMap.investLimit);
          this.minInvest = investLimit.minInvest;
          this.publicData.maxBond.valueMax = investLimit.maxInvest;
          this.publicData.maxBond.valueMin = investLimit.minInvest;
          this.proposalInvest = investLimit.proposalInvest;
          this.strategyId = stratgyParamMap.strategyId;
        }
      });
    },
    // 创建机器人 修改机器人
    addCreateStrategy() {
      //创建机器人
      if (!this.publicData.strategyInput.value) {
        this.$message.error("请输入机器人名称");
        this.publicData.strategyInput.value = "DCA Bot";
        return;
      } else if (
        this.Public.countCharacters(this.publicData.strategyInput.value) > 20
      ) {
        return this.$message.error("机器人名称长度限制10个字符");
      } else if (!this.apiCurrent) {
        return this.$message.error("请选择账户");
      } else if (!this.pairCoin) {
        return this.$message.error("请选择交易对");
      }
      if (this.modelTab.value == 0) {
        //唐成需要的参数
        let strategyPayload = {
          userId: this.apiCurrent.userId, // 用户ID
          tradePair: {
            // 交易对
            exchange:
              this.apiCurrent.exchangeId == "4"
                ? "Binance"
                : this.apiCurrent.exchangeId == "29"
                  ? "OkexV5"
                  : "", // 交易所标识， sz-深圳交易所  sh-上海交易所  bj-北京交易所
            apiId: this.apiCurrent.apiId,
            // contractId: "BTCUSDT", // 股票代码
            contractId: this.pairCurrent.contractId, //合约
            coin: this.pairCurrent.baseCoin, // 交易币种
            leverRate: this.leverMultiple[this.leverValue.value], // 杠杆倍数
            type: "contract_usdt",
            id: "1",
          },
          strategy: {
            name: "contract_grid", // 策略名称
            params: {
              tradeOffset:
                this.publicData.direction.value == 0
                  ? 1
                  : this.publicData.direction.value == 1
                    ? 0
                    : this.publicData.direction.value,
              amount: this.copyAmount / this.pairCurrent.contractSize, // 单格投入
              spaceModel: 1, // 间隔模式，0-等比间隔  1-等差间隔
              spaceRate: null, // 间隔比例，单位%。（spaceModel=0时起作用）
              spaceDiff: this.Public.toMathNum(this.gridInterval), // 间隔价差（spaceModel=1时起作用）
              maxLongCount: Math.floor(this.longNum), //最大做多网格数（tradeOffset=1或者2时起作用）
              longOpenCount: 3, //最大开多活跃单数
              maxShortCount: Math.floor(this.longNum), //最大做空网格数（tradeOffset=0或者2时起作用）
              shortOpenCount: 3,//最大开空活跃单数 
              maxPrice: this.Public.toMathNum(this.openMax), //开仓价格上限
              minPrice: this.Public.toMathNum(this.openMin), //开仓价格下限
              maxUseInitMoney: this.publicData.maxBond.value, //最大初始保证金占用
            },
            options: {
              stopWithClose: 1, // 终止是否平仓，0-否 1-是
              stopWithCancel: 1, // 终止是否撤单，0-否 1-是
              triggerModel: 0, // 触发模式，0-立即触发 1-价格触发
              triggerPrice: null, // 触发价格，当triggerModel=1时，比如有值
              profit: null, // 总收益止盈
              loss: null, // 总收益止损
              maxPrice: null, // 价格终止上限
              minPrice: null, // 价格终止下限
            },
          },
        };
        let maxOrderNum = {
          maxShortCount: Math.floor(this.gridNum),
          maxLongCount: Math.floor(this.gridNum),
        }
        if (this.publicData.direction.value == 0) {
          maxOrderNum.maxShortCount = null;
        } else if (this.publicData.direction.value == 1) {
          maxOrderNum.maxLongCount = null;
        }
        let payload = {
          token: localStorage.getItem("token"),
          isStartStrategy: false,
          robotName: this.publicData.strategyInput.value,
          maxOrderNum: maxOrderNum,
          robotPattern:
            this.publicData.direction.value == 0
              ? 1
              : this.publicData.direction.value == 1
                ? 0
                : this.publicData.direction.value,
          bondPattern: this.publicData.MarginMode.value,
          apiId: this.apiCurrent.apiId,
          config: JSON.stringify(strategyPayload),
          advancedSetting: {
            profit: null, // 总收益止盈
            loss: null, // 总收益止损
            stopWithCancel: 1, // 终止时自动撤销委托
            stopWithClose: 1, // 终止时自动平仓
            triggerModel: 0, // 触发模式
            triggerPrice: null, // 触发价格
            maxPrice: null, // 价格终止上限
            minPrice: null, // 价格终止下限
          },
          transactionType: "contract_usdt",
          leverRate: this.leverMultiple[this.leverValue.value], //杠杆倍数
          transactionPair: this.pairCurrent.contractId, //交易对
          strategyId: this.strategyId,
          strategyName: this.strategyName,
          exchangeName: this.apiCurrent.exchangeId == "4" ? "Binance" : "OkexV5",
          pairType: 0,
          investCoin: this.pairCurrent.baseCoin,
          counterCoin: this.pairCurrent.counterCoin,
          baseCoin: this.pairCurrent.baseCoin,
          displayConfig: {
            contractSize: this.pairCurrent.contractSize,
            pairCurrent: this.pairCurrent,
            result: this.result,
          },
        };
        addRobot(payload).then((res) => {
          if (res.status == 200) {
            this.robotId = res.data.robotId;
            this.startRobotBtn(res.data.robotId);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        if (this.openPositionMax.value && this.openPositionMin.value && this.openPositionMin.value > this.openPositionMax.value) {
          this.$message.error(this.$t("m.上限应大于下限"));
          return
        }
        if (this.terminationMax.value && this.terminationMin.value && this.terminationMin.value > this.terminationMax.value) {
          this.$message.error(this.$t("m.上限应大于下限"));
          return
        }
        //唐成需要的参数
        let strategyPayload = {
          userId: this.apiCurrent.userId, // 用户ID
          tradePair: {
            // 交易对
            exchange:
              this.apiCurrent.exchangeId == "4"
                ? "Binance"
                : this.apiCurrent.exchangeId == "29"
                  ? "OkexV5"
                  : "", // 交易所标识， sz-深圳交易所  sh-上海交易所  bj-北京交易所
            apiId: this.apiCurrent.apiId,
            // contractId: "BTCUSDT", // 股票代码
            contractId: this.pairCurrent.contractId, //合约
            coin: this.pairCurrent.baseCoin, // 交易币种
            leverRate: this.leverMultiple[this.leverValue.value], // 杠杆倍数
            type: "contract_usdt",
            id: "1",
          },
          strategy: {
            name: "contract_grid", // 策略名称
            params: {
              tradeOffset:
                this.publicData.direction.value == 0
                  ? 1
                  : this.publicData.direction.value == 1
                    ? 0
                    : this.publicData.direction.value,
              amount: this.investment.value / this.pairCurrent.contractSize, // 单格投入
              spaceModel: this.intervalTab.value, // 间隔模式，0-等比间隔  1-等差间隔
              spaceRate: this.intervalTabValue1.value, // 间隔比例，单位%。（spaceModel=0时起作用）
              spaceDiff: this.intervalTabValue2.value, // 间隔价差（spaceModel=1时起作用）
              maxLongCount: this.maxLong.value, //最大做多网格数（tradeOffset=1或者2时起作用）
              longOpenCount: this.maxLongNumber.value, //最大开多活跃单数
              maxShortCount: this.maxEmpty.value, //最大做空网格数（tradeOffset=0或者2时起作用）
              shortOpenCount: this.maxEmptyNumber.value,//最大开空活跃单数 
              maxPrice: this.openPositionMax.value, //开仓价格上限
              minPrice: this.openPositionMin.value, //开仓价格下限
              maxUseInitMoney: this.publicData.maxBond.value, //最大初始保证金占用
            },
            options: {
              stopWithClose: this.publicData.checkboxCancel ? 1 : 0, // 终止是否平仓，0-否 1-是
              stopWithCancel: this.publicData.checkboxClose ? 1 : 0, // 终止是否撤单，0-否 1-是
              triggerModel: this.publicData.triggerModel.value ? 1 : 0, // 触发模式，0-立即触发 1-价格触发
              triggerPrice: this.publicData.triggerModel.value
                ? this.publicData.triggerModel.value
                : null, // 触发价格，当triggerModel=1时，比如有值
              profit: this.publicData.profitSurplus.value, // 总收益止盈
              loss: this.publicData.profitLoss.value, // 总收益止损
              maxPrice: this.terminationMax.value, // 价格终止上限
              minPrice: this.terminationMin.value, // 价格终止下限
            },
          },
        };
        let maxOrderNum = {
          maxShortCount: this.maxEmpty.value,
          maxLongCount: this.maxLong.value,
        };
        if (this.publicData.direction.value == 0) {
          maxOrderNum.maxShortCount = null;
          strategyPayload.strategy.params.maxShortCount = null;
        } else if (this.publicData.direction.value == 1) {
          maxOrderNum.maxLongCount = null;
          strategyPayload.strategy.params.maxLongCount = null;
        }
        if (this.intervalTab.value == 0) {
          if (!this.intervalTabValue1.value) {
            this.intervalTabValue1.redBorderStyle = true;
            this.$message.error(this.$t("m.网格间隔") + this.$t("m.参数出错"));
            document.getElementById("rightRef").scrollTop = 0;
            return;
          }
        } else {
          if (!this.intervalTabValue2.value || this.intervalTabValue2.value == 0) {
            this.intervalTabValue2.redBorderStyle = true;
            this.$message.error(this.$t("m.网格间隔") + this.$t("m.参数出错"));
            document.getElementById("rightRef").scrollTop = 0;
            return;
          }
        }
        let payload = {
          token: localStorage.getItem("token"),
          isStartStrategy: false,
          robotName: this.publicData.strategyInput.value,
          maxOrderNum: maxOrderNum,
          robotPattern:
            this.publicData.direction.value == 0
              ? 1
              : this.publicData.direction.value == 1
                ? 0
                : this.publicData.direction.value,
          bondPattern: this.publicData.MarginMode.value,
          apiId: this.apiCurrent.apiId,
          config: JSON.stringify(strategyPayload),
          advancedSetting: {
            profit: this.publicData.profitSurplus.value, // 总收益止盈
            loss: this.publicData.profitLoss.value, // 总收益止损
            stopWithCancel: this.publicData.checkboxCancel ? 1 : 0, // 终止时自动撤销委托
            stopWithClose: this.publicData.checkboxClose ? 1 : 0, // 终止时自动平仓
            triggerModel: this.publicData.triggerModel.value ? 1 : 0, // 触发模式
            triggerPrice: this.publicData.triggerModel.value
              ? this.publicData.triggerModel.value
              : null, // 触发价格
            maxPrice: 0, // 价格终止上限
            minPrice: 0, // 价格终止下限
          },
          transactionType: "contract_usdt",
          leverRate: this.leverMultiple[this.leverValue.value], //杠杆倍数
          transactionPair: this.pairCurrent.contractId, //交易对
          strategyId: this.strategyId,
          strategyName: this.strategyName,
          exchangeName: this.apiCurrent.exchangeId == "4" ? "Binance" : "OkexV5",
          pairType: 0,
          investCoin: this.pairCurrent.baseCoin,
          counterCoin: this.pairCurrent.counterCoin,
          baseCoin: this.pairCurrent.baseCoin,
          displayConfig: {
            contractSize: this.pairCurrent.contractSize,
            pairCurrent: this.pairCurrent,
            result: this.result,
          },
        };

        //修改机器人
        if (this.copyStrategy) {
          let upDataPayload = {
            ...payload,
            robotId: this.copyStrategy.robotId,
            robotName: this.publicData.strategyInput.value,
          };
          updataRobot(upDataPayload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t("m.修改成功"));
              this.qureyExecuteStrategyById(this.copyStrategy.executeId);
              this.clearStratgy();
              this.copyStrategy = null;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          addRobot(payload).then((res) => {
            if (res.status == 200) {
              this.robotId = res.data.robotId;
              this.startRobotBtn(res.data.robotId);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }

    },
    // 清空参数
    clearStratgy() {
      this.publicData.strategyInput.value = 'Grid bot'
      this.publicData.direction.value = 0;
      this.intervalTab.value = 0; // 间隔模式，0-等比间隔  1-等差间隔
      this.intervalTabValue1.value = null; // 间隔比例，单位%。（spaceModel=0时起作用）
      this.intervalTabValue2.value = null; // 间隔价差（spaceModel=1时起作用）
      this.maxLong.value = 1; //最大做多网格数（tradeOffset=1或者2时起作用）
      this.maxEmpty.value = 1; //最大做空网格数（tradeOffset=0或者2时起作用） 

      this.maxEmptyNumber.value = 3; //最大开空活跃单数 
      this.maxLongNumber.value = 3; //最大开多活跃单数
      this.openPositionMax.value = null; //开仓价格上限
      this.openPositionMin.value = null; //开仓价格下限
      this.publicData.maxBond.value = null; //最大初始保证金占用

      this.publicData.checkboxCancel = true; // 终止是否平仓，0-否 1-是
      this.publicData.checkboxClose = true; // 终止是否撤单，0-否 1-是
      this.publicData.triggerModel.value = null; // 触发模式，0-立即触发 1-价格触发
      this.publicData.triggerModel.value = null; // 触发价格，当triggerModel=1时，比如有值
      this.publicData.profitSurplus.value = null; // 总收益止盈
      this.publicData.profitLoss.value = null; // 总收益止损
      this.terminationMax.value = null; // 价格终止上限
      this.terminationMin.value = null; // 价格终止下限
      setTimeout(() => {
        this.intervalTabValue1.redBorderStyle = false;
        this.intervalTabValue2.redBorderStyle = false;
      }, 100);

      let index = null
      if (this.pairCurrent.contractSize.indexOf(".") > -1) {
        index =
          this.pairCurrent.contractSize.length -
          this.pairCurrent.contractSize.indexOf(".");
      } else {
        index = 1;
      }
      if (this.pairCurrent.exchangeName == "binance") {
        let value = 10 / this.result.last;
        if (index == 1) {
          value = Math.ceil(value);
        } else {
          value =
            Math.ceil(value * Math.pow(10, index - 1)) /
            Math.pow(10, index - 1);
        }
        if (Number(value) == 0) {
          this.investment.value = this.pairCurrent.contractSize;
        } else {
          this.investment.value = value.toString();
        }
      } else {
        this.investment.value = this.pairCurrent.contractSize;
      }
    },
    // 复制参数 ||  取消修改
    copyStrategyBtn() {
      if (this.copyStrategy) {
        this.copyStrategy = null;
        this.clearStratgy();
        this.getUserApiList();
        return
      }
      this.modelTab.value = 1
      this.investment.value = this.copyAmount; // 单格投入
      this.intervalTab.value = 1; // 间隔模式，0-等比间隔  1-等差间隔
      this.intervalTabValue2.redBorderStyle = false;
      this.intervalTabValue1.value = null; // 间隔比例，单位%。（spaceModel=0时起作用）
      this.intervalTabValue2.value = this.Public.toMathNum(this.gridInterval); // 间隔价差（spaceModel=1时起作用）
      this.maxLong.value = Math.floor(this.longNum); //最大做多网格数（tradeOffset=1或者2时起作用）
      this.maxEmpty.value = Math.floor(this.longNum); //最大做空网格数（tradeOffset=0或者2时起作用）
      this.maxEmptyNumber.value = 3,//最大开空活跃单数 
        this.maxLongNumber.value = 3, //最大开多活跃单数
        this.openPositionMax.value = this.Public.toMathNum(this.openMax); //开仓价格上限
      this.openPositionMin.value = this.Public.toMathNum(this.openMin); //开仓价格下限
      this.advancedShow = true;

      this.publicData.checkboxCancel = true
      this.publicData.checkboxClose = true
      this.publicData.triggerModel.value = null; // 触发价格，当triggerModel=1时，比如有值
      this.publicData.profitSurplus.value = null; // 总收益止盈
      this.publicData.profitLoss.value = null; // 总收益止损
      this.terminationMax.value = null; // 价格终止上限
      this.terminationMin.value = null; // 价格终止下限
    },
    //编辑按钮
    editManualClick(item) {
      this.modelTab.value = 1
      let payload = {
        token: localStorage.getItem("token"),
        robotId: item.robotId,
      };
      queryRobotDetails(payload).then((res) => {
        if (res.status == 200) {
          let robot = res.data.robot;
          let config = JSON.parse(robot.config);
          let displayConfig = JSON.parse(robot.displayConfig)
          this.copyStrategy = {
            baseCoin: robot.baseCoin,
            counterCoin: robot.counterCoin,
            strategy: config.strategy,
            tradePair: config.tradePair,
            apiId: robot.apiId,
            disabled: true,
            robotId: item.robotId,
            executeId: item.executeId,
          };
          let params = config.strategy.params;
          let options = config.strategy.options;

          this.publicData.direction.value =
            params.tradeOffset == 0
              ? 1
              : params.tradeOffset == 1
                ? 0
                : params.tradeOffset;
          this.investment.value = params.amount * displayConfig.contractSize; // 单格投入
          this.intervalTab.value = params.spaceModel; // 间隔模式，0-等比间隔  1-等差间隔
          this.intervalTabValue1.value = params.spaceRate; // 间隔比例，单位%。（spaceModel=0时起作用）
          this.intervalTabValue2.value = params.spaceDiff; // 间隔价差（spaceModel=1时起作用）
          this.maxLong.value = params.maxLongCount; //最大做多网格数（tradeOffset=1或者2时起作用）
          this.maxEmpty.value = params.maxShortCount; //最大做空网格数（tradeOffset=0或者2时起作用）
          this.maxEmptyNumber.value = params.shortOpenCount,//最大开空活跃单数 
            this.maxLongNumber.value = params.longOpenCount, //最大开多活跃单数
            this.openPositionMax.value = params.maxPrice; //开仓价格上限
          this.openPositionMin.value = params.minPrice; //开仓价格下限
          this.publicData.maxBond.value = params.maxUseInitMoney; //最大初始保证金占用

          this.publicData.checkboxCancel =
            options.stopWithClose == 1 ? true : false; // 终止是否平仓，0-否 1-是
          this.publicData.checkboxClose =
            options.stopWithCancel == 1 ? true : false; // 终止是否撤单，0-否 1-是
          this.publicData.triggerModel.value =
            options.triggerModel == 1 ? true : false; // 触发模式，0-立即触发 1-价格触发
          this.publicData.triggerModel.value = options.triggerPrice
            ? options.triggerPrice
            : null; // 触发价格，当triggerModel=1时，比如有值
          this.publicData.profitSurplus.value = options.profit; // 总收益止盈
          this.publicData.profitLoss.value = options.loss; // 总收益止损
          this.terminationMax.value = options.maxPrice; // 价格终止上限
          this.terminationMin.value = options.minPrice; // 价格终止下限

          this.publicData.strategyInput.value = robot.robotName //机器人名称

          this.apiList.forEach((e) => {
            if (this.copyStrategy.apiId == e.apiId) {
              this.apiCurrentName = e.apiName;
              this.apiCurrent = e;
            }
          });
          for (let i in this.leverMultiple) {
            if (this.leverMultiple[i] == this.copyStrategy.tradePair.leverRate) {
              this.leverValue.value = i; //杠杆倍数
            }
          }
          this.result = displayConfig.result
          this.pairCurrent = displayConfig.pairCurrent
          this.pairCoin = displayConfig.pairCurrent.counterCoin + "-" + displayConfig.pairCurrent.baseCoin;
          this.synchronSingelCloudApi()
          let rightBox = document.getElementById("rightRef");
          rightBox.scrollTop = 0
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 转跳到机器人列表页
    startRobotBtn(v) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: v,
      };
      startRobot(payload).then((res) => {
        if (res.status == 200) {
          // this.$message.success(this.$t("m.启动成功"));
          this.$message.success(this.$t("m.机器人创建成功"));
          this.qureyExecuteStrategyList()
          this.clearStratgy();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //成交记录按钮
    qureyStrategyOrderList(item) {
      this.strategyOrderList = item;
      this.qureyStrategyOrderShow = true;
    },

    // 划转按钮
    transferShowBtn(item) {
      this.publicData.transferShow.value = 1;
      this.apiCurrent = item
    },
    // 左边实例滚动条
    scrollLeftBtn(e) {
      let leftBox = document.getElementById("leftRef");
      let rightBox = document.getElementById("rightRef");
      if (e.wheelDeltaY > 0) {
        if (rightBox.scrollTop !== 0) {
          // rightBox.scrollTop -= 130;
        } else {
          leftBox.scrollTop -= 120;
        }
      } else {
        if (
          rightBox.scrollHeight - rightBox.clientHeight - rightBox.scrollTop !==
          0
        ) {
          // rightBox.scrollTop += 130;
        } else {
          leftBox.scrollTop += 120;
        }
      }
    },
    // 右边参数滚动条
    scrollRightBtn(e) {
      let leftBox = document.getElementById("leftRef");
      let rightBox = document.getElementById("rightRef");
      if (rightBox.scrollTop == 0 &&
        e.wheelDeltaY > 0) {
        leftBox.scrollTop -= 130;
      } else if (
        rightBox.scrollHeight - rightBox.clientHeight - rightBox.scrollTop ==
        0 &&
        e.wheelDeltaY < 0
      ) {
        leftBox.scrollTop += 130;
      }
    },
    // 实例列表
    qureyExecuteStrategyList() {
      this.$store.commit("setIsSkeleton", true);
      let payload = {
        token: localStorage.getItem("token"),
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        strategyId: "WL202207311426369634",
      };
      if (this.timeShow !== 0) {
        payload.sortField = 'startTime'
        payload.descIsAsc = this.timeShow == 1 ? 'desc' : 'asc'
      } else {
        if (this.priceShow !== 0) {
          payload.sortField = 'incomeUsdtNum'
          payload.descIsAsc = this.priceShow == 1 ? 'desc' : 'asc'
        } else {
          payload.sortField = 'startTime'
          payload.descIsAsc = 'desc'
        }
      }
      qureyExecuteStrategyList(payload).then((res) => {
        if (res.status == 200) {
          if (this.robotName) {
            this.totalCopy = Number(res.data.countNum);
          } else {
            this.totalCopy = null;
            this.total = Number(res.data.countNum);
          }
          let executeStrategyList = res.data.executeStrategyList;
          if (executeStrategyList.length == 0) {
            return (this.executeStrategyList = null);
          }
          executeStrategyList.forEach((e) => {
            e.maxOrderNum = JSON.parse(e.maxOrderNum);
            e.contractSize = JSON.parse(e.position).contractSize;
            e.position = JSON.parse(e.position);
            if (e.robotPattern == 0 || e.robotPattern == 2) {
              if (
                e.position.position.sellPosition.costHold * 10 <
                e.liquidationPrice
              ) {
                e.liquidationPrice = 0;
              }
            } else {
              if (
                e.position.position.buyPosition.costHold * 10 <
                e.liquidationPrice
              ) {
                e.liquidationPrice = 0;
              }
            }
          });
          this.executeStrategyList = executeStrategyList;
        } else {
          this.$message.error(res.msg);
        }
        setTimeout(() => {
          this.$store.commit("setIsSkeleton", false);
        }, 500);
      });
    },
    // 终止机器人 弹框
    robotEndBtn(item) {
      this.robotId = item.robotId;
      this.robotItem = item;
      this.endVisible = true;
    },

    // 终止机器人
    endRobotBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
      };
      robotEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.终止成功"));
          this.endVisible = false;
          this.robotId = null;
          this.clearStratgy()
          this.copyStrategy = null
          setTimeout(() => {
            this.qureyExecuteStrategyById(this.robotItem.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //重启机器人
    restartRobot(item) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: item.robotId,
      };
      restartRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.重启成功"));
          setTimeout(() => {
            this.qureyExecuteStrategyById(item.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 暂停机器人
    suspendRobot(item) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: item.robotId,
      };
      suspendRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.暂停成功"));
          setTimeout(() => {
            this.qureyExecuteStrategyById(item.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 刷新单条实例
    qureyExecuteStrategyById(id, type) {
      let payload = {
        token: localStorage.getItem("token"),
        executeId: id,
      };
      qureyExecuteStrategyById(payload).then((res) => {
        if (res.status == 200) {
          this.executeStrategyList.forEach((e, idx) => {
            if (e.executeId == res.data.executeStrategy.executeId) {
              e = JSON.parse(JSON.stringify(res.data.executeStrategy));
              e.maxOrderNum = JSON.parse(e.maxOrderNum);
              e.position = JSON.parse(e.position);
              e.contractSize = e.position.contractSize;
              if (e.robotPattern == 0 || e.robotPattern == 2) {
                if (
                  e.position.position.sellPosition.costHold * 10 <
                  e.liquidationPrice
                ) {
                  e.liquidationPrice = 0;
                }
              } else {
                if (
                  e.position.position.buyPosition.costHold * 10 <
                  e.liquidationPrice
                ) {
                  e.liquidationPrice = 0;
                }
              }
              this.$set(this.executeStrategyList, idx, e);
            }
          });

          this.$forceUpdate();
          if (type) {
            this.$message.success(this.$t("m.刷新成功"));
          }
        } else {
          if (res.status == 1026) {
            this.$message.error(this.$t('m.实例已终止'))
          } else {
            this.$message.error(res.msg);
          }
          this.qureyExecuteStrategyList()
        }
      });

      // this.qureyExecuteStrategyList();
    },
    // 计算执行时间
    formatDuring(start) {
      let New = new Date().getTime();
      let mss = New - start;
      var day = parseInt(mss / (1000 * 24 * 60 * 60));
      var hours = parseInt((mss % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      var str =
        day +
        this.$t("m.天") +
        hours +
        this.$t("m.时") +
        minutes +
        this.$t("m.分");
      return str;
    },
    // 转跳到详情页
    strategyOrder(item) {
      this.$router.push({ path: "/contractStrategy/contractDetails" });
      localStorage.setItem("robotData", JSON.stringify(item));
    },
    //高级设置按钮
    advancedShowBtn() {
      this.advancedShow = !this.advancedShow;
      setTimeout(() => {
        this.RightHeight = this.$refs.rightRef.offsetHeight;
      }, 500);
    },

    // 跳网格
    toGrid(item) {
      this.gridShow = true;
      setTimeout(() => {
        this.$refs.child.qureyCellsData(item);
      }, 500);
    },
    // 历史实例列表
    qureyHistoryStrategyList() {
      let payload = {
        token: localStorage.getItem("token"),
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        strategyId: "WL202207311426369634",
      };
      if (this.timeShow !== 0) {
        payload.sortField = 'endTime'
        payload.descIsAsc = this.timeShow == 1 ? "asc" : 'desc'
      } else {
        if (this.priceShow !== 0) {
          payload.sortField = 'incomeUsdtNum'
          payload.descIsAsc = this.priceShow == 1 ? "asc" : 'desc'
        } else {
          payload.sortField = 'endTime'
          payload.descIsAsc = 'desc'
        }
      }
      qureyHistoryStrategyList(payload).then((res) => {
        if (res.status == 200) {
          let historyStrategyList = res.data.historyStrategyList;
          historyStrategyList.forEach((e) => {
            let contract = JSON.parse(
              JSON.parse(e.result).pairs[0].data.contract
            );
            e.contractSize = contract.pieceVal;
            e.counterCoin = contract.counterCoin;
            e.baseCoin = contract.baseCoin;
            e.realEarnings = this.Public.toMathNum(e.realEarnings);
          });
          this.total = Number(res.data.countNum);
          this.historyStrategyList = historyStrategyList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 删除历史实例
    deleteHistoryStrategy(item) {
      let _this = this;
      this.$confirm(_this.$t("m.确定删除") + "？", _this.$t("m.提示"), {
        confirmButtonText: _this.$t("m.确定"),
        cancelButtonText: _this.$t("m.取消"),
        closeOnClickModal: false,
      })
        .then(() => {
          let payload = {
            token: localStorage.getItem("token"),
            executeId: item.executeId,
            strategyId: "WL202207311426369634",
          };
          deleteHistoryStrategy(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t("m.删除成功"));
              this.qureyHistoryStrategyList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => { });
    },
    // k线图
    showChart(exchange, counterCoin, baseCoin) {
      var symbol = (exchange + ":" + counterCoin + baseCoin).toUpperCase();
      if (this.tradingViewEle) {
        this.tradingViewEle.options.symbol = symbol;
        this.tradingViewEle.reload();
      } else {
        var width = document.getElementById("tradingViewChart").style.width;
        this.tradingViewEle = new TradingView.widget({
          width: width,
          height: 345,
          symbol: symbol,
          interval: "5",
          timezone: "Asia/Shanghai",
          theme: "dark",
          style: "1",
          locale: "zh_CN",
          toolbar_bg: "#1B232E",
          enable_publishing: false,
          studies: ["Volume@tv-basicstudies"],
          container_id: "tradingview_1662d",
        });
        window.tradingViewEle = this.tradingViewEle;
      }
    },

    // 查询K线
    queryCandlesticks(exchangeName, contractId) {
      let payload = {
        token: localStorage.getItem('token'),
        exchangeName: exchangeName,
        contractId: contractId,
      }
      queryCandlesticks(payload).then((res) => {
        if (res.status == 200) {
          let list = []
          let lists = null
          if (exchangeName == 'okexv5') {
            lists = res.data.candlesticks
            lists.forEach(e => {
              list.unshift(e)
            })
          } else {
            list = res.data.candlesticks
          }

          // 上下限  MA + 布林线_标准差*3  
          let openMax = this.stdMa(list, 2) + this.stdDeviation(list, this.stdMa(list)) * 3
          let openMin = this.stdMa(list, 2) - this.stdDeviation(list, this.stdMa(list)) * 3
          this.openMax = openMax.toString().indexOf('.') > -1 ? Number(openMax.toString().slice(0, openMax.toString().indexOf('.') + Number(this.contractPrecision.pricePrecision)) + 1) : openMax
          this.openMin = openMin.toString().indexOf('.') > -1 ? Number(openMin.toString().slice(0, openMin.toString().indexOf('.') + Number(this.contractPrecision.pricePrecision)) + 1) : openMin
          this.openMin = this.openMin > 0 ? this.openMin : this.openMin = 0
          // 网格间隔（等差）= ATR*0.2
          this.gridInterval = this.sedATR(list, exchangeName) * 0.2
          this.gridInterval = this.gridInterval.toString().indexOf('.') > -1 ? Number(this.gridInterval.toString().slice(0, this.gridInterval.toString().indexOf('.') + Number(this.contractPrecision.pricePrecision)) + 1) : this.gridInterval

          // 网格数量 =（最高价-最低价）/ (ATR*0.2)
          this.gridNum = (this.openMax - this.openMin) / (this.sedATR(list, exchangeName) * 0.2)
          this.amountBtn()

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 
    amountBtn() {
      // 单格投入 = Max（最大初始保证金占用 * 杠杆 /（网格数量 * 当前价），最小值）

      // 最大做多 / 做空网格数 = Min（5 * 网格数量 / 杠杆，网格数量）；

      // 单格投入
      let copyAmount = null
      let availableNum = this.publicData.maxBond.value ? this.publicData.maxBond.value : this.assets.available
      if (availableNum == 0) {
        return this.longNum = 0
      }
      copyAmount = Math.floor(availableNum * this.leverMultiple[this.leverValue.value] / (this.gridNum * this.result.last) / this.pairCurrent.contractSize) * this.pairCurrent.contractSize
      if (this.apiCurrent.exchangeId == 4 || this.apiCurrent.exchangeId == 30) {
        let contractSizeCopy = Math.max(Math.ceil(10 / this.result.last / this.pairCurrent.contractSize) * this.pairCurrent.contractSize, this.pairCurrent.contractSize)
        this.copyAmount = Math.max(this.Public.toMathNum(copyAmount), this.Public.toMathNum(contractSizeCopy))
      } else {
        this.copyAmount = Math.max(this.Public.toMathNum(copyAmount), this.pairCurrent.contractSize)
      }

      let longNum = null
      if (this.copyAmount == this.pairCurrent.contractSize) {
        // 最大做多 / 做空网格数 = Min（5 * 最大初始保证金占用 /（当前价 * 最小值），网格数量）。
        longNum = Math.min(5 * availableNum / (this.result.last * this.pairCurrent.contractSize), this.gridNum)
      } else {
        // 最大做多/做空网格数 = Min（5*网格数量 / 杠杆，网格数量）；
        longNum = Math.min(5 * this.gridNum / this.leverMultiple[this.leverValue.value], this.gridNum)
      }
      this.longNum = longNum


      // 当 单格投入 = 最小值 时，
      // 当 单格投入 = 最大初始保证金占用*杠杆 /（网格数量*当前价）时，

    },
    // 计算MA
    stdMa(list, n, exchange) {
      let MA
      let MaNum = 0
      if (n) {
        let one = list[18]
        let two = list[19]
        for (let val in one) {
          if (val == 4) {
            MaNum += Number(one[val])
          }
        }
        for (let val in two) {
          if (val == 4) {
            MaNum += Number(two[val])
          }
        }
        MA = MaNum / 2
      } else {
        for (let key of list) {
          for (let val in key) {
            if (val == 4) {
              MaNum += Number(key[val])
            }
          }
        }
        MA = MaNum / list.length
      }



      return MA
    },

    //计算ATR
    sedATR(list, exchange) {
      // 收市价   okex  4  
      // 当日最高价  okex 2
      // 当日最低价  okex 3 

      let ATR
      let TRI = 0  //TRi = max(Hi,Ci-1)-min(Li,Ci-1,'1111111111111111')

      for (let i = 6; i < list.length; i++) {
        let closing = 0 // 收市价
        let min = 0  //最低价
        let max = 0  //最高价
        for (let val in list[i - 1]) {
          if (val == 4) {
            closing = Number(list[i - 1][val])
          }
        }

        for (let val in list[i]) {
          if (val == 2) {
            max = Number(list[i][val])
          }

          if (val == 3) {
            min = Number(list[i][val])
          }
        }
        // TRI+= (max>closing -1 ?max :closing -1) - (min<closing -1 ?min :closing -1)
        TRI += Math.max(max, closing) - Math.min(min, closing)
      }
      ATR = TRI / 14
      return ATR
    },

    // boll 标准差
    stdDeviation(list, MA, exchange) {
      let bollDeviation
      let num = 0
      for (let key of list) {
        for (let val in key) {
          let C = 0  //收市价
          if (val == 4) {
            C = Number(key[val])
            num += Math.pow(C - MA, 2)
          }
        }
      }
      bollDeviation = num / list.length
      return Math.sqrt(bollDeviation)
    },
    timeSort() {
      this.timeShow++
      this.priceShow = 0
      this.timeShow = this.timeShow % 3
      if (this.tabIndex == 1) {
        this.qureyHistoryStrategyList()
      } else {
        this.qureyExecuteStrategyList()
      }
    },
    priceSort() {
      this.priceShow++
      this.timeShow = 0
      this.priceShow = this.priceShow % 3
      if (this.tabIndex == 1) {
        this.qureyHistoryStrategyList()
      } else {
        this.qureyExecuteStrategyList()
      }
    },
    apiVisibleChange(item) {
      if (item && this.pairList.length < 5) {
        this.getContractPairList('type')
      }
    },
    // 获取币对价格精度
    queryContractPrecision() {
      let payload = {
        token: localStorage.getItem('token'),
        exchangeName: this.apiCurrent.exchangeName,
        contractIdStr: this.pairCurrent.contractId
      }
      queryContractPrecision(payload).then(res => {
        if (res.status == 200) {
          let contractPrecisionList = res.data.contractPrecision
          let contractPrecision = null
          for (let item in contractPrecisionList) {
            contractPrecision = contractPrecisionList[item];
          }
          if (this.apiCurrent.exchangeId == 29) {
            if (contractPrecision.pricePrecision.indexOf(".") > -1) {
              contractPrecision.pricePrecision =
                contractPrecision.pricePrecision.length -
                contractPrecision.pricePrecision.indexOf(".")
            } else {
              contractPrecision.pricePrecision = 1
            }
          }
          this.contractPrecision = contractPrecision
          this.investment.pairUsdtSize = contractPrecision.pricePrecision
          this.terminationMax.pairUsdtSize = contractPrecision.pricePrecision
          this.terminationMin.pairUsdtSize = contractPrecision.pricePrecision
          this.openPositionMax.pairUsdtSize = contractPrecision.pricePrecision
          this.openPositionMin.pairUsdtSize = contractPrecision.pricePrecision
          this.publicData.triggerModel.pairUsdtSize = contractPrecision.pricePrecision
          this.publicData.profitLoss.pairUsdtSize = contractPrecision.pricePrecision
          this.publicData.profitSurplus.pairUsdtSize = contractPrecision.pricePrecision
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //解释文案
    openLimitsAlert() {
      this.$alert(
        this.$store.state.lang == 'ch'
          ? "下限:低于此价格机器人将终止运行。上限:高于此价格机器人将终止运行。"
          : "Lower limit:The bot will stop running when below this price。Upper limit:The bot will stop running when beyond this price。",
        "",
        {
          confirmButtonText: this.$t("m.确定"),
          callback: (action) => { },
        }
      );
    },
    toContractCourse() {
      window.open("https://intercom.help/vtrading/zh-CN/articles/6161067-%E7%BD%91%E6%A0%BC%E5%A7%94%E6%89%98%E5%B7%A5%E5%85%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C");
    }
  },
  mounted() {
    this.getUserApiList();
    this.qureyExecuteStrategyList();
    let rightBox = document.getElementById("rightRef");
    this.RightHeight = rightBox.scrollHeight - 60;
    let leftBox = document.getElementById("leftRef");
    leftBox.style.overflow = 'hidden'
  },
  created() {
    this.publicData.strategyInput.value = 'Grid Bot'
    this.publicData.direction.value = 0
  },
};
