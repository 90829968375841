<template>
  <div class="create-box">
    <div class="title">{{ $t("m.参数") }}</div>
    <div class="createFlex">
      <template v-for="item in dataList">
        <!-- <template v-if="item.id == 1 || item.id == 2">
          <div class="create-item" v-if="item.type == 'number-input'">
            <div class="flex-average" style="margin: 20px 0 0 0">
              <div class="create-title">StrategyName</div>
              <div class="minBox">
                <div class="box-smlBox">min</div>
                <InputIconBox style="margin: 0" icon="BTC" />
              </div>
              <div style="margin: 4px 0 10px">~~600btc</div>
            </div>
          </div>
          <div class="create-item" v-if="item.type == 'radio-number-input'">
            <TabSwitchClick
              title="DealStartTrigger"
              :dataList="item.radioName"
              inputType="true"
            />
          </div>
        </template> -->
        <div class="create-item" v-if="item.id == 1">
          <div class="flex-average" style="margin: 20px 0 0 0">
            <InputIconBox
              minIcon="true"
              :item="item"
              style="margin: 0"
              equivalent="true"
              :result="result"
              :pairCurrent="pairCurrent"
            />
          </div>
        </div>
        <div class="create-item" v-if="item.id == 2">
          <TabSwitchClick
            title="DealStartTrigger"
            :dataList="item"
            inputType="true"
          />
        </div>
        <div class="create-item" v-if="item.id == 3">
          <TabSwitchClick
            :title="item.name"
            :dataList="item"
            class="create-mrg"
            :switchClick.sync="replenishmentShow"
          />
        </div>
        <div
          class="create-item"
          v-if="
            (item.id == 5 && replenishmentShow == 0) ||
            (item.id == 6 && replenishmentShow == 0) ||
            item.id == 10 ||
            item.id == 12 ||
            (item.id == 13 && orderModelShow == 0) ||
            (item.id == 14 && directionShow !== 1) ||
            (item.id == 15 && directionShow !== 0)
          "
        >
          <Slider :dataList="item" />
        </div>
        <div class="create-item" v-if="item.id == 7 && replenishmentShow == 1">
          <InputIconBox
            :item="item"
            :result="result"
            :pairCurrent="pairCurrent"
          />
        </div>

        <!--预埋单补仓-->
        <div class="create-item" v-if="item.id == 8">
          <TabSwitchClick
            :title="item.name"
            :dataList="item"
            class="create-mrg"
            :switchClick.sync="orderModelShow"
            @change="directionChange"
          />
        </div>
        <div class="create-item" v-if="item.id == 16">
          <InputIconBox :item="item" />
        </div>
        <div
          class="create-item"
          v-if="
            item.id == 1 ||
            item.id == 3 ||
            item.id == 8 ||
            item.id == 10 ||
            (item.id == 7 && replenishmentShow == 1) ||
            (item.id == 13 && orderModelShow !== 0) ||
            (item.id == 14 && directionShow == 0) ||
            (item.id == 15 && directionShow == 1)
          "
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
import TabSwitchClick from "../../../components/TabSwitchClick";
import InputIconBox from "../../../components/InputIconBox";
import Slider from "../../../components/Slider";
export default {
  components: {
    TabSwitchClick,
    InputIconBox,
    Slider,
  },
  props: ["dataList", "directionShow", "result", "pairCurrent",'apiCurrent'],
  watch: {
    dataList() {
      this.dataList.forEach((e) => {
        if (e.name == "补仓数量模式") {
          this.replenishmentShow = e.value;
        } else if (e.name == "补仓下单模式") {
          this.orderModelShow = e.value;
        }
      });
    },

    apiCurrent() {
      this.dataList.forEach((e) => {
        if (e.id == 8) {
          e.value = 0;
        } 
      });
    }
  },
  data() {
    return {
      stopLossValue: null,
      stopLossShow: true,
      checkboxCancel: false,
      checkboxClose: true,
      leverShow: false, //调整杠杆
      leverValue: 1, //杠杆倍数
      leverList: [1, 2, 3, 5, 10, 20, 50],
      apiList: null, //用户api列表
      apiChangeId: null, //选中的用户交易所
      pairList: null, //交易对列表
      pairCoin: null, //选中的交易对
      strategyConfig: null, //策略参数
      replenishmentShow: 0, //补仓数量
      orderModelShow: 0, //补仓下单
      stopSurplusShow: 0, //止盈下单模式
    };
  },
  methods: {

    directionChange(index, back) {

      //CoinTR不支持切换操作
      if (!this.apiCurrent || this.apiCurrent.exchangeId!=30 || index!=1) return;
      this.$message.warning("CoinTr不支持该操作");
      back();
    },
  },
  created() {},
  mounted() {
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.create-box {
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;

  color: #999fb4;
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  .createFlex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .create-item {
      width: 48%;
    }
  }
  .create-style {
    display: flex;
    .create-first {
      width: 306px;
    }
    .create-special {
      height: 36px;
      line-height: 36px;
      color: #2670FA;
    }
  }
  .title {
    @include themeify {
      color: themed("primaryColor") !important;
    }
  }
}
.create-flex {
  display: flex;
}
.flex-average {
  flex: 1;
}
.create-mrg {
  margin-right: 30px;
  flex: 1;
}
</style>