<template>
  <div class="newsSearch" ref="newsSearch">
    <!--导航栏-->
    <TopNavigation :menu="2" />

    <component v-if="current"
    :is="'NewsSearch'+current" 

    :title="title"

    :keyWord="keyWord"
    :pageNo="pageNo"
    :pageSize="pageSize"
    :totalNum="totalNum"
    :datas="datas"

    @page="pageClick"
    @cell="cellClick"
    @search="searchClick" />

    <Bottom />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {getShowPage} from "@/utils/function.js"

import news from "./components/news.js"
import {articleList} from "@/request/api.js"

import TopNavigation from "@/components/TopNavigation.vue";
import Bottom from "@/components/Bottom.vue";
import NewsSearchPc from "./newsSearchPc"
import NewsSearchH5 from "./newsSearchH5"
export default {
  components: {
    TopNavigation,
    Bottom,
    NewsSearchPc: NewsSearchPc,
    NewsSearchH5: NewsSearchH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件
      title: '搜索结果',

      keyWord:'', //搜索关键字

      pageNo: 1, //页码
      pageSize: 6, //每页获取数量
      totalNum:0 , //总文章数量
      datas:[], //文章列表

      langId: 3, //文章固定死只有英文版本2024.5.8
    };
  },

  computed: {
    // ...mapGetters(['langId']),  //语言ID
  },

  watch: {

    langId(newVal, oldVal) {
      news.delectList();
      this.pageClick(1);
    }
  },

  created() {
    this.initView();

    this.pageNo = news.getSearchPage(this.current);
    this.keyWord = news.getSearchKey();
    this.pageClick(this.pageNo);
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {

    //初始化界面判断是H5 还是  PC
    initView() {

      var current = this.current;
      this.pageSize = getShowPage(6, 10);
      this.current = getShowPage('Pc', 'H5');
      if (current && current != this.current) {
        this.pageClick(1);
      }
    },

    //初始化数据
    initData(data) {

      this.totalNum = data.count;

      var datas = data.list;
      datas.forEach((item)=>{
        item.timeCn = this.Public.getTime(item.createTime, 'y/M/d');
        item.timeEn = this.Public.getTimeEn(item.createTime)
      })
      this.datas = datas;
    },

    /******************************************** https */
    //文章列表
    httpArticleList() {

      var param = {
        'pageNo': this.pageNo, 
        'pageSize':this.pageSize, 
        'languageId': this.langId,
        'keyWord':this.keyWord}
      articleList(param).then((res) => {
        
        //切换语言，忽略上次请求
        if (param.languageId != this.langId) return;
        
        if (res.status == 200) {
          
          var data = res.data;
          this.initData(data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    /******************************************** 点击 */
    //页码点击
    pageClick(page) {
      this.pageNo = page;
      news.setSearchPage(page, this.current);
      this.$refs.newsSearch && this.$refs.newsSearch.scrollTo(0,0);
      this.httpArticleList();
    },

    //cell点击
    cellClick(item) {
      
      news.setInfoId(item.id);
      news.setInfoBack({'url':'newsSearch', 'title':this.title})
      this.$router.push({ name: 'newsInfo'});
    },

    //搜索
    searchClick(keyWord) {

      this.keyWord = keyWord;
      news.setSearchKey(keyWord);
      this.pageClick(1);
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.newsSearch {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
