<template>
  <div class="aggregateAcc">
    <!-- @click="$router.push({ path: '/myExchange/accDetails' })" -->
    <div class="all-acc">
      <div class="label">
        <span>{{ $t("m.聚合账户") }}</span>
        <div class="imgStyle" @click.stop="refreshBtn()">
          <img src="@/assets/icon/shuaxin.svg" alt="" />
        </div>
      </div>
      <div class="top-box" v-if="allApiData">
        <div class="box-left">
          <div class="assets">
            <span class="title">{{ $t("m.资产折合") }}</span>
            <p>
              {{
                (allApiData ? Public.toMathNum(allApiData.assets) : 0) + " USDT"
              }}
            </p>
            <!-- <p class="text">
              {{
                "≈ " +
                (allApiData ? Public.toMathNum(allApiData.assets) : 0) +
                " USDT"
              }}
            </p> -->
          </div>
          <div class="earnings">
            <!-- <span class="title">{{ $t("m.今日收益") }}</span> -->
            <span class="title">{{ $t("m.今日资产变动") }}</span>
            <p
              class="text"
              :class="{
                green: Public.toMathNum(allApiData.todayProfit) > 0,
                red: Public.toMathNum(allApiData.todayProfit) < 0,
              }"
            >
              {{
                (allApiData
                  ? (allApiData.todayProfit > 0 ? "+" : "") +
                    Public.toMathNum(allApiData.todayProfit)
                  : 0) + " USDT"
              }}
            </p>
          </div>
          <div class="seven-earnings">
            <span class="title">{{ $t("m.近7天资产变动") }}</span>
            <!-- <span class="title">{{ $t("m.近7天累计收益") }}</span> -->
            <p
              class="text"
              :class="{
                green: Public.toMathNum(allApiData.sevendayProfit) > 0,
                red: Public.toMathNum(allApiData.sevendayProfit) < 0,
              }"
            >
              {{
                (allApiData
                  ? (allApiData.sevendayProfit > 0 ? "+" : "") +
                    Public.toMathNum(allApiData.sevendayProfit)
                  : 0) + " USDT"
              }}
            </p>
          </div>
        </div>
        <div class="box-right">
          <LineCharts
            height="230px"
            chartsId="line"
            :number="Public.toMathNum(allApiData.assets)"
            :data="lineData"
            :name="$t('m.资产折合') + ' ( USDT )'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { querySumApiData, synchronizationApi } from "@/request/api";
import LineCharts from "@/components/lineCharts.vue";
import moment from "moment";
export default {
  components: {
    LineCharts,
  },
  data() {
    return {
      allApiData: null,
      lineData: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    //获取聚合账户数据
    querySumApiData() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      querySumApiData(payload).then((res) => {
        if (res.status == 200) {
          this.allApiData = res.data.sumApiData;

          let lineData = JSON.parse(res.data.sumApiData.iconData);
          let list = [];
          lineData.sort(function (a, b) {
            return a.time - b.time;
          });
          for (let key of lineData) {
            let item = {
              time: key.time,
              value: key.sumAssets,
            };

            list.push(item);
          }
          this.lineData = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    refreshBtn() {
      if (localStorage.getItem("apiData")) {
        let payload = {
          token: localStorage.getItem("token"),
          apiData: JSON.parse(localStorage.getItem("apiData")),
        };
        synchronizationApi(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t("m.刷新成功"));
            this.querySumApiData();
          }
        });
      } else {
        this.querySumApiData();
        this.$message.success(this.$t("m.刷新成功"));
      }
    },
  },
  created() {},
  mounted() {
    this.querySumApiData();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.aggregateAcc {
  // width: 1140px;
  margin-top: 20px;
  .all-acc {
    padding: 20px 0 20px 20px;
    font-size: 20px;
    height: 296px;
    border-radius: 8px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
      box-shadow: themed("shadow") !important;
    }
    .label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      @include themeify {
        color: themed("primaryColor") !important;
        border-bottom: 1px solid themed("splitline") !important;
      }
      .imgStyle {
        border: 1px solid #2670FA;
        margin-right: 20px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;
      }
    }
    .top-box {
      display: flex;
      justify-content: flex-start;
      .box-left {
        width: 200px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        font-size: 16px;
        line-height: 22px;
        @include themeify {
          color: themed("primaryColor") !important;
        }
        .title {
          font-size: 12px;
        }
        .assets {
          margin-top: 20px;
          .text {
            font-size: 12px;
            @include themeify {
              color: themed("secondaryColor") !important;
            }
          }
        }
        .earnings {
          margin-top: 20px;
        }
        .seven-earnings {
          margin-top: 20px;
        }
      }
      .box-right {
        width: calc(100% - 230px);
        .charts {
          width: 100%;
        }
      }
    }
  }
}
</style>