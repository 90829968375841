<template>
  <div class="barCharts">
    <div class="title-warp">
      <span class="charts-title">{{ label }}</span>
      <div v-if="time">
        <span class="time-label">{{ $t("m.日期范围") + " : " }}</span>
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="—"
          :start-placeholder="$t('m.开始日期')"
          :end-placeholder="$t('m.结束日期')"
          :picker-options="pickerOptions"
          @change="timeChange"
        >
        </el-date-picker>
      </div>
    </div>
    <div
      :id="chartsId"
      class="charts"
      :style="{ height: height }"
      style="margin-top: 10px"
      name="name"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  props: {
    //高度
    height: {
      type: String,
      default: "280px",
    },
    // //标题
    label: {
      type: String,
      required: true,
    },

    time: {
      type: Boolean,
    },

    chartsId: {
      type: String,
    },
    name: {
      type: String,
    },
    // 图表数据
    data: {
      type: Array,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      timeValue: null,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {},
  watch: {
    data(New, old) {
      this.histogramCharts(New);
    },
  },
  methods: {
    // 柱状图
    histogramCharts(val) {
      var myChart = echarts.init(document.getElementById(this.chartsId));
      var name = this.name;
      var xData = [];
      var yData = [];
      if (this.data && JSON.stringify(this.data) != "[]") {
        let list = this.data;
        xData = [];
        yData = [];
        for (let key of list) {
          key.value = this.Public.toMathNum(key.value);
          // key.value =Math.floor(key.value * 10 0000) / 10 0000;
          yData.push(key.value);
          xData.push(moment(key.time).format("YYYY-MM-DD"));
        }
      } else {
        xData = [0];
        yData = [0];
      }

      var options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: name,
            type: "bar",
            barWidth: "60%",
            data: yData,
          },
        ],
      };
      myChart.setOption(options);
    },

    // 时间选择
    timeChange(v) {
      if (!v) {
        this.$emit("childFn", this.label);
        return;
      }
      let startTime = new Date(v[0]).getTime();
      let endTime = new Date(v[1]).getTime();
      this.$emit("childFn", this.label, startTime, endTime);
    },
  },
  created() {},
  mounted() {
    this.histogramCharts();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.barCharts {
  margin-top: 20px;
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  .title-warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 20px 20px 0 20px;
    @include themeify {
      color: themed("primaryColor") !important;
    }
    .charts-title {
      font-size: 20px;
    }
    .time-label {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .charts {
    width: 100%;
    height: 300px;
    margin-top: -20px;
  }
}
</style>