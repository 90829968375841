import { render, staticRenderFns } from "./ContractStrategy.vue?vue&type=template&id=f8f28a8e&scoped=true"
import script from "./ContractStrategy.js?vue&type=script&lang=js&external"
export * from "./ContractStrategy.js?vue&type=script&lang=js&external"
import style0 from "./ContractStrategy.vue?vue&type=style&index=0&id=f8f28a8e&prod&scoped=true&lang=scss"
import style1 from "./ContractStrategy.vue?vue&type=style&index=1&id=f8f28a8e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f28a8e",
  null
  
)

export default component.exports