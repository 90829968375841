<template>
  <div class="pagination">
    <div class="cont">
      <div class="total">{{ "共 " + (total ? total : 0) + " 条" }}</div>
      <div class="select">
        <el-select
          v-model="pageSize"
          placeholder="请选择"
          @change="pageSizeChange"
        >
          <el-option
            v-for="item in pageSizeList"
            :key="item"
            :label="item + '条/页'"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="page-no">
        <i
          class="el-icon-arrow-left"
          @click="previousPage()"
          :class="{
            disable: pageNo == 0 || pageNo == 1,
            'no-disable': pageNo != 0 && pageNo != 1,
          }"
        >
        </i>
        <div class="page-no-warp">
          <template v-for="item in pageNoList">
            <div
              :key="item"
              class="delete"
              :class="{ on: item == pageNo }"
              @click="pageNoSelect(item)"
            >
              {{ item }}
            </div>
          </template>
        </div>
        <i
          class="el-icon-arrow-right"
          @click="nextPage()"
          :class="{
            disable: pageNo == pageNoList.length,
            'no-disable': pageNo != pageNoList.length,
          }"
        >
        </i>
      </div>

      <div class="to-pageNo">
        <span>前往</span>
        <el-input v-model="setPageNo" @change="pageNoChange"></el-input>
        <span>页</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["total", "size", "current", "sizeList"],
  components: {},
  data() {
    return {
      pageNoList: [0],
      pageSizeList: this.total < 10 ? [10] : this.sizeList,
      pageNo: 1,
      pageSize: 10,
      setPageNo: 1,
    };
  },
  computed: {},
  watch: {
    total(a, b) {
      this.calculation();
    },
  },
  methods: {
    pageNoSelect(v) {
      if (v == this.pageNo) {
        return;
      }
      this.pageNo = v;
      this.setPageNo = v;
      this.$emit("currentChange", v);
    },
    // 输入pageNo
    pageNoChange(v) {
      let list = this.pageNoList;
      let change = true;
      for (let key of list) {
        if (v == key) {
          this.pageNo = v;
          change = false;
          this.$emit("currentChange", v);
        }
      }
      if (change) {
        this.setPageNo = this.pageNo;
      }
    },
    // pageSize 变化
    pageSizeChange(v) {
      this.calculation(v);
      this.$emit("sizeChange", v);
    },
    // 计算页码
    calculation(v) {
      let num = Math.ceil(this.total / (v ? v : 10));
      if (num != 0) {
        this.pageNoList = [];
        this.setPageNo = 1;
        this.pageNo = 1;
        for (let i = 0; i < num; i++) {
          this.pageNoList.push(i + 1);
        }
      } else {
        this.pageNoList = [0];
        this.setPageNo = 0;
        this.pageNo = 0;
      }
    },
    // 上一页
    previousPage() {
      if (this.pageNoList[0] == 0) {
        return;
      } else if (this.pageNoList[0] == 1 && this.pageNoList.length == 1) {
        return;
      } else if (this.pageNo != 1) {
        this.setPageNo = this.setPageNo - 1;
        this.pageNo = this.pageNo - 1;
        this.$emit("currentChange", this.pageNo);
      }
    },

    // 下一页
    nextPage() {
      if (this.pageNoList[0] == 0) {
        return;
      } else if (this.pageNoList[0] == 1 && this.pageNoList.length == 1) {
        return;
      } else if (this.pageNo < this.pageNoList.length) {
        this.setPageNo = this.setPageNo + 1;
        this.pageNo = this.pageNo + 1;
        this.$emit("currentChange", this.pageNo);
      }
    },

    // 初始
    fn() {
      this.pageSizeList = this.sizeList;
      this.pageNo = this.current;
      this.pageSize = this.size;
      this.setPageNo = this.current;
    },
  },
  created() {},
  mounted() {
    this.calculation();
    // this.fn()
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.pagination {
  .cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .total {
      min-width: 30px;
    }
    .select {
      width: 100px;
      height: 30px;
      margin: 0 20px;
      ::v-deep.el-input__inner {
        height: 30px !important;
        background: none !important;
      }

      ::v-deep.el-input__icon {
        height: 30px !important;
        line-height: 30px !important;
      }
    }
    .page-no {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 80px;
      max-width: 370px;
      margin-left: 10px;
      .page-no-warp {
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 320px;
        overflow: hidden;
      }
      .delete {
        cursor: pointer;
        margin: 0px 10px;
        @include themeify {
          border-bottom: 1px solid themed("splitline") !important;
        }
        padding: 5px 10px;
        color: #162447;
        border-radius: 4px;
      }
      .on {
        color: #fff;
        background: #2670FA;
        border-radius: 4px;
      }
      .disable {
        cursor: not-allowed;
      }
      .no-disable {
        cursor: pointer;
      }
    }
    .to-pageNo {
      width: 120px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 30px;

      ::v-deep.el-input {
        width: 50px;
      }
      ::v-deep.el-input__inner {
        width: 50px;
        height: 30px !important;
        background: none !important;
      }
    }
  }
}
</style>