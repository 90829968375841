<template>
  <div class="index">
    <TopNavigation :menu="0" />
    <el-container style="height: calc(100vh - 65px)">
      <el-aside width="240px">
        <Menu :idx="'1'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <HomeAccount />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import HomeSkeleton from "../transaction/modular/homeSkeleton.vue";
import HomeAccount from "../transaction/modular/homeAccount.vue";

export default {
  components: {
    TopNavigation,
    Menu,
    HomeSkeleton,
    HomeAccount,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("guidance")) {
        this.$store.state.noviceShow = true;
        localStorage.removeItem("guidance");
      } else if (!localStorage.getItem("guidanceLogin")) {
        this.$store.state.noviceShow = true;
        localStorage.setItem("guidanceLogin", true);
      }
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.index {
  height: 100%;
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      // 骨架
      .default-page {
        // width: 1150px;
        min-width: 1120px;
        width: 100%;
        height: 100%;
        position: relative;
        @include themeify {
          // background: themed("secondaryBackColor") !important;
        }
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
}
</style>