<template>
  <div class="downloadPc">
    <div class="warppc">
      
      <!--1 Watch Your Profits Grow -->
      <div class="contentpc onepc" v-if="one">
          
        <div class="column">
          <div class="mb20">
            <span class="font48 fontx ch0">{{$t('m.'+one.span1)}}</span>
            <span class="font48 fontx clan">{{' '+$t('m.'+one.span2)}}</span>
          </div>
          <span class="font18 ch1 mb60">{{$t('m.'+one.span3)}}</span>


          <div class="row">
            <RichBut @click="tapClick(one.but1.click)" 
            class="butpc" styleType="blan">
            {{$t('m.'+one.but1.txt)}}
            </RichBut>
          </div>
        </div>

        <img class="img-icqpc" :src="one.src1">
      </div>

      <!--2 Watch Your Profits Grow -->
      <div class="contentpc twopc" v-if="two">
        <img class="img-icqpc" :src="two.src1">

        <div class="column">
          <span class="font48 fontx ch0 mb20">{{$t('m.'+two.span1)}}</span>
          <span class="font18 ch1 mb20">{{$t('m.'+two.span2)}}</span>
          <span class="font18 ch1">{{$t('m.'+two.span3)}}</span>
        </div>
      </div>

      <!--3 Automated crypto  trading bot without coding -->
      <div class="contentpc threepc" v-if="three">

        <div class="row acenter jbetween mb48">
          <div class="column">
            <span class="font48 fontx ch0 mb20">{{$t('m.'+three.span1)}}</span>
            <span class="font18 ch1 mb20">{{$t('m.'+three.span2)}}</span>
            <span class="font18 ch1">{{$t('m.'+three.span3)}}</span>
          </div>

          <img class="img-icqpc" :src="three.src1">
        </div>

        <div class="row jbetween">
          <div class="cellpc" v-for="(item, index) in three.cells" :key="'three'+index">
            <img :src="item.src1">
            <span class="font24 fontx ch0 mb10">{{$t('m.'+item.span1)}}</span>
            <span class="font14 ch3">{{$t('m.'+item.span2)}}</span>
          </div>
        </div>
      </div>

      <!--4 One terminal manages accounts on multiple exchanges -->
      <div class="contentpc fourpc" v-if="four">
        <img class="img-icqpc" :src="two.src1">

        <div class="column">
          <span class="font48 fontx ch0 mb20">{{$t('m.'+two.span1)}}</span>
          <span class="font18 ch1 mb20">{{$t('m.'+two.span2)}}</span>
          <span class="font18 ch1">{{$t('m.'+two.span3)}}</span>
        </div>
      </div>

      <!--5 如何启动网格交易策略？ -->
      <div class="contentpc fivepc" v-if="five">
        <div class="column">
          <span class="font48 fontx ch0 mb20">{{$t('m.'+five.span1)}}</span>
          <span class="font18 ch1 mb60">{{$t('m.'+five.span2)}}</span>

          <div class="row">
            <RichBut @click="tapClick(five.but1.click)"  
            class="butpc" styleType="blan">
            {{$t('m.'+five.but1.txt)}}
            </RichBut>
          </div>
        </div>

        <img class="img-icqpc" :src="five.src1">
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },

  data() {
    return {
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.downloadPc {
  display: flex;
  flex-direction: column;

  .warppc {
    display: flex;
    flex-direction: column;
    align-items: center;
    .line-fenge {
      width: 1200px;
      height: 1px;
      background: #E4E6EF;
      margin-bottom: 120px;
    }
  }
  
  /************************************* 内容 */
  .contentpc {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin-bottom: 120px;
  }

  /**** 1 Watch Your Profits Grow */
  .onepc {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
    .img-icqpc {
      width:  670px;
      height: 450px;
      margin-left: 76px;
    }
  }
  
  /**** 2 Watch Your Profits Grow */
  .twopc {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .img-icqpc {
      width: 440px;
      height: 440px;
      margin-right: 90px;
    }
  }
  
  /**** 3 Vtrading 网格机器人的优势*/
  .threepc {

    .img-icqpc {
      width: 440px;
      height: 440px;
      margin-left: 76px;
    }

    .cellpc {
      display: flex;
      flex-direction: column;
      padding: 40px;

      border-radius: 8px;
      width: 360px;
      height: 348px;
      background-image: url('../../assets/image/bot/grid_3_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 96px;
        height: 96px;
        margin-bottom: 20px;
      }
    }
    .cellpc:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 4 观看网格讲解视频*/
  .fourpc {
    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .img-icqpc {
      width: 440px;
      height: 440px;
      margin-right: 90px;
    }
  }

  /**** 5 如何启动网格交易策略？*/
  .fivepc {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
    .img-icqpc {
      width: 440px;
      height: 440px;
      margin-left: 76px;
    }
  }

  .butpc {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    height: 60px;
    border-radius: 6px;
  }
}
</style>
