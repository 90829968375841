<template>
  <div class="index">
    <TopNavigation />
    <div class="content">
      <div class="cont-warp">
        <!-- 搜索 -->
        <div class="search">
          <el-input :placeholder="$t('m.请输入关键词搜索')" v-model="Text">
            <div
              slot="suffix"
              class="search-right"
              style="cursor: pointer"
              @click="search()"
            >
              <span></span>
              <img src="../../assets/icon/souso.png" alt="" />
              <span>{{ $t("m.搜索") }}</span>
            </div>
          </el-input>
        </div>

        <!-- 内容 -->
        <div class="cont-box">
          <div class="menu">
            <el-menu
              :default-active="idx"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#fff"
              :text-color="$store.state.theme == 'light' ? '#162447' : '#fff'"
              active-text-color="#fff"
            >
              <el-menu-item
                index="1"
                @click.native="getArticleList('热门问题')"
              >
                <span slot="title">{{ $t("m.热门问题") }}</span>
              </el-menu-item>

              <el-menu-item
                index="2"
                @click.native="getArticleList('支付问题')"
              >
                <span slot="title">{{ $t("m.支付问题") }}</span>
              </el-menu-item>

              <el-menu-item
                index="3"
                @click.native="getArticleList('消费问题')"
              >
                <span slot="title">{{ $t("m.消费问题") }}</span>
              </el-menu-item>

              <el-menu-item
                index="4"
                @click.native="getArticleList('账户问题')"
              >
                <span slot="title">{{ $t("m.账户问题") }}</span>
              </el-menu-item>

              <el-menu-item index="5" @click.native="getArticleList('机器人')">
                <span slot="title">{{ $t("m.机器人") }}</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="box" v-if="!articleDetails">
            <div class="title">
              {{
                searchText == "热门问题"
                  ? $t("m.热门问题")
                  : searchText == "支付问题"
                  ? $t("m.支付问题")
                  : searchText == "消费问题"
                  ? $t("m.消费问题")
                  : searchText == "账户问题"
                  ? $t("m.账户问题")
                  : $t("m.热门问题")
              }}
            </div>

            <template
              v-if="
                (total != 0 && !Text) ||
                (articleList.length != 0 && Text && total != 0)
              "
            >
              <div class="article">
                <div
                  class="article-box"
                  v-for="(item, idx) in articleList"
                  :key="idx"
                  @click="getArticleDetails(item.id)"
                  :class="idx == 0 ? 'article-boxStyle' : 'article-boxTopStyle'"
                >
                  <span
                    class="article-box-title"
                    v-if="searchText == '热门问题'"
                  >
                    <span>{{ idx + 1 + "." }}</span>
                    <span v-html="item.title"></span>
                  </span>
                  <span
                    class="article-box-title"
                    v-else
                    v-html="item.title"
                  ></span>
                  <div class="article-box-text">
                    <span class="article-cont" v-html="item.outline"></span>
                    <span class="article-time">{{
                      moment(item.createTime).format("YYYY/MM/DD HH:mm")
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="pagination-warp"
                v-if="searchText != '热门问题' && total > 10"
              >
                <Pagination
                  :total="total"
                  :size="pageSize"
                  :current="pageNo"
                  :sizeList="[10, 20, 50, 100, 500]"
                  @sizeChange="handleSizeChange"
                  @currentChange="handleCurrentChange"
                />
              </div>
            </template>

            <div class="no-data" v-else>
              <img src="@/assets/icon/quesheng.svg" alt="" />
              <span>{{ $t("m.抱歉，没有找到相关内容") }}</span>
            </div>
          </div>
          <div v-else class="details-warp">
            <div class="details">
              <div class="details-title">
                <span>{{ articleDetails.title }}</span>
                <span>
                  {{
                    moment(articleDetails.createTime).format("YYYY-MM-DD HH:mm")
                  }}
                </span>
              </div>
              <div
                v-html="articleDetails.content"
                class="details-cont-warp"
              ></div>
            </div>
            <div class="right-list">
              <span class="right-list-title">{{ $t("m.相关文章") }}</span>
              <div class="right-list-box-warp">
                <template v-for="(item, idx) in articleList">
                  <div
                    class="right-list-box"
                    :key="idx"
                    v-if="item.id != articleDetails.id"
                    @click="getArticleDetails(item.id)"
                  >
                    <div>{{ item.title }}</div>
                    <div class="right-list-box-text">{{ item.outline }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import moment from "moment";
import HomeSkeleton from "../transaction/modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import { getArticleList, getArticleDetails } from "@/request/api";
export default {
  components: {
    TopNavigation,
    Menu,
    HomeSkeleton,
    Pagination,
  },
  data() {
    return {
      moment,
      idx: "1",
      Text: null,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      searchText: "热门问题",
      articleList: null,
      articleDetails: null,
      articleType: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getArticleList(this.searchText);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getArticleList(this.searchText);
    },

    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},

    // 搜索
    searchChange(v) {
      this.getArticleList("热门问题", v);
    },
    // 查询文章列表
    getArticleList(text, keyword) {
      this.articleDetails = null;
      let payload = {
        token: localStorage.getItem("token"),
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        keyword: keyword,
      };
      switch (text) {
        case "热门问题":
          this.searchText = "热门问题";
          payload.orderBy = "clickNum";
          payload.articleType = null;
          break;
        case "支付问题":
          this.searchText = "支付问题";
          payload.orderBy = "weight";
          payload.articleType = "1";
          break;
        case "消费问题":
          this.searchText = "消费问题";
          payload.orderBy = "weight";
          payload.articleType = "2";
          break;
        case "账户问题":
          this.searchText = "账户问题";
          payload.orderBy = "weight";
          payload.articleType = "3";
          break;
        case "机器人":
          this.searchText = "机器人";
          payload.orderBy = "weight";
          payload.articleType = "4";
          break;
      }
      this.articleType = payload.articleType;
      getArticleList(payload).then((res) => {
        if (res.status == 200) {
          this.total = Number(res.data.count);
          this.articleList = res.data.articleList;
          if (keyword) {
            this.changeColor(this.articleList, keyword);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 搜索高亮
    changeColor(result, text) {
      //result为接口返回的数据
      result.map((item, index) => {
        if (text) {
          /**
           * 使用正则表达式进行全文匹配关键词
           * ig : 表示 全文查找 ,忽略大小写
           *  i : 忽略大小写
           *  g : 全文查找
           *
           * 使用字符串的replace方法进行替换
           * stringObject.replace('被替换的值',替换的值)
           */
          let replaceReg = new RegExp(text, "ig");
          let replaceString = `<span style="color: #02C8A8;margin:0">${text}</span>`;
          result[index].title = item.title.replace(replaceReg, replaceString);
          result[index].outline = item.outline.replace(
            replaceReg,
            replaceString
          );
        }
      });
      this.articleList = result;
    },
    // 文章详情
    getArticleDetails(id) {
      let payload = {
        token: localStorage.getItem("token"),
        articleId: id,
      };
      getArticleDetails(payload).then((res) => {
        if (res.status == 200) {
          this.articleDetails = res.data.articleDetails;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 搜索
    search() {
      if (!this.Text) {
        return;
      }

      // window.open(
      //   "http://localhost:8080/#/helpCenter/search" + "?searchText=" + this.Text
      // );

      window.open(
        "https://vtrading.com/helpCenter/search" + "?searchText=" + this.Text
      );
      // window.open(
      //   "http://159.138.104.103:8797/#/helpCenter/search" +
      //     "?searchText=" +
      //     this.Text
      // );
    },
  },
  created() {},
  mounted() {
    this.getArticleList();
    if (this.$route.query.id) {
      this.getArticleDetails(this.$route.query.id);
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .content {
    width: 1440px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .cont-warp {
      width: 1080px;
      padding-top: 20px;
      .search {
        height: 104px;
        border-radius: 8px;
        background: url("../../assets/icon/back.png") no-repeat;
        background-size: 100% 100%;
        ::v-deep.el-input {
          width: 459px;
          margin: 30px 0 30px 40px;
        }
        ::v-deep.el-input__inner {
          border-radius: 10px !important;
        }
        .search-right {
          display: flex;
          align-items: center;
          font-size: 14px;
          height: 40px;

          :first-child {
            height: 16px;
            border-left: 1px solid #dee2e6;
          }
          img {
            width: 17px;
            height: 18px;
            margin: 0 6px 0 20px;
          }
          :last-child {
            margin-right: 24px;
          }
        }
      }
      .cont-box {
        width: 100%;
        height: calc(100vh - 266px);
        padding-top: 20px;
        display: flex;
        @include themeify {
          background: themed("secondaryBackColor") !important;
        }
        .menu {
          width: 240px;
          height: 100%;
          border-right: 1px solid #dee2e6;
          ::v-deep.el-menu {
            padding: 0 20px;
            border-right: 0;
            @include themeify {
              background: themed("secondaryBackColor") !important;
            }
          }
          ::v-deep.el-menu-item {
            height: 44px;
            line-height: 44px;
            min-width: 150px;
            @include themeify {
              background: themed("secondaryBackColor") !important;
            }
          }
          ::v-deep.el-menu-item-group__title {
            padding: 0;
          }
          ::v-deep.el-submenu__title {
            padding: 0 0 0 19px !important;
            display: flex;
            align-items: center;
            @include themeify {
              background: themed("secondaryBackColor") !important;
              color: menucolor;
            }
          }
          ::v-deep.is-active {
            border-radius: 8px;

            @include themeify {
              background: themed("btnBackGreen") !important;
            }
          }

          .customer-service {
            position: fixed;
            left: 20px;
            bottom: 40px;
            width: 160px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            @include themeify {
              color: themed("primaryColor") !important;
              border: themed("kefuborder") !important;
              background: themed("kefuBack") !important;
            }
            img {
              margin-right: 6px;
            }
          }
        }
        .box {
          width: 100%;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          @include themeify {
            background: themed("secondaryBackColor") !important;
          }
          .title {
            font-size: 16px;
            border-bottom: 1px solid #dee2e6;
            padding: 10px 0;
            margin-bottom: 20px;
            @include themeify {
              color: themed("primaryColor") !important;
            }
          }
          .article {
            width: 100%;
            flex: 1;
            overflow: auto;
            .article-box {
              line-height: 22px;
              padding: 7px 0;
              cursor: pointer;
              .article-box-title {
                display: inline-block;
                font-size: 14px;
                width: 570px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include themeify {
                  color: themed("primaryColor") !important;
                }
              }
              .article-box-text {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                .article-cont {
                  display: inline-block;
                  text-indent: 14px;
                  width: 570px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .article-time {
                  margin-right: 20px;
                }
                @include themeify {
                  color: themed("secondaryColor") !important;
                }
              }
            }
            .article-boxStyle {
              border: 0px !important;
            }
            .article-boxTopStyle {
              @include themeify {
                border-top: 1px solid themed("splitline") !important;
              }
            }
          }
          .pagination-warp {
            width: 100%;
            height: 50px;
            text-align: right;
          }
          .no-data {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 180px;
              margin-bottom: 10px;
            }
            .text {
              margin: 25px 0;
              @include themeify {
                color: themed("secondaryColor");
              }
            }
          }
        }
        .details-warp {
          display: flex;
          .details {
            width: 599px;
            padding: 20px;
            @include themeify {
              background: themed("secondaryBackColor") !important;
              color: themed("secondaryColor") !important;
              border-right: 1px solid themed("splitline") !important;
            }
            .details-title {
              width: 100%;
              padding-bottom: 10px;
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              :first-child {
                @include themeify {
                  color: themed("primaryColor") !important;
                }
              }
              @include themeify {
                border-bottom: 1px solid themed("splitline") !important;
              }
            }
            .details-cont-warp {
              word-break: break-all;
            }
          }
          .right-list {
            width: 239px;
            padding: 20px;
            font-size: 14px;
            @include themeify {
              background: themed("secondaryBackColor") !important;
              color: themed("primaryColor") !important;
            }
            .right-list-box {
              margin-top: 20px;
              cursor: pointer;
              .right-list-box-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include themeify {
                  color: themed("secondaryColor") !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>