<template>
  <div class="fuGrid">
    <TopNavigation 
    seoMetaTitle="Grid Futures Trading Bot - Vtrading AI trading platform" 
    seoKeyWords="Futures Trading, Grid Futures, grid strategy, Futures bot, Bitcoin, Altcoin Futures bot" 
    seoDescription="Vtrading Automated cryptocurrency  AI trading platform - provide cryptocurrency investors  grid trading  Futures tools, Empower your trading game and achieve your goals with Vtrading’s GRID Futures crypto trading bot, whether the market is falling or rising."/>
    
    <component v-if="current"
    :is="'Grid'+current" 

    :one="one" :two="two" :three="three" 
    :four="four" :five="five"

    @tapClick="tapClick"/>

    <Bottom />
  </div>
</template>

<script>

import {getShowPage, isLogin} from "@/utils/function.js"

import TopNavigation from "@/components/TopNavigation";
import Bottom from "@/components/Bottom";
import GridPc from "./fuGridPc.vue"
import GridH5 from "./fuGridH5.vue"

export default {

  components: {
    TopNavigation: TopNavigation,
    Bottom: Bottom,
    GridPc: GridPc,
    GridH5: GridH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      one: {
        'span1':'期货网格交易机器人',
        'span2':'降低您的风险，增强您的交易盈利',
        'span3':'期货市场波动比较大、交易风险高，如果我们使用网格交易机器人在期货市场交易，可以利用杠杆放大收益，通过设定的网格区间可以持续交易获利，交易产生的利润又可以补充保证金，从而降低期货账户的风险',
        'but1':{'txt':'立即交易', 'click':'toExchange'},
        'src1': require('@/assets/image/bot/fugrid_1.png')
      },

      two: {

        'span1':'利用加密货币期货交易获利',
        'cells':[
          {
            'span1':'以更低的保证金进行更高的交易质量',
            'src1': require('@/assets/image/bot/fugrid_2_1.png')
          },
          {
            'span1':'交易杠杆高达 X10',
            'src1': require('@/assets/image/bot/fugrid_2_2.png')
          },
          {
            'span1':'灵活选择多空交易',
            'src1': require('@/assets/image/bot/fugrid_2_3.png')
          }
        ]
      },

      three: {
        'span1': '在顶级加密货币交易所运行交易机器人',
        'span2': '将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。',
        'but1': {
          'txt':'接入账户',
          'click':'toIndex'
        },
        'src1': require('@/assets/image/v3/home_2_icq.png'),
        'src2': require('@/assets/image/v3/binance.png'),
        'src3': require('@/assets/image/v3/okx.png'),
        'src4': require('@/assets/image/v3/bybit.png'),
        'src5': require('@/assets/image/v3/bitfinex.png'),
        'src6': require('@/assets/image/v3/bitstamp.png'),
        'src7': require('@/assets/image/v3/coinbase.png'),
        'src8': require('@/assets/image/v3/gemini.png'),
        'src9': require('@/assets/image/v3/cointr.png'),
        'src10': require('@/assets/image/v3/gate.png'),
        'src11': require('@/assets/image/v3/bitget.png'),
        'src12': require('@/assets/image/v3/htx.png'),
        'src13': require('@/assets/image/v3/kucoin.png'),
      },
      
      four: {
        'cells':[
          {
            'src1': require('@/assets/image/bot/fugrid_4_1.png'),
            'title1':['选择您的策略'],
            'span2':'无论市场是下跌还是上涨，都可以通过 Vtrading 的网格期货加密交易机器人增强您的交易游戏并实现您的目标。'
          },
          {
            'src1': require('@/assets/image/bot/fugrid_4_2.png'),
            'title1':['当市场上涨时', '增强利润'],
            'span1':'网格策略期货多头',
            'span2':'通过不间断交易来积累利润，从而增强收益。'
            
          },
          {
            'src1': require('@/assets/image/bot/fugrid_4_2.png'),
            'title1':['当市场下跌时', '赚取加密货币'],
            'span1':'网格策略期货空头',
            'span2':'通过不断高卖低买的形式，持续追踪下跌市场交易获利。'
          }
        ]
      },

      five: {
        
        'span1':'只需三步就可以实现网格期货自动化交易',
        'cells':[
          {
            'span1':'通过API链接您的交易所',
            'src1': require('@/assets/image/bot/fugrid_5_1.png')
          },
          {
            'span1':'选择期货网格策略',
            'src1': require('@/assets/image/bot/fugrid_5_2.png')
          },
          {
            'span1':'选择策略方向并启动策略',
            'src1': require('@/assets/image/bot/fugrid_5_3.png')
          }
        ]
      }
    };
  },

  created() {
    this.initView();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    /******************************************** 点击 */
    //点击事件(PC、H5)
    tapClick(name, param) {
      this[name](param);
    },

    //交易策略-我的交易所
    toExchange() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/myExchange" });
    },

    //交易策略-首页
    toIndex() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/transac" });
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.fuGrid {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cbai60 {color: rgba($color: #ffffff, $alpha: 0.6);}
.but {cursor: pointer;}
</style>
