<template>
  <div class="newsinfopc">

    <div class="warppc">

      <div class="infopc">

        <el-breadcrumb class="mb40" separator="/">

          <el-breadcrumb-item :to="{ path: '/newsHome' }">
          {{$t('m.量化学院')}}
          </el-breadcrumb-item>

          <el-breadcrumb-item v-if="breadcrumb" :to="{ path: '/'+breadcrumb.url }">
            {{breadcrumb.fy ? breadcrumb.title : $t('m.'+breadcrumb.title)}}
          </el-breadcrumb-item>

          <el-breadcrumb-item>
            {{$t('m.文章')}}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <template v-if="data">

          <span class="font30 fontc ch1 mb10">{{data.artTitle}}</span>
          
          <span class="font10 ch3 mb30">{{$t('m.'+'发布于') +' ' + (lang == 'en' ? data.timeEn  : data.timeCn)}}</span>

          <div class="htmlpc" v-html="data.html"></div>
        </template>
      </div>

      <div class="popularpc">

        <div class="column">
          <RichInput class="searchpc"
          v-model="keyWord"
          :placeholder="$t('m.请输入搜索内容')"
          @enter="searchClick">
            <template #right="{select}">
              <img v-if="select" @click.stop="searchClick"
              class="img-searchpc but" src="@/assets/image/news/sousuo.png">
              
              <img v-else style="opacity:0.3"
              class="img-searchpc" src="@/assets/image/news/sousuo.png">
            </template>
          </RichInput>

          <span class="font20 fontc ch1 mb20">{{$t('m.推荐文章')}}</span>

          <RichBut class="font16 textpc"  
          :class="[index<populars.length-1 ? 'mb40' : '']" styleType="lhei"
          v-for="(item, index) in populars" :key="'tuijian'+index"
          @click="tuiJianClick(item)">
            <span>{{item.artTitle}}</span>
          </RichBut>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue";

export default {
  components: {
    RichInput,
    RichBut,
  },


  props: {
    breadcrumb: Object,
    data: Object,
    populars: Array
  },

  data() {
    return {
      keyWord: '', //搜索关键字
    };
  },

  computed: {
    ...mapState(['lang']),  //语言ID
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {

    /******************************************** 点击 */
    //搜索点击
    searchClick() {
      this.$emit('search', this.keyWord);
    },

    //推荐文章点击
    tuiJianClick(item) {
      this.$emit('tuiJian', item)
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.newsinfopc {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0 40px 0;
  
  .warppc {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1200px;
    height: 100%;
  }

  .infopc {
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 860px;
    height: 100%;
    .htmlpc {
      color: #181C32;
      margin-bottom: 40px;
    }
    img {
      max-width: 800px;
      height: auto !important;
    }
  }

  .popularpc {
    display: flex;
    flex-direction: column;

    .searchpc {
      width: 209px;
      height: 44px;
      background: #FFFFFF;
      border-radius: 22px;
      border: 1px solid rgba(38,112,250,0.3);
      margin-top: 80px;
      margin: 80px 0 50px 0;
    }
    .img-searchpc {
      width: 18px;
      height: 18px;
      margin-left: 12px;
    }
    .textpc {
      width: 250px;
    }
  }
  .popularpc-absolute {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 1200px;
    position: absolute; 
    top: 102px;
  }
}
</style>

