<template>
  <div class="accDetails">
    <TopNavigation />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'2'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <template>
              <div class="accDetails-warp">
                <div class="breadcrumb-warp">
                  <span @click="$router.push({ path: '/myExchange' })">
                    {{ $t("m.我的交易所") + " / " }}</span
                  >
                  <span>{{ $t("m.聚合账户详情") }}</span>
                </div>
                <DetailBox :data="accTopData" :title="$t('m.统计数据')" />
                <template v-if="isCharts">
                  <LineCharts
                    :label="$t('m.资产走势')"
                    :name="$t('m.资产折合') + ' ( USDT )'"
                    height="300px"
                    :time="true"
                    chartsId="acc222"
                    @childFn="querySumApiDetailsIconData"
                    :data="assets"
                    style="margin-top: 20px"
                  />
                  <LineCharts
                    :label="$t('m.累计收益')"
                    :name="$t('m.累计收益') + ' ( USDT )'"
                    height="300px"
                    :time="true"
                    chartsId="line111"
                    @childFn="querySumApiDetailsIconData"
                    :data="allProfit"
                    style="margin-top: 20px"
                  />
                  <BarCharts
                    :label="$t('m.每日收益')"
                    :name="$t('m.每日收益') + ' ( USDT )'"
                    height="300px"
                    style="margin-top: 20px"
                    :time="true"
                    chartsId="bar11"
                    @childFn="querySumApiDetailsIconData"
                    :data="eventProfit"
                  />
                </template>
              </div>
            </template>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import LineCharts from "@/components/lineCharts.vue";
import BarCharts from "@/components/barCharts.vue";
import DetailBox from "./detailBox.vue";
import HomeSkeleton from "../homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";

import {
  querySumApiDetailsTopData,
  querySumApiDetailsIconData,
} from "@/request/api";
export default {
  components: {
    LineCharts,
    BarCharts,
    DetailBox,
    TopNavigation,
    Menu,
    HomeSkeleton,
  },
  data() {
    return {
      accTopData: null, //顶部数据
      assets: null, //资产数据
      allProfit: null, //累计收益
      eventProfit: null, //每日收益
      isCharts: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 顶部数据
    querySumApiDetailsTopData() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      querySumApiDetailsTopData(payload).then((res) => {
        if (res.status == 200) {
          this.accTopData = res.data.sumApiData;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 图表数据
    querySumApiDetailsIconData(name, start, end) {
      let payload = {
        token: localStorage.getItem("token"),
        startTime: start ? start + "" : null,
        endTime: end ? end + "" : null,
      };

      querySumApiDetailsIconData(payload).then((res) => {
        if (res.status == 200) {

          this.isCharts = true;
          // 资产 sumAssets
          let assets = [];
          // 累计收益 sumEarnings
          let allProfit = [];
          // 每日收益
          let eventProfit = [];

          let data = JSON.parse(res.data.sumApiIconData);

          if (data && data.length != 0) {
            data.sort(function (a, b) {
              return a.time - b.time;
            });

            for (let key of data) {
              let assetsItem = {
                time: key.time,
                value: key.sumAssets,
              };
              assets.push(assetsItem);

              let allProfitItem = {
                time: key.time,
                value: key.sumEarnings,
              };
              allProfit.push(allProfitItem);
            }

            if (allProfit.length == 1) {
              eventProfit = allProfit;
            } else {
              eventProfit[0] = JSON.parse(JSON.stringify(allProfit[0]));

              for (let i = 1; i < allProfit.length; i++) {
                let num = allProfit[i].value - allProfit[i - 1].value;
                let item = {
                  time: allProfit[i].time,
                  value: Math.floor(num * 1000) / 1000,
                };
                eventProfit.push(item);
              }
            }
          }

          if (name) {
            switch (name) {
              case "资产走势":
                this.assets = assets.length == 0 ? null : assets;
                break;
              case "Asset Trend":
                this.assets = assets.length == 0 ? null : assets;
                break;
              case "累计收益":
                this.allProfit = allProfit.length == 0 ? null : allProfit;
                break;
              case "Benefits":
                this.allProfit = allProfit.length == 0 ? null : allProfit;
                break;
              case "每日收益":
                this.eventProfit = eventProfit.length == 0 ? null : eventProfit;
                break;
              case "Daily Benefit":
                this.eventProfit = eventProfit.length == 0 ? null : eventProfit;
                break;
              default:
                break;
            }
          } else {
            this.assets = assets;
            this.allProfit = allProfit;
            this.eventProfit = eventProfit;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.querySumApiDetailsTopData();
    this.querySumApiDetailsIconData();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.accDetails {
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      justify-content: space-between;
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        // height: 100%;
        position: relative;
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
  .accDetails-warp {
    padding-bottom: 20px;
    .breadcrumb-warp {
      margin-bottom: 20px;
      :first-child {
        cursor: pointer;
        @include themeify {
          color: themed("secondaryColor") !important;
        }
      }
      :last-child {
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
    }
  }
}
/deep/.lineCharts {
  padding: 20px;
}
/deep/.barCharts {
  padding: 20px;
}
</style>