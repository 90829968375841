var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider"},[_c('div',{staticClass:"slider-title"},[_vm._v(" "+_vm._s(_vm.$store.state.lang == 'ch' ? _vm.dataList.name : _vm.dataList.enName)+" "),(_vm.dataList && _vm.dataList.explain)?_c('img',{staticClass:"explain",attrs:{"src":require("@/assets/icon/explain.svg"),"alt":""},on:{"click":function($event){return _vm.openAlert()}}}):_vm._e()]),_c('div',{staticClass:"inputBox"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.dataValue),expression:"dataValue",modifiers:{"number":true}}],staticClass:"slider-input inpStyle",class:_vm.dataList.redBorderStyle ? 'redBorderStyle' : '',attrs:{"placeholder":_vm.$store.state.lang == 'ch'
          ? _vm.dataList.placeholder
          : _vm.dataList.enPlaceholder},domProps:{"value":(_vm.dataValue)},on:{"blur":[function($event){return _vm.inputChange(_vm.dataValue)},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.dataValue=_vm._n($event.target.value)}}}),(_vm.dataList.inpUnit)?_c('span',{staticClass:"input-text"},[_vm._v(" "+_vm._s(_vm.dataList.inpUnit == "currency" ? "USDT" : _vm.dataList.inpUnit == "multiple" ? _vm.$t("m.倍") : _vm.dataList.inpUnit == "single" ? _vm.$t("m.单") : _vm.dataList.inpUnit == "percentage" ? "%" : _vm.dataList.inpUnit == "time" ? _vm.$t("m.分") : "USDT")+" ")]):_vm._e()]),(_vm.dataList.redBorderStyle)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("m.不能为空"))+" ")]):_vm._e(),_c('el-slider',{staticClass:"sliderStyle",attrs:{"format-tooltip":(val) => `${val}`,"max":_vm.dataList.slideMax == 'Infinity' ? 999999 : _vm.dataList.slideMax,"min":_vm.dataList.slideMin,"step":_vm.dataList.name.indexOf('单') > -1 ||
      _vm.dataList.name.indexOf('网格数') > -1 ||
      _vm.dataList.name.indexOf('开仓等待时长') > -1
        ? 1
        : 0.1},on:{"change":function($event){return _vm.sliderChange(_vm.dataList.value)}},model:{value:(_vm.dataList.value),callback:function ($$v) {_vm.$set(_vm.dataList, "value", $$v)},expression:"dataList.value"}}),_c('div',{staticClass:"slider-text"},[_c('span',{staticStyle:{"margin-left":"-4px"}},[_vm._v(_vm._s(_vm.dataList.slideMin))]),_c('span',[_vm._v(" "+_vm._s(_vm.dataList.slideMax == "Infinity" ? 999999 : _vm.dataList.slideMax)+" ")])]),_c('div',{staticClass:"box"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }