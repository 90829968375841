<template>
  <div class="startegyTradeHistory">
    <div class="startegyTradeHistory-warp">
      <!-- <p class="label">Positions</p> -->
      <div class="positions-cont-warp">
        <span>{{ $t("m.成交时间") }}</span>
        <span>{{ $t("m.方向") }}</span>
        <span>
          {{
            $t("m.成交数量") +
            "(" +
            (strategyOrderList && strategyOrderList.length !== 0
              ? strategyOrderList[0].counterCoin + ")"
              : "BTC)")
          }}
        </span>
        <span>{{ $t("m.成交价格") }}</span>
        <span>{{ $t("m.收益") + "(USDT)" }}</span>
      </div>

      <div
        class="positions-cont"
        v-for="(item, idx) in strategyOrderList"
        :key="idx"
        v-if="total"
      >
        <span class="box">
          {{ moment(item.finishTime).format("YYYY/MM/DD HH:mm") }}
        </span>
        <span class="box">
          <span
            v-if="item.tradeOffset == 1 && item.tradeType == 1"
            class="green"
          >
            {{ $t("m.开多") }}
          </span>
          <span
            v-if="item.tradeOffset == 0 && item.tradeType == 1"
            class="green"
          >
            {{ $t("m.平空") }}
          </span>
          <span v-if="item.tradeOffset == 0 && item.tradeType == 0" class="red">
            {{ $t("m.平多") }}
          </span>
          <span v-if="item.tradeOffset == 1 && item.tradeType == 0" class="red">
            {{ $t("m.开空") }}
          </span>
        </span>
        <span class="box">
          <!-- {{
            Math.floor(item.dealAmount * Number(contractSize) * 10 0000) / 10 0000
          }} -->
          {{ Public.toMathNum(item.dealAmount * Number(contractSize)) }}
        </span>
        <span class="box">{{ item.dealPrice }}</span>
        <span class="box">
          <div
            class="box-cont"
            v-if="
              (item.tradeOffset == 1 && item.tradeType == 1) ||
              (item.tradeOffset == 1 && item.tradeType == 0)
            "
          >
            <span>--</span>
            <span>--</span>
          </div>
          <div class="box-cont" v-else>
            <span
              :class="{
                green: Public.toMathNum(item.profit) > 0,
                red: Public.toMathNum(item.profit) < 0,
              }"
              >{{ item.profit }}</span
            >
            <span
              :class="{
                green: Public.toMathNum(item.profitRate) > 0,
                red: Public.toMathNum(item.profitRate) < 0,
              }"
            >
              {{
                (item.profitRate ? Public.toMathNum(item.profitRate) : 0) + "%"
              }}
            </span>
          </div>
        </span>
      </div>
      <div class="no-data" v-if="!total">{{ $t("m.暂无数据") }}</div>
      <div class="pagination-warp" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { qureyStrategyOrderList } from "@/request/api";
import moment from "moment";
export default {
  props: ["executeId", "contractSize"],
  components: {},
  data() {
    return {
      moment,
      strategyOrderList: null,
      total: null,
      pageNo: 1,
      pageSize: 10,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询日志
    qureyStrategyOrderList() {
      let payload = {
        token: localStorage.getItem("token"),
        executeId: this.executeId,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      if (!this.executeId) {
        return;
      }
      qureyStrategyOrderList(payload).then((res) => {
        if (res.status == 200) {
          let strategyOrderList = res.data.strategyOrderList;
          strategyOrderList.forEach((e) => {
            // e.profit = Math.floor(e.profit * 1 0000) / 1 0000;
            e.profit = this.Public.toMathNum(e.profit);
            e.dealPrice = Number(e.dealPrice);
            e.profitRate = Math.floor(e.profitRate * 100) / 100;
          });
          this.strategyOrderList = strategyOrderList;
          this.total = Number(res.data.countNum);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.qureyStrategyOrderList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.qureyStrategyOrderList();
    },
  },
  created() {},
  mounted() {
    this.qureyStrategyOrderList();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.startegyTradeHistory {
  .startegyTradeHistory-warp {
    .label {
      margin: 10px 0 20px 0;
      font-size: 16px;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
    }
    .positions-cont-warp {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      span {
        width: 20%;
      }
      :last-child {
        text-align: right;
      }
    }
    .positions-cont {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 14px;
      padding: 0 10px;
      @include themeify {
        color: themed("primaryColor") !important;
      }
      .box {
        display: flex;
        flex-direction: column;
        line-height: 30px;
        width: 20%;
        .box-cont {
          display: flex;
          flex-direction: column;
          line-height: 20px;
          span {
            text-align: right;
          }
        }
      }
    }
  }
}
.no-data {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themeify {
    color: themed("secondaryColor") !important;
  }
}
.pagination-warp {
  width: 100%;
  height: 50px;
  padding-right: 40px;
  text-align: right;
  margin-top: 20px;
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  .el-pagination {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ::v-deep.el-pagination__sizes {
    height: auto;
    line-height: normal;
  }
}
</style>