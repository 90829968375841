<template>
  <div class="news">
    <!--导航栏-->
    <TopNavigation :menu="2" 
    :seoMetaTitle="seoMetaTitle" 
    :seoKeyWords="seoKeyWords" 
    :seoDescription="seoDescription" />

    <component v-if="current"
    :is="'NewsInfo'+current"
    
    :breadcrumb="breadcrumb"
    :data="data"
    :populars="populars"
    
    @tuiJian="tuiJianClick"
    @search="searchClick" />

    <Bottom />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getShowPage, getFitterValue} from "@/utils/function.js"


import news from "./components/news.js"
import {articleDetails, recommendedArticle} from "@/request/api.js"

import TopNavigation from "@/components/TopNavigation.vue";
import Bottom from "@/components/Bottom.vue";
import NewsInfoPc from "./newsInfoPc"
import NewsInfoH5 from "./newsInfoH5"
export default {
  components: {
    TopNavigation,
    Bottom,
    NewsInfoPc: NewsInfoPc,
    NewsInfoH5: NewsInfoH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      breadcrumb: undefined, //面包屑返回
      newsId: '', //文章ID

      data: undefined, //文章内容
      populars: undefined, //热门

      langId: 3, //文章固定死只有英文版本2024.5.8
    };
  },

  computed: {
    // ...mapGetters(['langId']),  //语言ID

    seoMetaTitle() {
      return this.data ? this.data.artTitle : '';
    },
    
    seoKeyWords() {
      return this.data ? this.data.seoKeyword : '';
    },
    
    seoDescription() {
      return this.data ? this.data.artIntroduce : '';
    }
  },

  watch: {

    langId(newVal, oldVal) {
      news.delectList();
      this.$router.push({ name: 'newsHome'});
    }
  },

  created() {

    this.initView();

    this.breadcrumb = news.getInfoBack();
    try {
      this.newsId = parseInt(this.$route.params.id); //news.getInfoId();
    } catch (error) {}
    if (this.newsId == '') {
      this.$router.push({ name: 'newsHome'});
      return;
    }
    this.httpArticleDetails();
    this.httpRecommendedArticle();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
      
      this.data && this.initData(this.data);
    },

    
    initData(data) {
      
      data.timeCn = this.Public.getTime(data.createTime, 'y/M/d');
      data.timeEn = this.Public.getTimeEn(data.createTime)
        
      var html = data.artContent;
      var p = getFitterValue(1);
      p = p > 1 ? 1 : p;
      html = html.replaceAll('<p></p>', '<p style=\"height:'+p+'em\"></p>');
      if (!html) return;

      var newHtml = '';
      var fonts = html.split("font-size:");
      fonts.forEach((str, i)=>{  

        if (i) {

          var pxs = str.split("px");
          var px = pxs[0];
          if(pxs.length > 1 && px.length<=3) {
            
            var font = getFitterValue(px);
            newHtml = newHtml + 'font-size:' + font + str.substr(px.length, str.length);
          } else {
            newHtml = newHtml + str;
          }
        } else {
          newHtml = str;
        }
      });
      
      data.html = newHtml;
      this.data = data;
    },

    /******************************************** https */
    //文章详情
    httpArticleDetails() {
      
      var param = {'id': this.newsId}
      articleDetails(param).then((res) => {
        
        if (res.status == 200) {

          var data = res.data;
          this.initData(data);
          
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //推荐文章
    httpRecommendedArticle() {

      var param = {}
      recommendedArticle(param).then((res) => {
        
        if (res.status == 200) {
          this.populars = res.data;
        } else {
          // this.$message.error(res.msg);
        }
      });
    },

    /******************************************** 点击 */
    //推荐文章点击
    tuiJianClick(item) {

      // news.setInfoId(item.id);
      // location.reload();
      this.$router.push({ name: 'newsInfo', params: {id: item.id}});
      location.reload();
    },
    
    //搜索
    searchClick(keyWord) {
      
      news.setSearchKey(keyWord);
      this.$router.push({ name: 'newsSearch'});
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.news {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;

  overflow: hidden;
  overflow-y: auto;
}

</style>
