<template>
  <div class="gridH5">
    <div class="warph5">
      
      <!--1 什么是网格交易？ -->
      <div class="oneh5" v-if="one">
          
        <div class="column">
          <span class="font24 fontx ch0 mb12">{{$t('m.'+one.span1)}}</span>
          <span class="font14 ch1 mb12">{{$t('m.'+one.span2)}}</span>
          <span class="font14 ch1">{{$t('m.'+one.span3)}}</span>
        </div>

        <img class="img-icqh5" :src="one.src1">
      </div>

      <!--2 网格交易适用于什么行情？ -->
      <div class="twoh5" v-if="two">
        <div class="column">
          <span class="font24 fontx ch0 mb12">{{$t('m.'+two.span1)}}</span>
          <span class="font14 ch1 mb12">{{$t('m.'+two.span2)}}</span>
          <span class="font14 ch1">{{$t('m.'+two.span3)}}</span>
        </div>
        <img class="img-icqh5" :src="two.src1">
      </div>

      <!--3 Vtrading 网格机器人的优势 -->
      <div class="threeh5" v-if="three">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.'+three.span1)}}</span>
        <span class="font14 ch1 mb12">{{$t('m.'+three.span2)}}</span>
        <span class="font14 ch1 mb16">{{$t('m.'+three.span3)}}</span>

        <div class="cellh5" v-for="(item, index) in three.cells" :key="'three'+index">
          <img :src="item.src1">
          <span class="font24 fontx ch0 mb10">{{$t('m.'+item.span1)}}</span>
          <span class="font14 ch3">{{$t('m.'+item.span2)}}</span>
        </div>
      </div>

      <!--4 观看网格讲解视频 -->
      <div class="fourh5" v-if="four">
        <img class="img-lefth5" :src="four.src1">
        <div class="img-righth5">
          <span class="font24 fontc ch0 mb40">{{$t('m.'+four.span1)}}</span>

          <RichBut @click="isVideo=true" 
          class="buth5" styleType="bhei">
          {{$t('m.'+four.but1.txt)}}
          </RichBut>
        </div>
      </div>

      <!--5 如何启动网格交易策略？ -->
      <div class="fiveh5" v-if="five">
          <span class="font24 fontx ch0 mb20">{{$t('m.'+five.span1)}}</span>

          <div class="cellh5" v-for="(item, index) in five.cells" :key="'five'+index">
            <img :src="item.src1">
            <span class="font14 ch0">{{$t('m.'+item.span1)}}</span>
          </div>
      </div>
    </div>


    <!-- 视频-->
    <div class="videoh5" v-if="isVideo">
      
      <div class="column aend">
        <div class="closeh5" @click="isVideo=false">
         <img src="@/assets/icon/detele_bai.svg"/>
        </div>

        <iframe class="videoh5-size"
        :src="four.video" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" 
        allowfullscreen />
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },
  
  data() {
    return {
      isVideo: false,
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.gridH5 {
  display: flex;
  flex-direction: column;
  .warph5 {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
  }

  /**** 1 什么是网格交易？*/
  .oneh5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 240px;
      height: 240px;
    }
  }
  
  /**** 2 网格交易适用于什么行情？*/
  .twoh5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    .img-icqh5 {
      width: 240px;
      height: 240px;
    }
  }
  
  /**** 3 Vtrading 网格机器人的优势*/
  .threeh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .cellh5 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #F5F8FA;
      border-radius: 6px;
      padding: 24px;
      margin-bottom: 8px;
      img {
        width: 96px;
        height: 96px;
        margin-bottom: 20px;
      }
    }
  }

  /**** 4 观看网格讲解视频*/
  .fourh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-lefth5 {
      width: 100%;
      height: auto;
    }
    .img-righth5 {
      display: flex;
      flex-direction: column;
      padding: 40px;
      width: 100%;
      height: auto;

      background-image: url('../../assets/image/bot/grid_4_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */
      .buth5 {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 44px;
        border-radius: 6px;
      }
    }

  }

  /**** 5 如何启动网格交易策略？*/
  .fiveh5 {
    display: flex;
    flex-direction: column;
    .cellh5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      margin-bottom: 14px;

      border-radius: 8px;
      width: 100%;
      background-image: url('../../assets/image/bot/grid_5_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
    }
  }


  /**** 视频播放*/
  .videoh5 {
    z-index: 889;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0,0,0,0.6);
    .closeh5 {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .videoh5-size {
      width: 100%;
      height: 220px;
    }
  }
}
</style>
