<template>
  <div class="strategyParameter">
    <div class="strategyParameter-warp">
      <div
        class="strategyParameter-box"
        v-for="(item, idx) in params"
        :key="idx"
      >
        <span class="name">
          {{ $store.state.lang == 'ch' ? item.name : item.enName }}
        </span>
        <span
          class="text"
          v-if="
            item.type == 'number-input' ||
            item.type == 'number' ||
            item.type == 'slide-input'
          "
        >
          {{ item.value || item.value == "0" ? item.value : "--" }}
        </span>
        <span class="text" v-if="item.type == 'radio'">
          {{
            item.value || item.value == "0"
              ? $store.state.lang == 'ch'
                ? item.radioName[item.value]
                : item.enRadioName[item.value]
              : "--"
          }}
        </span>
        <span class="text" v-if="item.type == 'switch'">
          {{ item.value ? $t("m.启用") : $t("m.关闭") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["params"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.strategyParameter {
  .strategyParameter-warp {
    .strategyParameter-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 30px;
      padding: 0 10px;
      @include themeify {
        border-top: 1px solid themed("splitline") !important;
      }
      // margin-bottom: 40px;
      .name {
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
      .text {
        margin: 5px 0;
        display: flex;
        justify-content: flex-end;
        @include themeify {
          color: themed("secondaryColor") !important;
        }
      }
      span {
        width: 50%;
      }
    }
    .strategyParameter-box:first-child {
      border: 0px !important;
    }
  }
}
</style>