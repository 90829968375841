<template>
  <div class="loginh5">

    <div class="menuh5">
      <RichBut class="img-backh5" @click="Public.toHome()">
        <template #default="{richState}">
          <img v-if="richState=='down'" src="@/assets/image/v3/guanbi_down.png">
          <img v-else src="@/assets/image/v3/guanbi.png">
        </template>
      </RichBut>

      <span class="font18 fontc ch1">{{$t("m.邮箱登录")}}</span>
    </div>

    <div class="contenth5-user">

      <RichInput class="mb20"
      v-model="user"
      :placeholder="$t('m.请输入邮箱')"
      :imgLeft="require('@/assets/image/v3/signin_user.png')"
      :imgLeftSelect="require('@/assets/image/v3/signin_user_.png')" />

      <RichInput class="mb14"
      v-model="paw"
      :password="true"
      :placeholder="$t('m.请输入密码')"
      :imgLeft="require('@/assets/image/v3/signin_lock.png')"
      :imgLeftSelect="require('@/assets/image/v3/signin_lock_.png')" />

      <div class="row acenter jend mb32">
        
        <RichBut class="font14" styleType="llan" @click="resetPawClick">
          <span>{{$t('m.忘记密码')}}</span>
        </RichBut>
      </div>

      <RichBut class="buth5-ok mb60" styleType="blan" @click="loginClick()">
        <span class="font16 fontc">{{$t('m.登录')}}</span>
      </RichBut>

      <div class="row jcenter">
        <span class="font14 ch7 mr2">{{$t('m.没有账号')}}</span>
        
        <RichBut class="font14" styleType="llan" @click="signupClick">
          <span>{{' '+$t('m.立即注册')}}</span>
        </RichBut>
      </div>
    </div>

    <!-- 安全验证 -->
    <el-dialog
    :title="$t('m.安全验证')"
    :visible.sync="verifyMap.verifyShow"
    width="80%"
    :close-on-click-modal="false">
      <!-- 安全验证 -->
      <template>
        <div class="dialog-warp">

          <div class="inp-warp">
              <el-dropdown trigger="click" v-if="isBandGa">
                
                <RichBut styleType='lhei'>
                  <span class="el-dropdown-link" style="cursor: pointer">
                    {{verifyMap.verifyType ? $t("m.谷歌验证") : $t("m.邮箱验证") }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                </RichBut>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="verifyMap.verifyType=true">
                    {{ $t("m.谷歌验证") }}
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="verifyMap.verifyType=false">
                    {{ $t("m.邮箱验证") }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </div>

          <div class="inp-warp">

            <el-input v-if="verifyMap.verifyType" v-model="gaCode" :placeholder="$t('m.请输入谷歌验证码')" />
            <el-input v-else v-model="emailCode" :placeholder="$t('m.请输入邮箱验证码')">
              <i slot="suffix">
                
                <RichBut v-if="downTimeEmail == 0" styleType='llan'
                @click="$emit('httpEndEmail', user)" >
                  <div class="get-code">{{ $t("m.获取验证码") }}</div>
                </RichBut>
                
                <div v-else class="get-code clan">
                  <span>{{ downTimeEmail + " s "}}</span>
                </div>
              </i>
            </el-input>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          
          <RichBut class="buth5-ok" styleType="blan" @click="verifyClick()">
            <span class="font16 fontc">{{$t('m.提交')}}</span>
          </RichBut>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue"
export default {
  components: {
    RichInput,
    RichBut,
  },

  
  props: {
    
    downTimeEmail: { //邮箱验证码倒计时
      type: Number,
      default :0
    },
    
    isBandGa: true, //true 绑定了GA验证
    verifyMap: { 
      type: Object,
      default :{
        verifyType: true, //true 谷歌验证
        verifyShow: false, //true 显示登录验证
      }
    }
  },
  
  data() {
    return {
      user: '', //账户
      paw: '', //密码

      emailCode: '', //邮箱验证码
      gaCode: '', //GA验证码

      userToken: undefined, //用户登录Token
      userInfo: undefined, //用户信息
    }
  },
  
  created() {
  },

  mounted() {
  },

  beforeDestroy() {
    if(this.timeout) clearTimeout(this.timeout);
  },

  methods: {


    /******************************* https */

    /******************************* 点击 */
    //登录
    loginClick() {
      this.$emit('login', this.user, this.paw);
    },

    //验证提交点击
    verifyClick() {
      var code = this.verifyMap.verifyType ? this.gaCode : this.emailCode;
      this.$emit('verify', this.user, code);
    },

    //注册点击
    signupClick() {
      this.$emit('signup');
    },

    //忘记密码点击
    resetPawClick() {
      this.$emit('resetPaw');
    }
  }
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.loginh5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 32px 60px 32px;
  .menuh5 {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin: 0 -32px 20px -32px;
    .img-backh5 {
      position: absolute;
      left: 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  
  .contenth5-user {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.buth5-ok {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
}
</style>


<style lang='scss' scoped>
  .dialog-warp {
    .inp-warp {
      color: #6782a4;
      margin: 10px 0;

      ::v-deep.el-input__suffix {
        height: 40px !important;
        line-height: 40px !important;
      }
      .get-code {
        height: 40px;
      }
    }
  }
  /deep/.el-dialog__body {
    padding-top: 0px !important;
  }
  /deep/.el-form-item__error {
    position: relative;
  }
  /deep/.el-input__inner {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    height: 40px !important;
    line-height: 40px !important;
    border-radius: 4px;
    padding: 0 !important;
  }
</style>