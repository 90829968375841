<template>
  <div class="news" ref="news">
    <!--导航栏-->
    <TopNavigation  :menu="2" />

    <component v-if="current"
    :is="'NewsHome'+current"  ref="nav"

    :type="type"
    :types="types"
    :typeMap="typeMap"

    :pageNo="pageNo"
    :pageSize="pageSize"
    :totalNum="totalNum"
    :datas="datas"

    @type="typeClick"
    @page="pageClick"
    @cell="cellClick"
    @search="searchClick" />

    <Bottom />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {getShowPage} from "@/utils/function.js"
import news from "./components/news.js"
import {articleList, getArticleType} from "@/request/api.js"

import TopNavigation from "@/components/TopNavigation.vue";
import Bottom from "@/components/Bottom.vue";
import NewsHomePc from "./newsHomePc"
import NewsHomeH5 from "./newsHomeH5"
export default {
  components: {
    TopNavigation,
    Bottom,
    NewsHomePc: NewsHomePc,
    NewsHomeH5: NewsHomeH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      type: 0, //类型选中
      types: [], //类型列表

      pageNo: 1, //页码
      pageSize: 6, //每页获取数量
      totalNum:0 , //总文章数量
      datas:[], //文章列表

      langId: 3, //文章固定死只有英文版本2024.5.8
    };
  },

  computed: {
    // ...mapGetters(['langId']),  //语言ID

    typeMap() {
      return this.type < this.types.length ? this.types[this.type] : undefined;
    }
  },

  watch: {

    langId(newVal, oldVal) {
      news.delectList();
      this.httpGetArticleType();
    }
  },

  created() {
    this.initView();
    this.httpGetArticleType();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {

    //初始化界面判断是H5 还是  PC
    initView() {

      var current = this.current;
      this.pageSize = getShowPage(6, 10);
      this.current = getShowPage('Pc', 'H5');
      if (current && current != this.current) {
        this.httpGetArticleType();
      }
    },

    //初始化数据
    initData(data) {

      this.totalNum = data.count;

      var datas = data.list;
      datas.forEach((item)=>{
        item.timeCn = this.Public.getTime(item.createTime, 'y/M/d');
        item.timeEn = this.Public.getTimeEn(item.createTime)
      })
      this.datas = datas;
    },

    /******************************************** https */
    //文章类型
    httpGetArticleType() {
      
      this.type = news.getType();
      this.pageNo = news.getPage(this.current)

      this.types = [];
      var param = {'languageId': this.langId}
      getArticleType(param).then((res) => {
        if (res.status == 200) {
          this.types = res.data;
          this.pageClick(this.pageNo);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    //文章列表
    httpArticleList() {

      var param = {
        'pageNo': this.pageNo, 
        'pageSize':this.pageSize, 
        'artTypeId': this.typeMap.id, 
        'languageId': this.langId}
      articleList(param).then((res) => {
        
        //切换语言，忽略上次请求
        if (param.languageId != this.langId) return;
        
        if (res.status == 200) {
          
          var data = res.data;
          this.initData(data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    /******************************************** 点击 */
    //类型点击
    typeClick(type) {
      this.type = type;
      news.setType(type);
      this.pageClick(1)
    },

    //页码点击
    pageClick(page) {
      
      this.pageNo = page;
      news.setPage(page, this.current);
      this.$refs.news && this.$refs.news.scrollTo(0,0);
      this.httpArticleList();
    },

    //cell点击
    cellClick(item) {
      
      // news.setInfoId(item.id)
      news.setInfoBack({'url':'newsHome', 'title':this.typeMap.artType, 'fy':true});
      this.$router.push({ name: 'newsInfo', params: {id: item.id}});
    },

    //搜索
    searchClick(keyWord) {
      
      news.delectList();
      news.setSearchKey(keyWord);
      this.$router.push({ name: 'newsSearch'});
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.news {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
