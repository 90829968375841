<template>
  <div class="login">
    <TopNavigation v-if="current == 'Pc'" />

    <component v-if="current"
    :is="'Login'+current"

    :downTimeEmail="downTimeEmail"
    :isBandGa="isBandGa"
    :verifyMap="verifyMap"

    @loginOk="loginOk"
    @login="loginClick"
    @verify="verifyClick"
    @signup="signupClick"
    @resetPaw="resetPawClick"
    @httpSendEmail="httpSendEmail"  />

  </div>
</template>

<script>
import {
  login,
  sendEmail,
  checkCode,
  checkGACode,
  getUserGAIsBind} from "@/request/api";
import TopNavigation from "@/components/TopNavigation.vue";
import LoginH5 from "./loginH5.vue"
import LoginPc from "./loginPc.vue"
import {getShowPage} from "@/utils/function.js"
export default {
  components: {
    TopNavigation,
    LoginH5,
    LoginPc
  },
  
  data() {
    return {
      current: undefined, // 当前渲染的组件

      timeoutEmail: undefined, //邮箱验证-定时器
      downTimeEmail: 0, //邮箱验证倒计时

      isBandGa: true, //true 绑定了GA验证
      verifyMap: {
        verifyType: true, //true 谷歌验证
        verifyShow: false, //true 显示登录验证
      },

      userToken: undefined, //用户登录Token
      userInfo: undefined, //用户信息
    };
  },

  created() {
    this.initView();
  },
  
  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    if(this.timeout) clearTimeout(this.timeout);
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    /******************************************** 其他 */
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    //更新倒计时-邮箱验证
    upDownTimeEmail(downTime) {

      if(downTime) this.downTimeEmail = downTime;
      if (this.downTimeEmail < 1) return;
      
      this.timeoutEmail && clearTimeout(this.timeoutEmail);
      this.timeoutEmail = setTimeout(() => {

        this.downTimeEmail--;

        this.upDownTimeEmail();
      }, 1000);
    },
    
    //登录成功
    loginOk(token, userInfo) {

      this.$message.success(this.$t("m.登录成功"));
      this.verifyMap.verifyShow = false;

      var accInfo = JSON.stringify(userInfo);
      localStorage.setItem("accInfo", accInfo);
      localStorage.setItem("token", token);

      this.Public.toHome();
    },

    /******************************* https */
    //http账号密码登录
    httpLogin(account, paw) {
      
      var password = this.$md5(paw);
      var param = {account, password};
      login(param).then((res) => {

        if (res.status == 200) {

          var data = res.data;
          
          this.userToken = data.token;
          this.userInfo = data.user;
          if (this.userInfo.loginCheck) {
            this.httpGetUserGAIsBind(account);
          } else {
            this.loginOk(this.userToken, this.userInfo);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //http获取Ga绑定状态
    httpGetUserGAIsBind(email) {

      var param = {email};
      getUserGAIsBind(param).then((res) => {

        if (res.status == 200) {
          this.isBandGa = res.data;
          this.verifyMap.verifyType = res.data;
          this.verifyMap.verifyShow = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    //http验证GA
    httpCheckGACode(email, gaCode) {

      let payload = {email,gaCode};
      checkGACode(payload).then((res) => {

        if (res.status == 200) {
          this.loginOk(this.userToken, this.userInfo);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //http验证邮箱
    httpCheckCode(email, valicode) {

      let payload = {
        'token': this.userToken,
        'email': email,
        'valicode': valicode,
      };
      checkCode(payload).then((res) => {
        if (res.status == 200) {
          this.loginOk(this.userToken, this.userInfo);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    //http发送邮箱验证码
    httpSendEmail(email) {

      let param = {
        'token': this.userToken,
        'email': email};
      sendEmail(param).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.发送成功"));
          this.upDownTimeEmail(60);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    /******************************* 点击 */
    //登录
    loginClick(user, paw) {

      if(!user) {
        this.$message.warning(this.$t('m.请输入邮箱'));
        return;
      }

      if(!paw) {
        this.$message.warning(this.$t('m.请输入密码'));
        return;
      }

      this.httpLogin(user, paw);
    },

    //验证提交点击
    verifyClick(user, code) {
      
      if (this.verifyMap.verifyType) {

        code ?  this.httpCheckGACode(user, code) 
        : this.$message.warning(this.$t("m.请输入谷歌验证码"));
        return;
      }

      code ?  this.httpCheckCode(user, code) 
      : this.$message.warning(this.$t("m.请输入邮箱验证码"));
    },

    //注册点击
    signupClick() {
      this.Public.toRegister();
    },

    //忘记密码点击
    resetPawClick() {
      this.Public.toResetPass()
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.login {
  display: flex;
  flex-direction: column;
  background: #FFF;
  width: 100%;
  height: 100%;
}

</style>

