<template>
  <div class="newsInfoH5">

    <RichInput class="search5"
    v-model="keyWord"
    :placeholder="$t('m.请输入搜索内容')"
    @enter="searchClick">
      <template #right="{select}">
        <img v-if="select" @click.stop="searchClick"
        class="img-search5 but" src="@/assets/image/news/sousuo.png">
        
        <img v-else style="opacity:0.3"
        class="img-search5" src="@/assets/image/news/sousuo.png">
      </template>
    </RichInput>
    
    <el-breadcrumb class="mb40" separator="/">

      <el-breadcrumb-item :to="{ path: '/newsHome' }">
      {{$t('m.量化学院')}}
      </el-breadcrumb-item>

      <el-breadcrumb-item v-if="breadcrumb" :to="{ path: '/'+breadcrumb.url }">
        {{breadcrumb.fy ? breadcrumb.title : $t('m.'+breadcrumb.title)}}
      </el-breadcrumb-item>

      <el-breadcrumb-item>
        {{$t('m.文章')}}
      </el-breadcrumb-item>
    </el-breadcrumb>
    
    <template v-if="data">

      <span class="font30 fontc ch1 mb10">{{data.artTitle}}</span>
      
      <span class="font10 ch3 mb30">{{$t('m.'+'发布于') +' ' + ($store.state.lang == 'en' ? data.timeEn  : data.timeCn)}}</span>

      <div class="htmlh5" v-html="data.html"></div>
    </template>


    <span class="font20 fontc ch1 mb20">{{$t('m.推荐文章')}}</span>

    <RichBut class="font16 mb20 texth5" styleType="lhei"
    v-for="(item, index) in populars" :key="'tuijian'+index"
    @click="tuiJianClick(item)">
      <span>{{item.artTitle}}</span>
    </RichBut>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue";
export default {
  components: {
    RichInput,
    RichBut,
  },


  props: {
    breadcrumb: Object,
    data: Object,
    populars: Array
  },

  data() {
    return {
      keyWord: '', //搜索关键字
    };
  },

  computed: {
    ...mapState(['lang']),  //语言ID
  },
  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {

    /******************************************** 点击 */
    //搜索点击
    searchClick() {
      this.$emit('search', this.keyWord);
    },

    //推荐文章点击
    tuiJianClick(item) {
      this.$emit('tuiJian', item)
    }
   
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.newsInfoH5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 16px;

  .search5 {
    height: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid rgba(38,112,250,0.3);
    margin-bottom: 20px;
  }
  .img-search5 {
    width: 18px;
    height: 18px;
    margin-left: 12px;
  }
  .htmlh5 {
    color: #181C32;
    margin-bottom: 40px;
    img {
      max-width: 100%;
      height: auto !important;
    }
  }
  
  .warppc {
    display: flex;
    flex-direction: column;
    .cellh5 {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .img-cellpc {
        overflow: hidden;
        width: 100%;
        height: 190px;
        border-radius: 4px;
        border: 1px solid #F3F7FF;
        margin-bottom: 8px;
      }
    }

    .pageh5 {    
      display: flex;
      align-items: center;
      justify-content: center;
      .el-pager {
        display: flex;
        align-items: center;
      }
      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 30px;

        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
      }
      .active {
        background: #2670FA;
        color: #fff;
      }
    }

    .emptyh5 {
      margin: 120px 0;
    }
  }
}
</style>

