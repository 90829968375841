<template>
  <el-dialog
    :title="$t('m.手动加仓')"
    :visible.sync="addManualShow.value == 1"
    :before-close="handleClose"
    width="366px"
    class="manualBox"
  >
    <div class="manualItem">
      <div class="manual-name">{{ $t("m.委托方向") }}</div>
      <el-select
        v-model="patternValue"
        :disabled="addManualList.robotPattern !== '2'"
        :placeholder="$t('m.请选择')"
      >
        <el-option
          v-for="item in [$t('m.开多'), $t('m.开空')]"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div class="manualItem">
      <div class="manual-name">{{ $t("m.委托类型") }}</div>
      <el-select v-model="addManualValue" :placeholder="$t('m.请选择')">
        <el-option
          v-for="item in [$t('m.市价委托'), $t('m.限价委托')]"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div class="manualItem">
      <div class="manual-name">{{ $t("m.委托价格") }}</div>
      <el-input
        type="text"
        :disabled="addManualValue == $t('m.市价委托')"
        v-model="addManualPrice"
        @change="addManualValueChange()"
        :placeholder="$t('m.请输入价格')"
      >
        <template slot="suffix">
          <span class="manual-icon">
            {{ addManualList ? addManualList.baseCoin : "USDT" }}
          </span>
        </template>
      </el-input>
    </div>
    <div class="manualItem">
      <div class="manual-name">{{ $t("m.委托数量") }}</div>
      <el-input
        type="number"
        @change="addManualNumChange()"
        v-model="addManualNum"
        :placeholder="$t('m.请输入数量')"
      >
        <template slot="suffix">
          <span class="manual-icon">{{
            addManualList ? addManualList.counterCoin : "BTC"
          }}</span>
        </template>
      </el-input>
    </div>
    <div class="manualItem">
      <div class="green">
        {{ $t("m.金额") + ":" + (addManualNum ? addManualPriceNum : "--") }}
      </div>
      <div class="green">
        {{
          $t("m.可用") +
          ":" +
          (assets ? assets.available : 0) +
          (addManualList ? addManualList.baseCoin : " USDT")
        }}
      </div>
    </div>
    <div slot="footer" class="open-footer">
      <div class="closeBtn" @click="handleClose()">{{ $t("m.取消") }}</div>
      <div class="clickBtn" @click="addPosition('open')">
        {{ $t("m.确定") }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  querylastIncrease,
  synchronSingelCloudApi,
  addPosition,
  queryContractPrecision,
} from "@/request/api";
export default {
  props: { addManualShow: {}, addManualList: {} },
  data() {
    return {
      addManualValue: this.$t("m.市价委托"),
      addManualPrice: null, //加仓委托价格
      addManualNum: null, //加仓委托数量
      assets: null, //资产
      addManualPriceNum: null, //数量*金额
      patternValue:
        this.addManualList.robotPattern == 1
          ? this.$t("m.开多")
          : this.$t("m.开空"),
      contractPrecision: null,
    };
  },
  watch: {
    addManualNum() {
      if (isNaN(Number(this.addManualNum))) {
        this.addManualNum =
          this.addManualNum.split(".")[0] +
          "." +
          this.addManualNum.split(".")[1];
      }
      if (this.addManualNum) {
        if (
          this.addManualNum.toString().indexOf(".") > -1 &&
          this.addManualList.contractSize <
            this.addManualNum.toString().length -
              this.addManualNum.toString().indexOf(".")
        ) {
          let value = this.addManualNum.slice(
            0,
            this.addManualNum.indexOf(".") + this.addManualList.contractSize
          );
          this.addManualNum = Number(value).toString();
        }
        this.addManualPriceNum = this.Public.toMathNum(
          this.addManualNum * this.addManualPrice
        );
        //  this.addManualPriceNum = Math.ceil(this.addManualNum * this.addManualPrice * 1 0000) / 1 0000;
      }
    },
    addManualPrice() {
      if (isNaN(Number(this.addManualPrice))) {
        this.addManualPrice =
          this.addManualPrice.split(".")[0] +
          "." +
          this.addManualPrice.split(".")[1];
      }
      if (
        this.addManualPrice &&
        this.addManualPrice.toString().indexOf(".") > -1
      ) {
        this.addManualPrice = this.contractPrecision
          ? this.addManualPrice
              .toString()
              .slice(
                0,
                this.addManualPrice.toString().indexOf(".") +
                  Number(this.contractPrecision.pricePrecision) +
                  1
              )
          : this.addManualPrice
              .toString()
              .slice(0, this.addManualPrice.toString().indexOf(".") + 7);
      }
    },
  },
  methods: {

    //手动加仓
    addPosition() {

      if (!this.addManualNum) {
        return this.$message.warning(this.$t("m.请输入数量"));
      } else if (isNaN(Number(this.addManualNum))) {
        return this.$message.warning(this.$t("m.请输入正确的数量"));
      } else if (this.addManualPriceNum > this.assets.available) {
        return this.$message.warning(this.$t("m.金额不能大于可用金额"));
      }

      if (this.addManualList.exchangeName == "Binance") {
        if (this.addManualPriceNum < 10) {
          return this.$message.warning(this.$t("m.金额不能小于") + "10USDT");
        }
      }


      let payload = {
        token: localStorage.getItem("token"),
        executeId: this.addManualList.executeId,
        amount:
          this.addManualNum * Math.pow(10, this.addManualList.contractSize - 1),
        priceType: this.addManualValue == this.$t("m.市价委托") ? "1" : "0",
        price:
          this.addManualValue == this.$t("m.市价委托")
            ? null
            : this.addManualPrice,
        direction:
          this.addManualList.robotPattern == 2
            ? this.patternValue == "开多"
              ? "1"
              : "0"
            : this.addManualList.robotPattern + "",
      };
      console.log('3')
      addPosition(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("手动加仓成功");
          this.handleClose();
          localStorage.setItem("addPosition", true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取币对涨跌幅
    querylastIncrease() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeName: this.addManualList.exchangeName,
        transactionType: this.addManualList.isContract,
        counterCoin: this.addManualList.counterCoin,
        baseCoin: this.addManualList.baseCoin,
      };
      querylastIncrease(payload).then((res) => {
        if (res.status == 200) {
          let result = res.data.result;
          result = JSON.parse(result);
          this.addManualPrice = Number(result.last);
        }
      });
    },
    // 同步资产
    synchronSingelCloudApi() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.addManualList.apiId,
      };
      synchronSingelCloudApi(payload).then((res) => {


        if (res.status == 200) {
          let dataList = null;
          for (let item in res.data) {
            dataList = res.data[item];
          }

          if (dataList.isExpire) {
            this.$message.warning(this.$t("m.API已过期,请重新录入"));
            return;
          }

          if (this.addManualList.isContract == "contract_usdt") {
            if (!dataList.linerSwapAssets || dataList.linerSwapAssets.length == 0) {
              this.assets = null;
              return;
            }

            let assets = null;
            console.log(this.addManualList)
            if (this.addManualList.exchangeName == "OkexV5") {
              dataList.linerSwapAssets[0].available = this.Public.toMathNum(
                dataList.linerSwapAssets[0].available
              );
              assets = dataList.linerSwapAssets[0];
            } else {
              let linerSwapAssets = dataList.linerSwapAssets;
              linerSwapAssets.forEach((e) => {
                if (e.currency == this.addManualList.baseCoin) {
                  e.available = this.Public.toMathNum(e.available);
                  assets = e;
                }
              });
            }
            this.assets = assets;
          } else {
            if (!dataList.accountAssets || dataList.accountAssets.length == 0) {
              this.assets = null;
              return;
            }
            let accountAssets = dataList.accountAssets;
            let assets = null;
            accountAssets.forEach((e) => {
              if (e.currency == this.addManualList.baseCoin) {
                if (e.available.toString().indexOf(".") > -1) {
                  e.available = e.available
                    .toString()
                    .slice(0, e.available.toString().indexOf(".") + 7);
                }
                assets = e;
              }
            });
            this.assets = assets;
          }
        }
      });
    },

    //数量change
    addManualNumChange() {
      if (!this.addManualNum) {
        return this.$message.warning(this.$t("m.请输入数量"));
      }
      if (isNaN(Number(this.addManualNum))) {
        return this.$message.warning(this.$t("m.请输入正确的数量"));
      }
      if (this.assets && this.addManualPriceNum > this.assets.available) {
        this.$message.warning(this.$t("m.金额不能大于可用金额"));
      }
      
      if (this.addManualList.exchangeName == "Binance") {
        if (this.addManualPriceNum < 10) {
          this.$message.warning(this.$t("m.金额不能小于") + "10USDT");
        }
      }
    },
    //金额change
    addManualValueChange() {
      if (!this.addManualValue) {
        return this.$message.warning(this.$t("m.请输入数量"));
      }
      if (isNaN(Number(this.addManualValue))) {
        return this.$message.warning(this.$t("m.请输入正确的数量"));
      }
    },
    handleClose() {
      this.addManualShow.value = 0;
      this.addManualNum = null;
    },
    // 获取币对价格精度
    queryContractPrecision() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeName: this.addManualList.exchangeName,
        contractIdStr: this.addManualList.transactionPair,
      };
      queryContractPrecision(payload).then((res) => {
        if (res.status == 200) {
          let contractPrecisionList = res.data.contractPrecision;
          let contractPrecision = null;
          for (let item in contractPrecisionList) {
            contractPrecision = contractPrecisionList[item];
          }
          if (this.addManualList.exchangeName == "OkexV5") {
            if (contractPrecision.pricePrecision.indexOf(".") > -1) {
              contractPrecision.pricePrecision =
                contractPrecision.pricePrecision.length -
                contractPrecision.pricePrecision.indexOf(".") -
                1;
            } else {
              contractPrecision.pricePrecision = 1;
            }
          }
          this.contractPrecision = contractPrecision;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  mounted() {
    this.synchronSingelCloudApi();
    this.querylastIncrease();
    this.queryContractPrecision();
  },
};
</script>

<style scoped lang='scss'>
.manualBox {
  .manualItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .manual-name {
      display: flex;
      align-items: center;
      width: 100px;
    }
    .manual-span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
    }
    .manual-icon {
      position: relative;
      top: 10px;
      left: -10px;
    }
    .el-select {
      flex: 1;
    }
    .el-input {
      flex: 1;
    }
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>