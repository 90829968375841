<template>
  <div class="strategyStates">
    <div class="positions-warp">
      <p class="label">{{ $t("m.持仓") }}</p>
      <div class="positions-cont">
        <span>{{ $t("m.方向") }}</span>
        <span>{{ $t("m.持仓数量") }}</span>
        <span :style="$store.state.lang == 'en' ? 'width:130px' : ''">
          {{ $t("m.开仓均价") + "(USDT)" }}
        </span>
        <span style="text-align: right">{{ $t("m.强平价") + "(USDT)" }}</span>
        <span
          :style="$store.state.lang == 'en' ? 'min-width:160px' : ''"
          style="text-align: right"
          >{{ $t("m.持仓收益") + "(USDT)" }}
        </span>
      </div>

      <template v-if="buyPosition || sellPosition">
        <div
          class="positions-cont"
          v-if="(robotPattern == 1 || robotPattern == 2) && buyPosition"
        >
          <span class="green">{{ $t("m.做多") }}</span>
          <span>
            {{ Public.toMathNum(Number(buyPosition.volume) * contractSize) }}
          </span>
          <span :style="$store.state.lang == 'en' ? 'min-width:130px' : ''">
            <!-- {{ Math.floor(Number(buyPosition.costHold) * 10 0000) / 10 0000 }} -->
            {{ Public.toMathNum(buyPosition.costHold) }}
          </span>
          <span class="box-cont">
            <div v-if="contractPrecision">
              {{
                Number(buyPosition.volume) !== 0
                  ? liquidationPrice && liquidationPrice !== "0"
                    ? liquidationPrice.indexOf(".") > -1
                      ? Number(
                          liquidationPrice.slice(
                            0,
                            liquidationPrice.indexOf(".") +
                              Number(contractPrecision.pricePrecision) +
                              1
                          )
                        )
                      : liquidationPrice
                    : "--"
                  : "--"
              }}
            </div>
          </span>
          <span
            class="box-cont"
            :style="$store.state.lang == 'en' ? 'min-width:160px' : ''"
          >
            <div
              :class="{
                green: Public.toMathNum(buyPosition.profit) > 0,
                red: Public.toMathNum(buyPosition.profit) < 0,
              }"
            >
              {{ Public.toMathNum(buyPosition.profit) }}
            </div>
          </span>
        </div>

        <div
          class="positions-cont"
          v-if="(robotPattern == 0 || robotPattern == 2) && sellPosition"
        >
          <span class="red">{{ $t("m.做空") }}</span>
          <span>
            {{ Public.toMathNum(Number(sellPosition.volume) * contractSize) }}
          </span>
          <span :style="$store.state.lang == 'en' ? 'min-width:130px' : ''">
            <!-- {{ Math.floor(Number(sellPosition.costHold) * 10 0000) / 10 0000 }} -->
            {{ Public.toMathNum(sellPosition.costHold) }}
          </span>
          <span class="box-cont">
            <div>
              {{
                Number(sellPosition.volume) !== 0
                  ? liquidationPrice && liquidationPrice !== "0"
                    ? liquidationPrice.indexOf(".") > -1
                      ? liquidationPrice.slice(
                          0,
                          liquidationPrice.indexOf(".") +
                            Number(contractPrecision.pricePrecision) +
                            1
                        )
                      : liquidationPrice
                    : "--"
                  : "--"
              }}
            </div>
          </span>
          <span
            class="box-cont"
            :style="$store.state.lang == 'en' ? 'min-width:160px' : ''"
          >
            <div
              :class="{
                green: Public.toMathNum(sellPosition.profit) > 0,
                red: Public.toMathNum(sellPosition.profit) < 0,
              }"
            >
              {{ Public.toMathNum(sellPosition.profit) }}
            </div>
          </span>
        </div>

        <div class="ops-warp">
          <span
            class="clickBtn add green greenBorder"
            @click="addManualClick()"
          >
            {{ $t("m.加仓") }}
          </span>
          <span class="clickBtn close" @click="closePositionClick()">
            {{ $t("m.市价平仓") }}
          </span>
        </div>
      </template>

      <div class="no-data" v-else>
        {{ $t("m.暂无数据") }}
      </div>

      <!-- 委托 -->
      <div class="open-order">
        <p class="label">{{ $t("m.当前委托") }}</p>
        <div class="order-title">
          <span style="min-width: 150px">{{ $t("m.时间") }}</span>
          <span>{{ $t("m.方向") }}</span>
          <span>{{ $t("m.委托数量") }}</span>
          <span style="text-align: right; width: 150px">
            {{ $t("m.委托价格") }}
          </span>
          <span style="text-align: right">{{ $t("m.来源") }}</span>
          <span style="text-align: right">{{ $t("m.操作") }}</span>
        </div>
        <template v-if="orders">
          <div class="order-title" v-for="(item, idx) in orders" :key="idx">
            <span style="min-width: 150px">
              {{ moment(item.orderTime).format("YYYY/MM/DD HH:mm") }}
            </span>
            <span
              class="red"
              v-if="item.tradeOffset == '1' && item.tradeType == '1'"
            >
              {{ $t("m.开多") }}
            </span>
            <span
              class="red"
              v-if="item.tradeOffset == '0' && item.tradeType == '1'"
            >
              {{ $t("m.平空") }}
            </span>
            <span
              class="green"
              v-if="item.tradeOffset == '0' && item.tradeType == '0'"
            >
              {{ $t("m.平多") }}
            </span>
            <span
              class="green"
              v-if="item.tradeOffset == '1' && item.tradeType == '0'"
            >
              {{ $t("m.开空") }}
            </span>
            <span>
              {{
                Public.toMathNum(item.amount * contractSize) +
                " (" +
                item.counterCoin +
                ")"
              }}
            </span>
            <span style="text-align: right; width: 150px">
              {{ Number(item.price) + " (" + item.baseCoin + ")" }}
            </span>
            <span style="text-align: right">
              {{ item.sourceType == 1 ? $t("m.手动操作") : $t("m.机器人") }}
            </span>
            <u
              class="red"
              style="cursor: pointer; text-align: right"
              @click="revokeEntrust(item)"
            >
              {{ item.copyOrderId ? $t("m.撤销中") : $t("m.撤销") }}
            </u>
          </div>
        </template>
        <div class="no-data" v-else>
          {{ $t("m.暂无数据") }}
        </div>
      </div>

      <div class="line-warp" v-if="chartsData">
        <LineCharts
          height="260px"
          chartsId="line"
          :data="chartsData.performanceList"
          :label="$t('m.机器人表现')"
          :name="$t('m.机器人表现') + 'USDT'"
        />
      </div>
      <div class="histogram-warp">
        <BarCharts
          :label="$t('m.每日利润')"
          height="300px"
          chartsId="bar"
          :name="$t('m.每日利润') + '( USDT )'"
          :data="chartsData.everyList"
        />
      </div>
    </div>

    <!-- 手动加仓 -->
    <AddManual
      v-if="addManualShow.value == 1"
      :addManualShow="addManualShow"
      :addManualList="addManualList"
    />
    <!-- 市价平仓 -->
    <el-dialog
      :title="$t('m.市价平仓')"
      :visible.sync="closePositionShow"
      v-if="closePositionList"
      :before-close="handleClose"
      width="366px"
      class="manualBox"
    >
      <div class="manualItem">
        <div class="manual-name">{{ $t("m.委托方向") }}</div>
        <el-select
          v-model="closePositionValue"
          v-if="closePositionList.robotPattern == 2"
          :placeholder="$t('m.请选择')"
        >
          <el-option
            v-for="item in [
              { name: $t('m.平多'), value: '平多' },
              { name: $t('m.平空'), value: '平空' },
            ]"
            :key="item.name"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-else>
          {{
            closePositionList.robotPattern == 1 ? $t("m.平多") : $t("m.平空")
          }}
        </span>
      </div>
      <div class="manualItem">
        <div class="manual-name">{{ $t("m.委托类型") }}</div>
        <div>{{ $t("m.市价委托") }}</div>
      </div>
      <div class="manualItem">
        <div class="manual-name">{{ $t("m.委托数量") }}</div>
        <div>
          <span
            v-if="
              closePositionList.robotPattern == 1 ||
              (closePositionList.robotPattern == 2 &&
                closePositionValue == '平多')
            "
          >
            {{
              Public.toMathNum(closePositionList.buyPosition.volume) *
              closePositionList.contractSize
            }}
          </span>
          <span
            v-if="
              closePositionList.robotPattern == 0 ||
              (closePositionList.robotPattern == 2 &&
                closePositionValue == '平空')
            "
          >
            {{
              Public.toMathNum(closePositionList.sellPosition.volume) *
              closePositionList.contractSize
            }}
          </span>
          {{ closePositionList.counterCoin }}
        </div>
      </div>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="handleClose()">{{ $t("m.取消") }}</div>
        <div class="clickBtn" @click="closePosition()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import LineCharts from "@/components/lineCharts.vue";
import BarCharts from "@/components/barCharts.vue";
import AddManual from "@/components/addManual.vue";
import {
  getEntrustOrders,
  qureyStrategyPosition,
  revokeEntrust,
  closePosition,
  queryContractPrecision,
} from "@/request/api";
export default {
  props: {
    chartsData: {
      type: Object,
    },
    robotPattern: String,
    pairDataList: {},
  },
  components: {
    LineCharts,
    BarCharts,
    AddManual,
  },
  data() {
    return {
      moment,
      orders: null,
      liquidationPrice: null,
      sellPosition: null,
      buyPosition: null,
      addManualShow: {
        value: 0, //加仓显隐
      },
      closePositionShow: null, //市价平仓弹框显隐
      closePositionList: null, //市价平仓数据
      contractSize: null, //合约汇率
      closePositionValue: "平多",
      contractPrecision: null,
    };
  },
  computed: {},
  watch: {
    "addManualShow.value"() {
      if (
        this.addManualShow.value == 0 &&
        localStorage.getItem("addPosition")
      ) {
        setTimeout(() => {
          this.getEntrustOrders();
        }, 1000);
        localStorage.removeItem("addPosition");
      }
    },
  },
  methods: {
    // 委托
    getEntrustOrders() {
      let executeId = localStorage.getItem("executeId");
      if (!executeId) {
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        executeId: executeId,
      };
      getEntrustOrders(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.countNum == 0) {
            this.orders = null;
            return;
          }
          let orders = res.data.orderList;
          let copyOrderId = localStorage.getItem("copyOrderId");
          if (copyOrderId) {
            copyOrderId = JSON.parse(copyOrderId);
            let copyList = [];
            copyOrderId.forEach((item) => {
              orders.forEach((e) => {
                if (e.id == item) {
                  e.copyOrderId = true;
                  copyList.push(e.id);
                }
              });
            });
            if (copyList.length == 0) {
              localStorage.removeItem("copyOrderId");
            } else {
              localStorage.setItem("copyOrderId", JSON.stringify(copyList));
            }
          }
          this.orders = orders;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 持仓
    qureyStrategyPosition() {
      let executeId = localStorage.getItem("executeId");
      if (!executeId) {
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        executeId: executeId,
      };
      qureyStrategyPosition(payload).then((res) => {
        if (res.status == 200) {
          let position = res.data.position;
          this.contractSize = res.data.contractSize;
          if (!position && position.length == 0) {
            return;
          }
          this.sellPosition = position.sellPosition;
          this.buyPosition = position.buyPosition;
          if (this.robotPattern == 0 || this.robotPattern == 2) {
            if (
              position.sellPosition.costHold * 10 <
              position.liquidationPrice
            ) {
              position.liquidationPrice = 0;
            }
          } else {
            if (
              position.buyPosition.costHold * 10 <
              position.liquidationPrice
            ) {
              position.liquidationPrice = 0;
            }
          }
          this.liquidationPrice = position.liquidationPrice;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 撤销
    revokeEntrust(v) {
      let payload = {
        token: localStorage.getItem("token"),
        executeId: localStorage.getItem("executeId"),
        orderId: v.id,
      };
      revokeEntrust(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.撤销成功"));
          let copyOrderId = localStorage.getItem("copyOrderId");
          if (copyOrderId) {
            copyOrderId = JSON.parse(copyOrderId);
            copyOrderId.push(v.id);
          } else {
            copyOrderId = [];
            copyOrderId.push(v.id);
          }
          localStorage.setItem("copyOrderId", JSON.stringify(copyOrderId));
          this.getEntrustOrders();
          this.qureyStrategyPosition();
        } else {
          this.$message.error(res.msg);
          this.getEntrustOrders();
        }
      });
    },
    // 加仓
    addManualClick() {
      this.addManualShow.value = 1;
      let addManualList = this.sellPosition
        ? this.sellPosition
        : this.buyPosition;
      addManualList.robotPattern = this.robotPattern;
      addManualList.baseCoin = this.pairDataList.baseCoin;
      addManualList.counterCoin = this.pairDataList.counterCoin;
      addManualList.apiId = this.pairDataList.apiId;
      addManualList.exchangeName = this.pairDataList.exchangeName;
      addManualList.isContract = "contract_usdt";
      addManualList.executeId = this.pairDataList.executeId;
      addManualList.transactionPair = this.pairDataList.transactionPair;
      if (this.contractSize.toString().indexOf(".") > -1) {
        addManualList.contractSize =
          this.contractSize.toString().length -
          this.contractSize.toString().indexOf(".");
      } else {
        addManualList.contractSize = 1;
      }

      this.addManualList = addManualList;
    },
    //市价平仓按钮
    closePositionClick() {
      this.closePositionList = {
        sellPosition: this.sellPosition,
        buyPosition: this.buyPosition,
        robotPattern: this.robotPattern,
        contractSize: this.contractSize,
      };
      this.closePositionShow = true;
    },
    //市价平仓
    closePosition() {
      let value = 0;
      if (this.closePositionList.robotPattern == 2) {
        if (this.closePositionValue == "平多") {
          value =
            this.Public.toMathNum(this.closePositionList.buyPosition.volume) *
            this.closePositionList.contractSize;
        } else {
          value =
            this.Public.toMathNum(this.closePositionList.sellPosition.volume) *
            this.closePositionList.contractSize;
        }
      } else if (this.closePositionList.robotPattern == 1) {
        this.closePositionValue = "平多";
        value =
          this.Public.toMathNum(this.closePositionList.buyPosition.volume) *
          this.closePositionList.contractSize;
      } else {
        this.closePositionValue = "平空";
        value =
          this.Public.toMathNum(this.closePositionList.sellPosition.volume) *
          this.closePositionList.contractSize;
      }
      if (value == 0) {
        this.$message.error("当前没有可平仓位");
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        executeId: localStorage.getItem("executeId"),
        direction: this.closePositionValue == "平多" ? "1" : "0",
      };
      closePosition(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.操作成功"));
          this.closePositionShow = false;
          this.qureyStrategyPosition();
        } else {
          this.$message.success(res.msg);
        }
      });
    },
    handleClose() {
      this.addManualShow.value = 0;
      this.closePositionShow = false;
      this.closePositionValue = "平多";
    },
    // 获取币对价格精度
    queryContractPrecision() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeName: this.pairDataList.exchangeName,
        contractIdStr: this.pairDataList.transactionPair,
      };
      queryContractPrecision(payload).then((res) => {
        if (res.status == 200) {
          let contractPrecisionList = res.data.contractPrecision;
          let contractPrecision = null;
          for (let item in contractPrecisionList) {
            contractPrecision = contractPrecisionList[item];
          }
          if (contractPrecision == "") {
            this.contractPrecision = null;
            return;
          }
          if (this.pairDataList.exchangeName == "OkexV5") {
            if (
              contractPrecision.pricePrecision &&
              contractPrecision.pricePrecision.indexOf(".") > -1
            ) {
              contractPrecision.pricePrecision =
                contractPrecision.pricePrecision.length -
                contractPrecision.pricePrecision.indexOf(".") -
                1;
            } else {
              contractPrecision.pricePrecision = 1;
            }
          }
          this.contractPrecision = contractPrecision;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getEntrustOrders();
    this.queryContractPrecision();
    this.qureyStrategyPosition();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    localStorage.removeItem("copyOrderId");
  },
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.strategyStates {
  .positions-warp {
    .label {
      margin: 10px 0 20px 0;
      font-size: 16px;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
    }
    .positions-cont {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 14px;
      padding: 10px;
      @include themeify {
        color: themed("primaryColor") !important;
        background: #fcfcfc;
      }
      span {
        width: 18%;
      }
      .box-cont {
        display: flex;
        flex-direction: column;
        line-height: 20px;
        div {
          text-align: right;
        }
      }
    }
    .ops-warp {
      display: flex;
      align-items: center;
      margin-top: 30px;
    }
    .add {
      width: 107px;
      @include themeify {
        background: themed("inpBack") !important;
      }
    }
    .close {
      min-width: 107px;
      background: #0895f7 !important;
      margin-left: 20px;
    }
  }
  .open-order {
    margin-top: 20px;
    .label {
      margin: 10px 0 20px 0;
      font-size: 16px;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
    }
    .order-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 10px 10px;
      @include themeify {
        color: themed("primaryColor") !important;
        background: #fcfcfc;
      }
      span,
      u {
        width: 18%;
      }
    }
    .cancel {
      text-align: right;
      padding-right: 32px;
    }
  }
  .no-data {
    text-align: center;
    padding: 30px 0 10px 0;
    @include themeify {
      color: themed("secondaryColor") !important;
      background: #fcfcfc;
    }
  }
  .line-warp {
    width: 100%;
  }
  .histogram-warp {
    margin-top: 30px;
  }
}
.manualBox {
  .manualItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .manual-name {
      display: flex;
      align-items: center;
      width: 100px;
    }
    .el-select {
      flex: 1;
    }
    .el-input {
      flex: 1;
    }
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
/deep/.lineCharts .title-warp {
  padding: 20px 0;
}
/deep/.barCharts .title-warp {
  padding: 20px 0;
}
</style>