<template>
  <div class="short">
    <div class="label">
      <span>{{ $t("m.开空") }}</span>
      <span>{{ $t("m.价格") }}</span>
      <span>{{ $t("m.平空") }}</span>
    </div>
    <div id="cont-warp" class="cont-warp">
      <div class="box-warp">
        <div class="cont-box">
          <div
            class="item"
            :class="{
              gridItem: item.gridList && item.gridList.length != 0,
              gray: item.value < marketPrice,
              redBack: item.gridList && item.gridList.length > 0,
              lightRed:
                item.gridList &&
                item.gridList.length > 0 &&
                item.gridList.openPrice !=
                  Number(item.price.toFixed(priceDecimal)),
              dashed: item.price > maxPrice || item.price < minPrice,
            }"
            v-for="item in leftData"
            :key="item.id"
            :itemId="item.id"
            :itemvalue="'开空'"
          >
            <span
              class="quantity"
              v-for="(val, idx) in item.gridList"
              :key="idx"
              :gridId="val.id"
              :style="{ width: 100 - idx * 10 + '%', left: idx * 35 + 'px' }"
              :class="{ allQuantity: item.gridList.length > 1 }"
              draggable="true"
              @dragstart="startData = leftData"
              @dblclick="entrustInfo(item, '开空')"
            >
              <span>
                {{
                  val.openAmount
                    ? (val.openAmount * contractSize).toString().indexOf(".") >
                      -1
                      ? (val.openAmount * contractSize)
                          .toString()
                          .slice(
                            0,
                            (val.openAmount * contractSize)
                              .toString()
                              .indexOf(".") +
                              contractSizeIndex +
                              1
                          )
                      : val.openAmount * contractSize
                    : null
                }}
              </span>
              <span class="bot" v-if="val.isUpdate"></span>
            </span>
          </div>
        </div>
        <div class="cont-box">
          <span class="item center" v-for="(item, idx) in price" :key="idx">{{
            item.price.toFixed(priceDecimal)
          }}</span>
        </div>
        <div class="cont-box">
          <div
            class="item"
            :class="{
              gridItem: item.gridList && item.gridList.length != 0,
              gray: item.value > marketPrice,
              greenBack: item.gridList && item.gridList.length > 0,
              lightGreen:
                item.gridList &&
                item.gridList.length > 0 &&
                item.gridList.closePrice !=
                  Number(item.price.toFixed(priceDecimal)),
              dashed: item.price > maxPrice || item.price < minPrice,
            }"
            v-for="item in rightData"
            :key="item.id"
            :itemId="item.id"
            :itemvalue="'平空'"
          >
            <span
              class="quantity"
              v-for="(val, idx) in item.gridList"
              :key="idx"
              :gridId="val.id"
              :style="{ width: 100 - idx * 10 + '%', left: idx * 35 + 'px' }"
              :class="{ allQuantity: item.gridList.length > 1 }"
              draggable="true"
              @dragstart="startData = rightData"
              @dblclick="entrustInfo(item, '平空')"
            >
              <span>
                {{
                  val.openAmount
                    ? (val.openAmount * contractSize).toString().indexOf(".") >
                      -1
                      ? (val.openAmount * contractSize)
                          .toString()
                          .slice(
                            0,
                            (val.openAmount * contractSize)
                              .toString()
                              .indexOf(".") +
                              contractSizeIndex +
                              1
                          )
                      : val.openAmount * contractSize
                    : null
                }}
              </span>
              <span class="bot" v-if="val.isUpdate"></span>
            </span>
          </div>
        </div>
      </div>

      <!-- line -->
      <div class="line-warp" :style="{ top: top }" v-if="maxPrice && top">
        <span class="text">{{ "(" + $t("m.价格上限") + ")" }}</span>
        <div class="warp1">
          <img src="@/assets/icon/zuo.png" alt="" />
          <span class="line"></span>
          <img src="@/assets/icon/you.png" alt="" />
        </div>
        <span class="price">{{ maxPrice }}</span>
      </div>
      <div
        id="center-line"
        class="line-warp"
        :style="{ top: center }"
        v-if="marketPrice"
      >
        <span class="text">{{ "(" + $t("m.市场价") + ")" }}</span>
        <div class="warp1">
          <img src="@/assets/icon/zuo.png" alt="" />
          <span class="line"></span>
          <img src="@/assets/icon/you.png" alt="" />
        </div>
        <span class="price">{{ marketPrice }}</span>
      </div>
      <div class="line-warp" :style="{ top: bottom }" v-if="minPrice && bottom">
        <span class="text">{{ "(" + $t("m.价格下限") + ")" }}</span>
        <div class="warp1">
          <img src="@/assets/icon/zuo.png" alt="" />
          <span class="line"></span>
          <img src="@/assets/icon/you.png" alt="" />
        </div>
        <span class="price">{{ minPrice }}</span>
      </div>
    </div>
    <!-- 委托信息 -->
    <el-dialog
      :title="$t('m.委托信息')"
      :visible.sync="dialogVisible"
      width="336px"
      :close-on-click-modal="false"
    >
      <div class="entrustment">
        <div class="entrustment-box">
          <span class="box-title">{{ $t("m.委托方向") }}</span>
          <span class="box-text" style="padding-left: 10px">
            {{
              entrustType == "平多"
                ? $t("m.平多")
                : entrustType == "平空"
                ? $t("m.平空")
                : entrustType == "开多"
                ? $t("m.开多")
                : entrustType == "开空"
                ? $t("m.开空")
                : $t("m.开多")
            }}
          </span>
        </div>
        <div class="entrustment-box">
          <span class="box-title">{{ $t("m.委托类型") }}</span>
          <span class="box-text" style="padding-left: 10px">
            {{
              marketPrice > entrustPrice ? $t("m.市价委托") : $t("m.限价委托")
            }}
          </span>
        </div>
        <div class="entrustment-box">
          <span class="box-title">{{ $t("m.委托价格") }}</span>
          <span class="box-text">
            <el-input placeholder="" v-model="entrustPrice">
              <template slot="suffix">{{ baseCoin }}</template>
            </el-input>
          </span>
        </div>
        <div class="entrustment-box">
          <span class="box-title">{{ $t("m.委托数量") }}</span>
          <span class="box-text">
            <el-input placeholder="" v-model="entrustNum">
              <template slot="suffix">{{ counterCoin }}</template>
            </el-input>
          </span>
        </div>
        <div class="tips">{{ "-" + $t("m.此版本暂不支持直接编辑") + "-" }}</div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "price",
    "marketPrice",
    "maxPrice",
    "minPrice",
    "leftData",
    "rightData",
    "priceDecimal",
    "counterCoin",
    "baseCoin",
    "contractSize",
  ],
  components: {},
  data() {
    return {
      direction: 1, // 0 做空 ，1做多 ，2双向

      locationNum: null,
      top: null,
      center: null,
      bottom: null,
      oldId: null,
      newId: null,
      dragValue: null,
      logList: [], //移动记录
      startName: null,
      endName: null,
      dialogVisible: false,
      grldItem: null,

      entrustType: null, //委托方向

      entrustPrice: null, // 委托价格
      entrustNum: null, //委托数量
      contractSizeIndex: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    Drag() {
      let _this = this;
      /* 拖动时触发*/
      document.addEventListener("dragstart", function (event) {
        if (event.target.className.indexOf("center") != -1) {
          return;
        }

        event.target.style.background = "yellowgreen";
        event.target.style.zIndex = 999;
        let id = null;
        let itemValue = null;
        if (event.target.className.indexOf("item") != -1) {
          let text = event.target.outerHTML;
          id = text.split("itemid=")[1].split('"')[1];
          itemValue = text.split("itemvalue=")[1].split('"')[1];
        } else if (event.target.className.indexOf("quantity") != -1) {
          let text = event.target.parentElement.outerHTML;
          id = text.split("itemid=")[1].split('"')[1];
          itemValue = text.split("itemvalue=")[1].split('"')[1];
        }
        _this.oldId = id;
        let value = event.target.outerHTML;
        if (value.split("gridid=")[1]) {
          _this.valueId = value.split("gridid=")[1].split('"')[1];
        } else {
          _this.valueId = null;
        }
        _this.startName = itemValue;
      });

      /* 拖动完成后触发 */
      document.addEventListener("dragenter", function (event) {
        event.target.style.background = "rgba(255,255,255,0)";
      });

      // 默认情况下,数据/元素不能在其他元素中被拖放。对于drop我们必须防止元素的默认处理
      document.addEventListener("dragover", function (event) {
        event.preventDefault();
      });

      // 当可拖放的p元素离开droptarget
      document.addEventListener("dragleave", function (event) {});

      /*对于drop,防止浏览器的默认处理数据(在drop中链接是默认打开)
      拖拽元素附加到drop元素*/

      document.addEventListener("drop", function (event) {
        event.preventDefault();
        let id = null;
        let itemValue = null;
        if (event.target.className.indexOf("center") != -1) {
          return;
        }

        if (event.target.className.indexOf("item") != -1) {
          let text = event.target.outerHTML;
          id = text.split("itemid=")[1].split('"')[1];
          itemValue = text.split("itemvalue=")[1].split('"')[1];
        } else if (event.target.className.indexOf("quantity") != -1) {
          let text = event.target.parentElement.outerHTML;
          id = text.split("itemid=")[1].split('"')[1];
          itemValue = text.split("itemvalue=")[1].split('"')[1];
        }
        _this.newId = id;
        _this.endName = itemValue;

        if (_this.startName != _this.endName) {
          _this.clearData();
          return;
        } else if (!_this.valueId) {
          _this.clearData();
          return;
        }

        let list = _this.startData;
        let log = {
          type: _this.startName,
          // value: _this.valueId,
        };

        for (let j = 0; j < list.length; j++) {
          if (list[j].id == _this.newId) {
            if (_this.maxPrice && list[j].price > _this.maxPrice) {
              return;
            } else if (_this.minPrice && list[j].price < _this.minPrice) {
              return;
            }
          }

          if (list[j].id == _this.oldId) {
            log.oldPrice = list[j].price; //记录旧ID
            for (let i = 0; i < list[j].gridList.length; i++) {
              if (list[j].gridList[i].id == Number(_this.valueId)) {
                _this.grldItem = JSON.parse(
                  JSON.stringify(list[j].gridList[i])
                );
                _this.grldItem.isUpdate = true;
                list[j].gridList.splice(i, 1);
              }
            }
          }

          setTimeout(() => {
            if (list[j].id == _this.newId) {
              log.newPrice = list[j].price;
              log.newIndex = list[j].id;
              log.GridId = _this.grldItem.id;
              log.value = _this.grldItem.openAmount;
              if (list[j].gridList) {
                list[j].gridList.push(_this.grldItem);
                _this.grldItem = null;
              } else {
                list[j].gridList = [];
                list[j].gridList.push(_this.grldItem);
              }
            }

            _this.$forceUpdate();
          }, 100);
        }
        _this.logList.push(log);
      });
    },

    clearData() {
      this.oldId = null;
      this.newId = null;
      this.startData = null;
      this.dragValue = null;
      this.startName = null;
      this.endName = null;
    },

    // 对齐
    alignment() {
      var dom = document.getElementById("cont-warp");
      dom.scrollTop = this.locationNum;
    },

    location(center, top, bottom) {
      var dom = document.getElementById("cont-warp");
      this.center = center * 40 + 25 + "px";
      this.locationNum = (center - 6) * 40;
      dom.scrollTop = this.locationNum;
      this.top = top * 40 + 25 + "px";
      this.bottom = bottom * 40 + 25 + "px";
    },
    // 委托信息
    entrustInfo(v, type) {
      this.entrustType = type;

      if (v.gridList[0].status == 0 || v.gridList[0].status == 1) {
        this.entrustPrice = Number(v.gridList[0].openPrice.toFixed(4));
      } else {
        this.entrustPrice = Number(v.gridList[0].closePrice.toFixed(4));
      }
      // this.entrustPrice = Number(v.price.toFixed(4)); // 委托价格

      this.entrustNum = v.gridList[0].openAmount * this.contractSize; //委托数量
      this.dialogVisible = true;
    },
  },
  created() {},
  mounted() {
    this.Drag();
    this.contractSizeIndex = this.Public.contractSizeNumber(this.contractSize);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.short {
  position: relative;
  width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .label {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;
    @include themeify {
      border-bottom: 1px solid themed("splitline");
    }
    span {
      width: 160px;
      text-align: center;
    }
  }
  .cont-warp {
    width: 100%;
    height: calc(100vh - 205px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: auto;
    .box-warp {
      width: 500px;
      display: flex;
      justify-content: space-between;
      .cont-box {
        width: 160px;
        // height: 300px;
        .item {
          border: 1px solid #e6e5f2;
          display: flex;
          align-items: center;
          width: 160px;
          height: 30px;
          position: relative;
          overflow: hidden;
          .quantity {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            line-height: 30px;
            padding-left: 10px;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.32);
            .bot {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #fff;
              margin-right: 10px;
            }
          }
          .allQuantity {
            position: absolute;
          }
        }
        .center {
          width: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
        }
        .redBack {
          color: #fff;
          background: #fa5c5d;
        }
        .greenBack {
          color: #fff;
          background: #2670FA;
        }
        .lightGreen {
          background: #5ce6d3 !important;
        }
        .lightRed {
          background: #ff9c9f !important;
        }
        .gray {
          background: #d0d7df;
        }
        // 虚线
        .dashed {
          background: #fff !important;
          border: 1px dashed #999fb4 !important;
        }
        .item:hover {
          // background-color: #fdfdfd;
          cursor: move;
        }
        .item + .item {
          border-top: none;
          margin-top: 10px;
        }
        .gridItem {
          // hover 用，不删
        }
        .gridItem:hover {
          cursor: pointer;
          border: 0px solid #fff;
          background: #2670FA;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.48);
        }
      }
    }
    .line-warp {
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -70px;
      .text {
        width: 111px;
        font-size: 12px;
        text-align: right;
        @include themeify {
          color: themed("secondaryColor") !important;
        }
      }
      .warp1 {
        width: 540px;
        display: flex;
        align-items: center;
        margin: 0 10px;
        img {
          width: 9px;
          height: 8px;
        }
        .line {
          flex: 1;
          @include themeify {
            border-top: 1px solid themed("splitline") !important;
          }
        }
      }
      .price {
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
    }
  }
  .entrustment {
    @include themeify {
      color: themed("secondaryColor") !important;
    }
    .entrustment-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      margin-bottom: 20px;
      font-size: 14px;
      .box-text {
        width: 226px;
        height: 36px;
        background: #f0f0f0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        ::v-deep.el-input__inner {
          padding-left: 8px !important;
          background: #f0f0f0 !important;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
        ::v-deep.el-input__suffix {
          height: 36px !important;
          line-height: 36px !important;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
      }
    }
    .tips {
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>