<template>
  <div class="download">
    <TopNavigation  
    seoMetaTitle="Download Vtrading App | Crypto iOS & Android App | Vtrading" 
    seoKeyWords="Vtrading DCA App , Grid trade app , Bitcoin,Altcoin trading tools" 
    seoDescription="Using the trading tools and strategies provided by vtrading app can better help traders continue to trade in the cryptocurrency market."/>
    <div class="banner">
      <div class="banner-left"></div>
      <div class="banner-right">
        <div style="font-size: 34px">智能交易 就选Vtrading</div>
        <div style="font-size: 16px; margin: 12px 0 8px 0">
          个性定制，便捷切换，随心享受精彩直播
        </div>
        <div style="font-size: 16px">
          Personalized customization
          <br />
          convenient switch
        </div>
        <div class="banner-btn">
          <div class="banner-download">
            <div class="btn-style">
              <img src="" alt="" />
              <div class="btn-text">
                <span>下载客户端</span>
                <span>iPhone</span>
              </div>
            </div>
            <div class="btn-style">
              <img src="" alt="" />
              <div class="btn-text">
                <span>下载客户端</span>
                <span>Android</span>
              </div>
            </div>
          </div>
          <div class="btn-code">二维码</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation";

export default {
  name: "Download",
  components: {
    TopNavigation,
  },
  data() {
    return {};
  },
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.download {
  display: flex;
  flex-direction: column;
  height: 100%;
  .banner {
    flex: 1;
    background: url("../assets/image/xiazai.svg") no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include themeify {
      color: themed("btnText") !important;
    }
    .banner-left {
      height: 664px;
      width: 622px;
      margin-left: 185px;
      background: url("../assets/image/peitu.svg") no-repeat;
    }
    .banner-right {
      margin-right: 200px;
      margin-top: -70px;
      .banner-btn {
        display: flex;
        margin-top: 30px;
        .banner-download {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 18px;
          .btn-style {
            width: 164px;
            height: 52px;
            @include themeify {
              background: themed("topColor") !important;
            }
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .btn-text {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .btn-code {
          width: 114px;
          height: 114px;
          border-radius: 8px;
          border: 1px solid;
        }
      }
    }
  }
}
</style>