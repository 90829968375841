import HomeSkeleton from "../transaction/modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import {
  getUserApiList,
  getContractPairList,
  queryStratgyParam,
  addRobot,
  querylastIncrease,
  getLeverMultiple,
  startRobot,
  updataRobot,
  synchronSingelCloudApi,
  queryContractPrecision,
} from "@/request/api";
import TabSwitchClick from "../../components/TabSwitchClick";
import InputIconBox from "../../components/InputIconBox";
import Slider from "../../components/Slider";
import DCA from "./childModular/DCA.vue";
import Transfer from "../../components/Transfer.vue";
import { init } from "echarts";
import LeverDialog from "@/components/leverDialog.vue";
import publicData from "@/components/public.js";
export default {
  name: "CreateStrategy",
  components: {
    TabSwitchClick,
    InputIconBox,
    Slider,
    DCA,
    Transfer,
    TopNavigation,
    Menu,
    HomeSkeleton,
    LeverDialog,
  },
  watch: {
    "publicData.direction.value"() {
      this.directionShow = this.publicData.direction.value;
    },
    "transferShow.value"() {
      this.synchronSingelCloudApi();
    },
  },
  data() {
    return {
      publicData,
      advancedShow: false, // 高级设置显隐
      stopLossShow: false, // 开启持仓止损
      takeOver: false, // 接管仓位
      coinShow: false, // 是否显示解释字段
      checkboxCancel: true, // 终止时自动撤销委托
      checkboxClose: true, // 终止时自动平仓
      leverShow: false, // 调整杠杆
      leverValue: { value: 1 }, // 杠杆倍数
      leverMultiple: null, // 杠杆
      apiList: null, // 用户api列表
      apiCurrentName: null, // 选中的用户名称
      apiCurrent: null, // 选中的用户
      pairList: null, // 交易对列表
      pairCoin: null, // 选中的交易对
      pairCurrent: null, // 选中的交易对信息
      strategyConfig: null, // 策略参数
      directionShow: 0, // 策略方向
      result: null, // 币对涨跌幅
      strategyName: null, // 策略名称
      strategyId: null, // 策略ID
      assets: null, //选中的api的当前币种资产
      // 最低价
      minimum: {
        value: null,
        placeholder: "选填",
        enPlaceholder: "optional",
        name: "最低价",
        enName: "MinPrice",
        inpUnit: "currency",
        valueMin: 0,
        valueMax: "Infinity",
        explain: "低于此价格机器人将暂停开仓操作。",
        enExplain:
          "The robot will suspend the opening operation below this price.",
        type: "number",
      },
      // 最高价
      maximum: {
        value: null,
        placeholder: "选填",
        enPlaceholder: "optional",
        name: "最高价",
        enName: "MaxPrice",
        inpUnit: "currency",
        valueMin: 0,
        valueMax: "Infinity",
        explain: "高于此价格机器人将暂停开仓操作。",
        enExplain:
          "The robot will suspend the opening operation higher this price.",
        type: "number",
      },
      // 开仓等待时间 
      waitTime: {
        value: 1,
        name: "开仓等待时长",
        enName: "CooldownBetweenDeals",
        inpUnit: "time",
        slideMin: 0,
        slideMax: 1000,
        accuracy: 0,
        type: "number",
        enExplain:
          "Cooldown between deals for reopening after a round of arbitrage is completed. ",
        explain:
          "一轮套利完成后等待重新开仓的时间，等待时间内机器人按照追踪开仓逻辑执行（按照首次补仓规则——价格回落触发追踪开仓，锚定价格为平仓后行情高点），追踪开仓和冷却开仓先触发的生效。",
      },
      // 止盈阈值
      surplusThreshold: {
        value: null,
        copyValue: null,
        name: "止盈阈值",
        enName: "TPThreshold",
        inpUnit: "percentage",
        slideMin: 0.1,
        slideMax: 100,
        accuracy: 1,
        placeholder: "请输入百分比",
        enPlaceholder: "Enter a percentage",
        redBorderStyle: false,
        type: "number",
        enExplain:
          "When buy long, only when the market price is ≥ the average position price * (1 + TP threshold), the trailing TP is triggered; When sell short, trailing TP is triggered only when the market price ≤ the average position price * (1- TP threshold).",
        explain:
          "做多时，只有在行情价≥持仓均价 *（1+止盈阈值）时，才触发追踪止盈；做空时，只有在行情价≤持仓均价 *（1-止盈阈值）时，才触发追踪止盈。",
      },
      // 止盈回撤比例
      surplusRetracement: {
        value: 0,
        name: "止盈回撤比例",
        enName: "TPDevintion",
        inpUnit: "percentage",
        slideMin: 0,
        slideMax: 20,
        accuracy: 1,
        placeholder: "请输入百分比",
        enPlaceholder: "Enter a percentage",
        redBorderStyle: false,
        type: "number",
        explain:
          "做多时，触发追踪止盈后从价格高点回落此比例后即平仓；做空时，触发追踪止盈后从价格低点反弹此比例后即平仓。",
        enExplain:
          "When buy long, after triggering the trailing TP, it will fall back from the high price point, and the position will be closed after this proportion; When sell short, after triggering the trailing TP, it rebounds from the price low, and the position is closed after this proportion.",
      },
      // 止损阈值
      threshold: {
        value: null,
        name: "止损阈值",
        enName: "StopLossThreshold",
        inpUnit: "percentage",
        valueMin: 0.1,
        valueMax: 100,
        accuracy: 2,
        placeholder: "请输入百分比",
        enPlaceholder: "Enter a percentage",
        redBorderStyle: false,
        type: "number",
        explain:
          "做多时，只有在行情价≤持仓均价 *（1-止损阈值）时，才触发追踪止损；做空时，只有在行情价≥持仓均价 *（1+止损阈值）时，才触发追踪止损。",
        enExplain:
          "When buy long,  the trailing stop loss is triggered only when the market price ≤ the average price * (1-stop loss threshold); When shorting, the trailing stop loss is triggered only when the market price ≥ the average  price * (1 + stop loss threshold).",
      },
      // 止盈平仓模式
      surplusFlatModel: {
        radioName: ["一次性止盈", "逐单止盈"],
        enRadioName: ["Take profit", "Take profit inbatches"],
        name: "止盈平仓模式",
        enName: "Take profit modea",
        value: 0,
        type: "radio",
      },
      // 止盈下单模式
      surplusOrderModel: {
        radioName: ["追踪止盈", "预埋单止盈"],
        enRadioName: ["TrailingTP", "LimitOrderTP"],
        name: "止盈下单模式",
        enName: "TakeProfitMode",
        type: "radio",
        value: 0,
        explain:
          "追踪止盈，机器人会等待行情达到设定的止盈值后追踪行情触发回撤执行平仓操作；预埋单止盈，机器人将会按照设定的止盈值提前挂出限价单等待平仓，更精准盈利避免滑点。",
        enExplain:
          "Trailing TP, the bot will wait for the market to reach the set stop-loss value, and then track the market to trigger the withdrawal and close the position; With the embedded order to stop the profit, the robot will put up the price limit order in advance according to the set stop-loss value and wait for the position to be closed.",
      },
      // 止损后的操作
      closeLossAfter: {
        radioName: ["终止实例", "继续交易"],
        enRadioName: ["TerminateBot", "ContinueTrading"],
        name: "止损后的操作",
        enName: "ExecutionAfterStopLoss",
        value: 0,
        type: "radio",
        explain:
          "终止机器人，触发持仓终止后机器人将停止运行；继续交易，触发持仓终止后经过冷却时间继续开启交易。",
        enExplain:
          "Terminate the robot. After triggering the position termination, the robot will stop running; Continue trading. After triggering the termination of position, continue to open trading after cooling time.",
      },
      copyStrategy: null, // 复制后的参数
      copyType: null, // 是否禁止按钮
      leverVisible: {
        value: 0,
      }, // 杠杆倍数  弹框
      leverList: null, // 杠杆倍数 对象格式
      openVisible: false, // 是否启动机器人  弹框
      transactionType: "contract_usdt", // 币币合约
      transferShow: {
        value: 0,
      }, //划转页面显隐
      proposalInvest: null, //建议投入
      minInvest: null, //最小投入
      dcaType: null,
    };
  },
  methods: {
    // 接管仓位提示tips
    explainBtn() {
      this.$alert(
        this.$store.state.lang == 'ch'
          ? '开启后，机器人将接管当前品种与机器人同方向的仓位。在机器人运行之前已经建立的仓位，机器人会对其执行【持仓止盈】或【持仓止损】命令，在机器人运行期间手动建立仓位也会被机器人平仓。'
          : '',
        "",
        {
          confirmButtonText: this.$t("m.确定"),
          callback: (action) => { },
        }
      );
    },
    // 交易对
    pairChange(v) {
      this.pairCoin = v.counterCoin + "-" + v.baseCoin;
      this.pairCurrent = v;
      this.querylastIncrease();
    },
    // 用户
    apiChange(v) {
      this.apiCurrentName = v.apiName;
      this.apiCurrent = v;
      
      this.publicData.direction.value = 0;
      this.surplusOrderModel.value = 0;
      this.getContractPairList();
      this.getLeverMultiple();
    },
    
    //方向点击
    directionChange(index, back) {

      //CoinTR不支持双向交易
      if (!this.apiCurrent || this.apiCurrent.exchangeId!=30 || index!=2) return;
      this.$message.warning("CoinTr不支持双向交易");
      back();
    },

    //保证金模式
    marginChange(index, back) {
      if (index!=1) return;
      this.$message.warning("暂时只支持全仓模式");
      back();
    },
    
    //止盈下单模式
    surplusOrderChange(index, back) {

      //CoinTR不支持该操作
      if (!this.apiCurrent || this.apiCurrent.exchangeId!=30 || index!=1) return;
      this.$message.warning("CoinTR不支持该操作");
      back();
    },

    leverBtn() {
      if (this.copyType == "edit") {
        return;
      }
      this.leverVisible.value = 1;
    },
    // 获取用户api列表
    getUserApiList() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      getUserApiList(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.apiList.length == 0) {
            this.$message.warning("当前未录入API，请先录入");
            this.$emit("childFn", "2", "2-1");
            return;
          }
          let apiList = res.data.apiList;
          if (this.copyStrategy) {
            apiList.forEach((e) => {
              if (this.copyStrategy.apiId == e.apiId) {
                this.apiCurrentName = e.apiName;
                this.apiCurrent = e;
              }
            });
          } else {
            this.apiCurrentName = apiList[0].apiName;
            this.apiCurrent = apiList[0];
          }
          this.getContractPairList();
          this.apiList = apiList;
        }
      });
    },
    // 获取合约交易对
    getContractPairList() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeId: this.apiCurrent.exchangeId,
      };
      getContractPairList(payload).then((res) => {
        if (res.status == 200) {
          let contractPairList = res.data.contractPairList;
          let list = [];
          contractPairList.forEach((e) => {
            if (e.baseCoin == "USDT") {
              list.push(e);
            }
          });
          if (this.copyStrategy) {
            list.forEach((e) => {
              if (e.counterCoin == this.copyStrategy.counterCoin) {
                this.pairCurrent = e;
                this.pairCoin = e.counterCoin + "-" + e.baseCoin;
              }
            });
          } else if (this.pairCurrent) {
            list.forEach((e) => {
              if (
                e.counterCoin == this.pairCurrent.counterCoin &&
                e.baseCoin == this.pairCurrent.baseCoin
              ) {
                this.pairCurrent = e;
              }
            });
          } else {
            list.forEach((e) => {
              if (e.counterCoin == "BTC" && e.baseCoin == "USDT") {
                this.pairCurrent = e;
                this.pairCoin = e.counterCoin + "-" + e.baseCoin;
              }
            });
          }
          this.queryContractPrecision()
          this.querylastIncrease();
          this.pairList = list;
        }
      });
    },
    // 获取DCA策略参数
    queryStratgyParam() {
      let payload = {
        token: localStorage.getItem("token"),
        strategyId: "WL20210624182847777",
      };
      queryStratgyParam(payload).then((res) => {
        if (res.status == 200) {
          let stratgyParamMap = res.data.stratgyParamMap;
          let strategyConfig = JSON.parse(stratgyParamMap.strategyConfig);
          console.log(strategyConfig)
          strategyConfig.forEach((e) => {
            if (this.copyStrategy) {
              let strategy = this.copyStrategy.strategyPayload.strategy;
              if (e.name == "首单投入") {
                e.value = strategy.params.amount;
              } else if (e.name == "补仓数量模式") {
                e.value = strategy.params.amountModel;
              } else if (e.name == "补仓金额倍数") {
                e.value = strategy.params.amountScale
                  ? strategy.params.amountScale
                  : e.value;
              } else if (e.name == "倍投起始单") {
                e.value = strategy.params.scaleIndex
                  ? strategy.params.scaleIndex
                  : e.value;
              } else if (e.name == "补仓金额增量") {
                e.value = strategy.params.amountAdd || strategy.params.amountAdd == 0
                  ? strategy.params.amountAdd * this.copyStrategy.contractSize
                  : e.value;
              } else if (e.name == "补仓下单模式") {
                e.value = strategy.params.openModel;
              } else if (e.name == "补仓间隔比例") {
                e.value = strategy.params.openSpace
                  ? strategy.params.openSpace
                  : e.value;
              } else if (e.name == "补仓增幅") {
                e.value = strategy.params.openSpaceAdd
                  ? strategy.params.openSpaceAdd
                  : e.value;
              } else if (e.name == "补仓反弹比例") {
                e.value = strategy.params.openRebound || strategy.params.openRebound == 0
                  ? strategy.params.openRebound
                  : e.value;
              } else if (e.name == "最大做多单数") {
                e.value =
                  strategy.params.maxLongCount ||
                    strategy.params.maxLongCount == "0"
                    ? strategy.params.maxLongCount
                    : e.value;
              } else if (e.name == "最大做空单数") {
                e.value =
                  strategy.params.maxShortCount ||
                    strategy.params.maxShortCount == "0"
                    ? strategy.params.maxShortCount
                    : e.value;
              } else if (e.name == "最大初始保证金占用") {
                e.value = strategy.params.maxUseInitMoney
                  ? strategy.params.maxUseInitMoney
                  : e.value;
              }
            }
          });
          this.strategyConfig = strategyConfig;
          this.strategyName = stratgyParamMap.strategyName;
          let investLimit = JSON.parse(stratgyParamMap.investLimit);
          this.minInvest = investLimit.minInvest;
          this.publicData.maxBond.valueMax = investLimit.maxInvest;
          this.publicData.maxBond.valueMin = investLimit.minInvest;
          this.proposalInvest = investLimit.proposalInvest;
          this.strategyId = stratgyParamMap.strategyId;
        }
      });
    },
    //创建机器人 修改机器人
    addCreateStrategy() {
      //创建机器人
      if (!this.publicData.strategyInput.value) {
        this.$message.error("请输入机器人名称");
        this.publicData.strategyInput.value = "DCA Bot";
        return;
      } else if (
        this.Public.countCharacters(this.publicData.strategyInput.value) > 20
      ) {
        return this.$message.error("机器人名称长度限制10个字符");
      } else if (!this.apiCurrent) {
        return this.$message.error("请选择账户");
      } else if (!this.pairCoin) {
        return this.$message.error("请选择交易对");
      }
      //唐成需要的参数
      let strategyPayload = {
        userId: this.apiCurrent.userId, // 用户ID
        tradePair: {
          // 交易对
          exchange: this.apiCurrent.exchangeName, // 交易所标识， sz-深圳交易所  sh-上海交易所  bj-北京交易所
          apiId: this.apiCurrent.apiId,
          // contractId: "BTCUSDT", // 股票代码
          contractId: this.pairCurrent.contractId, //合约
          coin: this.pairCurrent.baseCoin, // 交易币种
          leverRate: this.leverMultiple[this.leverValue.value], // 杠杆倍数
          type: this.transactionType,
          id: "1",
        },
        strategy: {
          name: "contract_smt", // 策略名称
          params: {
            tradeOffset:
              this.publicData.direction.value == 0
                ? 1
                : this.publicData.direction.value == 1
                  ? 0
                  : this.publicData.direction.value,
            // closeAmountModel: this.surplusFlatModel.value,
            closeThreshold: this.surplusThreshold.value,
            closeModel: this.surplusOrderModel.value,
            closeRebound:
              this.surplusOrderModel.value == 0
                ? this.surplusRetracement.value
                : null,
            closeLossModel: this.stopLossShow ? 1 : 0,
            closeLossThreshold: this.threshold.value,
            closeLossAfter: this.closeLossAfter.value,
            minPrice: this.minimum.value,
            maxPrice: this.maximum.value,
            openWaitTime: this.waitTime.value,
          },
          options: {
            // 其它通用性参数
            profit: this.publicData.profitSurplus.value, // 总收益止盈
            loss: this.publicData.profitLoss.value, // 总收益止损
            stopWithCancel: this.checkboxCancel ? 1 : 0, // 终止时自动撤销委托
            stopWithClose: this.checkboxClose ? 1 : 0, // 终止时自动平仓
            triggerModel: this.publicData.triggerModel.value ? 1 : 0, // 触发模式
            triggerPrice: this.publicData.triggerModel.value
              ? this.publicData.triggerModel.value
              : null, // 触发价格
            positionMode: this.takeOver ? 1 : 0
          },
        },
      };
      let maxOrderNum = {};
      let delList = [];
      this.strategyConfig.forEach((e) => {
        if (e.name == "首单投入") {
          if (this.pairCurrent.exchangeName == "binance") {
            if (e.value < 10 / this.result.last) {
              delList.push(this.$t("m.首单投入"));
            }
          } else {
            if (!e.value) {
              delList.push(this.$t("m.首单投入"));
            }
          }
          strategyPayload.strategy.params.amount = Number(
            Math.ceil(e.value / this.pairCurrent.contractSize)
          );
        } else if (e.name == "补仓数量模式") {
          strategyPayload.strategy.params.amountModel = e.value;
        } else if (e.name == "补仓金额倍数") {
          strategyPayload.strategy.params.amountScale = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.补仓金额倍数"));
          }
        } else if (e.name == "倍投起始单") {
          strategyPayload.strategy.params.scaleIndex = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.倍投起始单"));
          }
        } else if (e.name == "补仓金额增量") {
          strategyPayload.strategy.params.amountAdd = Number(
            Math.ceil(e.value / this.pairCurrent.contractSize)
          );
          if (e.redBorderStyle || e.value == null) {
            delList.push(this.$t("m.补仓金额增量"));
          }
        } else if (e.name == "补仓下单模式") {
          strategyPayload.strategy.params.openModel = e.value;
        } else if (e.name == "补仓间隔比例") {
          strategyPayload.strategy.params.openSpace = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.补仓间隔比例"));
          }
        } else if (e.name == "补仓增幅") {
          strategyPayload.strategy.params.openSpaceAdd = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.补仓增幅"));
          }
        } else if (e.name == "补仓反弹比例") {
          strategyPayload.strategy.params.openRebound = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.补仓反弹比例"));
          }
        } else if (e.name == "最大做多单数") {
          strategyPayload.strategy.params.maxLongCount = e.value;
          maxOrderNum.maxLongCount = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.最大做多单数"));
          }
        } else if (e.name == "最大做空单数") {
          strategyPayload.strategy.params.maxShortCount = e.value;
          maxOrderNum.maxShortCount = e.value;
          if (e.redBorderStyle) {
            delList.push(this.$t("m.最大做空单数"));
          }
        }
      });
      let CopyConfig = JSON.parse(JSON.stringify(this.strategyConfig))

      if (strategyPayload.strategy.params.amountModel == 0) {
        strategyPayload.strategy.params.amountAdd = null;
        if (delList.indexOf(this.$t("m.补仓金额增量")) > -1) {
          delList.splice(delList.indexOf(this.$t("m.补仓金额增量")), 1);
        }
        let index = null
        CopyConfig.forEach((e, idx) => {
          if (e.name == this.$t("m.补仓金额增量")) {
            index = idx
          }
        });
        CopyConfig.splice(index, 1)
      } else {
        strategyPayload.strategy.params.scaleIndex = null;
        strategyPayload.strategy.params.amountScale = null;
        if (delList.indexOf(this.$t("m.补仓金额倍数")) > -1) {
          delList.splice(delList.indexOf(this.$t("m.补仓金额倍数")), 1);
        } else if (delList.indexOf(this.$t("m.倍投起始单")) > -1) {
          delList.splice(delList.indexOf(this.$t("m.倍投起始单")), 1);
        }
        let index = null
        CopyConfig.forEach((e, idx) => {
          if (
            e.name == this.$t("m.补仓金额倍数")
          ) {
            index = idx
          }
        });
        CopyConfig.splice(index, 1)
        let indexs = null
        CopyConfig.forEach((e, idx) => {
          if (
            e.name == this.$t("m.倍投起始单")
          ) {
            indexs = idx
          }
        });
        CopyConfig.splice(indexs, 1)
      }
      if (strategyPayload.strategy.params.openModel == 1) {
        strategyPayload.strategy.params.openRebound = null;
        let index = null
        CopyConfig.forEach((e, idx) => {
          if (e.name == this.$t("m.补仓反弹比例")) {
            index = idx
          }
        });
        CopyConfig.splice(index, 1)
        if (delList.indexOf(this.$t("m.补仓反弹比例")) > -1) {
          delList.splice(delList.indexOf(this.$t("m.补仓反弹比例")), 1);
        }
      }
      if (this.publicData.direction.value == 0) {
        strategyPayload.strategy.params.maxShortCount = null;
        maxOrderNum.maxShortCount = null;
        if (delList.indexOf(this.$t("m.最大做空单数")) > -1) {
          delList.splice(delList.indexOf(this.$t("m.最大做空单数")), 1);
        }
        let index = null
        CopyConfig.forEach((e, idx) => {
          if (e.name == this.$t("m.最大做空单数")) {
            index = idx
          }
        });
        CopyConfig.splice(index, 1)
      } else if (this.publicData.direction.value == 1) {
        strategyPayload.strategy.params.maxLongCount = null;
        maxOrderNum.maxLongCount = null;
        if (delList.indexOf(this.$t("m.最大做多单数")) > -1) {
          delList.splice(delList.indexOf(this.$t("m.最大做多单数")), 1);
        }
        let index = null
        CopyConfig.forEach((e, idx) => {
          if (e.name == this.$t("m.最大做多单数")) {
            index = idx
          }
        });
        CopyConfig.splice(index, 1)
      }
      if (this.publicData.maxBond.value) {
        strategyPayload.strategy.params.maxUseInitMoney =
          this.publicData.maxBond.value;
      }
      if (
        this.surplusThreshold.redBorderStyle ||
        !this.surplusThreshold.copyValue
      ) {
        this.surplusThreshold.redBorderStyle = true;
        delList.push(this.$t("m.止盈阈值"));
      }
      if (this.surplusOrderModel.value !== 0) {
        this.surplusRetracement.value = null;
      } else {
        if (this.surplusRetracement.redBorderStyle) {
          delList.push(this.$t("m.止盈回撤比例"));
        }
      }

      let threshold = this.threshold;
      let closeLossAfter = this.closeLossAfter;
      if (this.stopLossShow) {
        if (!this.threshold.value) {
          this.threshold.redBorderStyle = true;
          delList.push(this.$t("m.止损阈值"));
        }
      } else {
        this.threshold.redBorderStyle = false;
        threshold.value = null;
        closeLossAfter.value = null;
      }
      if (delList.length !== 0) {
        this.$message.error(delList[0] + this.$t("m.参数出错"));
        return;
      }

      // 详情中的参数列表
      let strategyConfig = [
        ...CopyConfig,
        this.surplusOrderModel,
        this.surplusThreshold,
        this.surplusRetracement,
        {
          value: this.stopLossShow,
          name: "持仓止损",
          enName: "StopLoss",
          type: "switch",
        },
        threshold,
        closeLossAfter,
        this.publicData.triggerModel,
        this.minimum,
        this.maximum,
        this.waitTime,
        this.publicData.profitSurplus,
        this.publicData.profitLoss,
        {
          value: this.checkboxCancel,
          name: "终止时自动撤销委托",
          enName: "AutoTermination",
          type: "switch",
        },
        {
          value: this.checkboxClose,
          name: "终止时自动平仓",
          enName: "AutoCloseout",
          type: "switch",
        },
        this.publicData.maxBond,
      ];
      let copyStrategyPayload = JSON.parse(JSON.stringify(strategyPayload));

      //回显的数据
      let displayConfig = {
        strategyPayload: copyStrategyPayload,
        apiId: this.apiCurrent.apiId,
        robotName: this.publicData.strategyInput.value, //机器人名称
        counterCoin: this.pairCurrent.counterCoin,
        baseCoin: this.pairCurrent.baseCoin, //币对
        bondPattern: this.publicData.MarginMode.value, //保证金模式
        contractSize: this.pairCurrent.contractSize, //币种汇率
        stopWithCancel: this.checkboxCancel, // 终止时自动撤销委托
        stopWithClose: this.checkboxClose, // 终止时自动平仓
        positionMode: this.takeOver,
        strategyConfig: strategyConfig,
      };
      displayConfig.strategyPayload.strategy.params.amount =
        displayConfig.strategyPayload.strategy.params.amount *
        this.pairCurrent.contractSize;
      if (this.takeOver) {
        displayConfig.takeOver = true
      }
      let payload = {
        token: localStorage.getItem("token"),
        isStartStrategy: false,
        robotName: this.publicData.strategyInput.value,
        maxOrderNum: maxOrderNum,
        robotPattern:
          this.publicData.direction.value == 0
            ? 1
            : this.publicData.direction.value == 1
              ? 0
              : this.publicData.direction.value,
        bondPattern: this.publicData.MarginMode.value,
        apiId: this.apiCurrent.apiId,
        config: JSON.stringify(strategyPayload),
        advancedSetting: {
          profit: this.publicData.profitSurplus.value, // 总收益止盈
          loss: this.publicData.profitLoss.value, // 总收益止损
          stopWithCancel: this.checkboxCancel ? 1 : 0, // 终止时自动撤销委托
          stopWithClose: this.checkboxClose ? 1 : 0, // 终止时自动平仓
          triggerModel: this.publicData.triggerModel.value ? 1 : 0, // 触发模式
          triggerPrice: this.publicData.triggerModel.value
            ? this.publicData.triggerModel.value
            : null, // 触发价格
          positionMode: this.takeOver ? 1 : 0
        },
        transactionType: "contract_usdt",
        leverRate: this.leverMultiple[this.leverValue.value], //杠杆倍数
        transactionPair: this.pairCurrent.contractId, //交易对
        strategyId: this.strategyId,
        strategyName: this.strategyName,
        exchangeName: this.apiCurrent.exchangeName,
        pairType: 0,
        investCoin: this.pairCurrent.baseCoin,
        counterCoin: this.pairCurrent.counterCoin,
        baseCoin: this.pairCurrent.baseCoin,
        displayConfig: displayConfig,
      };

      //修改机器人
      if (this.copyType == "edit") {
        let upDataPayload = {
          ...payload,
          robotId: this.copyStrategy.robotId,
          robotName: this.publicData.strategyInput.value,
        };
        updataRobot(upDataPayload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t("m.修改成功"));
            this.$router.push({ path: "/robotList" });
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addRobot(payload).then((res) => {
          if (res.status == 200) {
            this.robotId = res.data.robotId;
            this.$message.success(this.$t("m.机器人创建成功"));
            if (this.takeOver) {
              let payload = {
                token: localStorage.getItem('token'),
                apiId: this.apiCurrent.apiId,
              }
              synchronSingelCloudApi(payload).then(res => {
                if (res.status == 200) {
                  let dataList = null
                  for (let item in res.data) {
                    dataList = res.data[item];
                  }
                  let linerSwapAssets = dataList.linerSwapAssets
                  if (linerSwapAssets && linerSwapAssets.length !== 0) {
                    let positions = linerSwapAssets[0].positions
                    if (positions && positions.length !== 0) {
                      positions.forEach(e => {
                        if (e.contractId.indexOf(this.pairCurrent.counterCoin) > -1) {
                          this.coinShow = true
                        }
                      })
                    } else {
                      this.coinShow = false
                    }
                  } else {
                    this.coinShow = false
                  }
                  this.openVisible = true;
                } else {
                  this.$message.error(res.msg)
                }
              })
            } else {
              this.openVisible = true;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    //高级设置按钮
    advancedShowBtn() {
      this.advancedShow = !this.advancedShow;
    },
    // 获取币对涨跌幅
    querylastIncrease() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeName: this.apiCurrent.exchangeName,
        transactionType: "contract_usdt",
        counterCoin: this.pairCurrent.counterCoin,
        baseCoin: this.pairCurrent.baseCoin,
      };
      querylastIncrease(payload).then((res) => {
        if (res.status == 200) {
          let result = res.data.result;
          result = JSON.parse(result);
          this.result = result;
          this.synchronSingelCloudApi();
          this.queryStratgyParam();
        }
      });
    },
    // 获取交易所杠杆
    getLeverMultiple() {
      let payload = {
        token: localStorage.getItem("token"),
        exchangeId: this.apiCurrent ? this.apiCurrent.exchangeId : 4,
      };
      getLeverMultiple(payload).then((res) => {
        if (res.status == 200) {
          let leverMultipleMap = res.data.leverMultipleMap;
          let leverMultiple = eval(leverMultipleMap.leverMultiple);
          let leverList = {};
          for (let i in leverMultiple) {
            leverList[i] = leverMultiple[i] + "X";
          }
          if (this.copyStrategy) {
            for (let i in leverMultiple) {
              if (
                leverMultiple[i] ==
                this.copyStrategy.strategyPayload.tradePair.leverRate
              ) {
                this.leverValue.value = i; //杠杆倍数
              }
            }
          }
          this.leverList = leverList;
          this.leverMultiple = leverMultiple;
        }
      });
    },

    // 转跳到机器人列表页
    startRobotBtn(v) {
      if (v == "close") {
        this.$router.push({ path: "/robotList" });
      } else {
        let payload = {
          token: localStorage.getItem("token"),
          robotId: this.robotId,
        };
        startRobot(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t("m.启动成功"));
            this.$router.push({ path: "/robotList" });
          } else {
            this.$message.error(res.msg);
            this.$router.push({ path: "/robotList" });
          }
        });
      }
    },
    // 划转按钮
    transferShowBtn() {
      this.transferShow.value = 1;
    },
    // 同步资产
    synchronSingelCloudApi() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiCurrent.apiId,
      };
      synchronSingelCloudApi(payload).then((res) => {
        if (res.status == 200) {
          let dataList = null;
          for (let item in res.data) {
            dataList = res.data[item];
          }
          if (dataList.isExpire) {
            this.$message.warning(this.$t('m.API已过期,请重新录入'))
            return
          }
          if (this.transactionType == "contract_usdt") {
            let linerSwapAssets = dataList.linerSwapAssets;
            let assets = null;
            if (linerSwapAssets && linerSwapAssets.length > 0) {
              if (
                this.apiCurrent.exchangeId == "29" ||
                this.apiCurrent.exchangeName == "OkexV5"
              ) {
                assets = linerSwapAssets[0];
                assets.available = this.Public.toMathNum(assets.available);
                assets.currency = "USDT";
              } else {
                linerSwapAssets.forEach((e) => {
                  if (e.currency == this.pairCurrent.baseCoin) {
                    if (e.available.toString().indexOf(".") > -1) {
                      e.available = this.Public.toMathNum(e.available);
                    }
                    assets = e;
                  }
                });
              }
            }
            this.assets = assets;
          } else {
            let accountAssets = dataList.accountAssets;
            let assets = null;
            if (accountAssets && accountAssets.length > 0) {
              accountAssets.forEach((e) => {
                if (e.currency == this.pairCurrent.baseCoin) {
                  if (e.available.toString().indexOf(".") > -1) {
                    e.available = e.available
                      .toString()
                      .slice(0, e.available.toString().indexOf(".") + 7);
                  }
                  assets = e;
                }
              });
            }
            this.assets = assets;
          }
        }
      });
    },

    toDCAcourse() {
      window.open("https://intercom.help/vtrading/zh-CN/articles/6161085-u%E6%9C%AC%E4%BD%8D%E5%90%88%E7%BA%A6dca%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C");
    },
    // 获取币对价格精度
    queryContractPrecision() {
      let payload = {
        token: localStorage.getItem('token'),
        exchangeName: this.apiCurrent.exchangeName,
        contractIdStr: this.pairCurrent.contractId
      }
      queryContractPrecision(payload).then(res => {
        if (res.status == 200) {
          let contractPrecisionList = res.data.contractPrecision
          let contractPrecision = null
          for (let item in contractPrecisionList) {
            contractPrecision = contractPrecisionList[item];
          }
          if (this.apiCurrent.exchangeId == 29) {
            if (contractPrecision.pricePrecision.indexOf(".") > -1) {
              contractPrecision.pricePrecision =
                contractPrecision.pricePrecision.length -
                contractPrecision.pricePrecision.indexOf(".")
            } else {
              contractPrecision.pricePrecision = 1;
            }
          }
          this.contractPrecision = contractPrecision
          this.minimum.pairUsdtSize = contractPrecision.pricePrecision
          this.maximum.pairUsdtSize = contractPrecision.pricePrecision

          this.publicData.triggerModel.pairUsdtSize = contractPrecision.pricePrecision
          this.publicData.profitLoss.pairUsdtSize = contractPrecision.pricePrecision
          this.publicData.profitSurplus.pairUsdtSize = contractPrecision.pricePrecision
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    explainLossBtn() {
      this.$alert(
        this.$store.state.lang == 'ch'
          ? "开启后，机器人将接管当前品种与机器人同方向的仓位。在机器人运行之前已经建立的仓位，机器人会对其执行【持仓止盈】或【持仓止损】命令，在机器人运行期间手动建立仓位也会被机器人平仓。"
          : "The bot will take ove d the running positions in the same deriction,and take profit or stop loss either。",
        "",
        {
          confirmButtonText: this.$t("m.确定"),
          callback: (action) => { },
        }
      );
    }


  },
  mounted() {
    this.dcaType = localStorage.getItem("dcaType");
  },
  created() {
    let copyStrategy = localStorage.getItem("copyStrategy");
    this.publicData.strategyInput.value = 'DCA Bot'
    if (copyStrategy) {
      copyStrategy = JSON.parse(copyStrategy);
      let strategy = copyStrategy.strategyPayload.strategy;
      this.copyStrategy = copyStrategy;
      localStorage.removeItem("copyStrategy");
      if (copyStrategy.type !== "edit") {
        this.publicData.strategyInput.value =
          this.$t("m.副本") + copyStrategy.robotName; //机器人名称
      } else {
        this.publicData.strategyInput.value = copyStrategy.robotName; //机器人名称
      }
      this.apiCurrentName = copyStrategy; //用户名称
      this.pairCoin = copyStrategy.baseCoin + "-" + copyStrategy.counterCoin; //交易对
      this.publicData.direction.value =
        strategy.params.tradeOffset == 0
          ? 1
          : strategy.params.tradeOffset == 1
            ? 0
            : strategy.params.tradeOffset; //交易方向
      this.directionShow = this.publicData.direction.value
      this.publicData.MarginMode.value = copyStrategy.bondPattern; //保证金模式

      //高级设置
      this.publicData.triggerModel.value = strategy.options.triggerPrice; //机器人触发价
      this.minimum.value = strategy.params.minPrice; //最低价
      this.maximum.value = strategy.params.maxPrice; //最高价
      this.waitTime.value = strategy.params.openWaitTime; //开仓时间
      this.publicData.maxBond.value = strategy.params.maxUseInitMoney; //保证金
      this.checkboxClose = strategy.options.stopWithClose == 0 ? false : true; // 终止时自动撤销委托
      this.checkboxCancel = strategy.options.stopWithCancel == 0 ? false : true; // 终止时自动平仓
      this.surplusThreshold.value = strategy.params.closeThreshold; //止盈阈值
      if (strategy.params.closeThreshold) {
        this.surplusThreshold.redBorderStyle = false;
      }
      this.surplusOrderModel.value = strategy.params.closeModel; //止盈下单模式
      this.surplusRetracement.value = strategy.params.closeRebound; //止盈回撤比例
      this.stopLossShow = strategy.params.closeLossModel == 0 ? false : true; //持仓止损
      this.threshold.value = strategy.params.closeLossThreshold; //持仓止损阈值
      this.closeLossAfter.value = strategy.params.closeLossAfter; //持仓止损后的操作
      this.publicData.profitSurplus.value = strategy.options.profit; //止盈
      this.publicData.profitLoss.value = strategy.options.loss; //止损

      this.takeOver = strategy.options.positionMode == 0 ? false : true;
      this.copyType = copyStrategy.type;
    } else {
      this.publicData.direction.value = 0
    }
    this.getUserApiList();
    this.getLeverMultiple();
  },
  destroyed() {
    localStorage.removeItem("dcaType");
  },
};
