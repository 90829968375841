<template>
  <div class="example">
    <TopNavigation :menu="0" />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'3-2'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <div style="height: calc(100vh - 65px)">
              <div class="search" v-if="total > 0">
                <div style="display: flex">
                  <div class="searchBox">
                    <div>{{ $t("m.交易所") }}</div>
                    <el-select
                      v-model="exchangeName"
                      :placeholder="$t('m.全部')"
                    >
                      <el-option
                        v-for="item in exchanges"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value"
                        :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="searchBox">
                    <div>{{ $t("m.机器人名称") }}</div>
                    <input
                      class="inpStyle"
                      type="text"
                      v-model="robotName"
                      :placeholder="$t('m.全部')"
                    />
                  </div>
                  <div class="searchBox">
                    <div></div>
                    <div
                      class="clickBtn"
                      style="width: 90px"
                      @click="qureyExecuteStrategyList()"
                    >
                      {{ $t("m.筛选") }}
                    </div>
                  </div>
                  <div class="searchBox">
                    <div></div>
                    <div
                      class="clickBtn whiteBackground green"
                      style="min-width: 90px"
                      @click="clear()"
                    >
                      {{ $t("m.清除筛选") }}
                    </div>
                  </div>
                </div>
                <div class="searchBox">
                  <div></div>
                  <div class="clickBtn" @click="createBot()">
                    {{ $t("m.创建DCA机器人") }}
                  </div>
                </div>
              </div>

              <div class="data-warp" v-if="total != 0 && totalCopy != 0">
                <div
                  class="dataBox"
                  v-for="(item, idx) in executeStrategyList"
                  :key="idx"
                  :class="idx % 2 == 0 ? '' : 'dataBoxColor'"
                >
                  <div class="listTitle" v-if="idx == 0">
                    <div
                      class="titleItem sortStyle"
                      @click="timeSort()"
                      style="width: 180px"
                    >
                      {{ $t("m.实例") }}
                      <img
                        v-if="timeShow == 0"
                        src="@/assets/icon/sort@2x.png"
                        alt=""
                      />
                      <img
                        v-if="timeShow == 1"
                        src="@/assets/icon/sortBottom@2x.png"
                        alt=""
                      />
                      <img
                        v-if="timeShow == 2"
                        src="@/assets/icon/sortTop@2x.png"
                        alt=""
                      />
                    </div>
                    <div class="titleItem" style="width: 140px">
                      {{ $t("m.持仓数量") }}
                    </div>
                    <div class="titleItem" style="width: 150px">
                      {{ $t("m.开仓均价") + "（USDT）" }}
                    </div>
                    <div class="titleItem" style="width: 180px">
                      {{ $t("m.强平价") + "（USDT）" }}
                    </div>
                    <div
                      class="titleItem sortStyle"
                      @click="priceSort()"
                      style="width: 250px"
                    >
                      {{ $t("m.盈亏") + "（USDT）" }}
                      <img
                        v-if="priceShow == 0"
                        src="@/assets/icon/sort@2x.png"
                        alt=""
                      />
                      <img
                        v-if="priceShow == 1"
                        src="@/assets/icon/sortBottom@2x.png"
                        alt=""
                      />
                      <img
                        v-if="priceShow == 2"
                        src="@/assets/icon/sortTop@2x.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="listBox">
                    <div class="list-top">
                      <div class="list-user">
                        <span
                          class="list-user-robotName"
                          @click="strategyOrder(item)"
                        >
                          {{ item.robotName }}
                          <span
                            class="identification"
                            v-if="item.errorInfo"
                          ></span>
                        </span>

                        <span
                          class="stateStyle"
                          v-if="
                            (item.robotPattern == 1 ||
                              item.robotPattern == 2) &&
                            item.longState
                          "
                          :style="
                            item.robotPattern == 2 ? 'margin-right:10px' : ''
                          "
                        >
                          <span>[</span>
                          <span v-if="item.longState == 0">
                            {{ $t("m.做多等待开仓") }}
                          </span>
                          <span v-if="item.longState == 2">
                            {{ $t("m.做多追踪止盈") }}
                          </span>
                          <span
                            v-if="item.longState == 14 || item.longState == 15"
                          >
                            {{ $t("m.做多平仓中") }}
                          </span>
                          <span
                            v-if="
                              item.longState != 2 &&
                              item.longState != 0 &&
                              item.longState != 14 &&
                              item.longState != 15
                            "
                          >
                            {{ $t("m.做多开仓中") }}
                          </span>
                          <span>]</span>
                        </span>

                        <span
                          class="stateStyle"
                          v-if="
                            (item.robotPattern == 0 ||
                              item.robotPattern == 2) &&
                            item.shortState
                          "
                        >
                          <span>[</span>
                          <span v-if="item.shortState == 0">
                            {{ $t("m.做空等待开仓") }}</span
                          >
                          <span v-if="item.shortState == 2">
                            {{ $t("m.做空追踪止盈") }}</span
                          >
                          <span
                            v-if="
                              item.shortState == 14 || item.shortState == 15
                            "
                          >
                            {{ $t("m.做空平仓中") }}
                          </span>
                          <span
                            v-if="
                              item.shortState != 2 &&
                              item.shortState != 0 &&
                              item.shortState != 14 &&
                              item.shortState != 15
                            "
                          >
                            {{ $t("m.做空开仓中") }}
                          </span>
                          <span>]</span>
                        </span>
                      </div>
                      <div class="list-time">
                        <span class="list-time-name">
                          <img
                            v-if="
                              item.apiName.split('-')[0].indexOf('Okex') > -1
                            "
                            src="@/assets/icon/OKX@2x.png"
                            alt=""
                            style="width: 20px; height: 20px; margin-right: 5px"
                          />
                          <img
                            v-if="
                              item.apiName.split('-')[0].indexOf('Binance') > -1
                            "
                            src="@/assets/icon/bianlogo@2x.png"
                            style="width: 20px; height: 20px; margin-right: 5px"
                            alt=""
                          />
                          {{ item.apiName.split("-")[1] }}
                        </span>
                        <span style="margin: 0 8px">
                          {{ item.counterCoin + "/" + item.baseCoin }}
                        </span>
                        <span>
                          {{
                            moment(item.startTime).format("YYYY.MM.DD HH:mm")
                          }}
                        </span>
                        <span class="list-time-carry"> - </span>
                        <span>
                          {{ formatDuring(item.startTime) }}
                        </span>
                      </div>
                    </div>
                    <div class="listStyle">
                      <div class="listItem" style="width: 180px">
                        <div
                          class="robotPatternBox short"
                          style="width: 68px; padding: 0 5px"
                          v-if="item.robotPattern == 0"
                        >
                          {{ $t("m.做空") + " " + item.leverRate + "X " }}
                        </div>
                        <div
                          class="robotPatternBox long"
                          style="width: 68px; padding: 0 5px"
                          v-if="item.robotPattern == 1"
                        >
                          {{ $t("m.做多") + " " + item.leverRate + "X " }}
                        </div>
                        <div
                          class="robotPatternBox Neutral"
                          style="width: 80px; padding: 0 5px"
                          v-if="item.robotPattern == 2"
                        >
                          {{ $t("m.双向") + " " + item.leverRate + "X " }}
                        </div>
                        <div style="margin-top: 6px">
                          {{ "ID : " + item.robotId }}
                        </div>
                      </div>
                      <div class="listItem" style="width: 140px">
                        <span
                          v-if="
                            item.robotPattern == 1 || item.robotPattern == 2
                          "
                          class="listBottom-Box"
                          :style="
                            item.robotPattern == 2 ? 'margin-bottom:10px' : ''
                          "
                        >
                          <span class="robotPatternBox long">{{
                            $t("m.做多")
                          }}</span>

                          <span>
                            {{
                              (item.position
                                ? Public.toMathNum(
                                    Number(
                                      item.position.position.buyPosition.volume
                                    ) * item.contractSize
                                  )
                                : "--") +
                              " " +
                              item.counterCoin
                            }}
                            <!-- {{
                              (item.position
                                ? Math.floor(
                                    Number(
                                      item.position.position.buyPosition.volume
                                    ) *
                                      item.contractSize *
                                      10 0000
                                  ) / 10 0000
                                : "--") +
                              " " +
                              item.counterCoin
                            }} -->
                          </span>
                        </span>
                        <span
                          v-if="
                            item.robotPattern == 0 || item.robotPattern == 2
                          "
                          class="listBottom-Box"
                        >
                          <span class="robotPatternBox short">{{
                            $t("m.做空")
                          }}</span>
                          <span>
                            <!-- {{
                              (item.position
                                ? Math.floor(
                                    Number(
                                      item.position.position.sellPosition.volume
                                    ) *
                                      item.contractSize *
                                      10 0000
                                  ) / 10 0000
                                : "--") +
                              " " +
                              item.counterCoin
                            }} -->

                            {{
                              (item.position
                                ? Public.toMathNum(
                                    Number(
                                      item.position.position.sellPosition.volume
                                    ) * item.contractSize
                                  )
                                : "--") +
                              " " +
                              item.counterCoin
                            }}
                          </span>
                        </span>
                      </div>
                      <div class="listItem" style="width: 150px">
                        <span
                          v-if="
                            item.robotPattern == 1 || item.robotPattern == 2
                          "
                          class="listBottom-Box"
                          :style="
                            item.robotPattern == 2 ? 'margin-bottom:10px' : ''
                          "
                        >
                          <span class="robotPatternBox long">
                            {{ $t("m.做多") }}
                          </span>
                          <span>
                            {{
                              item.position
                                ? Public.toMathNum(
                                    item.position.position.buyPosition.costHold
                                  )
                                : "--"
                            }}
                          </span>
                        </span>
                        <span
                          v-if="
                            item.robotPattern == 0 || item.robotPattern == 2
                          "
                          class="listBottom-Box"
                        >
                          <span class="robotPatternBox short">{{
                            $t("m.做空")
                          }}</span>
                          <span>
                            {{
                              item.position
                                ? Public.toMathNum(
                                    item.position.position.sellPosition.costOpen
                                  )
                                : "--"
                            }}
                          </span>
                        </span>
                      </div>
                      <div class="listItem" style="width: 180px">
                        <div style="display: flex">
                          <div
                            :class="
                              item.robotPattern == 0
                                ? 'short'
                                : item.robotPattern == 1
                                ? 'long'
                                : 'Neutral'
                            "
                            class="robotPatternBox"
                            style="display: flex; min-width: 40px"
                          >
                            {{
                              item.robotPattern == 0
                                ? $t("m.做空")
                                : item.robotPattern == 1
                                ? $t("m.做多")
                                : $t("m.双向")
                            }}
                          </div>
                          <div style="display: flex; align-items: center">
                            {{
                              item.position.position.liquidationPrice == 0 ||
                              !item.position.position.liquidationPrice
                                ? "--"
                                : Public.toMathNum(
                                    item.position.position.liquidationPrice
                                  )
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="listItem itemStyle" style="width: 250px">
                        <span class="listBottom-Box listBottom-style">
                          <span style="margin-bottom: 10px">
                            {{ $t("m.未实现") + " : " }}
                          </span>
                          <span>{{ $t("m.总盈亏") + " : " }}</span>
                        </span>
                        <span
                          class="listBottom-Box listBottom-style"
                          style="margin-left: 8px"
                        >
                          <span
                            :class="{
                              green: Public.toMathNum(item.floatEarnings) > 0,
                              red: Public.toMathNum(item.floatEarnings) < 0,
                            }"
                            class="listBottom-number"
                            style="margin: 2px 0 13px"
                          >
                            {{ Public.toMathNum(item.floatEarnings) }}
                          </span>
                          <span
                            :class="{
                              green: Public.toMathNum(item.realEarnings) > 0,
                              red: Public.toMathNum(item.realEarnings) < 0,
                            }"
                            class="listBottom-number"
                          >
                            {{ Public.toMathNum(item.realEarnings) }}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div class="listBottom">
                      <div class="listBottom-item">
                        <div>
                          <span
                            class="bottomBorder"
                            @click="getEntrustOrders(item)"
                            >{{
                              $t("m.当前委托") + " : " + item.entrustOrdersNum
                            }}</span
                          >
                        </div>
                        <div>
                          <span class="bottomBorder" @click="orderShow(item)">
                            {{ $t("m.当前做单数") + " :" }}
                            <span
                              class="red"
                              v-if="
                                item.maxOrderNum.maxShortCount ||
                                item.maxOrderNum.maxShortCount == 0
                              "
                            >
                              {{
                                $t("m.空") +
                                item.shortOrderNum +
                                "/" +
                                item.maxOrderNum.maxShortCount
                              }}
                            </span>
                            <span
                              v-if="
                                item.maxOrderNum.maxLongCount ||
                                item.maxOrderNum.maxLongCount == 0
                              "
                              class="green"
                            >
                              {{
                                $t("m.多") +
                                item.longOrderNum +
                                "/" +
                                item.maxOrderNum.maxLongCount
                              }}
                            </span>
                          </span>
                        </div>
                        <div>
                          <span
                            class="bottomBorder"
                            @click="qureyStrategyOrderList(item)"
                          >
                            {{
                              $t("m.成交记录") + " : " + item.strategyOrderNum
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="listBottom-item">
                        <div style="margin-left: 30px; cursor: pointer">
                          <img
                            src="@/assets/icon/shuaxin.svg"
                            alt=""
                            @click="
                              qureyExecuteStrategyById(
                                item.executeId,
                                'refresh'
                              )
                            "
                          />
                        </div>
                        <div
                          class="clickBtn whiteBackground green greenBorder"
                          @click="transferShowBtn(item)"
                        >
                          {{ $t("m.划转") }}
                        </div>
                        <div
                          class="clickBtn whiteBackground green greenBorder"
                          @click="addManualClick(item)"
                        >
                          {{ $t("m.加仓") }}
                        </div>
                        <div
                          class="clickBtn whiteBackground green greenBorder"
                          @click="editRobot(item)"
                        >
                          {{ $t("m.编辑") }}
                        </div>
                        <div
                          class="clickBtn whiteBackground green greenBorder"
                          @click="restartRobot(item)"
                          v-if="item.states == 'SUSPEND'"
                        >
                          {{ $t("m.重启") }}
                        </div>
                        <div
                          class="clickBtn whiteBackground green greenBorder"
                          @click="suspendRobot(item)"
                          v-if="item.states == 'EXECUTE'"
                        >
                          {{ $t("m.暂停") }}
                        </div>
                        <div
                          class="
                            clickBtn
                            red
                            redBorder
                            btnClickStyle
                            whiteBackground
                          "
                          @click.self="
                            endVisible = true;
                            robotData = item;
                          "
                          v-if="item.states && item.states !== 'STOPING'"
                        >
                          {{ $t("m.终止") }}
                        </div>

                        <div
                          class="clickBtn whiteBackground green greenBorder"
                          @click="startRobot(item)"
                          v-if="item.states == 'STOPING' || !item.states"
                        >
                          {{ $t("m.启动") }}
                        </div>
                        <div
                          class="clickBtn blueBackground"
                          :style="
                            $store.state.lang == 'ch'
                              ? 'width: 84px'
                              : 'width:150px'
                          "
                          @click="closePositionClick(item)"
                        >
                          {{ $t("m.市价平仓") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="no-data" v-if="total == 0">
                <img
                  style="margin: -40px 0 20px"
                  src="@/assets/icon/quesheng.svg"
                  alt=""
                />
                <span class="hui">{{ $t("m.暂无数据") }}</span>
              </div>

              <div class="no-data" v-if="totalCopy == 0">
                <img
                  style="margin: -40px 0 20px"
                  src="@/assets/icon/quesheng.svg"
                  alt=""
                />
                <span class="hui">{{ $t("m.暂无数据") }}</span>
              </div>

              <div
                class="pagination-warp"
                v-if="totalCopy || totalCopy == 0 ? totalCopy > 0 : total > 0"
              >
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNo"
                  :page-sizes="[10, 20, 50, 100, 500]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCopy || totalCopy == 0 ? totalCopy : total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <!-- 当前做单记录 -->
    <el-dialog
      :title="$t('m.当前做单')"
      :visible.sync="openOrdersShow"
      :before-close="handleClose"
      width="800px"
      class="transferBox"
    >
      <template>
        <el-table
          :empty-text="$t('m.暂无数据')"
          :data="openOrders"
          style="width: 100%"
        >
          <el-table-column
            prop="orderTime"
            :label="$t('m.成交时间')"
            width="180px"
          >
          </el-table-column>
          <el-table-column prop="tradeOffset" :label="$t('m.方向')">
            <template slot-scope="scope">
              <span
                class="green"
                v-if="
                  scope.row.tradeOffset == '1' && scope.row.tradeType == '1'
                "
              >
                {{ $t("m.开多") }}
              </span>
              <span
                class="green"
                v-if="
                  scope.row.tradeOffset == '0' && scope.row.tradeType == '1'
                "
              >
                {{ $t("m.平空") }}
              </span>
              <span
                class="red"
                v-if="
                  scope.row.tradeOffset == '0' && scope.row.tradeType == '0'
                "
              >
                {{ $t("m.平多") }}
              </span>
              <span
                class="red"
                v-if="
                  scope.row.tradeOffset == '1' && scope.row.tradeType == '0'
                "
              >
                {{ $t("m.开空") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="dealAmount"
            :label="$t('m.成交数量') + '(BTC)'"
            width="170px"
          >
            <template slot="header" slot-scope="scope">
              {{ $t("m.成交数量") + "(" + counterCoin + ")" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="dealPrice"
            :label="$t('m.成交价格') + '(USDT)'"
            width="170px"
          >
            <template slot="header" slot-scope="scope">
              {{ $t("m.成交价格") + "(" + baseCoin + ")" }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="profit" :label="$t('m.收益')">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.profit > 0
                    ? 'longSize'
                    : scope.row.profit < 0
                    ? 'shortSize'
                    : ''
                "
              >
                <div>{{ scope.row.profit }}</div>
                <div v-if="scope.row.profit !== '--'">
                  {{ scope.row.profitRate + "%" }}
                </div>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>
    <!-- 委托记录 -->
    <el-dialog
      :title="$t('m.当前委托')"
      :visible.sync="orderListShow"
      :before-close="handleClose"
      width="850px"
      class="transferBox"
    >
      <template>
        <el-table
          :data="orderList"
          style="width: 100%"
          :empty-text="$t('m.暂无数据')"
        >
          <el-table-column prop="orderTime" :label="$t('m.时间')" width="180px">
          </el-table-column>
          <el-table-column prop="tradeOffset" :label="$t('m.方向')">
            <template slot-scope="scope">
              <span
                class="red"
                v-if="
                  scope.row.tradeOffset == '1' && scope.row.tradeType == '1'
                "
                >{{ $t("m.开多") }}</span
              >
              <span
                class="red"
                v-if="
                  scope.row.tradeOffset == '0' && scope.row.tradeType == '1'
                "
                >{{ $t("m.平空") }}</span
              >
              <span
                class="green"
                v-if="
                  scope.row.tradeOffset == '0' && scope.row.tradeType == '0'
                "
                >{{ $t("m.平多") }}</span
              >
              <span
                class="green"
                v-if="
                  scope.row.tradeOffset == '1' && scope.row.tradeType == '0'
                "
                >{{ $t("m.开空") }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="amount">
            <template slot="header" slot-scope="scope">
              {{ $t("m.委托数量") + "(" + counterCoin + ")" }}
            </template>
            <template slot-scope="scope">
              {{ scope.row.amount * scope.row.contractSize }}
            </template>
          </el-table-column>
          <el-table-column prop="price" width="130px">
            <template slot="header" slot-scope="scope">
              {{ $t("m.委托价格") + "(" + baseCoin + ")" }}
            </template>
            <template slot-scope="scope">
              {{ Number(scope.row.price) }}
            </template>
          </el-table-column>
          <el-table-column prop="sourceType" :label="$t('m.来源')" width="80px">
            <template slot-scope="scope">
              {{
                scope.row.sourceType == 1 ? $t("m.手动操作") : $t("m.机器人")
              }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="profit" :label="$t('m.操作')">
            <template slot-scope="scope">
              <span class="tableRevoke" @click="revokeEntrust(scope.row)">
                {{ $t("m.撤销") }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>
    <!-- 手动加仓 -->
    <AddManual
      v-if="addManualShow.value == 1"
      :addManualShow="addManualShow"
      :addManualList="addManualList"
    />
    <!-- 划转 -->
    <Transfer
      v-if="transferShow.value == 1"
      :transferShow="transferShow"
      :apiCurrent="apiCurrent"
    />
    <!-- 市价平仓 -->
    <el-dialog
      :title="$t('m.市价平仓')"
      :visible.sync="closePositionShow"
      v-if="closePositionList"
      :before-close="handleClose"
      width="366px"
      class="manualBox"
    >
      <div class="manualItem">
        <div class="manual-name">{{ $t("m.委托方向") }}</div>
        <el-select
          v-model="closePositionValue"
          :disabled="closePositionList.robotPattern !== 2"
          :placeholder="$t('m.请选择')"
        >
          <el-option
            v-for="item in [
              { name: $t('m.平多'), value: '平多' },
              { name: $t('m.平空'), value: '平空' },
            ]"
            :key="item.name"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="manualItem">
        <div class="manual-name">{{ $t("m.委托类型") }}</div>
        <div>{{ $t("m.市价委托") }}</div>
      </div>
      <div class="manualItem">
        <div class="manual-name">{{ $t("m.委托数量") }}</div>
        <div>
          <span
            v-if="
              closePositionList.robotPattern == 1 ||
              (closePositionList.robotPattern == 2 &&
                closePositionValue == $t('m.平多'))
            "
          >
            <!-- {{
              (Math.floor(
                Number(closePositionList.position.position.buyPosition.volume) *
                  10 0000
              ) /
                10 0000) *
              closePositionList.contractSize
            }} -->
            {{
              Public.toMathNum(
                closePositionList.position.position.buyPosition.volume
              ) * closePositionList.contractSize
            }}
          </span>
          <span
            v-if="
              closePositionList.robotPattern == 0 ||
              (closePositionList.robotPattern == 2 &&
                closePositionValue == $t('m.平空'))
            "
          >
            <!-- {{
              (Math.floor(
                Number(
                  closePositionList.position.position.sellPosition.volume
                ) * 10 0000
              ) /
                10 0000) *
              closePositionList.contractSize
            }} -->
            {{
              Public.toMathNum(
                closePositionList.position.position.sellPosition.volume
              ) * closePositionList.contractSize
            }}
          </span>
          {{ closePositionList.counterCoin }}
        </div>
      </div>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="handleClose()">{{ $t("m.取消") }}</div>
        <div class="clickBtn" @click="closePosition()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>
    <!-- 成交记录 -->
    <el-dialog
      :title="$t('m.成交记录')"
      :visible.sync="qureyStrategyOrderShow"
      v-if="qureyStrategyOrderShow"
      :before-close="handleClose"
      width="800px"
      class="manualBox"
    >
      <StartegyTradeHistory
        :executeId="strategyOrderList.executeId"
        :contractSize="strategyOrderList.contractSize"
      />
    </el-dialog>
    <!-- 创建完成是否开启机器人 -->
    <el-dialog
      :title="$t('m.启动机器人')"
      :visible.sync="openVisible"
      width="366px"
      class="openBox"
    >
      <span>{{ $t("m.确定要启动当前机器人") + "？" }}</span>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="openVisible = false">
          {{ $t("m.取消") }}
        </div>
        <div class="clickBtn" @click="startRobotBtn()">
          {{ $t("m.确定") }}
        </div>
      </div>
    </el-dialog>
    <!-- 终止启机器人 -->
    <el-dialog
      :title="$t('m.终止机器人')"
      :visible.sync="endVisible"
      width="366px"
      class="openBox"
      :show-close="false"
    >
      <span>{{ $t("m.确定要终止当前机器人") + "？" }}</span>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="endVisible = false">
          {{ $t("m.取消") }}
        </div>
        <div class="clickBtn" @click="endRobotBtn()">
          {{ $t("m.确定") }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import Transfer from "@/components/Transfer.vue";
import AddManual from "@/components/addManual.vue";
import HomeSkeleton from "../modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import StartegyTradeHistory from "./childModular/startegyTradeHistory.vue";
import Menu from "@/components/menu.vue";
import Vuex from "vuex";
import {
  qureyExecuteStrategyList,
  qureyExecuteStrategyById,
  getEntrustOrders,
  closePosition,
  revokeEntrust,
  restartRobot,
  suspendRobot,
  startRobot,
  robotEnd,
  queryRobotDetails,
} from "@/request/api";
export default {
  name: "exampleList",
  components: {
    Transfer,
    AddManual,
    TopNavigation,
    Menu,
    HomeSkeleton,
    StartegyTradeHistory,
  },
  data() {
    return {
      moment,
      pageNo: 1,
      pageSize: 10,
      executeStrategyList: null,
      exchangeName: null,
      robotName: null,
      total: 0,
      openOrdersShow: false, //当前做单显隐
      openOrders: null, //当前做单列表
      baseCoin: null, //当前投入币种
      counterCoin: null, //当前获取币种
      orderListShow: false, //委托显隐
      orderList: null, //委托列表
      openVisible: false,
      endVisible: false,
      transferShow: {
        value: 0,
      }, //划转页面显隐
      addManualShow: {
        value: 0,
      }, //加仓页面显隐
      addManualList: null, //当前加仓数据
      executeId: null,
      revokeValue: false,
      closePositionShow: false, //市价平仓显隐
      closePositionList: null,
      closePositionValue: "平多",
      exchanges: [
        {
          value: "全部",
        },
        {
          value: "Huobi",
          disabled: true,
        },
        {
          value: "Binance",
        },
        {
          value: "OkexV5",
        },
      ],
      isApi: null,
      totalCopy: null, //查询后的条数
      strategyOrderList: null, //成交记录
      qureyStrategyOrderShow: false,
      timeShow: 0,
      priceShow: 0,
    };
  },
  watch: {
    "addManualShow.value"() {
      if (
        this.addManualShow.value == 0 &&
        localStorage.getItem("addPosition")
      ) {
        this.qureyExecuteStrategyList();
        localStorage.removeItem("addPosition");
      }
    },
  },
  methods: {
    // 实例列表
    qureyExecuteStrategyList() {
      this.$store.commit("setIsSkeleton", true);
      let payload = {
        token: localStorage.getItem("token"),
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        robotName: this.robotName,
        exchangeName: this.exchangeName == "全部" ? null : this.exchangeName,
        strategyId: "WL20210624182847777",
      };
      if (this.timeShow !== 0) {
        payload.sortField = "startTime";
        payload.descIsAsc = this.timeShow == 1 ? "desc" : "asc";
      } else {
        if (this.priceShow !== 0) {
          payload.sortField = "incomeUsdtNum";
          payload.descIsAsc = this.priceShow == 1 ? "desc" : "asc";
        } else {
          payload.sortField = "startTime";
          payload.descIsAsc = "desc";
        }
      }
      qureyExecuteStrategyList(payload).then((res) => {
        if (res.status == 200) {
          if (this.robotName) {
            this.totalCopy = Number(res.data.countNum);
          } else {
            this.totalCopy = null;
            this.total = Number(res.data.countNum);
          }
          let executeStrategyList = res.data.executeStrategyList;
          executeStrategyList.forEach((e) => {
            e.maxOrderNum = JSON.parse(e.maxOrderNum);
            e.contractSize = JSON.parse(e.position).contractSize;
            e.position = JSON.parse(e.position);
            if (e.robotPattern == 0 || e.robotPattern == 2) {
              if (
                e.position.position.sellPosition.costHold * 10 <
                e.position.position.liquidationPrice
              ) {
                e.position.position.liquidationPrice = 0;
              }
              if (
                e.position.position.liquidationPrice &&
                e.position.position.liquidationPrice > 0 &&
                e.position.position.sellPosition.costHold.indexOf(".") > -1 &&
                e.position.position.liquidationPrice.indexOf(".") > -1
              ) {
                let pricePrecision =
                  e.position.position.sellPosition.costHold.length -
                  e.position.position.sellPosition.costHold.indexOf(".");
                e.position.position.liquidationPrice = Number(
                  e.position.position.liquidationPrice.slice(
                    0,
                    e.position.position.liquidationPrice.indexOf(".") +
                      pricePrecision
                  )
                );
              }
            } else {
              if (
                e.position.position.buyPosition.costHold * 10 <
                e.position.position.liquidationPrice
              ) {
                e.position.position.liquidationPrice = 0;
              }
              if (
                e.position.position.liquidationPrice &&
                e.position.position.liquidationPrice > 0 &&
                e.position.position.buyPosition.costHold.indexOf(".") > -1 &&
                e.position.position.liquidationPrice.indexOf(".") > -1
              ) {
                let pricePrecision =
                  e.position.position.buyPosition.costHold.length -
                  e.position.position.buyPosition.costHold.indexOf(".");
                e.position.position.liquidationPrice = Number(
                  e.position.position.liquidationPrice.slice(
                    0,
                    e.position.position.liquidationPrice.indexOf(".") +
                      pricePrecision
                  )
                );
              }
            }
          });
          this.executeStrategyList = executeStrategyList;
        } else {
          this.$message.error(res.msg);
        }
        setTimeout(() => {
          this.$store.commit("setIsSkeleton", false);
        }, 500);
      });
    },
    // 清除筛选
    clear() {
      this.robotName = null;
      this.exchangeName = null;
      this.qureyExecuteStrategyList();
    },
    // 计算执行时间
    formatDuring(start) {
      let New = new Date().getTime();
      let mss = New - start;
      var day = parseInt(mss / (1000 * 24 * 60 * 60));
      var hours = parseInt((mss % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      var str =
        day +
        this.$t("m.天") +
        hours +
        this.$t("m.时") +
        minutes +
        this.$t("m.分");
      return str;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.qureyExecuteStrategyList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.qureyExecuteStrategyList();
    },

    // 刷新单条实例
    qureyExecuteStrategyById(id, type) {
      let payload = {
        token: localStorage.getItem("token"),
        executeId: id,
      };
      qureyExecuteStrategyById(payload).then((res) => {
        if (res.status == 200) {
          this.executeStrategyList.forEach((e, idx) => {
            if (e.executeId == res.data.executeStrategy.executeId) {
              e = res.data.executeStrategy;
              e.maxOrderNum = JSON.parse(e.maxOrderNum);
              e.contractSize = JSON.parse(e.position).contractSize;
              e.position = JSON.parse(res.data.executeStrategy.position);
              if (e.robotPattern == 0 || e.robotPattern == 2) {
                if (
                  e.position.position.sellPosition.costHold * 10 <
                  e.liquidationPrice
                ) {
                  e.liquidationPrice = 0;
                }
              } else {
                if (
                  e.position.position.buyPosition.costHold * 10 <
                  e.liquidationPrice
                ) {
                  e.liquidationPrice = 0;
                }
              }
              this.$set(this.executeStrategyList, idx, e);
            }
          });

          this.$forceUpdate();
          if (type) {
            this.$message.success(this.$t("m.刷新成功"));
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 当前做单
    orderShow(item) {
      this.openOrdersShow = true;
      this.baseCoin = item.baseCoin;
      this.counterCoin = item.counterCoin;
      let openOrders = [];
      if (item.longOpenOrders) {
        let longOpenOrders = JSON.parse(item.longOpenOrders);
        longOpenOrders.forEach((e) => {
          this.$store.commit("timestampToTime", e.orderTime);
          e.orderTime = this.$store.state.timeNow;
          if (e.tradeOffset == 1) {
            e.profit = "--";
          }
          e.dealPrice = Number(e.dealPrice);
          let profitRate = ((e.profit / e.dealPrice) * 100).toString();
          e.dealAmount = this.Public.toMathNum(
            e.dealAmount * item.contractSize
          );

          if (profitRate.indexOf(".") > -1) {
            e.profitRate = profitRate.slice(0, profitRate.indexOf(".") + 3);
          } else {
            e.profitRate = profitRate;
          }
          openOrders.push(e);
        });
      }
      if (item.shortOpenOrders) {
        let shortOpenOrders = JSON.parse(item.shortOpenOrders);
        shortOpenOrders.forEach((e) => {
          this.$store.commit("timestampToTime", e.orderTime);
          e.orderTime = this.$store.state.timeNow;
          if (e.tradeOffset == 1) {
            e.profit = "--";
          }
          e.dealPrice = Number(e.dealPrice);
          let profitRate = ((e.profit / e.dealPrice) * 100).toString();

          e.dealAmount = e.dealAmount * item.contractSize;
          if (profitRate.indexOf(".") > -1) {
            e.profitRate = this.Public.toMathNum(profitRate);
            // e.profitRate = Math.floor(profitRate * 1 0000) / 1 0000;
          } else {
            e.profitRate = profitRate;
          }
          openOrders.push(e);
        });
      }
      this.openOrders = openOrders;
    },
    // 当前委托
    getEntrustOrders(item) {
      this.orderListShow = true;
      if (item.baseCoin) {
        this.baseCoin = item.baseCoin;
        this.counterCoin = item.counterCoin;
      }
      this.executeId = item.executeId;
      let payload = {
        token: localStorage.getItem("token"),
        executeId: item.executeId,
      };
      getEntrustOrders(payload).then((res) => {
        if (res.status == 200) {
          let orderList = res.data.orderList;
          if (orderList && orderList.length !== 0) {
            orderList.forEach((e) => {
              this.$store.commit("timestampToTime", e.orderTime);
              e.orderTime = this.$store.state.timeNow;
              e.contractSize = item.contractSize;
            });
            this.orderList = orderList;
          } else {
            this.orderList = null;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 撤销委托
    revokeEntrust(item) {
      let payload = {
        token: localStorage.getItem("token"),
        orderId: item.id,
        executeId: this.executeId,
      };
      revokeEntrust(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("撤销成功");
          let dataList = {
            executeId: this.executeId,
          };
          this.revokeValue = true;
          setTimeout(() => {
            this.getEntrustOrders(dataList);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.openOrdersShow = false;
      this.orderListShow = false;
      this.addManualShow.value = 0;
      this.addManualValue = this.$t("m.市价委托");
      this.closePositionShow = false;
      this.closePositionValue = "平多";
      this.qureyStrategyOrderShow = false;
      if (this.revokeValue) {
        this.qureyExecuteStrategyList();
        this.revokeValue = false;
      }
    },

    // 划转按钮
    transferShowBtn(item) {
      this.transferShow.value = 1;
      this.apiCurrent = item;
    },
    // 转跳到详情页
    strategyOrder(item) {
      this.$router.push({ path: "/robotList/strategyDatails" });
      localStorage.setItem("robotId", item.robotId);
    },
    // 加仓
    addManualClick(item) {
      this.addManualList = item;
      if (this.addManualList.contractSize.toString().indexOf(".") > -1) {
        this.addManualList.contractSize =
          this.addManualList.contractSize.toString().length -
          this.addManualList.contractSize.toString().indexOf(".");
      }
      this.addManualShow.value = 1;
    },
    // 市价平仓按钮
    closePositionClick(item) {
      this.closePositionList = item;
      this.closePositionShow = true;
    },

    // 市价平仓
    closePosition() {
      let value = 0;
      if (this.closePositionList.robotPattern == 2) {
        if (this.closePositionValue == "平多") {
          value =
            this.Public.toMathNum(
              this.closePositionList.position.position.buyPosition.volume
            ) * this.closePositionList.contractSize;
        } else {
          value =
            this.Public.toMathNum(
              this.closePositionList.position.position.sellPosition.volume
            ) * this.closePositionList.contractSize;
        }
      } else if (this.closePositionList.robotPattern == 1) {
        value =
          this.Public.toMathNum(
            this.closePositionList.position.position.buyPosition.volume
          ) * this.closePositionList.contractSize;
      } else {
        value =
          this.Public.toMathNum(
            this.closePositionList.position.position.sellPosition.volume
          ) * this.closePositionList.contractSize;
      }
      if (value == 0) {
        this.$message.error("当前没有可平仓位");
        return;
      }

      let payload = {
        token: localStorage.getItem("token"),
        executeId: this.closePositionList.executeId,
        direction:
          this.closePositionList.robotPattern == 2
            ? this.closePositionValue == "平多"
              ? "1"
              : "0"
            : this.closePositionList.robotPattern + "",
      };
      closePosition(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.操作成功"));
          this.closePositionShow = false;
          this.qureyExecuteStrategyList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    createBot() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning(this.$t("m.请登录后再试"));
        this.Public.toLogin();
      } else if (!this.isApi) {
        this.$router.push({ path: "/myExchange" });
      } else {
        this.$router.push({ path: "/robotList/createStrategy" });
      }
    },
    //成交记录按钮
    qureyStrategyOrderList(item) {
      this.strategyOrderList = item;
      this.qureyStrategyOrderShow = true;
    },

    // 编辑机器人
    editRobot(item) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: item.robotId,
      };
      queryRobotDetails(payload).then((res) => {
        let displayConfig = JSON.parse(res.data.robot.displayConfig);
        displayConfig.type = "edit";
        displayConfig.robotId = item.robotId;
        localStorage.setItem("dcaType", "edit");
        localStorage.setItem("copyStrategy", JSON.stringify(displayConfig));
        this.$router.push({ path: "/robotList/createStrategy" });
      });
    },

    //重启机器人
    restartRobot(item) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: item.robotId,
      };
      restartRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.重启成功"));
          setTimeout(() => {
            this.qureyExecuteStrategyById(item.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 暂停机器人
    suspendRobot(item) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: item.robotId,
      };
      suspendRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.暂停成功"));
          setTimeout(() => {
            this.qureyExecuteStrategyById(item.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 启动机器人实例
    startRobot(id) {
      this.robotId = id;
      this.openVisible = true;
    },
    // 启动机器人
    startRobotBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
      };
      startRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.启动成功"));
          this.openVisible = false;
          setTimeout(() => {
            this.qureyExecuteStrategyById(this.robotData.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 终止机器人
    endRobotBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
      };
      robotEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.终止成功"));
          this.endVisible = false;
          setTimeout(() => {
            this.qureyExecuteStrategyById(this.robotData.executeId);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    timeSort() {
      this.timeShow++;
      this.priceShow = 0;
      this.timeShow = this.timeShow % 3;
      this.qureyExecuteStrategyList();
    },
    priceSort() {
      this.priceShow++;
      this.timeShow = 0;
      this.priceShow = this.priceShow % 3;
      this.qureyExecuteStrategyList();
    },
  },
  mounted() {
    this.qureyExecuteStrategyList();
    this.isApi = localStorage.getItem("isApi");
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.example {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include themeify {
    background: themed("secondaryBackColor") !important;
    box-shadow: themed("shadow") !important;
    color: themed("primaryColor") !important;
  }

  border-radius: 8px;
  .el-container {
    height: calc(100vh - 85px);
    @include themeify {
      background: themed("primaryBackColor") !important;
    }
    .el-main {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      // overflow-x: scroll;
      overflow-y: hidden;
      @include themeify {
        background: themed("primaryBackColor") !important;
      }
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        flex: 1;
        // height: 100%;
        position: relative;
        padding: 20px;
        padding-right: 0;
        @include themeify {
          background: themed("secondaryBackColor") !important;
        }
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
  .search {
    display: flex;
    justify-content: space-between;
    height: 65px;
    margin-bottom: 20px;
    padding-right: 60px;
    .searchBox {
      margin-right: 20px;
      display: flex;
      flex-direction: column;

      .inputStyle {
        margin-top: 10px;
      }
      :last-child {
        // margin-top: 10px;
      }
      :first-child {
        flex: 1;
      }
      // ::v-deep.el-input__suffix{
      //   margin-top: 0 !important;
      // }
    }
    .searchBox:last-child {
      margin-right: 0;
    }
  }
  .data-warp {
    min-width: 1140px;
    height: calc(100vh - 65px);
    overflow: auto;
    padding-right: 20px;
    padding-bottom: 190px;
    position: relative;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
  }
  .dataBox {
    .listTitle {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 20px;
      .titleItem {
        display: flex;
        width: 120px;
      }
      :last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
    .listBox {
      padding: 20px 0;
      @include themeify {
        border-top: 1px solid themed("splitline") !important;
      }
      .list-top {
        display: flex;
        justify-content: space-between;
        .list-user {
          display: flex;
          align-items: center;
          height: 20px;
          .list-user-robotName {
            margin-right: 15px;
            color: #0895f7;
            cursor: pointer;
            position: relative;
            .identification {
              width: 8px;
              height: 8px;
              background: #fa5c5d;
              border-radius: 50%;
              position: absolute;
              top: -4px;
              right: -10px;
            }
          }
        }
        .list-time {
          display: flex;
          align-items: center;
          color: #999fb4;
          .list-time-name {
            display: flex;
            align-items: center;
          }
          .list-time-carry {
            display: flex;
            align-items: center;
            margin: -2px 3px 0;
          }
        }
      }
      .listStyle {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        .listItem {
          display: flex;
          flex-direction: column;
          width: 120px;
          .listBottom-Box {
            display: flex;
            align-items: center;
          }
          .listBottom-style {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .listBottom-number {
              display: flex;
              justify-content: flex-end;
              width: 100%;
            }
          }
        }
        .itemStyle {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
    .listBottom {
      display: flex;
      justify-content: space-between;
      .listBottom-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
        :last-child {
          margin: 0;
        }
        .clickBtn {
          min-width: 60px;
          height: 28px;
          font-size: 12px;
        }
        .stopBtn {
          width: 51px;
          @include themeify {
            background: themed("secondaryBackColor") !important;
          }
        }
      }
    }
  }
  .dataBoxColor {
    background: #fbfbfb;
  }
  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 180px;
    }
    .text {
      margin: 25px 0;
      @include themeify {
        color: themed("secondaryColor");
      }
    }
  }
  .pagination-warp {
    min-width: 1140px;
    width: 100%;
    height: 50px;
    text-align: right;
    margin-top: 20px;
    position: absolute;
    left: 0;
    bottom: -20px;
    padding-right: 60px;
    background: #fff;
    border-radius: 8px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
    .el-pagination {
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    ::v-deep.el-pagination__sizes {
      height: auto;
      line-height: normal;
    }
    ::v-deep.el-input__inner {
      background: #fff !important;
      color: #606266 !important;
    }
  }
}
.robotPatternBox {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 2px 6px;
  // height: 18px;
  border-radius: 2px;
  color: #fff;
  margin-right: 5px;
}
.tableRevoke {
  color: #0895f7;
  cursor: pointer;
}
.manualBox {
  /deep/.el-dialog__body {
    padding: 0 20px;
  }
  .manualItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .manual-name {
      display: flex;
      align-items: center;
      width: 100px;
    }
    .el-select {
      flex: 1;
    }
    .el-input {
      flex: 1;
    }
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.stateStyle {
  // padding-top: 15px;
  min-width: 90px;
  display: flex;
}
.sortStyle {
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 6px;
    margin-left: 3px;
  }
}
.openBox {
  /deep/.el-dialog {
    margin-top: 34vh !important;
  }
  /deep/.el-dialog__header {
    padding: 20px 30px 10px;
  }
  /deep/.el-dialog__body {
    padding: 20px 30px 35px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 30px 30px;
  }
  span {
    color: #999fb4;
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>