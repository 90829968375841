<template>
  <div class="neutral">
    <div class="label">
      <span class="first"></span>
      <span>{{ $t("m.开多") }}</span>
      <span>{{ $t("m.平多") }}</span>
      <span>{{ $t("m.价格") }}</span>
      <span>{{ $t("m.开空") }}</span>
      <span>{{ $t("m.平空") }}</span>
      <span class="last"></span>
    </div>
    <div id="cont-warp" class="cont-warp">
      <div class="cont-box box1"></div>
      <div class="cont-box">
        <div
          class="item"
          :class="{ gray: item < 9 }"
          v-for="item in 20"
          :key="item"
        ></div>
      </div>
      <div class="cont-box">
        <div
          class="item"
          :class="{ gray: item > 8 }"
          v-for="item in 20"
          :key="item"
        ></div>
      </div>
      <div class="cont-box">
        <span class="item center" v-for="item in 20" :key="item">--</span>
      </div>

      <div class="cont-box">
        <div
          class="item"
          :class="{ gray: item > 8 }"
          v-for="item in 20"
          :key="item"
        ></div>
      </div>
      <div class="cont-box">
        <div
          class="item"
          :class="{
            gray: item < 9,
          }"
          v-for="item in 20"
          :key="item"
        ></div>
      </div>
      <div class="cont-box box1"></div>

      <!-- line -->

      <div id="center-line" class="line-warp" :style="{ top: 305 + 'px' }">
        <span class="text">{{ "(" + $t("m.市场价") + ")" }}</span>
        <div class="warp1">
          <img src="@/assets/icon/zuo.png" alt="" />
          <span class="line"></span>
          <img src="@/assets/icon/you.png" alt="" />
        </div>
        <span class="price">55000</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.neutral {
  .label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;
    @include themeify {
      border-bottom: 1px solid themed("splitline");
    }
    .first,
    .last {
      width: 130px;
    }
    span {
      width: 160px;
      text-align: center;
    }
  }
  .cont-warp {
    width: 100%;
    height: calc(100vh - 195px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    overflow: auto;
    .cont-box {
      width: 160px;
      // height: 300px;
      .item {
        border: 1px solid #e6e5f2;
        display: flex;
        align-items: center;
        width: 160px;
        height: 30px;
        position: relative;
        overflow: hidden;
        .quantity {
          display: block;
          height: 30px;
          line-height: 30px;
          padding-left: 10px;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.32);
        }
        .allQuantity {
          position: absolute;
        }
      }
      .center {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
      }
      .redBack {
        color: #fff;
        background: #fa5c5d;
      }
      .greenBack {
        color: #fff;
        background: #2670FA;
      }
      .gray {
        background: #d0d7df;
      }

      .item:hover {
        // background-color: #fdfdfd;
        cursor: move;
      }
      .item + .item {
        border-top: none;
        margin-top: 10px;
      }
    }
    .box1 {
      width: 130px;
    }
    .line-warp {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      .text {
        width: 111px;
        font-size: 12px;
        text-align: right;
        @include themeify {
          color: themed("secondaryColor") !important;
        }
      }
      .warp1 {
        width: 878px;
        display: flex;
        align-items: center;
        margin: 0 10px;
        img {
          width: 9px;
          height: 8px;
        }
        .line {
          flex: 1;
          @include themeify {
            border-top: 1px solid themed("splitline") !important;
          }
        }
      }
      .price {
        // margin-left: 10px;
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
    }
  }
}
</style>