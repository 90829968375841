<template>
  <div class="reseth5">
    <template v-if="step == 0">
      <div class="menuh5">
        <RichBut class="img-backh5" @click="backClick(step)">
          <template #default="{richState}">
            <img v-if="richState=='over'" src="@/assets/image/v3/fanhui.png">
            <img v-else-if="richState=='down'" src="@/assets/image/v3/fanhui.png">
            <img v-else src="@/assets/image/v3/fanhui.png">
          </template>
        </RichBut>

        <span class="font20 fontc ch1">{{$t("m.重置密码")}}</span>
      </div>
      
      <div class="contenth5-user">

        <RichInput class="mb32"
        v-model="user"
        :placeholder="$t('m.请输入邮箱')"
        :imgLeft="require('@/assets/image/v3/signin_user.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_user_.png')" />

        <RichBut class="buth5-ok" styleType="blan" @click="oneStep()">
          <span class="font16 fontc">{{$t('m.下一步')}}</span>
        </RichBut>
      </div>
    </template>

    <template v-if="step == 1">
      <div class="menuh5">
        <RichBut class="img-backh5" @click="backClick(step)">
          <template #default="{richState}">
            <img v-if="richState=='over'" src="@/assets/image/v3/fanhui.png">
            <img v-else-if="richState=='down'" src="@/assets/image/v3/fanhui.png">
            <img v-else src="@/assets/image/v3/fanhui.png">
          </template>
        </RichBut>

        <span class="font20 fontc ch1">{{$t("m.安全验证")}}</span>
      </div>
    
      <div class="contenth5-user">

        <RichInput class="mb20"
        v-model="user"
        :disabled="true"
        :imgLeft="require('@/assets/image/v3/signin_user.png')" />

        <RichInput class="mb32"
        v-model="emailCode"
        :placeholder="$t('m.请输入验证码') "
        :imgLeft="require('@/assets/image/v3/signin_num.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_num_.png')">
          <template #right>
            <div v-if="downTimeEmail" class="but-code">
              <span class="font14 ch3">{{downTimeEmail + ' s'}}</span>
            </div>
            <div v-else  @click.stop="$emit('httpSendEmail', user)">
              <RichBut class="but-code" style="llan">
                <span class="font14 clan">{{$t('m.获取验证码')}}</span>
              </RichBut>
            </div>
          </template>
        </RichInput>

        <RichBut class="buth5-ok" styleType="blan" @click="towStep()">
          <span class="font16 fontc">{{$t('m.提交')}}</span>
        </RichBut>
      </div>
    </template>

    <template v-if="step == 2">
      <div class="menuh5">
        <RichBut class="img-backh5" @click="backClick(step)">
          <template #default="{richState}">
            <img v-if="richState=='over'" src="@/assets/image/v3/fanhui.png">
            <img v-else-if="richState=='down'" src="@/assets/image/v3/fanhui.png">
            <img v-else src="@/assets/image/v3/fanhui.png">
          </template>
        </RichBut>

        <span class="font20 fontc ch1">{{$t("m.谷歌验证")}}</span>
      </div>
    
      <div class="contenth5-user">

        <RichInput class="mb32"
        v-model="gaCode"
        :placeholder="$t('m.请输入谷歌验证码') "
        :imgLeft="require('@/assets/image/v3/signin_num.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_num_.png')" />

        <RichBut class="buth5-ok" styleType="blan" @click="threeStep()">
          <span class="font16 fontc">{{$t('m.提交')}}</span>
        </RichBut>
      </div>
    </template>

    <template v-if="step == 3">
      <div class="menuh5">
        <RichBut class="img-backh5" @click="backClick(step)">
          <template #default="{richState}">
            <img v-if="richState=='over'" src="@/assets/image/v3/fanhui.png">
            <img v-else-if="richState=='down'" src="@/assets/image/v3/fanhui.png">
            <img v-else src="@/assets/image/v3/fanhui.png">
          </template>
        </RichBut>

        <span class="font20 fontc ch1">{{$t("m.重置密码")}}</span>
      </div>
    
      <div class="contenth5-user">

        <RichInput class="mb20"
        v-model="paw1"
        :password="true"
        :placeholder="$t('m.请输入6-20位字母加数字新密码')"
        :imgLeft="require('@/assets/image/v3/signin_lock.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_lock_.png')" />

        <RichInput class="mb32"
        v-model="paw2"
        :password="true"
        :placeholder="$t('m.请再次输入密码')"
        :imgLeft="require('@/assets/image/v3/signin_lock.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_lock_.png')" />

        <RichBut class="buth5-ok" styleType="blan" @click="fourStep()">
          <span class="font16 fontc">{{$t('m.提交')}}</span>
        </RichBut>
      </div>
    </template>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue"
export default {
  components: {
    QrcodeVue,
    RichInput,
    RichBut,
  },

  props: {
    downTimeEmail: { //邮箱验证码倒计时
      type: Number,
      default :0
    },
    
    step: { //步骤
      type: Number,
      default :0
    },
  },
  data() {
    return {
      user: '', //账户
      
      emailCode: '', //邮箱验证码    
      gaCode: '', //GA验证码

      paw1: '', //密码
      paw2: '', //确认密码  
    };
  },
  
  
  created() {
  },

  methods: {
   
    /******************************* 点击 */
    //返回
    backClick() {

      this.$emit('back');
      this.emailCode = '';
      this.gaCode = '';
      this.paw1 = '';
      this.paw2 = '';
    },

    //第一步
    oneStep() {
      this.$emit('oneStep', this.user);
    },

    //第二步
    towStep() {
      this.$emit('towStep', this.user, this.emailCode);
    },

    //第三步
    threeStep() {
      this.$emit('threeStep', this.user, this.gaCode);
    },

    //第四部
    fourStep() {
      this.$emit('fourStep', this.user, this.paw1, this.paw2);
    }
  },
};
</script>

<style lang='scss' scoped>
.reseth5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 32px 60px 32px;
  .menuh5 {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin: 0 -32px 20px -32px;
    .img-backh5 {
      position: absolute;
      left: 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .contenth5-user {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 0px 16px 16px 16px;

    .but-code {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-left: 12px;
    }
    
    .buth5-ok {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      border-radius: 8px;
      margin-bottom: 32px;
    }
  }
}

</style>