<template>
  <div class="accDetails-data">
    <div class="data-title">
      <span>{{ title }}</span>
      <div class="title-right">
        <img
          @click.stop="editClick()"
          src="@/assets/icon/bianji@2x.png"
          alt=""
        />
        <img
          @click.stop="deleteApiShowBtn()"
          src="@/assets/icon/shanchu@2x.png"
          alt=""
        />
        <img
          @click.stop="refreshApi()"
          src="@/assets/icon/shuaxin@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="box-warp" v-if="data">
      <div class="box">
        <span class="box-title">{{ $t("m.总资产折合") }}</span>
        <span class="box-text">{{
          Public.toMathNum(data.assets) + "USDT"
        }}</span>
      </div>
      <div class="box">
        <span class="box-title">{{ $t("m.今日资产变动") }}</span>
        <!-- <span class="box-title">{{ $t("m.今日收益") }}</span> -->
        <div class="box-text">
          <span
            :class="{
              green: Public.toMathNum(data.todayProfit) > 0,
              red: Public.toMathNum(data.todayProfit) < 0,
            }"
          >
            {{
              (data.todayProfit > 0 ? "+" : "") +
              Public.toMathNum(data.todayProfit) +
              " USDT"
            }}
          </span>

          <img
            src="@/assets/icon/die.svg"
            alt=""
            v-if="Public.toMathNum(data.todayProfit) < 0"
          />
          <img
            src="@/assets/icon/zhang.svg"
            alt=""
            v-if="Public.toMathNum(data.todayProfit) > 0"
          />
        </div>
      </div>
      <div class="box">
        <!-- <span class="box-title">{{ $t("m.近7天累计收益") }}</span> -->
        <span class="box-title">{{ $t("m.近7天资产变动") }}</span>
        <div class="box-text">
          <span
            :class="{
              green: Public.toMathNum(data.sevendayProfit) > 0,
              red: Public.toMathNum(data.sevendayProfit) < 0,
            }"
          >
            {{
              (data.sevendayProfit > 0 ? "+" : "") +
              Public.toMathNum(data.sevendayProfit) +
              " USDT"
            }}
          </span>
          <img
            src="@/assets/icon/die.svg"
            alt=""
            v-if="Public.toMathNum(data.sevendayProfit) < 0"
          />
          <img
            src="@/assets/icon/zhang.svg"
            alt=""
            v-if="Public.toMathNum(data.sevendayProfit) > 0"
          />
        </div>
      </div>
      <div class="box">
        <!-- <span class="box-title">{{ $t("m.总收益") }}</span> -->
        <span class="box-title">{{ $t("m.总资产变动") }}</span>
        <div class="box-text">
          <span
            :class="{
              green: Public.toMathNum(data.earnings) > 0,
              red: Public.toMathNum(data.earnings) < 0,
            }"
          >
            {{
              (data.earnings > 0 ? "+" : "") +
              Public.toMathNum(data.earnings) +
              " USDT"
            }}
          </span>
          <img
            src="@/assets/icon/die.svg"
            alt=""
            v-if="Public.toMathNum(data.earnings) < 0"
          />
          <img
            src="@/assets/icon/zhang.svg"
            alt=""
            v-if="Public.toMathNum(data.earnings) > 0"
          />
        </div>
      </div>
    </div>
    <!-- 删除当前api -->
    <el-dialog
      :title="$t('m.删除账户')"
      :visible.sync="delVisible"
      width="366px"
      class="openBox"
    >
      <span>{{ $t("m.确定要删除当前账户") + "？" }}</span>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="delVisible = false">
          {{ $t("m.取消") }}
        </div>
        <div class="clickBtn" @click="deleteApi()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryApiDetailsTopData,
  synchronSingelCloudApi,
  deleteApi,
  GmApi,
} from "@/request/api";
export default {
  props: ["data", "title"],
  data() {
    return {
      delVisible: false,
    };
  },
  methods: {
    editClick() {
      let editItem = {
        exchangeName: this.data.exchangeId == "29" ? "OkexV5" : "Binance",
        apiKey: this.data.apiKey,
        apiName: this.data.apiName,
        apiId: this.data.apiId,
        accountId: this.data.accountId,
      };
      localStorage.setItem("editItem", JSON.stringify(editItem));
      this.$router.push({ path: "/myExchange" });
    },
    // 刷新
    refreshApi(v) {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.data.apiId,
      };
      synchronSingelCloudApi(payload).then((res) => {
        if (res.status == 200) {
          //获取头部数据
          let payload = {
            token: localStorage.getItem("token"),
            apiId: this.data.apiId,
          };
          queryApiDetailsTopData(payload).then((res) => {
            if (res.status == 200) {
              let apiMap = res.data.apiMap;
              this.data.assets = apiMap.assets;
              this.data.todayProfit = apiMap.todayProfit;
              this.data.sevendayProfit = apiMap.sevendayProfit;
              this.data.earnings = apiMap.earnings;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.success(this.$t("m.刷新失败"));
        }
      });
    },
    //删除当前api
    deleteApiShowBtn() {
      this.delVisible = true;
    },
    //删除当前api
    deleteApiBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.data.apiId,
      };
      deleteApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.删除成功"));
          this.$router.push({ path: "/myExchange" });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.accDetails-data {
  border-radius: 8px;
  padding: 20px;
  @include themeify {
    background-color: themed("secondaryBackColor") !important;
    color: themed("primaryColor") !important;
    box-shadow: themed("shadow") !important;
  }
  .data-title {
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    @include themeify {
      border-bottom: 1px solid themed("splitline") !important;
    }
    .title-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #2670FA;
      border-radius: 4px;
      width: 116px;
      height: 28px;
      img {
        height: 100%;
        padding: 5px 11px;
        cursor: pointer;
        border-right: 1px solid #2670FA;
      }
      :last-child {
        border: 0;
      }
    }
  }
  .box-warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box {
      display: flex;
      flex-direction: column;
      line-height: 24px;
      .box-title {
        font-size: 12px;
      }
      .box-text {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-top: 3px;
        img {
          margin-left: 4px;
        }
      }
    }
  }
}
.openBox {
  /deep/.el-dialog__body {
    padding: 20px 20px 35px;
  }
  span {
    color: #999fb4;
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>