<template>
  <div class="exchange">
    <TopNavigation />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'2'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <template>
              <div class="breadcrumb-warp">
                <span @click="$router.push({ path: '/myExchange' })">
                  {{ $t("m.我的交易所") + " / " }}
                </span>
                <span>{{ $t("m.账户详情") }}</span>
              </div>
              <div class="dataBox" v-if="apiMap">
                <div class="title">
                  <div class="title-box">
                    <img
                      v-if="apiMap.exchangeId == 29"
                      src="@/assets/icon/OKX@2x.png"
                      alt=""
                      style="width: 24px; margin-right: 8px"
                    />
                    <img
                      v-if="apiMap.exchangeId == 4"
                      src="@/assets/icon/bianlogo@2x.png"
                      style="width: 24px; margin-right: 8px"
                      alt=""
                    />
                    <img
                      v-if="apiMap.exchangeId == 30"
                      src="@/assets/icon/cointr@2x.png"
                      style="width: 24px; margin-right: 8px"
                      alt=""
                    />
                    {{ apiMap.apiName.split("-")[1] }}
                  </div>
                  <div>{{ "Api Key：" + apiMap.apiKey }}</div>
                </div>
                <DetailBox :data="apiMap" :title="$t('m.统计数据')" />
              </div>

              <div class="table">
                <div class="tab">
                  <div
                    @click="tabClick('2')"
                    :class="[tabActive == 2 ? 'active' : 'tabTitle']"
                    v-if="apiMap && apiMap.exchangeId == 29"
                  >
                    {{ $t("m.资金账户") }}
                  </div>
                  <div
                    @click="tabClick('0')"
                    :class="[tabActive == 0 ? 'active' : 'tabTitle']"
                  >
                    {{
                      apiMap && apiMap.exchangeId == 29
                        ? $t("m.交易账户-现货")
                        : $t("m.现货账户")
                    }}
                  </div>
                  <div
                    @click="tabClick('1')"
                    :class="[tabActive == 1 ? 'active' : 'tabTitle']"
                  >
                    {{
                      apiMap && apiMap.exchangeId == 29
                        ? $t("m.交易账户-合约")
                        : $t("m.合约账户-U本位合约")
                    }}
                  </div>
                </div>
                <div class="box" v-if="tabActive == 0">
                  <div class="title" style="margin-bottom: 10px">
                    <input
                      style="width: 300px"
                      :placeholder="$t('m.搜索')"
                      type="text"
                      class="inpStyle"
                      v-model="searchValue"
                    />
                    <div
                      class="
                        clickBtn
                        whiteBackground
                        green
                        greenBorder
                        flexStyle
                      "
                      style="width: 112px"
                    >
                      <img src="@/assets/icon/shuaxin.svg" alt="" />
                      <div
                        @click="synchronSingelCloudApi()"
                        style="margin: -1px 0 0 5px"
                      >
                        {{ $t("m.同步资产") }}
                      </div>
                    </div>
                  </div>
                  <el-table
                    :empty-text="$t('m.暂无数据')"
                    :data="tableData"
                    style="width: 100%"
                    ref="tableList"
                  >
                    <el-table-column
                      prop="currency"
                      sortable
                      :label="$t('m.币种')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="last"
                      sortable
                      :label="'USDT' + $t('m.估值')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="increase"
                      sortable
                      :label="$t('m.价格变化')"
                    >
                      <template slot-scope="scope">
                        <span
                          :class="
                            scope.row.increase < 0
                              ? 'red'
                              : scope.row.increase > 0
                              ? 'green'
                              : ''
                          "
                          style="margin-right: 3px"
                        >
                          {{
                            (scope.row.increase > 0 ? "+" : "") +
                            scope.row.increase +
                            "%"
                          }}
                        </span>
                        <img
                          v-if="scope.row.increase < 0"
                          src="@/assets/icon/die.svg"
                          alt=""
                          style="margin-bottom: -3px"
                        />
                        <img
                          v-if="scope.row.increase > 0"
                          src="@/assets/icon/zhang.svg"
                          alt=""
                          style="margin-bottom: -3px"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="available"
                      sortable
                      :label="$t('m.数量')"
                    >
                    </el-table-column>
                    <el-table-column
                      align="right"
                      sortable
                      prop="totalNumber"
                      :label="$t('m.合计') + '( USDT )'"
                      width="150"
                    >
                    </el-table-column>
                  </el-table>
                </div>

                <div class="box" v-if="tabActive == 1">
                  <div class="title">
                    <div class="flexStyle">
                      <div style="margin-right: 10px">
                        {{ $t("m.账户类型") + " : " }}
                      </div>
                      <!-- <div class="flexStyle positionBtn">
              <div
                @click="positionIndex = 0"
                :class="positionIndex == 0 ? 'positionStyle' : ''"
              >
                {{ $t("m.逐仓") }}
              </div>
              <div
                @click="positionIndex = 1"
                :class="positionIndex == 1 ? 'positionStyle' : ''"
              >
                {{ $t("m.全仓") }}
              </div>
            </div> -->
                      <div>{{ $t("m.全仓") }}</div>
                    </div>
                    <div class="flexStyle">
                      <input
                        style="width: 300px"
                        :placeholder="$t('m.搜索')"
                        v-model="searchValue"
                        class="inpStyle"
                        type="text"
                      />
                      <div
                        class="
                          clickBtn
                          whiteBackground
                          green
                          greenBorder
                          flexStyle
                        "
                        style="width: 80px; margin: 0 20px"
                        @click="transferShowBtn()"
                      >
                        <img src="@/assets/icon/huazhuan.svg" alt="" />
                        <div style="margin: -1px 0 0 5px">
                          {{ $t("m.划转") }}
                        </div>
                      </div>
                      <div
                        class="
                          clickBtn
                          whiteBackground
                          green
                          greenBorder
                          flexStyle
                        "
                        style="width: 112px"
                      >
                        <img src="@/assets/icon/shuaxin.svg" alt="" />
                        <div
                          @click="synchronSingelCloudApi()"
                          style="margin: -1px 0 0 5px"
                        >
                          {{ $t("m.同步资产") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceStyle">
                    <span style="margin-right: 20px">
                      {{
                        $t("m.权益") +
                        "：" +
                        (apiMap.exchangeId == 4 || apiMap.exchangeId == 30
                          ? Public.toMathNum(
                              dataList.linerSwapAssets[0].marginBalance
                            )
                          : Public.toMathNum(
                              dataList.linerSwapAssets[0].equity
                            )) +
                        "USDT"
                      }}
                    </span>
                    <span>
                      {{
                        $t("m.可用担保资产") +
                        "：" +
                        Public.toMathNum(
                          dataList.linerSwapAssets[0].available
                        ) +
                        "USDT"
                      }}
                    </span>
                  </div>
                  <el-table
                    :empty-text="$t('m.暂无数据')"
                    :data="tableData"
                    style="width: 100%"
                    ref="tableList"
                  >
                    <el-table-column
                      prop="contractId"
                      sortable
                      :label="$t('m.币种')"
                    >
                    </el-table-column>
                    <el-table-column :label="$t('m.方向')">
                      <template slot-scope="scope">
                        {{
                          (scope.row.direction == "buy"
                            ? $t("m.做多")
                            : $t("m.做空")) +
                          "·" +
                          scope.row.leverRate +
                          "X " +
                          (scope.row.marginMode == "cross"
                            ? $t("m.全仓")
                            : $t("m.逐仓"))
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="volumeNum"
                      sortable
                      :label="$t('m.持仓量')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="costOpen"
                      sortable
                      :label="$t('m.开仓均价') + '( USDT )'"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="maintRiskRate"
                      sortable
                      :label="
                        exchangeId == 4 || exchangeId == 30
                          ? $t('m.保证金比率')
                          : $t('m.保证金率')
                      "
                    >
                    </el-table-column>
                    <el-table-column
                      prop="liquidationPrice"
                      align="right"
                      sortable
                      :label="$t('m.预估强平价') + '( USDT )'"
                      :width="$store.state.lang == 'ch' ? 180 : 250"
                    >
                      <template slot-scope="scope">
                        {{
                          scope.row.liquidationPrice == "0"
                            ? "--"
                            : scope.row.liquidationPrice
                        }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="box" v-if="tabActive == 2">
                  <div class="title" style="margin-bottom: 10px">
                    <input
                      style="width: 300px"
                      :placeholder="$t('m.搜索')"
                      type="text"
                      class="inpStyle"
                      v-model="searchValue"
                    />
                    <div
                      class="
                        clickBtn
                        whiteBackground
                        green
                        greenBorder
                        flexStyle
                      "
                      style="width: 112px"
                    >
                      <img src="@/assets/icon/shuaxin.svg" alt="" />
                      <div
                        @click="synchronSingelCloudApi()"
                        style="margin: -1px 0 0 5px"
                      >
                        {{ $t("m.同步资产") }}
                      </div>
                    </div>
                  </div>
                  <el-table
                    :empty-text="$t('m.暂无数据')"
                    :data="tableData"
                    style="width: 100%"
                    ref="tableList"
                  >
                    <el-table-column
                      prop="currency"
                      sortable
                      :label="$t('m.币种')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="last"
                      sortable
                      :label="'USDT' + $t('m.估值')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="increase"
                      sortable
                      :label="$t('m.价格变化')"
                    >
                      <template slot-scope="scope">
                        <span
                          :class="
                            scope.row.increase < 0
                              ? 'red'
                              : scope.row.increase > 0
                              ? 'green'
                              : ''
                          "
                          style="margin-right: 3px"
                        >
                          {{
                            (scope.row.increase > 0 ? "+" : "") +
                            scope.row.increase +
                            "%"
                          }}
                        </span>

                        <img
                          v-if="scope.row.increase < 0"
                          src="@/assets/icon/die.svg"
                          alt=""
                          style="margin-bottom: -3px"
                        />
                        <img
                          v-if="scope.row.increase > 0"
                          src="@/assets/icon/zhang.svg"
                          alt=""
                          style="margin-bottom: -3px"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="available"
                      sortable
                      :label="$t('m.数量')"
                    >
                    </el-table-column>
                    <el-table-column
                      align="right"
                      sortable
                      prop="totalNumber"
                      :label="$t('m.合计') + '( USDT )'"
                      width="150"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <template v-if="isCharts && apiMap">
                <LineCharts
                  :label="$t('m.资产走势')"
                  :name="$t('m.资产折合') + ' ( USDT )'"
                  height="300px"
                  :time="true"
                  chartsId="acc"
                  @childFn="queryApiDetailsIconData"
                  :data="assets"
                  :number="Public.toMathNum(apiMap.assets)"
                  :createTime="apiMap.createTime - 86400000"
                  style="margin-top: 20px; border-radius: 8px"
                />
                <!-- <LineCharts
                  :label="$t('m.累计收益')"
                  :name="$t('m.累计收益') + ' ( USDT )'"
                  height="300px"
                  :time="true"
                  chartsId="line"
                  @childFn="queryApiDetailsIconData"
                  :data="allProfit"
                  style="margin-top: 20px"
                /> -->
                <!-- <BarCharts
                  :label="$t('m.每日收益')"
                  :name="$t('m.每日收益') + ' ( USDT )'"
                  height="300px"
                  :time="true"
                  chartsId="bar"
                  @childFn="queryApiDetailsIconData"
                  :data="eventProfit"
                /> -->
              </template>

              <Transfer
                v-if="transferShow.value == 1"
                :transferShow="transferShow"
                :apiCurrent="apiCurrent"
              />
            </template>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LineCharts from "@/components/lineCharts.vue";
import BarCharts from "@/components/barCharts.vue";
import Transfer from "@/components/Transfer.vue";

import HomeSkeleton from "../homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import {
  queryApiDetailsTopData,
  queryApiDetailsIconData,
  synchronSingelCloudApi,
  getContractSize,
} from "@/request/api";
import DetailBox from "./detailBox.vue";
export default {
  components: {
    LineCharts,
    BarCharts,
    DetailBox,
    Transfer,
    TopNavigation,
    Menu,
    HomeSkeleton,
  },
  data() {
    return {
      tableData: null,
      accountAssets: null, //币币资产列表
      linerSwapAssets: null, //合约资产列表
      tabActive: 0, //币币合约
      positionIndex: 0, //逐仓全仓
      apiMap: null,
      apiId: null,
      isCharts: false,
      assets: null, // 资产
      allProfit: null, //累计
      eventProfit: null, // 每日
      transferShow: {
        value: 0,
      }, //划转页面显隐
      dataList: null, //账户信息
      searchValue: null, //搜索
    };
  },
  watch: {
    searchValue() {
      if (this.searchValue) {
        this.searchValue = this.searchValue.trim();
        let list = [];
        if (this.tabActive == 0) {
          if (this.accountAssets && this.accountAssets.length > 0) {
            this.accountAssets.forEach((e) => {
              if (e.currency.indexOf(this.searchValue.toUpperCase()) > -1) {
                list.push(e);
              }
            });
          }
        } else if (this.tabActive == 1) {
          if (this.linerSwapAssets && this.linerSwapAssets.length > 0) {
            this.linerSwapAssets.forEach((e) => {
              if (e.contractId.indexOf(this.searchValue.toUpperCase()) > -1) {
                list.push(e);
              }
            });
          }
        } else {
          if (this.moneyAssets && this.moneyAssets.length > 0) {
            this.moneyAssets.forEach((e) => {
              if (e.currency.indexOf(this.searchValue.toUpperCase()) > -1) {
                list.push(e);
              }
            });
          }
        }
        this.tableData = list;
      } else {
        if (this.tabActive == 0) {
          this.tableData = this.accountAssets;
        } else if (this.tabActive == 1) {
          this.tableData = this.linerSwapAssets;
        } else {
          this.tableData = this.moneyAssets;
        }
      }
    },
  },
  methods: {
    tabClick(index) {
      this.$refs.tableList.clearSort();
      this.searchValue = null;
      this.tableData = null;
      this.tabActive = index;
      if (this.tabActive == 0) {
        this.tableData = this.accountAssets;
      } else if (this.tabActive == 1) {
        this.tableData = this.linerSwapAssets;
      } else {
        this.tableData = this.moneyAssets;
      }
    },
    //获取头部数据
    queryApiDetailsTopData() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiId,
      };
      queryApiDetailsTopData(payload).then((res) => {
        if (res.status == 200) {
          this.apiMap = res.data.apiMap;
          if (this.apiMap.exchangeId == 29) {
            this.tabActive = 2;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取图表数据
    queryApiDetailsIconData(name, start, end) {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiId,
        startTime: start ? start + "" : null,
        endTime: end ? end + "" : null,
      };
      queryApiDetailsIconData(payload).then((res) => {
        if (res.status == 200) {
          this.isCharts = true;
          // let iconData = res.data.iconData;
          let data = JSON.parse(res.data.iconData);

          // 资产 sumAssets
          let assets = [];
          // 累计收益 sumEarnings
          let allProfit = [];
          // 每日收益
          let eventProfit = [];

          if (data && data.length != 0) {
            data.sort(function (a, b) {
              return a.time - b.time;
            });

            for (let key of data) {
              let assetsItem = {
                time: key.time,
                value: key.sumAssets,
              };
              assets.push(assetsItem);

              let allProfitItem = {
                time: key.time,
                value: key.sumEarnings,
              };
              allProfit.push(allProfitItem);
            }

            if (allProfit.length == 1) {
              eventProfit = allProfit;
            } else {
              eventProfit[0] = JSON.parse(JSON.stringify(allProfit[0]));

              for (let i = 1; i < allProfit.length; i++) {
                let num = allProfit[i].value - allProfit[i - 1].value;
                let item = {
                  time: allProfit[i].time,
                  value: Math.floor(num * 1000) / 1000,
                };
                eventProfit.push(item);
              }
            }
          }
          if (name) {
            switch (name) {
              case "资产走势":
                this.assets = assets.length == 0 ? null : assets;
                break;
              case "Asset Trend":
                this.assets = assets.length == 0 ? null : assets;
                break;
              case "累计收益":
                this.allProfit = allProfit.length == 0 ? null : allProfit;
                break;
              case "Benefits":
                this.allProfit = allProfit.length == 0 ? null : allProfit;
                break;
              case "每日收益":
                this.eventProfit = eventProfit.length == 0 ? null : eventProfit;
                break;
              case "Daily Benefit":
                this.eventProfit = eventProfit.length == 0 ? null : eventProfit;
                break;
              default:
                break;
            }
          } else {
            this.assets = assets;
            this.allProfit = allProfit;
            this.eventProfit = eventProfit;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取币币合约列表
    synchronSingelCloudApi() {
      this.tableData = null;
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiId,
      };
      synchronSingelCloudApi(payload).then((res) => {
        if (res.status == 200) {
          let dataList = [];
          for (let item in res.data) {
            dataList = res.data[item];
          }
          if (dataList.isExpire) {
            this.$message.warning(this.$t("m.API已过期,请重新录入"));
            return;
          }
          let accountAssetsList = dataList.accountAssets;
          let accountAssets = [];
          if (accountAssetsList && accountAssetsList.length > 0) {
            accountAssetsList.forEach((e) => {
              e.increase = this.Public.toMathNum(e.increase / 100, 100);
              e.last = this.Public.toMathNum(e.last);
              e.totalNumber = this.Public.toMathNum(
                Number(e.last) * Number(e.available)
              );
              if (e.currency == "BTC") {
                e.available = Number(
                  Math.floor(Number(e.available) * 1000000) / 1000000
                );
                if (e.available >= 0.000001) {
                  accountAssets.push(e);
                }
              } else {
                e.available = this.Public.toMathNum(e.available);
                if (e.available >= 0.0001) {
                  accountAssets.push(e);
                }
              }
            });
          } else {
            accountAssetsList = null;
          }

          this.accountAssets = accountAssetsList
            ? accountAssets
            : accountAssetsList;
          let positions = null;
          if (dataList.linerSwapAssets && dataList.linerSwapAssets.length > 0) {
            let linerSwapAssets = dataList.linerSwapAssets[0];
            positions = linerSwapAssets.positions;
            this.marginMode = linerSwapAssets.marginMode;
            if (positions && positions.length > 0) {
              let contractIdStr = "";
              positions.forEach((e) => {
                e.maintRiskRate =
                  (this.apiMap.exchangeId == 4 || this.apiMap.exchangeId == 30
                    ? this.Public.toMathNum(linerSwapAssets.maintRiskRate, 100)
                    : this.Public.toMathNum(linerSwapAssets.mgnRatio, 100)) +
                  "%";
                if (this.exchangeId == 29) {
                  contractIdStr += "," + e.contractId;
                }
                if (e.contractId.indexOf("USDT") > -1) {
                  e.contractId =
                    this.apiMap.exchangeId == 4 || this.apiMap.exchangeId == 30
                      ? e.contractId.split("USDT")[0] +
                        " " +
                        this.$t("m.永续") +
                        "/USDT"
                      : e.contractId.split("-")[0] +
                        " " +
                        this.$t("m.永续") +
                        "/USDT";
                }
                e.liquidationPrice = this.Public.toMathNum(e.liquidationPrice);
                e.costOpen = this.Public.toMathNum(e.costOpen);
              });
              if (this.exchangeId == 29) {
                contractIdStr = contractIdStr.slice(1, contractIdStr.length);
                let payload = {
                  token: localStorage.getItem("token"),
                  exchangeId: this.exchangeId,
                  contractIdStr: contractIdStr,
                };
                getContractSize(payload).then((res) => {
                  if (res.status == 200) {
                    let contractSizeList = res.data.contractSizeList;
                    positions.forEach((e) => {
                      contractSizeList.forEach((item) => {
                        if (
                          e.contractId.indexOf(item.contractId.split("-")[0]) >
                          -1
                        ) {
                          e.volumeNum = e.volumeNum * item.contractSize;
                        }
                      });
                    });
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              }
            } else {
              positions = null;
            }
          }

          this.linerSwapAssets = positions;
          let moneyAssets = dataList.moneyAssets;
          if (moneyAssets && moneyAssets.length > 0) {
            moneyAssets.forEach((e) => {
              e.available = this.Public.toMathNum(e.available);
              e.increase = this.Public.toMathNum(e.increase / 100, 100);
              e.last = this.Public.toMathNum(e.last);
              e.totalNumber = this.Public.toMathNum(
                Number(e.last) * Number(e.available)
              );
            });
          } else {
            moneyAssets = null;
          }

          this.moneyAssets = moneyAssets;
          dataList.apiId = this.apiId;
          this.dataList = dataList;
          if (this.tabActive == 0) {
            this.tableData = this.accountAssets;
          } else if (this.tabActive == 1) {
            this.tableData = this.linerSwapAssets;
          } else {
            this.tableData = this.moneyAssets;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //划转按钮
    transferShowBtn() {
      this.transferShow.value = 1;
      this.apiCurrent = this.apiMap;
    },
  },
  mounted() {
    this.apiId = localStorage.getItem("ApiId");
    this.exchangeId = localStorage.getItem("exchangeId");
    this.queryApiDetailsTopData();
    this.queryApiDetailsIconData();
    this.synchronSingelCloudApi();
  },
};
</script>

<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.exchange {
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 80px);
      display: flex;
      justify-content: space-between;
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        // height: 100%;
        position: relative;
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }

  .breadcrumb-warp {
    margin-bottom: 20px;
    :first-child {
      cursor: pointer;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
    }
    :last-child {
      @include themeify {
        color: themed("primaryColor") !important;
      }
    }
  }
  .dataBox {
    background: linear-gradient(90deg, #2670FA 0%, #0c5ffa 49%, #0895f7 100%);
    border-radius: 8px;
    @include themeify {
      color: themed("btnText") !important;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 30px;
      .title-box {
        display: flex;
        align-items: center;
      }
    }

    .dataBox-nav {
      padding: 0 20px;
      border-radius: 8px;
      @include themeify {
        color: themed("primaryColor") !important;
        background: themed("secondaryBackColor") !important;
      }
      .nav-title {
        padding: 20px 0 10px;
        display: flex;
        justify-content: space-between;
        @include themeify {
          border-bottom: 1px solid themed("splitline") !important;
          box-shadow: themed("shadow") !important;
        }
      }
      .navBox {
        padding: 30px 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        .navItem {
          width: 200px;
          :last-child {
            font-size: 20px;
            margin-top: 8px;
          }
        }
      }
    }
  }
  .table {
    margin-top: 20px;

    ::v-deep.el-table th,
    ::v-deep.el-table tr,
    ::v-deep.el-table td {
      @include themeify {
        color: themed("tabColor") !important;
        background: themed("secondaryBackColor") !important;
      }
    }
    // .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    // ::v-deep.el-table th.el-table__cell.is-leaf {
    //   @include themeify {
    //     border: themed("splitline") !important;
    //   }
    // }

    .tab {
      display: flex;
      margin-bottom: 10px;
      .tabTitle {
        padding: 8px 10px;
        margin-right: 20px;
        @include themeify {
          color: themed("primaryColor") !important;
        }
        cursor: pointer;
      }
    }
    .box {
      border-radius: 8px;
      padding: 20px;
      @include themeify {
        box-shadow: themed("shadow") !important;
        background: themed("secondaryBackColor") !important;
      }
      .title {
        display: flex;
        justify-content: space-between;
        .positionBtn {
          width: 160px;
          height: 36px;
          border-radius: 18px;
          background: #f6f6f6;
          display: flex;
          @include themeify {
            color: themed("primaryColor") !important;
          }
          div {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .positionStyle {
            height: 100%;
            border-radius: 18px;
            @include themeify {
              color: themed("btnText") !important;
              background: themed("btnBackGreen") !important;
            }
          }
        }
      }
      .priceStyle {
        padding: 20px 0;
        @include themeify {
          color: themed("secondaryColor") !important;
        }
      }
      .dataBox {
        .listTitle {
          display: flex;
          justify-content: space-between;
          margin: 30px 0 20px;
          .titleItem {
            display: flex;
            width: 120px;
          }
        }
        .listStyle {
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          @include themeify {
            border-top: 1px solid themed("splitline") !important;
          }
          .listItem {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 130px;
            span {
              margin-bottom: 10px;
            }
          }
        }
        .listBottom {
          display: flex;
          justify-content: space-between;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .active {
      padding: 8px 10px;
      margin-right: 20px;
      cursor: pointer;
      @include themeify {
        color: themed("btnBackGreen") !important;
        border-bottom: 2px solid themed("btnBackGreen") !important;
      }
    }
  }
}
/deep/.lineCharts {
  padding: 20px;
}
/deep/.barCharts {
  padding: 20px;
}
</style>
