<template>
  <div class="details">
    <TopNavigation />
    <div class="details-box">
      <div class="urlName">
        <span @click="toStrategy()" class="hui" style="cursor: pointer">
          {{ robotData.robotName + " / " }}
        </span>
        <span>{{ $t("m.信息") }}</span>
      </div>
      <div class="nav">
        <div class="nav-box">
          <div class="nav-top">
            <div class="nav-time">{{ $t("m.时间") }}</div>
            <div>{{ $t("m.详情") }}</div>
          </div>
          <div class="nav-bottom-warp">
            <div class="nav-bottom" v-for="item in logList">
              <div class="nav-time">
                {{ moment(item.createTime).format("YYYY/MM/DD HH:mm") }}
              </div>
              <div
                class="nav-text"
                :class="item.logType == 2 ? 'textDelStyle' : 'textStyle'"
              >
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-warp">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation.vue";
import moment from "moment";
import { qureyStrategyTradeLogList } from "@/request/api";
export default {
  components: {
    TopNavigation,
  },
  data() {
    return {
      moment,
      logList: null,
      total: null,
      pageNo: 1,
      pageSize: 10,
      robotData: null, //机器人信息
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.qureyStrategyTradeLogList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.qureyStrategyTradeLogList();
    },
    // 查询日志
    qureyStrategyTradeLogList() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      qureyStrategyTradeLogList(payload).then((res) => {
        if (res.status == 200) {
          this.logList = res.data.strategyTradeLogList;
          this.total = Number(res.data.countNum);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 转跳到网格页面
    toStrategy() {
      this.$router.push({ path: "/contractStrategy" });
    },
  },
  mounted() {
    this.qureyStrategyTradeLogList();
  },
  created() {
    if (localStorage.getItem("robotData")) {
      this.robotData = JSON.parse(localStorage.getItem("robotData"));
    }
  },
};
</script>

<style scss lang='scss'>
@import "@/assets/sass/_themeify.scss";
.details {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f9fafc;
  .details-box {
    width: 1080px;
    height: 90%;
    .urlName {
      margin: 20px 0 8px;
    }
    .nav {
      background: #fff;
      height: calc(100vh - 140px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px;
      .nav-box {
        padding: 0 20px;
        .nav-top {
          color: #999fb4;
          display: flex;
          padding: 20px 0 8px;
        }
        .nav-bottom-warp {
          width: 100%;
          height: calc(100vh - 234px);
          overflow: scroll;
        }
        .nav-bottom {
          display: flex;
          padding: 15px 0;
          @include themeify {
            border-bottom: 1px solid themed("splitline") !important;
          }
          .nav-text {
            flex: 1;
          }
          .textStyle {
            @include themeify {
              color: themed("primaryColor") !important;
            }
          }
          .textDelStyle {
            @include themeify {
              color: themed("upRed") !important;
            }
          }
        }
        .nav-time {
          width: 140px;
        }
      }
      .pagination-warp {
        text-align: right;
        bottom: -20px;
        padding-right: 20px;
        background: #fff;
        border-radius: 8px;
        width: 100%;
        @include themeify {
          background: themed("secondaryBackColor") !important;
        }
        .el-pagination {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .el-pagination__sizes {
          height: 36px !important;
          line-height: normal;
          /deep/.el-select {
            top: -5px;
          }
        }
        ::v-deep.el-input__inner {
          background: #fff !important;
          color: #606266 !important;
        }
      }
    }
  }
}
</style>