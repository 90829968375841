<template>
  <div class="fuGridPc">
    <div class="warppc">
      
      <!--1 期货网格交易机器人 -->
      <div class="contentpc onepc" v-if="one">
          
        <div class="column">

          <span class="font28 fontx ch1 mb10">{{$t('m.'+one.span1)}}</span>
          <span class="font48 fontx ch0 mb20">{{$t('m.'+one.span2)}}</span>
          <span class="font18 ch1 mb30">{{$t('m.'+one.span3)}}</span>
          
          <RichBut @click="tapClick(one.but1.click)"
          class="butpc" styleType="blan">
            <span>{{$t('m.'+one.but1.txt)}}</span>
          </RichBut>
        </div>

        <img class="img-icqpc" :src="one.src1">
      </div>

      <!--2 利用加密货币期货交易获利 -->
      <div class="contentpc twopc" v-if="two">

        <span class="font48 fontx ch0 mb50">{{$t('m.'+two.span1)}}</span>

        <div class="row jbetween">
          <div class="cellpc" v-for="(item, index) in two.cells" :key="'two'+index">
            <img :src="item.src1">
            <span class="font18 ch1">{{$t('m.'+item.span1)}}</span>
          </div>
        </div>
      </div>


      <!--3 在顶级加密货币交易所运行交易机器人-->
      <div class="contentpc threepc" v-if="three">
        
        <span class="font48 fontx ch0 mb8">{{$t('m.'+three.span1)}}</span>
        <span class="font18 ch3 mb60">{{$t('m.'+three.span2)}}</span>

        <div class="contpc">

          <div class="column acenter jbetween">
            <img class="img-icqpc" :src="three.src1">

            <RichBut @click="tapClick(three.but1.click)"
            class="butpc" styleType="bhei">
              <span>{{$t('m.'+three.but1.txt)}}</span>
            </RichBut>
          </div>

          <div class="line"></div>

          <div class="column jbetween">
            <img class="img-logopc" :src="three.src2">
            <img class="img-logopc" :src="three.src3">
            <img class="img-logopc" :src="three.src4">
            <img class="img-logopc" :src="three.src5">
          </div>

          <div class="line"></div>

          <div class="column jbetween">
            <img class="img-logopc" :src="three.src6">
            <img class="img-logopc" :src="three.src7">
            <img class="img-logopc" :src="three.src8">
            <img class="img-logopc" :src="three.src9">
          </div>

          <div class="column jbetween mrf_50">
            <img class="img-logopc" :src="three.src10">
            <img class="img-logopc" :src="three.src11">
            <img class="img-logopc" :src="three.src12">
            <img class="img-logopc" :src="three.src13">
          </div>
          
        </div>
      </div>

      <!--4 选择您的策略 -->
      <div class="contentpc fourpc" v-if="four">
        <div class="cellpc" v-for="(item, index) in four.cells" :key="'four'+index">
          <img :src="item.src1">
          <div class="column">
            <div class="row mb20">
              <span v-for="(title, j) in item.title1" :key="'fourtitle'+j"
              class="font48 fontx ch0" :class="j< item.title1.length-1 ? ['mr20'] : []">
              {{$t('m.'+title)}}
              </span>
            </div>
            <span v-if="item.span1" class="font18 clan">{{$t('m.'+item.span1)}}</span>
            <span v-if="item.span2" class="font18 ch2">{{$t('m.'+item.span2)}}</span>
          </div>
        </div>
      </div>
     
      <!--5 利用加密货币期货交易获利 -->
      <div class="contentpc fivepc" v-if="five">

        <span class="font48 fontx ch0 mb50">{{$t('m.'+five.span1)}}</span>

        <div class="row jbetween">
          <div class="cellpc" v-for="(item, index) in five.cells" :key="'five'+index">
            <img :src="item.src1">
            <span class="font18 ch1">{{$t('m.'+item.span1)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },

  data() {
    return {
      isVideo: false,
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.fuGridPc {
  display: flex;
  flex-direction: column;

  .warppc {
    display: flex;
    flex-direction: column;
    align-items: center;
    .line-fenge {
      width: 1200px;
      height: 1px;
      background: #E4E6EF;
      margin-bottom: 120px;
    }
  }
  
  /************************************* 内容 */
  .contentpc {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin-bottom: 120px;
  }

  /**** 1 期货网格交易机器人？*/
  .onepc {
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    .img-icqpc {
      width: 440px;
      height: 379px;
      margin-left: 30px;
    }
    .butpc {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 213px;
      height: 60px;
      border-radius: 6px;
    }
  }
  
  /**** 2 利用加密货币期货交易获利*/
  .twopc {

    .cellpc {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 30px;

      border-radius: 8px;
      width: 390px;
      height: 156px;
      background-image: url('../../assets/image/bot/fugrid_2_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 96px;
        height: 96px;
        margin-right: 20px;
      }
    }
    .cellpc:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 3 在顶级加密货币交易所运行交易机器人*/
  .threepc {
    
    .contpc {
      height: 432px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 60px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #E4E6EF;
      .butpc {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 52px;
        border-radius: 6px;
      }
      .img-icqpc {
        width: 240px;
        height: 240px;
      }
      .img-logopc {
        width: 200px;
        height: 36px;
      }
      .linepc {
        width: 0px;
        border: 1px solid #F0F2F6;
      }
    }
  }

  /**** 4 如何启动网格交易策略？*/
  .fourpc {
    margin-bottom: 90px;
    .cellpc {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 40px;
      margin-bottom: 30px;

      border-radius: 8px;
      width: 100%;
      height: 280px;
      background-image: url('../../assets/image/bot/fugrid_4_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 200px;
        height: 200px;
        margin-right: 80px;
      }
    }
    .cellpc:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 5 利用加密货币期货交易获利*/
  .fivepc {

    .cellpc {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 30px;

      border-radius: 8px;
      width: 390px;
      height: 156px;
      background-image: url('../../assets/image/bot/fugrid_5_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 96px;
        height: 96px;
        margin-right: 20px;
      }
    }
    .cellpc:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

}

.mrf_50 {margin-right: -50px}
</style>
