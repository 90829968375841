<template>
  <div id="dcaCard">
    <template v-if="robotList && robotList.length != 0">
      <div class="card-warp">
        <div
          class="rollbox"
          :class="{ box_rolling: item.isRolling }"
          @click="rollingClick(item)"
          v-for="(item, idx) in robotList"
          :key="idx"
          :style="
            offsetWidth - offsetWidthNum * 285 > 0 &&
            (idx + 1) % offsetWidthNum == 0
              ? 'margin-right:0'
              : ''
          "
        >
          <!-- 正面-->
          <div class="rollbox_front">
            <div class="contentbox">
              <div class="checked">
                <el-checkbox
                  v-model="item.checked"
                  @change="itemChange($event, item)"
                ></el-checkbox>
              </div>

              <div class="strategyName">
                <span class="strategyName-box">
                  {{ item.robotName }}
                  <span class="identification" v-if="item.errorInfo"></span>
                </span>
              </div>

              <div class="pair">
                <span>{{ item.counterCoin + " / " + item.baseCoin }}</span>
                <div
                  class="label"
                  :class="{
                    Neutral: item.robotPattern == 2,
                    long: item.robotPattern == 1,
                    short: item.robotPattern == 0,
                  }"
                  :style="$store.state.lang == 'en' ? 'width:88px' : ''"
                >
                  <span v-if="item.robotPattern == 0">{{ $t("m.做空") }}</span>
                  <span v-if="item.robotPattern == 1">{{ $t("m.做多") }}</span>
                  <span v-if="item.robotPattern == 2">{{ $t("m.双向") }}</span>
                  <span style="margin-top: 2px; margin-left: 6px">
                    {{ item.leverRate + "X" }}
                  </span>
                </div>
              </div>

              <div class="exchange">
                <img
                  v-if="item.exchangeName == 'OkexV5'"
                  src="@/assets/icon/OKX@2x.png"
                  alt=""
                  style="width: 20px; height: 20px; margin-right: 5px"
                />
                <img
                  v-if="item.exchangeName == 'Binance'"
                  src="@/assets/icon/bianlogo@2x.png"
                  style="width: 20px; height: 20px; margin-right: 5px"
                  alt=""
                />
                <span class="nameStyle">
                  {{ item.apiName ? item.apiName.split("-")[1] : "--" }}
                </span>
              </div>
              <!-- <img src="@/assets/icon/line02.png" alt="" /> -->
              <img :src="item.imagesUrl" alt="" v-if="item.imagesUrl" />
              <div class="no-line-data" v-else>{{ $t("m.暂无数据") }}</div>
              <div class="curr">
                {{ $t("m.总收益") + "(" + item.investCoin + ")" }}
              </div>
              <div class="Annualized">
                <span
                  :class="{
                    green: Public.toMathNum(item.sumEarnings) > 0,
                    red: Public.toMathNum(item.sumEarnings) < 0,
                  }"
                >
                  {{ Number(Public.toMathNum(item.sumEarnings)) }}
                </span>
                <span
                  :class="{
                    green: Public.toMathNum(item.earningRate) > 0,
                    red: Public.toMathNum(item.earningRate) < 0,
                    bckGren: Public.toMathNum(item.earningRate) > 0,
                    bckRed: Public.toMathNum(item.earningRate) < 0,
                    bckHui: Public.toMathNum(item.earningRate) == 0,
                  }"
                >
                  {{ Public.toMathNum(item.earningRate, 100) + "%" }}
                </span>
              </div>
              <div class="time">
                {{ $t("m.执行时长") + " " + formatDuring(item.createTime) }}
              </div>
              <div class="ops">
                <span v-if="!item.states" class="hui">{{
                  $t("m.未启用")
                }}</span>
                <span v-if="item.states == 'EXECUTE'">{{
                  $t("m.执行中")
                }}</span>
                <span v-if="item.states == 'SUSPEND'">{{ $t("m.暂停") }}</span>
                <span v-if="item.states == 'STARTING'">{{
                  $t("m.启动中")
                }}</span>
                <span v-if="item.states == 'STARTERROR'">{{
                  $t("m.启动失败")
                }}</span>
                <span v-if="item.states == 'STOPING'">{{
                  $t("m.终止中")
                }}</span>
              </div>
            </div>
          </div>
          <!-- 反面 -->
          <div class="rollbox_behind">
            <div class="contentbox-show">
              <div class="checked">
                <el-checkbox v-model="item.checked"></el-checkbox>
              </div>
              <div>
                <div class="title">
                  {{ $t("m.机器人") + "ID : " + item.robotId }}
                </div>
                <div class="name-warp">
                  <span class="name">
                    <span>{{ $t("m.参数") }}</span>
                    <img
                      @click.self="editRobot(item)"
                      src="@/assets/icon/bianji_bai.svg"
                      alt=""
                    />
                  </span>
                </div>
                <div class="two">
                  {{
                    $t("m.止盈阈值") +
                    " : " +
                    item.displayConfig.strategyPayload.strategy.params
                      .closeThreshold +
                    "%"
                  }}
                </div>
                <div class="two">
                  {{
                    $t("m.首单投入") +
                    " : " +
                    Public.toMathNum(
                      item.displayConfig.strategyPayload.strategy.params.amount
                    )
                  }}
                </div>
                <div class="two">
                  {{
                    $t("m.补仓间隔比例") +
                    " : " +
                    item.displayConfig.strategyPayload.strategy.params
                      .openSpace +
                    "%"
                  }}
                </div>
                <div
                  class="two"
                  v-if="
                    item.displayConfig.strategyPayload.strategy.params
                      .openRebound
                  "
                >
                  {{
                    $t("m.补仓反弹比例") +
                    " : " +
                    item.displayConfig.strategyPayload.strategy.params
                      .openRebound +
                    "%"
                  }}
                </div>
                <div
                  class="two"
                  v-if="item.robotPattern == '1' || item.robotPattern == '2'"
                >
                  {{
                    $t("m.最大做多单数") +
                    " : " +
                    item.displayConfig.strategyPayload.strategy.params
                      .maxLongCount
                  }}
                </div>
                <div
                  class="two"
                  v-if="item.robotPattern == '0' || item.robotPattern == '2'"
                >
                  {{
                    $t("m.最大做空单数") +
                    " : " +
                    item.displayConfig.strategyPayload.strategy.params
                      .maxShortCount
                  }}
                </div>
              </div>

              <div>
                <div class="ops-warp">
                  <span v-if="!item.states" @click.stop="startRobot(item)">{{
                    $t("m.启动")
                  }}</span>
                  <span
                    v-if="item.states == 'SUSPEND'"
                    @click.stop="restartRobot(item.robotId)"
                    >{{ $t("m.重启") }}</span
                  >
                  <span
                    v-if="item.states == 'EXECUTE'"
                    @click.stop="suspendRobot(item.robotId)"
                    >{{ $t("m.暂停") }}</span
                  >
                  <span v-if="item.states == 'STOPING'">
                    {{ $t("m.终止中") }}
                  </span>
                  <span v-if="item.states == 'STARTING'">
                    {{ $t("m.启动中") }}
                  </span>
                  <span v-if="item.states == 'STARTERROR'">
                    {{ $t("m.启动失败") }}
                  </span>

                  <span @click.self="copyRobot(item.displayConfig)">
                    {{ $t("m.复制") }}
                  </span>

                  <!-- copy  -->
                  <span @click.stop="toDatails(item)" class="details-op">
                    <span>{{ $t("m.详情") }}</span>
                    <span
                      class="identification-op"
                      v-if="item.errorInfo"
                    ></span>
                  </span>
                </div>
                <div
                  class="del-warp"
                  :style="$store.state.lang == 'en' ? 'margin-bottom:11px' : ''"
                >
                  <span
                    class="del"
                    v-if="item.states && item.states != 'STOPING'"
                    @click.self="robotEnd(item)"
                  >
                    {{ $t("m.终止") }}
                  </span>

                  <span
                    class="del"
                    v-if="!item.states"
                    @click.self="deleteShow(item.robotId)"
                    >{{ $t("m.删除") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      class="no-data"
      v-if="robotList && robotList.length == 0 && total != 0"
    >
      <img
        style="margin: -40px 0 20px"
        src="@/assets/icon/quesheng.svg"
        alt=""
      />
      <span class="hui">{{ $t("m.暂无数据") }}</span>
    </div>
    <el-dialog
      :title="$t('m.删除机器人')"
      :visible.sync="deleteVisible"
      width="366px"
      class="openBox"
      :show-close="false"
    >
      <span>{{ $t("m.确定要删除当前机器人") + "?" }}</span>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="deleteVisible = false">
          {{ $t("m.取消") }}
        </div>
        <div class="clickBtn" @click="deleteRobot()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>
    <!-- 启动机器人 -->
    <el-dialog
      :title="$t('m.启动机器人')"
      :visible.sync="openVisible"
      width="366px"
      class="openBox"
    >
      <span>{{
        (coinShow
          ? "账户当前品种已经存在仓位，且你在参数配置中选择了让机器人接管仓位，这可能会立即触发持仓止损。"
          : "") +
        $t("m.确定要启动当前机器人") +
        "？"
      }}</span>
      <div slot="footer" class="open-footer">
        <div
          class="closeBtn"
          @click="
            openVisible = false;
            robotId = null;
          "
        >
          {{ $t("m.取消") }}
        </div>
        <div class="clickBtn" @click="startRobotBtn()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>
    <!-- 终止机器人 -->
    <el-dialog
      :title="$t('m.终止机器人')"
      :visible.sync="endVisible"
      width="366px"
      class="openBox"
      :show-close="false"
    >
      <span>{{ $t("m.确定要终止当前机器人") }}</span>
      <div slot="footer" class="open-footer">
        <div
          class="closeBtn"
          @click="
            endVisible = false;
            robotId = null;
          "
        >
          {{ $t("m.取消") }}
        </div>
        <div class="clickBtn" @click="endRobotBtn()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>

    <!-- 多选操作 -->
    <div class="multiple-open" v-if="miltOpenShow">
      <div class="warp">
        <div class="box" @click="allCheck()">
          <img src="../../../assets/icon/xuanzhong.svg" alt="" v-if="isAll" />
          <img src="../../../assets/icon/weixuanzhong.svg" alt="" v-else />
          <span>全选</span>
        </div>
        <div class="box" @click="multStart()">
          <img src="../../../assets/icon/qidong.svg" alt="" />
          <span>批量启动</span>
        </div>
        <div class="box" @click="multSuspendRobot()">
          <img src="../../../assets/icon/zanting.svg" alt="" />
          <span>批量暂停</span>
        </div>
        <div class="box" @click="multRestartRobot()">
          <span>批量重启</span>
        </div>
        <div class="box" @click="multDeleteRobot()">
          <img src="../../../assets/icon/shanchu.svg" alt="" />
          <span>批量删除</span>
        </div>
        <div class="box" @click="multRobotEnd()">
          <img src="../../../assets/icon/zhongzhi.svg" alt="" />
          <span>批量终止</span>
        </div>
        <div class="box" @click="multClose()">
          <img src="../../../assets/icon/guanbi_bai.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  queryRobotList,
  deleteRobot,
  startRobot,
  suspendRobot,
  restartRobot,
  robotEnd,
  synchronSingelCloudApi,
  batchStartRobot,
  batchRobotEnd,
  batchDeleteRobot,
  batchSuspendRobot,
  batchRestartRobot,
} from "@/request/api";
export default {
  name: "dcaCard",
  props: ["isRobot"],
  data() {
    return {
      coinShow: false, // 接管账户
      isRolling: false,
      robotList: null,
      deleteVisible: false, //删除机器人弹框
      robotId: null, //当前删除的机器人id
      val: null,
      openVisible: false,
      endVisible: false,
      checkboxCancel: false,
      checkboxClose: false,
      robotName: null,
      robotItem: null,
      total: 0,
      offsetWidthStyle: false,
      offsetWidth: null,
      offsetWidthNum: null,

      miltOpenShow: true,
      isAll: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    rollingClick(item) {
      item.isRolling = !item.isRolling;
      this.$forceUpdate();
    },
    // 机器人列表
    queryRobotList(val) {
      this.robotList = null;
      this.$store.commit("setIsSkeleton", true);
      let payload = {
        token: localStorage.getItem("token"),
      };
      payload = { ...payload, ...val };
      if (val) {
        this.val = val;
      } else if (this.val) {
        payload = { ...payload, ...this.val };
      }
      queryRobotList(payload).then((res) => {
        if (res.status == 200) {
          let list = JSON.parse(JSON.stringify(res.data.result));
          this.total = Number(res.data.total);
          if (Number(res.data.total) > 0) {
            this.$emit("IsRobot", false);
            for (let key of list) {
              key.displayConfig = JSON.parse(key.displayConfig);
            }
          } else {
            this.$emit("IsRobot", true);
          }
          this.robotList = list;
        } else {
          this.$message.error(res.msg);
        }
        setTimeout(() => {
          this.$store.commit("setIsSkeleton", false);
        }, 500);
      });
    },
    deleteShow(id) {
      this.deleteVisible = true;
      this.robotId = id;
    },
    // 删除机器人
    deleteRobot() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
      };
      deleteRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.删除成功"));
          this.deleteVisible = false;
          this.robotId = null;
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 启动机器人实例
    startRobot(item) {
      this.robotId = item.robotId;
      if (item.displayConfig.takeOver) {
        let payload = {
          token: localStorage.getItem("token"),
          apiId: item.apiId,
        };
        synchronSingelCloudApi(payload).then((res) => {
          if (res.status == 200) {
            let dataList = null;
            for (let i in res.data) {
              dataList = res.data[i];
            }
            let linerSwapAssets = dataList.linerSwapAssets;
            if (linerSwapAssets && linerSwapAssets.length !== 0) {
              let positions = linerSwapAssets[0].positions;
              if (positions && positions.length !== 0) {
                positions.forEach((e) => {
                  if (e.contractId.indexOf(item.counterCoin) > -1) {
                    this.coinShow = true;
                  }
                });
              } else {
                this.coinShow = false;
              }
            } else {
              this.coinShow = false;
            }
            this.openVisible = true;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.openVisible = true;
      }
    },

    // 暂停机器人
    suspendRobot(id) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: id,
      };
      suspendRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.暂停成功"));
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 重启机器人
    restartRobot(id) {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: id,
      };
      restartRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.重启成功"));
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 终止机器人
    robotEnd(item) {
      this.robotId = item.robotId;
      this.robotItem = item;
      this.stopWithCancel = item.displayConfig.stopWithCancel;
      this.stopWithClose = item.displayConfig.stopWithClose;
      this.endVisible = true;
    },

    // 计算执行时间
    formatDuring(start) {
      let New = new Date().getTime();
      let mss = New - start;
      var day = parseInt(mss / (1000 * 24 * 60 * 60));
      var hours = parseInt((mss % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      var str =
        day +
        " " +
        this.$t("m.天") +
        " " +
        hours +
        " " +
        this.$t("m.时") +
        " " +
        minutes +
        " " +
        this.$t("m.分");
      return str;
    },

    // 跳转机器人详情
    toDatails(item) {
      localStorage.setItem("robotId", item.robotId);
      this.$router.push({ path: "/robotList/strategyDatails" });
    },

    copyRobot(item) {
      localStorage.setItem("copyStrategy", JSON.stringify(item));
      this.$router.push({ path: "/robotList/createStrategy" });
    },

    // 启动机器人
    startRobotBtn() {

      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
      };
      console.log(payload)
      startRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.启动成功"));
          this.queryRobotList();
          this.robotId = null;
          this.openVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 终止机器人
    endRobotBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
      };
      robotEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.终止成功"));
          this.endVisible = false;
          this.robotId = null;
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    editRobot(item) {
      item.displayConfig.type = "edit";
      item.displayConfig.robotId = item.robotId;
      localStorage.setItem("dcaType", "listEdit");
      localStorage.setItem("copyStrategy", JSON.stringify(item.displayConfig));
      this.$router.push({ path: "/robotList/createStrategy" });
    },
    // 全选/反选
    allCheck() {
      this.isAll = !this.isAll;
      for (let key of this.robotList) {
        if (this.isAll) {
          key.checked = true;
        } else {
          key.checked = false;
        }
      }
      console.log(this.robotList);
    },

    // 选中
    itemChange(v, item) {
      // console.log(v, item, this.robotList);
      let list = this.robotList.filter((item) => {
        return item.checked;
      });

      if (list.length == 0) {
        this.miltOpenShow = false;
      } else {
        this.miltOpenShow = true;
      }

      if (list.length == this.robotList.length) {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },

    // 关闭
    multClose() {
      this.miltOpenShow = false;
      this.isAll = false;
      for (let key of this.robotList) {
        key.checked = false;
      }
    },

    // 批量操作参数过滤
    robotIdFilter(list) {
      let robotIds = "";

      for (let key of list) {
        robotIds += "," + key.robotId;
      }

      robotIds = robotIds.slice(1, robotIds.length);

      let payload = {
        token: localStorage.getItem("token"),
        robotIds: robotIds,
      };

      console.log(payload);

      return payload;
    },

    // 批量启动
    multStart() {
      let list = this.robotList.filter((item) => {
        return item.checked && !item.states;
      });

      if (list.length == 0) {
        this.$message.error("没有符合操作的机器人");
        return;
      }

      batchStartRobot(this.robotIdFilter(list)).then((res) => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) == "{}") {
            this.$message.success(this.$t("m.操作成功"));
          } else {
            for (let key in res.data) {
              for (let val in res.data[key]) {
                this.$message.warning(
                  "机器人 " + key + "," + res.data[key][val]
                );
              }
            }
          }
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量终止机器人
    multRobotEnd() {
      let list = this.robotList.filter((item) => {
        return (
          item.checked &&
          (item.states == "EXECUTE" ||
            item.states == "SUSPEND" ||
            item.states == "STARTERROR")
        );
      });

      if (list.length == 0) {
        this.$message.error("没有符合操作的机器人");
        return;
      }

      batchRobotEnd(this.robotIdFilter(list)).then((res) => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) == "{}") {
            this.$message.success(this.$t("m.操作成功"));
          } else {
            for (let key in res.data) {
              for (let val in res.data[key]) {
                this.$message.warning(
                  "机器人 " + key + "," + res.data[key][val]
                );
              }
            }
          }
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量暂停机器人
    multSuspendRobot() {
      let list = this.robotList.filter((item) => {
        return item.checked && item.states == "EXECUTE";
      });

      if (list.length == 0) {
        this.$message.error("没有符合操作的机器人");
        return;
      }

      batchSuspendRobot(this.robotIdFilter(list)).then((res) => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) == "{}") {
            this.$message.success(this.$t("m.操作成功"));
          } else {
            for (let key in res.data) {
              for (let val in res.data[key]) {
                this.$message.warning(
                  "机器人 " + key + "," + res.data[key][val]
                );
              }
            }
          }
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量重启机器人
    multRestartRobot() {
      let list = this.robotList.filter((item) => {
        return item.checked && item.states == "SUSPEND";
      });

      if (list.length == 0) {
        this.$message.error("没有符合操作的机器人");
        return;
      }

      batchRestartRobot(this.robotIdFilter(list)).then((res) => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) == "{}") {
            this.$message.success(this.$t("m.操作成功"));
          } else {
            for (let key in res.data) {
              for (let val in res.data[key]) {
                this.$message.warning(
                  "机器人 " + key + "," + res.data[key][val]
                );
              }
            }
          }
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量删除机器人
    multDeleteRobot() {
      let list = this.robotList.filter((item) => {
        return item.checked && !item.states;
      });

      if (list.length == 0) {
        this.$message.error("没有符合操作的机器人");
        return;
      }

      batchDeleteRobot(this.robotIdFilter(list)).then((res) => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) == "{}") {
            this.$message.success(this.$t("m.操作成功"));
          } else {
            for (let key in res.data) {
              for (let val in res.data[key]) {
                this.$message.warning(
                  "机器人 " + key + "," + res.data[key][val]
                );
              }
            }
          }
          this.queryRobotList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    if (localStorage.getItem("activeName")) {
      this.val = {
        statesType: "open",
      };
      this.queryRobotList();
      setTimeout(() => {
        localStorage.removeItem("activeName");
      }, 1000);
    } else {
      this.queryRobotList();
    }
    let dcaCard = document.getElementById("dcaCard");
    // 获取盒子的宽度
    this.offsetWidthStyle = dcaCard.offsetWidth == 1160;
    this.offsetWidth = dcaCard.offsetWidth + 20;

    this.offsetWidthNum = Math.floor((dcaCard.offsetWidth + 20) / 285);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
#dcaCard {
  width: 100%;
  min-height: calc(100vh - 215px);
  height: 100%;
  padding: 0 17px;
  // position: relative;
  padding-bottom: 40px;
  .card-warp {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
  }
  // 正面
  .rollbox {
    position: relative;
    perspective: 1000px;
    width: 265px;
    height: 350px;
    margin: 0 20px 20px 0;
    cursor: pointer;
    &_front,
    &_behind {
      transform-style: preserve-3d; //表示所有子元素在3D空间中呈现
      backface-visibility: hidden; //元素背面向屏幕时是否可见
      transition-duration: 1s;
      transition-timing-function: "ease-in";

      // 正面
      .contentbox {
        width: 265px;
        height: 350px;
        border-radius: 8px;
        padding: 20px;
        position: relative;
        @include themeify {
          box-shadow: themed("shadow") !important;
          background: themed("secondaryBackColor") !important;
        }
        .no-line-data {
          width: 100%;
          height: 69px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f7f9ff;
          border-radius: 8px;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
        .checked {
          position: absolute;
          top: 10px;
          right: 10px;
          ::v-deep.el-checkbox__inner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          ::v-deep.el-checkbox__inner::after {
            width: 6px;
            height: 10px;
            left: 6px;
          }
        }
        .strategyName {
          font-size: 18px;
          @include themeify {
            color: themed("primaryColor") !important;
          }
          .strategyName-box {
            position: relative;
            .identification {
              width: 8px;
              height: 8px;
              background: #fa5c5d;
              border-radius: 50%;
              position: absolute;
              top: -2px;
              right: -10px;
            }
          }
        }
        .pair,
        .curr {
          font-size: 14px;
          color: #6782a4;
          margin: 8px 0;
        }
        .pair {
          display: flex;
          align-items: center;
          .label {
            // position: absolute;
            // top: 0;
            // right: 0;
            width: 68px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 8px 0px 8px;
            font-size: 12px;
            margin-left: 10px;
            @include themeify {
              color: themed("btnText") !important;
            }
          }
        }
        .exchange {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #6782a4;
          margin-bottom: 10px;
        }
        img {
          width: 225px;
          height: 69px;
          border-radius: 8px;
        }
        .Annualized {
          display: flex;
          align-items: center;
          font-size: 18px;
          @include themeify {
            color: themed("secondaryColor");
          }
          :last-child {
            width: 65px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #e8fffb;
            border-radius: 4px;
            font-size: 12px;
            margin-left: 8px;
          }
          .bckGren {
            background: #e8fffb;
          }
          .bckRed {
            background: #fff6f7;
          }
          .bckHui {
            background: #f7f9ff;
          }
        }
        .time {
          font-size: 12px;
          margin: 10px 0 47px 0;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
        .ops {
          font-size: 14px;
          text-align: center;
          @include themeify {
            color: themed("btnBackGreen") !important;
          }
        }
      }

      // 反面
      .contentbox-show {
        width: 265px;
        height: 350px;
        background: linear-gradient(180deg, #2670FA 0%, #1170b4 100%);
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        @include themeify {
          box-shadow: themed("shadow") !important;
        }
        .checked {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 9999 !important;
          ::v-deep.el-checkbox__inner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: none;
          }
          ::v-deep.el-checkbox__inner::after {
            width: 6px;
            height: 10px;
            left: 6px;
          }
        }

        .title {
          color: #e6e5f2;
          text-align: center;
        }

        .name-warp {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin: 10px 0;
          @include themeify {
            color: themed("btnText") !important;
          }
          img {
            margin-left: 8px;
          }
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #e6e5f2;
          margin-bottom: 10px;
        }
        .two {
          font-size: 14px;
          color: #e6e5f2;
          margin-bottom: 10px;
        }
        .ops-warp {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          @include themeify {
            color: themed("btnText") !important;
          }
          span {
            width: 30%;
            text-align: center;
            padding: 4px 0px;
          }

          .details-op {
            .identification-op {
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #fa5c5d;
              border-radius: 50%;
              margin-bottom: 6px;
            }
          }
        }
        .del-warp {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 4px;
          .del {
            width: 96px;
            height: 26px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            @include themeify {
              background: themed("upRed") !important;
              color: themed("btnText") !important;
            }
          }
        }
      }
    }

    &_behind {
      transform: rotateY(180deg);
      visibility: hidden; //元素不可见，但占据空间
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  // 翻转
  .box_rolling {
    .rollbox_front {
      transform: rotateY(180deg);
      visibility: hidden;
    }
    .rollbox_behind {
      transform: rotateY(360deg);
      visibility: visible;
    }
  }
}

.openBox {
  /deep/.el-dialog {
    margin-top: 34vh !important;
  }
  /deep/.el-dialog__header {
    padding: 20px 30px 10px;
  }
  /deep/.el-dialog__body {
    padding: 20px 30px 35px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 30px 30px;
  }
  span {
    color: #999fb4;
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
  img {
    width: 180px;
  }
  .text {
    margin: 25px 0;
    @include themeify {
      color: themed("secondaryColor");
    }
  }
}

// 多选操作
.multiple-open {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  .warp {
    width: 617px;
    height: 40px;
    background: #4a4b52;
    box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.31);
    border-radius: 20px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2670FA;
    font-size: 14px;
    .box {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 6px;
        margin-top: 2px;
      }
    }
  }
}
</style>