<template>
  <div class="strategyEvents">
    <div class="strategyEvent-warp">
      <div
        v-if="logList && logList.length !== 0"
        class="box"
        v-for="(item, idx) in logList"
        :key="idx"
      >
        <span class="title">
          {{ moment(item.createTime).format("YYYY/MM/DD HH:mm") }}
        </span>
        <span
          class="text"
          :class="item.logType == 2 ? 'textDelStyle' : 'textStyle'"
        >
          {{ item.content }}
        </span>
      </div>
      <div class="pagination-warp" v-if="logList && logList.length !== 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="no-data" v-else>
        {{ $t("m.暂无数据") }}
      </div>
    </div>
  </div>
</template>

<script>
import { qureyStrategyTradeLogList } from "@/request/api";
import moment from "moment";
export default {
  components: {},
  props: ["robotId"],

  data() {
    return {
      moment,
      logList: null, // 日志列表
      pageNo: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询日志
    qureyStrategyTradeLogList() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      qureyStrategyTradeLogList(payload).then((res) => {
        if (res.status == 200) {
          this.logList = res.data.strategyTradeLogList;
          this.total = Number(res.data.countNum);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.qureyStrategyTradeLogList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.qureyStrategyTradeLogList();
    },
  },
  created() {},
  mounted() {
    this.qureyStrategyTradeLogList();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.strategyEvents {
  .strategyEvent-warp {
    .box {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 0;
      @include themeify {
        border-bottom: 1px solid themed("splitline") !important;
      }
      .title {
        width: 20%;
        font-size: 12px;
        padding-top: 4px;
        @include themeify {
          color: themed("secondaryColor") !important;
        }
      }
      .text {
        width: 75%;
        font-size: 14px;
      }
      .textStyle {
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
      .textDelStyle {
        @include themeify {
          color: themed("upRed") !important;
        }
      }
    }
  }
}
.pagination-warp {
  width: 100%;
  height: 50px;
  text-align: right;
  margin-top: 20px;
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  .el-pagination {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ::v-deep.el-pagination__sizes {
    height: auto;
    line-height: normal;
  }
}
.no-data {
  text-align: center;
  padding: 30px 0 10px 0;
  @include themeify {
    color: themed("secondaryColor") !important;
  }
}
</style>