<template>
  <div class="invite">
    <TopNavigation :menu="0" />
    <div class="default-page">
      <el-aside width="240px">
        <Menu :idx="'5'" />
      </el-aside>
      <div class="main-box">
        <div class="box topBox">
          <div class="btnBox">
            <div class="title">{{ $t("m.邀请好友") }}</div>
            <div class="smlBox">
              <div class="linkBox" style="margin-bottom: 20px">
                <div>
                  <span>{{ $t("m.邀请链接") }} </span>
                  <!-- http://159.138.104.103:8797/#/register?inviteCode=  测试  -->
                  <!-- https://vtrading.com/index.html#/register?inviteCode=  正式-->
                  <span id="linkId">{{
                    "https://vtrading.com/index.html/register?inviteCode=" +
                    (accInfo ? accInfo.inviteCode : "")
                  }}</span>
                </div>
                <div class="clickBtn linkBtn" @click="linkClick()">
                  {{ $t("m.复制") }}
                </div>
              </div>
              <div class="linkBox">
                <div>
                  <span>{{ $t("m.邀请码") }} </span>
                  <span id="codeId">{{
                    accInfo ? accInfo.inviteCode : ""
                  }}</span>
                </div>
                <div class="clickBtn linkBtn" id="linkBtn" @click="codeClick()">
                  {{ $t("m.复制") }}
                </div>
              </div>
            </div>
          </div>

          <div class="flexStyle dataShow">
            <div class="dataShow-item">
              <div class="dataShow-item-top">{{ $t("m.访问") }}</div>
              <div class="dataShow-item-bottom">{{ requestNum }}</div>
            </div>
            <div class="line"></div>
            <div class="dataShow-item">
              <div class="dataShow-item-top">{{ $t("m.邀请") }}</div>
              <div class="dataShow-item-bottom">{{ inviteNum }}</div>
            </div>
          </div>
        </div>
        <div class="box recordBox">
          <div class="title">{{ $t("m.邀请记录") }}</div>
          <div class="record">
            <div class="recordTitle">
              <div>{{ $t("m.账户") + " UID" }}</div>
              <div>{{ $t("m.邀请时间") }}</div>
            </div>
            <div
              class="recordList"
              v-if="inviteList && inviteList.length !== 0"
            >
              <template v-for="(item, idx) in inviteList">
                <div class="recordItem">
                  <div>{{ item.uuid }}</div>
                  <div>{{ item.inviteTime }}</div>
                </div>
              </template>
            </div>
            <div v-else class="no-data">
              <img src="@/assets/icon/Group@2x.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation.vue";
import { inviteDate } from "@/request/api.js";
import Menu from "@/components/menu.vue";
export default {
  components: {
    TopNavigation,
    Menu,
  },
  data() {
    return {
      inviteList: null,
      inviteNum: 0,
      requestNum: 0,
      accInfo: null,
    };
  },
  methods: {
    // 获取页面信息
    inviteDate() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      inviteDate(payload).then((res) => {
        if (res.status == 200) {
          this.inviteNum = res.data.inviteNum;
          this.requestNum = res.data.requestNum;
          let inviteList = res.data.inviteList;
          if (inviteList.length <= 0) {
            this.inviteList = inviteList;
            return;
          }
          inviteList.forEach((e) => {
            this.$store.commit("timestampToTime", e.inviteTime);
            e.inviteTime = this.$store.state.timeNow;
          });
          this.inviteList = inviteList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //复制链接按钮
    linkClick() {
      let linkId = document.getElementById("linkId");
      let input = document.createElement("input");
      input.value = linkId.innerText;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.$message.success(this.$t("m.复制成功"));
    },

    //复制邀请码按钮
    codeClick() {
      let codeId = document.getElementById("codeId");
      let input = document.createElement("input");
      input.value = codeId.innerText;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.$message.success(this.$t("m.复制成功"));
    },
  },
  mounted() {
    let accInfo = JSON.parse(localStorage.getItem("accInfo"));
    this.accInfo = accInfo;
    this.inviteDate();
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.invite {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .default-page {
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
    .main-box {
      padding: 20px;
      min-width: 1200px;
      flex: 1;
      .box {
        min-width: 1080px;
        border-radius: 20px;
        @include themeify {
          background: themed("secondaryBackColor") !important;
          box-shadow: themed("shadow") !important;
        }

        .title {
          font-size: 20px !important;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
      }
      .topBox {
        height: 327px;
        @include themeify {
          color: themed("btnText") !important;
        }
        .btnBox {
          background: linear-gradient(
            134deg,
            #3b70d3 0%,
            #2670FA 48%,
            #0c5ffa 100%
          );
          height: 216px;
          padding: 20px;
          border-radius: 24px;
          .title {
            font-size: 24px;
            color: #fff !important;
          }

          .linkBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // font-size: 20px;
            .linkBtn {
              font-size: 16px;
              width: 78px;
              background: none;
              border: 1px solid #ffffff;
            }
            .linkBtn:hover {
              @include themeify {
                color: themed("btnBackGreen") !important;
                background: themed("secondaryBackColor") !important;
              }
            }
          }
          .smlBox {
            font-size: 18px;
            height: 133px;
            padding: 25px 30px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 24px;
            margin-top: 15px;
          }
        }
        .dataShow {
          padding: 20px;
          border-radius: 8px;
          @include themeify {
            background: themed("secondaryBackColor") !important;
          }
          .dataShow-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // border-right: 1px solid #dee2e6;
            .dataShow-item-top {
              font-size: 20px;
              @include themeify {
                color: themed("secondaryColor") !important;
              }
            }
            .dataShow-item-bottom {
              font-size: 24px;
              @include themeify {
                color: themed("primaryColor") !important;
              }
              margin-top: 10px;
            }
          }
          .line {
            height: 50px;
            border-left: 1px solid #dee2e6;
          }
          .dataShow-item:last-child {
            border: 0;
          }
        }
      }
      .recordBox {
        max-height: 460px;
        padding: 20px;
        font-size: 16px;
        margin-top: 20px;
        overflow-y: scroll;
        .title {
          // font-size: 24px;
          padding-bottom: 10px;
          border-bottom: 1px solid #dee2e6;
        }
        .recordTitle {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
        .record {
          padding: 20px 30px;
          .recordList {
            display: flex;
            flex-direction: column;
            .recordItem {
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              font-size: 18px;
              @include themeify {
                color: themed("secondaryColor") !important;
              }
            }
          }
          .no-data {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            img {
              width: 93px;
              margin: 60px 0 30px;
            }
          }
        }
      }
    }
  }
}
</style>