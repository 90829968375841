<template>
  <div class="fuGridH5">
    <div class="warph5">
      
      <!--1 期货网格交易机器人 -->
      <div class="oneh5" v-if="one">
          
        <span class="font24 fontx ch1 mb10">{{$t('m.'+one.span1)}}</span>
        <span class="font24 fontx ch0 mb20">{{$t('m.'+one.span2)}}</span>
        <span class="font14 ch1 mb30">{{$t('m.'+one.span3)}}</span>
    
        <img class="img-icqpc" :src="one.src1">
      </div>
      
      <!--2 利用加密货币期货交易获利 -->
      <div class="twoh5" v-if="two">

        <span class="font24 fontx ch0 mb30">{{$t('m.'+two.span1)}}</span>

        <div class="cellh5" v-for="(item, index) in two.cells" :key="'two'+index">
          <img :src="item.src1">
          <span class="font14 ch1">{{$t('m.'+item.span1)}}</span>
        </div>
      </div>
   
      <!--3 在顶级加密货币交易所运行交易机器人-->
      <div class="threeh5" v-if="three">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.'+three.span1)}}</span>
        <span class="font14 ch3 mb 16">{{$t('m.'+three.span2)}}</span>

        <div class="row jcenter">
          <img class="img-icqh5" :src="three.src1">
        </div>

        <div class="three-conth5">
          
          <div class="row jbetween mb20">
            <img class="img-logoh5" :src="three.src4">
            <img class="img-logoh5" :src="three.src2">
          </div>
          <div class="row jbetween mb20">
            <img class="img-logoh5" :src="three.src3">
            <img class="img-logoh5" :src="three.src5">
          </div>
          
          <div class="lineh5"></div>
          <div class="row jbetween mb20">
            <img class="img-logoh5" :src="three.src6">
            <img class="img-logoh5" :src="three.src10">
          </div>
          <div class="row jbetween mb20">
            <img class="img-logoh5" :src="three.src7">
            <img class="img-logoh5" :src="three.src11">
          </div>
          <div class="row jbetween mb20">
            <img class="img-logoh5" :src="three.src8">
            <img class="img-logoh5" :src="three.src12">
          </div>
          <div class="row jbetween">
            <img class="img-logoh5" :src="three.src13">
            <img class="img-logoh5" :src="three.src9">
          </div>
        </div>
        
        <RichBut @click="tapClick(three.but1.click)"
        class="buth5" styleType="bhei">
          <span>{{$t('m.'+three.but1.txt)}}</span>
        </RichBut>
      </div>

      <!--4 选择您的策略 -->
      <div class="fourh5" v-if="four">

        <div class="cellh5" v-for="(item, index) in four.cells" :key="'four'+index">
          <img :src="item.src1">

          <div class="column">
            
            <span v-for="(title, j) in item.title1" :key="'fourtitle'+j"
            class="font24 fontx ch0">{{$t('m.'+title)}}</span>
            <span class="mb20"></span>
            <span v-if="item.span1" class="font14 clan">{{$t('m.'+item.span1)}}</span>
            <span v-if="item.span2" class="font14 ch2">{{$t('m.'+item.span2)}}</span>
          </div>
        </div>
      </div>

      <!--5 利用加密货币期货交易获利 -->
      <div class="fiveh5" v-if="five">
        <span class="font24 fontx ch0 mb20">{{$t('m.'+five.span1)}}</span>
        <div class="cellh5" v-for="(item, index) in five.cells" :key="'five'+index">
          <img :src="item.src1">
          <span class="font14 ch1">{{$t('m.'+item.span1)}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut";
export default {

  components: {
    RichBut
  },

  props: {
    one: Object,
    two: Object,
    three: Object, 
    four: Object,
    five: Object,
  },

  data() {
    return {
      isVideo: false,
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.fuGridH5 {
  display: flex;
  flex-direction: column;
  .warph5 {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
  }

  /**** 1 期货网格交易机器人？*/
  .oneh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    .img-icqpc {
      width: 295px;
      height: auto;
      margin-left: 24px;
    }
  }
  
  /**** 2 利用加密货币期货交易获利*/
  .twoh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    .cellh5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 30px;
      margin-bottom: 20px;

      border-radius: 8px;
      width: 100%;
      background-image: url('../../assets/image/bot/fugrid_2_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 66px;
        height: 66px;
        margin-right: 20px;
      }
    }
    .cellh5:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 3 在顶级加密货币交易所运行交易机器人*/
  .threeh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .img-icqh5 {
      width: 240px;
      height: 240px;
      margin-bottom: 18px;
    }
    .three-conth5 {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid #E4E6EF;
      padding: 20px;
      margin-bottom: 32px;
      .img-logoh5 {
        width: 133px;
        height: 24px;
      }
      .lineh5 {
        height: 0px;
        border: 1px solid #F0F2F6;
        margin-bottom: 20px;
      }
    }
    .buth5 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      border-radius: 6px;
    }
  }

  /**** 4 如何启动网格交易策略？*/
  .fourh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    .cellh5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;

      border-radius: 8px;
      width: 100%;
      background-image: url('../../assets/image/bot/fugrid_4_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
    }
    .cellh5:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

  /**** 5 利用加密货币期货交易获利*/
  .fiveh5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    .cellh5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;

      border-radius: 8px;
      width: 100%;
      background-image: url('../../assets/image/bot/fugrid_5_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
    }
    .cellh5:hover {
      box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.08);
    }
  }

}

.mrf_50 {margin-right: -50px}
</style>
