import { render, staticRenderFns } from "./short.vue?vue&type=template&id=4e38e2da&scoped=true"
import script from "./short.vue?vue&type=script&lang=js"
export * from "./short.vue?vue&type=script&lang=js"
import style0 from "./short.vue?vue&type=style&index=0&id=4e38e2da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e38e2da",
  null
  
)

export default component.exports