<template>
  <div class="articleEditor">
    <div class="articleEditor-warp">
      <!-- <div style="margin-bottom: 100px">信息</div> -->
      <div class="box">
        <span class="title">文章标题：</span>
        <el-input v-model="title" placeholder="请输入文章标题"></el-input>
      </div>

      <div class="box">
        <span class="title">文章分类：</span>
        <el-select v-model="articleType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="box">
        <span class="title">文章权重：</span>
        <el-input v-model="weight" placeholder="请输入文章标题"></el-input>
      </div>

      <div class="box">
        <span class="title">摘要：</span>
        <el-input v-model="outline" placeholder="请输入文章标题"></el-input>
      </div>
      <div class="box">
        <span class="title">是否可见：</span>
        <el-switch
          v-model="isVisible"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>

      <div class="box box1">
        <span class="title">正文：</span>
        <div id="div1"></div>
      </div>
      <div class="btn-warp">
        <span class="clickBtn" @click="createEditor()">提交</span>
      </div>
    </div>
  </div>
</template>

<script>
import { addArticle } from "@/request/api";
export default {
  components: {},
  data() {
    return {
      options: [
        {
          value: "1",
          label: "支付类",
        },
        {
          value: "2",
          label: "消费类",
        },
        {
          value: "3",
          label: "账户类",
        },
        {
          value: "4",
          label: "机器人类",
        },
      ],
      title: null, //标题
      articleType: "", //类型
      outline: null, // 摘要
      weight: null, //权重
      isVisible: true, //是否可见
      content: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    fn() {
      const E = window.wangEditor;
      let _this = this;
      const editor = new E("#div1");
      // http://192.168.2.36:8066/user/uploadAvatar  本地
      // http://159.138.104.103:8797/VtradingPc/user/uploadAvatar  测试
      editor.config.uploadImgServer =
        "http://192.168.2.36:8066/user/uploadAvatar";
      editor.config.showLinkImg = false;
      editor.config.uploadVideoName = 'file';

      editor.config.uploadVideoServer =
        "http://192.168.2.36:8066/user/uploadAvatar";
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.showLinkVideo = false;

      editor.config.onblur = function (newHtml) {
        _this.content = JSON.parse(JSON.stringify(newHtml));
      };

      editor.config.onfocus = function (newHtml) {
      };

      editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function (xhr) {

          // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: "需要提示给用户的错误信息",
          // };
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function (xhr) {
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function (xhr, editor, resData) {
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
        },
        // 上传图片超时
        timeout: function (xhr) {
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
          // result 即服务端返回的接口

          // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
          insertImgFn(result.data.avatarUrl);
        },
      };

      // 插入网络图片的回调
      editor.config.linkImgCallback = function (src, alt, href) {
      };

      // 自定义检查插入视频的回调
      editor.config.onlineVideoCallback = function (video) {
        // 自定义回调内容，内容成功插入后会执行该函数
      };

      editor.create();
    },

    createEditor() {
      setTimeout(() => {
        if (!this.title) {
          this.$message.error("请输入标题");
          return;
        } else if (!this.articleType) {
          this.$message.error("请选择类型");
          return;
        } else if (!this.outline) {
          this.$message.error("请输入摘要");
          return;
        } else if (!this.weight) {
          this.$message.error("请输入权重");
          return;
        } else if (!this.content) {
          this.$message.error("请输入内容");
          return;
        }
        let payload = {
          title: this.title,
          outline: this.outline,
          content: this.content,
          articleType: this.articleType,
          isVisible: this.isVisible ? 0 : 1,
          weight: this.weight,
        };

        addArticle(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success("上传成功");
            this.title = null;
            this.outline = null;
            this.content = null;
            this.articleType = null;
            this.isVisible = true;
            this.weight = null;
          } else {
            this.$message.error(res.msg);
          }
        });
      }, 300);
    },
  },
  created() {},
  mounted() {
    this.fn();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.articleEditor {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .articleEditor-warp {
    width: 1080px;
    min-height: 1020px;
    height: 100%;
    padding: 0 20px;
    padding-top: 20px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
    ::v-deep.w-e-text-container {
      height: 500px !important;
    }
    .box {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .title {
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        width: 15%;
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
      .el-input,
      .el-select {
        width: 85%;
      }
      ::v-deep.el-input__inner {
        height: 36px !important;
        line-height: 36px !important;
        background: none !important;
      }
      ::v-deep.el-input__icon {
        height: 36px !important;
        line-height: 36px !important;
      }
    }
    .box1 {
      display: block;
    }
    .btn-warp {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 40px;
      margin-top: 20px;
      padding-bottom: 20px;
      span {
        width: 100px;
      }
    }
  }
}
</style>