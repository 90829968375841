<template>
  <div class="homeSkeleton">
    <div class="default-top">
      <div class="top-box">
        <div class="top-box-left" v-for="item in 8" :key="item">
          <el-skeleton-item variant="h3" style="width: 70%" />
          <el-skeleton-item variant="h3" style="width: 25%; margin-left: 5%" />
        </div>
      </div>

      <el-skeleton-item variant="image" style="width: 70%; height: 300px" />
    </div>

    <div v-for="item in 3" :key="item">
      <div class="default-bottom">
        <div v-for="a in 2" :key="a" class="boxOne">
          <div class="boxOne-cont">
            <div class="cont-left">
              <div class="left-warp" v-for="b in 5" :key="b">
                <el-skeleton-item variant="h3" style="width: 70%" />
                <el-skeleton-item
                  variant="h3"
                  style="width: 25%; margin-left: 5%"
                />
              </div>
            </div>
            <el-skeleton-item
              variant="image"
              style="width: 55%; height: 190px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.homeSkeleton {
  width: 100%;
  height: 100%;
    @include themeify {
      background: themed("primaryBackColor") !important;
    }
  .default-top {
    display: flex;
    justify-content: space-between;
    .top-box {
      padding: 14px;
      width: 28%;
      display: flex;
      flex-direction: column;
      .top-box-left {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }
  }
  .default-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .boxOne {
      width: 48%;
      display: flex;
      justify-content: space-between;
      .boxOne-cont {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .cont-left {
          width: 40%;
          display: flex;
          flex-direction: column;
          .left-warp {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>