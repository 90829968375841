var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aggregateAcc"},[_c('div',{staticClass:"all-acc"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v(_vm._s(_vm.$t("m.聚合账户")))]),_c('div',{staticClass:"imgStyle",on:{"click":function($event){$event.stopPropagation();return _vm.refreshBtn()}}},[_c('img',{attrs:{"src":require("@/assets/icon/shuaxin.svg"),"alt":""}})])]),(_vm.allApiData)?_c('div',{staticClass:"top-box"},[_c('div',{staticClass:"box-left"},[_c('div',{staticClass:"assets"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("m.资产折合")))]),_c('p',[_vm._v(" "+_vm._s((_vm.allApiData ? _vm.Public.toMathNum(_vm.allApiData.assets) : 0) + " USDT")+" ")])]),_c('div',{staticClass:"earnings"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("m.今日资产变动")))]),_c('p',{staticClass:"text",class:{
              green: _vm.Public.toMathNum(_vm.allApiData.todayProfit) > 0,
              red: _vm.Public.toMathNum(_vm.allApiData.todayProfit) < 0,
            }},[_vm._v(" "+_vm._s((_vm.allApiData ? (_vm.allApiData.todayProfit > 0 ? "+" : "") + _vm.Public.toMathNum(_vm.allApiData.todayProfit) : 0) + " USDT")+" ")])]),_c('div',{staticClass:"seven-earnings"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("m.近7天资产变动")))]),_c('p',{staticClass:"text",class:{
              green: _vm.Public.toMathNum(_vm.allApiData.sevendayProfit) > 0,
              red: _vm.Public.toMathNum(_vm.allApiData.sevendayProfit) < 0,
            }},[_vm._v(" "+_vm._s((_vm.allApiData ? (_vm.allApiData.sevendayProfit > 0 ? "+" : "") + _vm.Public.toMathNum(_vm.allApiData.sevendayProfit) : 0) + " USDT")+" ")])])]),_c('div',{staticClass:"box-right"},[_c('LineCharts',{attrs:{"height":"230px","chartsId":"line","number":_vm.Public.toMathNum(_vm.allApiData.assets),"data":_vm.lineData,"name":_vm.$t('m.资产折合') + ' ( USDT )'}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }