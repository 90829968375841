var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeAccount"},[_c('div',{staticClass:"homeAccount-warp"},[(_vm.isApi)?_c('div',[_c('AggregateAcc')],1):_vm._e(),_c('div',{staticClass:"my-strategy"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("m.我的机器人")))]),_c('div',{staticClass:"strategy-warp"},[_c('div',{staticClass:"strategy-list"},_vm._l((_vm.robotList),function(item,idx){return _c('div',{key:idx,staticClass:"strategy",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toDatails(item)}}},[_c('div',{staticClass:"strategy-box"},[_c('div',{staticClass:"strategy-title"},[_c('div',{staticClass:"title-name"},[_vm._v(" "+_vm._s(item.strategyId == "WL20210624182847777" ? _vm.$t("m.U本位合约DCA") : _vm.$t("m.U本位合约网格"))+" ")]),_c('img',{staticClass:"title-img",attrs:{"src":require("@/assets/icon/chuangjian@2x(1).png"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.creatBtn(item)}}})]),(item.sumExecuteNum !== 0)?_c('div',{staticClass:"strategy-nav"},[_c('div',{staticClass:"nav-box",on:{"click":function($event){$event.stopPropagation();return _vm.toDatails(item, 'activeName')}}},[_c('div',{staticClass:"nav-title"},[_vm._v(_vm._s(_vm.$t("m.活跃机器人")))]),_c('div',[_c('span',{class:item.activeExecuteNum > 0 ? 'blue' : ''},[_vm._v(" "+_vm._s(item.activeExecuteNum)+" ")]),_vm._v(" "+_vm._s("/ " + item.sumExecuteNum)+" ")])]),_c('div',{staticClass:"nav-box"},[_c('div',{staticClass:"nav-title"},[_vm._v(_vm._s(_vm.$t("m.今日收益")))]),_c('div',{class:item.todoyEarnings > 0
                        ? 'green'
                        : item.todoyEarnings < 0
                        ? 'red'
                        : ''},[_vm._v(" "+_vm._s((item.todoyEarnings > 0 ? "+" + item.todoyEarnings : item.todoyEarnings) + " USDT")+" ")])]),_c('div',{staticClass:"nav-box nav-boxStyle"},[_c('div',{staticClass:"nav-title"},[_vm._v(_vm._s(_vm.$t("m.总收益")))]),_c('div',[_c('span',{class:item.activeExecuteSumEarnings > 0
                          ? 'green'
                          : item.activeExecuteSumEarnings < 0
                          ? 'red'
                          : ''},[_vm._v(" "+_vm._s((item.activeExecuteSumEarnings > 0 ? "+" + item.activeExecuteSumEarnings : item.activeExecuteSumEarnings) + "USDT / ")+" ")]),_c('span',{class:item.sumEarnings > 0
                          ? 'green'
                          : item.sumEarnings < 0
                          ? 'red'
                          : ''},[_vm._v(" "+_vm._s((item.sumEarnings > 0 ? "+" + item.sumEarnings : item.sumEarnings) + " USDT")+" ")])])])]):_c('div',{staticStyle:{"font-size":"14px"}},[_c('div',[_vm._v(" "+_vm._s(item.strategyId == "WL20210624182847777" ? _vm.$t( "m.U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活" ) : _vm.$t( "m.U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利" ))+" ")]),_c('div',{staticClass:"strategy-course",on:{"click":function($event){return _vm.courseUrl(item)}}},[_vm._v(" "+_vm._s(_vm.$t("m.查看教程") + ">>")+" ")])])])])}),0)])]),_c('div',{staticClass:"apiListLabel"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("m.我的账户")))]),_c('div',{staticClass:"add clickBtn",on:{"click":function($event){return _vm.$router.push({ path: 'myExchange' })}}},[_c('img',{attrs:{"src":require("@/assets/icon/tianjia.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("m.添加账户")))])])]),_c('ApiList',{ref:"child",on:{"IsApi":_vm.IsApi}})],1),(!_vm.isApi)?_c('div',{staticClass:"no-acc"},[_c('img',{attrs:{"src":require("@/assets/image/kong_big@2x.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("m.你还没有连接到交易所")))]),_c('span',[_vm._v(_vm._s(_vm.$t("m.连接交易所开始交易")))]),_c('span',{staticClass:"clickBtn",staticStyle:{"width":"232px","margin-top":"20px"},on:{"click":function($event){return _vm.toAddExchange()}}},[_vm._v(_vm._s(_vm.$t("m.连接交易所")))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }