<!--丰富的输入框 普通 邮箱 密码
左图标 右图标
选中效果 错误提示-->
<!--
扩展
1、左插槽
2、右插槽

固定
1、边框线
-->
<template>
  <!-- $slots.default 检查插槽-->
  <div class="richInput" ref="richInput" :style="richStyle" @keydown="handleKeydown">

    <template v-if="imgLeft">
      <img class="richinput-img" v-if="select" :src="imgLeftSelect || imgLeft">
      <img class="richinput-img" v-else :src="imgLeft">
      <div class="widht12"></div>
    </template>

    <slot name="left" :select="select"></slot>

    <form class="input">
      <input class="input" ref="input"
    
      :style="inputStyle"

      :type="inputType"
      :disabled="disabled" 

      v-model="input" 
      :placeholder="placeholder"

      
      @blur="onBlur"
      @focus="onFocus"
      @input="onInput"
      @keyup.enter="onEnter" />
    </form>

    <template v-if="imgRight">
        <div class="widht12"></div>
      <img class="richinput-img" v-if="select" :src="imgRightSelect || imgRight">
      <img class="richinput-img" v-else :src="imgRight">
    </template>
    
    <template v-else-if="password">
      <div @click="eyeClick"
      class="eye-img" ref="eye">
        <img v-if="eye && select" src="@/assets/image/v3/signin_neye_.png">
        <img v-else-if="eye" src="@/assets/image/v3/signin_neye.png">

        <img v-else-if="select" src="@/assets/image/v3/signin_eye_.png">
        <img v-else src="@/assets/image/v3/signin_eye.png">
      </div>
    </template>

    <slot name="right" :select="select"></slot>
  </div>
</template>

<script>

export default {

  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: '', //输入值
    maxlength: {
      type: Number,
      default :99
    }, //最大输入长度

    type: 'text', //输入框类型 text Number password

    disabled: false, //true 禁用
    password: false, //true 密码输入框
    placeholder: '', //输入框占位文本

    imgLeft:'', //左图
    imgLeftSelect:'', //左图选中

    imgRight:'', //右图
    imgRightSelect:'', //有图选中
  },
  
  watch: {
    value(newValue, oblVlaue) {
      this.input = newValue;
    }
  },
  
  data() {
    return {
      select: false, //true选中
      input: '',//输入框内容
      eye: true, //true 隐藏密码
    };
  },


  computed: {

    //整体样式
    richStyle() {
      if (this.disabled) {
        return {background: '#F5F8FA !important', cursor: 'not-allowed'}
      }

      if (this.select) {
        return {border: '1px solid #2670FA !important'}
      }

      return {};
    },

    //输入框样式
    inputStyle() {
      if (this.disabled) {
        return {cursor: 'not-allowed'}
      }
      return {};
    },

    //输入框类型
    inputType() {
      
      if (this.password) {
        return this.eye ? 'password' : this.type;
      }

      return this.type;
    }
  },

  created() {
    this.input = this.value;
  },

  mounted() {

    // 监听全局事件点击事件
    window.addEventListener('click', this.allClick);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.allClick);
  },

  methods: {

    //全局监听点击事件触发
    allClick(event) {

      //判断点击事件在input内，不处理事件
      var input = this.$refs.input;
      var isInput = input.contains(event.target);
      if (isInput) {
        return;
      }

      //判断点击事件在eye内，如果焦点情况下恢复焦点。否者不处理。
      var eye = this.$refs.eye;
      var isEye = eye ? eye.contains(event.target) : false;
      if(isEye && !this.select) {
        return;
      }

      //判断点击事件在richInput内，激活焦点
      var richInput = this.$refs.richInput;
      var isRichInput = richInput.contains(event.target);
      if (isRichInput) {
        event.preventDefault();
        input.focus();
        return;
      }

      //不在richInput内，失去选择状态
      this.select = false;
    },

    /********************* 输入框触发 */
    //在 Input 失去焦点时触发	
    onBlur() {
    },

    //在 Input 获得焦点时触发
    onFocus() {
      this.select = true;
    },

    //在 Input 值改变时触发
    onInput(e) {
      var value = e.currentTarget.value;
      this.$emit('change', value)
    },

    //回车
    onEnter() {;
      this.$emit('enter', this.input)
    },

    /********************* 键盘事件 */
    handleKeydown(event) {

      // 在这里处理Tab键按下的事件
      if (event.key === 'Tab') {
        this.select = this.select ? false : this.select;
      }
    },

    /********************* 点击 */
    //眼睛
    eyeClick() {
      this.eye = !this.eye;
    }
  },
};
</script>
<style lang='scss' scoped>
  .richInput {
    cursor: text;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #E4E6EF;
    padding: 0 12px;
    .input {
      flex: 1;
      font-size: 16px;
    }

    .richinput-img {
      width: 16px;
      height: 16px;
    }
    .eye-img {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 40px;
      margin-right: -12px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .widht12 {
    width: 12px;
  }
</style>