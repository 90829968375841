<template>
  <div class="resetPass">
    <TopNavigation v-if="current == 'Pc'" />
    
    <component v-if="current"
    :is="'ResetPass'+current"
    :step="step"
    :downTimeEmail="downTimeEmail"
    @back="backClick"
    @oneStep="oneStep"
    @towStep="towStep"
    @threeStep="threeStep"
    @fourStep="fourStep"
    @httpSendEmail="httpSendEmail" />
  </div>
</template>

<script>
import {getShowPage} from "@/utils/function.js"
import {
  getUserGAIsBind,
  sendEmail,
  updatePassword,
  checkCode,
  checkGACode,
} from "@/request/api";

import TopNavigation from "@/components/TopNavigation.vue";
import ResetPassH5 from "./resetPassH5.vue";
import ResetPassPc from "./resetPassPc.vue";
export default {
  components: {
    TopNavigation,
    ResetPassH5,
    ResetPassPc
  },

  data() {
    return {
      currentView: undefined, // 当前渲染的组件

      step: 0 ,//步骤

      timeoutEmail: undefined, //邮箱验证-定时器
      downTimeEmail: 0, //邮箱验证倒计时

      isBandGa: false, //true 绑定了GA验证
    };
  },
  
  created() {
    this.initView();
  },
  
  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    /******************************************** 其他 */
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    //更新倒计时-邮箱验证
    upDownTimeEmail(downTime) {

      if(downTime) this.downTimeEmail = downTime;
      if (this.downTimeEmail < 1) return;
      
      this.timeoutEmail && clearTimeout(this.timeoutEmail);
      this.timeoutEmail = setTimeout(() => {

        this.downTimeEmail--;

        this.upDownTimeEmail();
      }, 1000);
    },
    
    /******************************* https */
    //http获取Ga绑定状态
    httpGetUserGAIsBind(email) {

      var param = {email};
      getUserGAIsBind(param).then((res) => {

        if (res.status == 200) {
          this.isBandGa = res.data;
          this.step++;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //http获取邮箱验证码
    httpSendEmail(email) {

      var param = {email};
      sendEmail(param).then((res) => {
        if (res.status == 200) {
          this.upDownTimeEmail(80);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //http检查邮箱验证码
    httpCheckCode(email, valicode) {
   
        var param = {email, valicode};
        checkCode(param).then((res) => {
          if (res.status == 200) {
            this.step = this.isBandGa ? 2 : 3;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    //http检查GA验证码
    httpCheckGACode(email, gaCode) {
 
        var param = {email, gaCode};
        checkGACode(param).then((res) => {
          if (res.status == 200) {
            this.step = 3;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 修改密码
    httpUpdatePassword(email, paw) {
      var password =  this.$md5(paw)
      var param = {email, password};
      updatePassword(param).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.修改成功"));
          this.Public.toLogin();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    /******************************* 点击 */
    //返回
    backClick() {

      switch (this.step) {
        case 0:
          this.Public.toLogin();
          break;
        case 1:
        case 2:
          this.step--;
          break;
        case 3:
          this.step = this.isBandGa ? 2 : 1;
          break;
      
        default:
          this.step--;
          break;
      }
    },

    //第一步
    oneStep(user) {
      
      if(!user) {
        this.$message.warning(this.$t('m.请输入邮箱'));
        return;
      }
      
      var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
      if (user.match(reg) == null) {
        this.$message.warning(this.$t("m.邮箱格式不正确"));
        return;
      } 

      this.httpGetUserGAIsBind(user);
    },

    //第二步
    towStep(user, emailCode) {

      if(!emailCode) {
        this.$message.warning(this.$t('m.请输入验证码'));
        return;
      }

      this.httpCheckCode(user, emailCode);
    },

    //第三步
    threeStep(user, gaCode) {

      if(!gaCode) {
        this.$message.warning(this.$t('m.请输入谷歌验证码'));
        return;
      }

      this.httpCheckGACode(user, gaCode);
    },

    //第四部
    fourStep(user, paw1, paw2) {

      if(!paw1) {
        this.$message.warning(this.$t('m.请输入6-20位字母加数字新密码'));
        return;
      }
      if(!paw2) {
        this.$message.warning(this.$t('m.请再次输入密码'));
        return;
      }
      if(paw1 != paw2) {
        this.$message.warning(this.$t('m.两次密码不一致'));
        return;
      }

      var reg = /^(?=.*?[a-zA-Z])(?=.*?[0-9])[!-~]{6,20}$/;
      if (paw1.match(reg) == null) {
        this.$message.warning(this.$t("m.密码格式错误"));
        return;
      }
      
      if (paw2.match(reg) == null) {
        this.$message.warning(this.$t("m.确认密码格式错误"));
        return;
      }

      this.httpUpdatePassword(user, paw1);
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.resetPass {
  display: flex;
  flex-direction: column;
  background: #FFF;
  width: 100%;
  height: 100%;
}
</style>