<template>
  <div class="register">
    <TopNavigation v-if="current == 'Pc'" />
    
    <component v-if="current"
    :is="'Signup'+current" 
    :downTimeEmail="downTimeEmail"
    :invite="invite"
    @signup="signupClick"
    @signin="signinClick"
    @send="sendClick"  />
  </div>
</template>

<script>
import {getShowPage} from "@/utils/function.js"
import {register, inviteRequest, sendEmail, checkCode} from "@/request/api";

import TopNavigation from "@/components/TopNavigation.vue";
import SignupH5 from "./signupH5.vue";
import SignupPc from "./signupPc.vue";
export default {
  components: {
    TopNavigation,
    SignupH5,
    SignupPc
  },

  data() {
    return {
      currentView: undefined, // 当前渲染的组件
      
      timeoutEmail: undefined, //邮箱验证-定时器
      downTimeEmail: 0, //邮箱验证倒计时
      invite: '', //邀请码
    };
  },
  
  created() {
    
    var cout = window.location.href.indexOf("?inviteCode=");
    if (cout > -1) {
      
      // 邀请链接访问记录 
      var inviteCode = window.location.href.split("?inviteCode=")[1];
      inviteCode = inviteCode.split("&")[0];
      let param = {inviteCode};
      inviteRequest(param).then((res) => {});

      //默认邀请码
      this.invite = inviteCode;
    }

    this.initView();
  },
  
  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    /******************************************** 其他 */
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    //更新倒计时-邮箱验证
    upDownTimeEmail(downTime) {

      if(downTime) this.downTimeEmail = downTime;
      if (this.downTimeEmail < 1) return;
      
      this.timeoutEmail && clearTimeout(this.timeoutEmail);
      this.timeoutEmail = setTimeout(() => {

        this.downTimeEmail--;

        this.upDownTimeEmail();
      }, 1000);
    },
    
    /******************************* https */
    //http发送邮箱验证码
    httpSendEmail(email) {

      let param = {email};
      sendEmail(param).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.发送成功"));
          this.upDownTimeEmail(60);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    //http验证邮箱
    httpCheckCode(email, valicode, paw, invite) {

      let payload = {email, valicode};
      checkCode(payload).then((res) => {
        if (res.status == 200) {
          this.httpRegister(email, paw, invite);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //http邮箱注册
    httpRegister(email, paw, inviteUserCode) {

      var password =  this.$md5(paw);
      var param = {email, password, inviteUserCode};
      register(param).then((res) => {

        if (res.status == 200) {
          var data = res.data;

          
          this.$message.success(this.$t("m.注册成功"));

          var userToken = data.token;
          var userInfo = data.user;
          var accInfo = JSON.stringify(userInfo);

          localStorage.setItem("guidance", true); //标记注册登录
          localStorage.setItem("accInfo", accInfo);
          localStorage.setItem("token", userToken);
          this.Public.toHome();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    /******************************* 点击 */
    //发送验证码
    sendClick(user) {

      if(!user) {
        this.$message.warning(this.$t('m.请输入邮箱'));
        return;
      }
      
      var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
      if (user.match(reg) == null) {
        this.$message.warning(this.$t("m.邮箱格式不正确"));
        return;
      } 

      this.httpSendEmail(user);
    },

    //注册
    signupClick(user, code, paw, invite) {

      if(!user) {
        this.$message.warning(this.$t('m.请输入邮箱'));
        return;
      }
      
      var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
      if (user.match(reg) == null) {
        this.$message.warning(this.$t("m.邮箱格式不正确"));
        return;
      } 

      if(!code) {
        this.$message.warning(this.$t('m.请输入验证码'));
        return;
      }

      if(!paw) {
        this.$message.warning(this.$t('m.请输入密码'));
        return;
      }

      var reg = /^(?=.*?[a-zA-Z])(?=.*?[0-9])[!-~]{6,20}$/;
      if (paw.match(reg) == null) {
        this.$message.warning(this.$t("m.密码格式错误"));
        return;
      }
      
      this.httpCheckCode(user, code, paw, invite);
    },

    //立即登录
    signinClick() {
      this.Public.toLogin();
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.register {
  display: flex;
  flex-direction: column;
  background: #FFF;
  width: 100%;
  height: 100%;
}
</style>