<template>
  <div class="loginpc">
    <div class="loginpc-warp">

      <div class="menupc">
          <span class="font20 fontc ch1">{{$t("m.邮箱注册")}}</span>
      </div>
      
      <div class="contentpc-user">

        <RichInput class="mb20"
        v-model="user"
        :placeholder="$t('m.请输入邮箱')"
        :imgLeft="require('@/assets/image/v3/signin_user.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_user_.png')" />

        <RichInput class="mb32"
        v-model="emailCode"
        :placeholder="$t('m.请输入验证码') "
        :imgLeft="require('@/assets/image/v3/signin_num.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_num_.png')">
          <template #right>
            <div v-if="downTimeEmail" class="but-codepc">
              <span class="font14 ch3">{{downTimeEmail + ' s'}}</span>
            </div>
            <RichBut v-else class="but-codepc" style="llan" @click="sendClick">
              <span class="font14 clan">{{$t('m.获取验证码')}}</span>
            </RichBut>
          </template>
        </RichInput>
        
        <RichInput class="mb20"
        v-model="paw"
        :password="true"
        :placeholder="$t('m.请输入6-20位字母加数字密码')"
        :imgLeft="require('@/assets/image/v3/signin_lock.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_lock_.png')" />

        <RichInput class="mb32"
        v-model="inviteValue"
        :placeholder="$t('m.请输入邀请码') + '（' + $t('m.选填') + '）'"
        :imgLeft="require('@/assets/image/v3/signin_num.png')"
        :imgLeftSelect="require('@/assets/image/v3/signin_num_.png')" />

        <RichBut class="butpc-ok" styleType="blan" @click="signupClick()">
          <span class="font16 fontc">{{$t('m.注册')}}</span>
        </RichBut>
        
        <div class="all"></div>
        <div class="row jcenter">
          <RichBut class="font14" styleType="llan" @click="signinClick()">
            <span>{{' '+$t('m.已有账号，立即登录')}}</span>
          </RichBut>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue"
export default {
  components: {
    RichInput,
    RichBut,
  },

  props: {
    downTimeEmail: { //邮箱验证码倒计时
      type: Number,
      default :0
    },
    
    invite:'',
  },

  data() {
    return {
      user: '', //账户
      emailCode: '', //邮箱验证码    
      paw: '', //密码
      inviteValue: '', //邀请码
    };
  },
  
  
  created() {
    this.inviteValue = this.invite;
  },

  methods: {
    
    /******************************* 点击 */
    //发送验证码
    sendClick() {
      this.$emit('send', this.user);
    },

    //注册
    signupClick() {
      this.$emit('signup', this.user, this.emailCode, this.paw, this.inviteValue);
    },

    //立即登录
    signinClick() {
      this.$emit('signin');
    }
  },
};
</script>

<style lang='scss' scoped>
.loginpc {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F5F8FA;
  .loginpc-warp {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
    width: 400px;
    padding: 48px;
    .menupc {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-bottom: 48px;
    }

    .contentpc-user {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 0px 16px 16px 16px;
      .but-codepc {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-left: 12px;
      }
    }
  }
}

.butpc-ok {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
  margin-bottom: 60px;
}
</style>