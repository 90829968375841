<template>
  <div class="example">
    <TopNavigation :menu="0" />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'3-3'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <div style="height: calc(100vh - 65px)">
              <div class="search" v-if="total > 0">
                <div class="searchBox">
                  <div>{{ $t("m.交易所") }}</div>
                  <el-select v-model="exchangeName" :placeholder="$t('m.全部')">
                    <el-option
                      v-for="item in exchanges"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="searchBox">
                  <div>{{ $t("m.机器人名称") }}</div>
                  <input
                    class="inpStyle"
                    type="text"
                    v-model="robotName"
                    :placeholder="$t('m.全部')"
                  />
                </div>
                <div class="searchBox">
                  <div></div>
                  <div
                    class="clickBtn"
                    style="width: 90px"
                    @click="qureyHistoryStrategyList()"
                  >
                    {{ $t("m.筛选") }}
                  </div>
                </div>
                <div class="searchBox">
                  <div></div>
                  <div
                    class="clickBtn whiteBackground green"
                    style="min-width: 90px"
                    @click="clear()"
                  >
                    {{ $t("m.清除筛选") }}
                  </div>
                </div>
              </div>

              <div class="data-warp" v-if="total != 0 && totalCopy != 0">
                <div class="dataBox">
                  <div class="listTitle">
                    <div class="titleItem sortStyle" @click="timeSort()">
                      {{ $t("m.实例") }}
                      <img
                        v-if="timeShow == 0"
                        src="@/assets/icon/sort@2x.png"
                        alt=""
                      />
                      <img
                        v-if="timeShow == 1"
                        src="@/assets/icon/sortBottom@2x.png"
                        alt=""
                      />
                      <img
                        v-if="timeShow == 2"
                        src="@/assets/icon/sortTop@2x.png"
                        alt=""
                      />
                    </div>
                    <div
                      class="titleItem sortStyle priceStyle"
                      @click="priceSort()"
                    >
                      {{ $t("m.盈亏") + "(USDT)" }}
                      <img
                        v-if="priceShow == 0"
                        src="@/assets/icon/sort@2x.png"
                        alt=""
                      />
                      <img
                        v-if="priceShow == 1"
                        src="@/assets/icon/sortBottom@2x.png"
                        alt=""
                      />
                      <img
                        v-if="priceShow == 2"
                        src="@/assets/icon/sortTop@2x.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    v-for="(item, idx) in historyStrategyList"
                    :key="idx"
                    class="dataBoxStyle"
                    :class="idx % 2 == 0 ? '' : 'dataBoxColor'"
                  >
                    <div class="example-item-user">
                      <span class="item-nameStyle">
                        <span>{{ item.robotName }}</span>
                        <span style="margin: -3px 0 0 5px">{{ "[" }}</span>
                        <span v-if="item.endType == 'STOP_PROFIT'">
                          {{ $t("m.止盈终止") }}
                        </span>
                        <span v-if="item.endType == 'STOP_AUTO'">
                          {{ $t("m.自动终止") }}
                        </span>
                        <span v-if="item.endType == 'STOP_LOSS'">
                          {{ $t("m.止损终止") }}
                        </span>
                        <span v-if="item.endType == 'STOP_TIME'">
                          {{ $t("m.定时终止") }}
                        </span>
                        <span v-if="item.endType == 'STOP_HAND'">
                          {{ $t("m.手动终止") }}
                        </span>
                        <span v-if="item.endType == 'FORCE_CLOSE'">
                          {{ $t("m.强制终止") }}
                        </span>
                        <span v-if="item.endType == 'MIN_PRICE'">
                          {{ $t("m.最低价终止") }}
                        </span>
                        <span v-if="item.endType == 'MAX_PRICE'">
                          {{ $t("m.最高价终止") }}
                        </span>
                        <span style="margin-top: -3px">{{ "]" }}</span>
                      </span>
                      <span class="list-time">
                        <span class="list-time-name">
                          <img
                            v-if="item.exchangeName == 'OkexV5'"
                            src="@/assets/icon/OKX@2x.png"
                            alt=""
                            style="width: 20px; height: 20px; margin-right: 5px"
                          />
                          <img
                            v-if="item.exchangeName == 'Binance'"
                            src="@/assets/icon/bianlogo@2x.png"
                            style="width: 20px; height: 20px; margin-right: 5px"
                            alt=""
                          />
                          {{ item.apiName ? item.apiName.split("-")[1] : "" }}
                        </span>
                        <span style="margin: 0 8px">
                          {{ item.baseCoin + "/" + item.counterCoin }}
                        </span>
                        <span>
                          {{
                            moment(item.startTime).format("YYYY/MM/DD HH:mm")
                          }}
                        </span>
                        <span class="list-time-carry">
                          {{ " - " }}
                        </span>
                        <span>
                          {{ moment(item.endTime).format("YYYY/MM/DD HH:mm") }}
                        </span>
                        <span style="margin: -2px 0 0 5px">
                          {{
                            "(" +
                            formatDuring(item.startTime, item.endTime) +
                            ")"
                          }}
                        </span>
                      </span>
                    </div>
                    <div class="example-item-box">
                      <div style="width: 120px">
                        <div>
                          <span
                            class="robotPatternBox short"
                            style="width: 66px"
                            v-if="item.robotPattern == 0"
                          >
                            {{ $t("m.做空") + " " + item.leverRate + "X" }}
                          </span>
                          <span
                            class="robotPatternBox long"
                            style="width: 66px"
                            v-if="item.robotPattern == 1"
                          >
                            {{ $t("m.做多") + " " + item.leverRate + " X" }}
                          </span>
                          <span
                            class="robotPatternBox Neutral"
                            style="width: 66px"
                            :style="
                              $store.state.lang == 'en' ? 'width:88px' : ''
                            "
                            v-if="item.robotPattern == 2"
                          >
                            {{ $t("m.双向") + " " + item.leverRate + "X" }}
                          </span>
                        </div>
                        <div style="margin-top: 2px">
                          {{ "ID : " + item.executeId.slice(0, 10) }}
                        </div>
                      </div>
                      <div
                        style="width: 150px"
                        :class="
                          item.realEarnings > 0
                            ? 'green'
                            : item.realEarnings < 0
                            ? 'red'
                            : ''
                        "
                        class="example-btnStyle"
                      >
                        {{ item.realEarnings }}
                      </div>
                    </div>

                    <div class="listBottom">
                      <div>
                        <div>
                          <span
                            @click="qureyStrategyShowList(item)"
                            class="bottomBorder"
                          >
                            {{
                              $t("m.成交记录") + " : " + item.strategyOrderNum
                            }}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div
                          class="
                            whiteBackground
                            clickBtn
                            redBorder
                            red
                            deleteBtn
                          "
                          @click="deleteHistoryStrategy(item)"
                        >
                          {{ $t("m.删除") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pagination-warp"
                v-if="totalCopy || totalCopy == 0 ? totalCopy > 0 : total > 0"
              >
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNo"
                  :page-sizes="[10, 20, 50, 100, 500]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalCopy || totalCopy == 0 ? totalCopy : total"
                >
                </el-pagination>
              </div>
              <div class="no-data" v-if="total == 0">
                <img
                  style="margin: -40px 0 20px"
                  src="@/assets/icon/quesheng.svg"
                  alt=""
                />
                <span class="hui">{{ $t("m.暂无数据") }}</span>
                <!-- <span
                  class="clickBtn"
                  style="width: 232px"
                  @click="createBot()"
                >
                  {{ $t("m.创建机器人") }}
                </span> -->
              </div>
              <div class="no-data" v-if="totalCopy == 0">
                <img
                  style="margin: -40px 0 20px"
                  src="@/assets/icon/quesheng.svg"
                  alt=""
                />
                <span class="hui">{{ $t("m.暂无数据") }}</span>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <!-- 成交记录 -->
    <el-dialog
      :title="$t('m.成交记录')"
      :visible.sync="strategyOrderShow"
      :before-close="handleClose"
      width="800px"
      class="transferBox"
      v-if="strategyOrderList"
    >
      <StartegyTradeHistory
        :executeId="strategyOrderList.executeId"
        :contractSize="strategyOrderList.contractSize"
      />
    </el-dialog>
  </div>
</template>
<script>
import HomeSkeleton from "../modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import StartegyTradeHistory from "./childModular/startegyTradeHistory";
import Menu from "@/components/menu.vue";
import moment from "moment";
import { qureyHistoryStrategyList, deleteHistoryStrategy } from "@/request/api";
export default {
  name: "exampleList",
  components: {
    TopNavigation,
    Menu,
    HomeSkeleton,
    StartegyTradeHistory,
  },
  data() {
    return {
      moment,
      exchangeName: null,
      pageNo: 1,
      pageSize: 10,
      historyStrategyList: null,
      robotName: null,
      total: 0,
      strategyOrderShow: false, //成交记录显隐
      strategyOrderList: null, //成交记录列表
      cointerCoin: null,
      baseCoin: null,
      exchanges: [
        {
          value: "Huobi",
          disabled: true,
        },
        {
          value: "Binance",
        },
        {
          value: "OkexV5",
        },
      ],
      isApi: null,
      totalCopy: null, //筛选后的 条数
      timeShow: 0,
      priceShow: 0,
    };
  },
  methods: {
    // 历史实例列表
    qureyHistoryStrategyList() {
      let payload = {
        token: localStorage.getItem("token"),
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        robotName: this.robotName,
        exchangeName: this.exchangeName,
        strategyId: "WL20210624182847777",
      };
      if (this.timeShow !== 0) {
        payload.sortField = "endTime";
        payload.descIsAsc = this.timeShow == 1 ? "desc" : "asc";
      } else {
        if (this.priceShow !== 0) {
          payload.sortField = "incomeUsdtNum";
          payload.descIsAsc = this.priceShow == 1 ? "desc" : "asc";
        } else {
          payload.sortField = "endTime";
          payload.descIsAsc = "desc";
        }
      }
      qureyHistoryStrategyList(payload).then((res) => {
        if (res.status == 200) {
          if (this.robotName) {
            this.totalCopy = Number(res.data.countNum);
          } else {
            this.totalCopy = null;
            this.total = Number(res.data.countNum);
          }
          let historyStrategyList = res.data.historyStrategyList;
          historyStrategyList.forEach((e) => {
            let contract = JSON.parse(
              JSON.parse(e.result).pairs[0].data.contract
            );
            e.contractSize = contract.pieceVal;
            e.counterCoin = contract.counterCoin;
            e.baseCoin = contract.baseCoin;

            e.realEarnings = this.Public.toMathNum(e.realEarnings);
          });
          this.historyStrategyList = historyStrategyList;
          setTimeout(() => {
            this.$store.commit("setIsSkeleton", false);
          }, 500);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 清除筛选
    clear() {
      this.robotName = null;
      this.exchangeName = null;
      this.qureyHistoryStrategyList();
    },
    // 计算执行时间
    formatDuring(start, end) {
      let New = new Date().getTime();
      let mss;
      if (end) {
        mss = end - start;
      } else {
        mss = New - start;
      }
      var day = parseInt(mss / (1000 * 24 * 60 * 60));
      var hours = parseInt((mss % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      var str =
        day +
        " " +
        this.$t("m.天") +
        " " +
        hours +
        " " +
        this.$t("m.时") +
        " " +
        minutes +
        " " +
        this.$t("m.分");
      return str;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.qureyHistoryStrategyList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.qureyHistoryStrategyList();
    },
    handleClose() {
      this.strategyOrderShow = false;
    },
    // 删除实例
    deleteHistoryStrategy(item) {
      let _this = this;
      this.$confirm(_this.$t("m.确定删除") + "？", _this.$t("m.提示"), {
        confirmButtonText: _this.$t("m.确定"),
        cancelButtonText: _this.$t("m.取消"),
        closeOnClickModal: false,
      })
        .then(() => {
          let payload = {
            token: localStorage.getItem("token"),
            executeId: item.executeId,
            strategyId: item.strategyId,
          };
          deleteHistoryStrategy(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t("m.删除成功"));
              this.qureyHistoryStrategyList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    qureyStrategyShowList(item) {
      this.strategyOrderList = item;
      this.strategyOrderShow = true;
    },

    // 创建机器人
    createBot() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning(this.$t("m.请登录后再试"));
        this.Public.toLogin();
      } else if (!this.isApi) {
        this.$router.push({ path: "/myExchange" });
      } else {
        this.$router.push({ path: "/robotList/createStrategy" });
      }
    },
    timeSort() {
      this.timeShow++;
      this.priceShow = 0;
      this.timeShow = this.timeShow % 3;
      this.qureyHistoryStrategyList();
    },
    priceSort() {
      this.priceShow++;
      this.timeShow = 0;
      this.priceShow = this.priceShow % 3;
      this.qureyHistoryStrategyList();
    },
  },
  mounted() {
    this.qureyHistoryStrategyList();
    this.isApi = localStorage.getItem("isApi");
  },
};
</script>



<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.example {
  // width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include themeify {
    background: themed("secondaryBackColor") !important;
    box-shadow: themed("shadow") !important;
    color: themed("primaryColor") !important;
  }

  border-radius: 8px;
  .el-container {
    height: calc(100vh - 85px);
    @include themeify {
      background: themed("primaryBackColor") !important;
    }
    .el-main {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      // overflow-x: scroll;
      overflow-y: hidden;
      @include themeify {
        background: themed("primaryBackColor") !important;
      }
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        // height: 100%;
        position: relative;
        padding: 20px;
        padding-right: 0;
        @include themeify {
          background: themed("secondaryBackColor") !important;
        }
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
  .search {
    display: flex;
    width: 1120px;
    height: 65px;
    margin-bottom: 20px;
    .searchBox {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      .inputStyle {
        margin-top: 10px;
      }
      :last-child {
        // margin-top: 10px;
      }
      :first-child {
        flex: 1;
      }
    }
    .searchBox:last-child {
      margin-right: 0;
    }
  }
  .data-warp {
    min-width: 1140px;
    height: calc(100vh - 65px);
    overflow: auto;
    padding-right: 20px;
    padding-bottom: 190px;
    position: relative;
  }
  .dataBox {
    .listTitle {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 20px;
      .titleItem {
        flex: 1;
      }
      .priceStyle {
        display: flex;
        justify-content: flex-end;
      }
    }
    .example-item-user {
      display: flex;
      justify-content: space-between;
      .item-nameStyle {
        display: flex;
        align-items: center;
        span {
          display: flex;
          height: 20px;
          line-height: 20px;
        }
      }
      .user-name {
        color: #0895f7;
        cursor: pointer;
      }
      .list-time {
        display: flex;
        align-items: center;
        color: #999fb4;
        height: 20px;
        .list-time-carry {
          margin: -2px 3px 0;
        }
        span {
          height: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
    .list-top {
      display: flex;
      justify-content: space-between;
      .list-user {
        display: flex;
        align-items: center;
        height: 20px;
        span {
          height: 20px;
          line-height: 20px;
        }
        .list-user-robotName {
          margin-right: 15px;
          color: #0895f7;
          cursor: pointer;
          position: relative;
          .identification {
            width: 8px;
            height: 8px;
            background: #fa5c5d;
            border-radius: 50%;
            position: absolute;
            top: -4px;
            right: -10px;
          }
        }
      }
      .list-time {
        display: flex;
        align-items: center;
        color: #999fb4;
        height: 20px;
        .list-time-carry {
          margin: -2px 3px 0;
        }
        span {
          height: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
    .example-item-box {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      .listItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 120px;
      }

      .example-btnStyle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .robotPatternBox {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 2px 6px;
        // height: 18px;
        border-radius: 2px;
        color: #fff;
        margin-right: 5px;
      }

      .listBottom-Box {
        display: flex;
        align-items: center;
      }
      .listBottom-style {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .listBottom-number {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
      .itemStyle {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
    .listBottom {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .deleteBtn {
        width: 96px;
        @include themeify {
          background: 1px solid themed("secondaryBackColor") !important;
        }
      }
    }
  }
  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 180px;
    }
    .text {
      margin: 25px 0;
      @include themeify {
        color: themed("secondaryColor");
      }
    }
  }
  .pagination-warp {
    width: 100%;
    height: 50px;
    text-align: right;
    margin-top: 20px;
    position: absolute;
    left: 0;
    bottom: -20px;
    padding-right: 60px;
    background: #fff;
    border-radius: 8px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
    .el-pagination {
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    ::v-deep.el-pagination__sizes {
      height: auto;
      line-height: normal;
    }
    ::v-deep.el-input__inner {
      background: #fff !important;
      color: #606266 !important;
    }
  }
}

.robotPatternBox {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  // min-width: 60px;
  padding: 0 6px;
  height: 18px;
  border-radius: 2px;
  color: #fff;
  margin-right: 5px;
}
/deep/.transferBox {
  .el-dialog {
    margin-top: 7vh !important;
  }
}
.order-pagination {
  width: 800px !important;
  position: relative;
}
.sortStyle {
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 6px;
    margin-left: 3px;
  }
}
.dataBoxStyle {
  padding: 20px 0;
  border-top: 1px solid #f5f6f5;
  .dataBoxColor {
    background: #fbfbfb;
  }
}
</style>