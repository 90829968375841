<!--丰富的按钮 默认、悬浮、点击、选中 样式-->
<template>
  <div class="menuspc">
    <RichBut 
    class="menuh5" :class="[(index < menus.length-1) ? 'mr40' : '']"
    v-for="(item, index) in menus" :key="'menu' + index"
    :select="index == menu" styleType="lhei"
    @click="menuCLick(index)">
    <span class="font16 fontc">{{getTitle(item)}}</span>
      <div class="menuh5-line" :class="index == menu ? 'blan' : ''"/>
    </RichBut>
  </div>
</template>

<script>
import RichBut from "@/components/RichBut.vue";
export default {
  components: {
    RichBut
  },

  model: {
    prop: 'menu',
    event: 'change'
  },
  
  props: {

    menu: { //当前选中菜单
      type: Number,
      default: 0
    }, 

    menus: { //菜单列表
      type: Array,
      default: undefined
    }, 

    menuKey: {
      type: String,
      default: ''
    },

    fy: { //true翻译
      type: Boolean,
      default: false
    }, 
  },

  data() {
    return {
      lineStyle: {transform: "translateX(0)"}
    };
  },

  mounted() {
  },

  computed: {
  },

  methods: {
    
    //获取标题
    getTitle(item) {

      var title = this.menuKey ? item[this.menuKey] : item;
      return this.fy ? this.$t('m.'+title) : title;
    },

    /************************** 事件 */
    //菜单点击
    menuCLick(menu) {

      var item = this.menus[menu];
      this.$emit('change', menu);
      this.$emit('click', menu, item);
    }
  },
};
</script>

<style lang='scss' scoped>
.menush5 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
  background: #FFF;
  overflow-x: auto;
  .menuh5{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .menuh5-line {
    position: absolute;
    left: 3px;
    right: 3px;
    bottom: 0;
    height: 2px;
  }
}
.blan {
  background: #2670FA;
}
</style>
