<template>
  <div class="robotList">
    <!-- <div class="breadcrumb-warp">
      <span @click="$router.push({ path: '/transac' })">首页 / </span>
      <span>我的机器人列表</span>
    </div> -->
    
    <div class="no-data" v-if="isRobot">
      <img src="@/assets/image/kong_big@2x.png" alt="" />
      <span class="text">{{ $t("m.未创建机器人") }}</span>
      <span class="clickBtn" style="width: 232px" @click="createBot()">{{
        $t("m.创建机器人")
      }}</span>
    </div>
    <div class="robotList-warp">

      <div class="clickBtn add-dca" v-if="!isRobot">
        <img
          src="../../../assets/icon/tianjia.svg"
          alt=""
          style="margin-top: 1px"
        />
        <span @click="createBot()">{{ $t("m.创建DCA机器人") }}</span>
      </div>

      <!-- tabs -->
      <el-tabs v-model="activeName" @tab-click="handleClick" v-if="!isRobot">
        <el-tab-pane :label="$t('m.全部')" name="0"></el-tab-pane>
        <el-tab-pane :label="$t('m.启用')" name="1"></el-tab-pane>
        <el-tab-pane :label="$t('m.关闭')" name="2"></el-tab-pane>
        <el-tab-pane :label="$t('m.做多')" name="3"></el-tab-pane>
        <el-tab-pane :label="$t('m.做空')" name="4"></el-tab-pane>
        <el-tab-pane :label="$t('m.双向')" name="5"></el-tab-pane>
      </el-tabs>

      <!-- 搜索 -->
      <div class="screen" v-if="!isRobot">
        <input
          type="text"
          class="inp"
          :placeholder="$t('m.请输入机器人名称')"
          v-model="robotName"
        />
        <input
          type="text"
          class="inp"
          :placeholder="$t('m.请输入交易对')"
          v-model="transactionPair"
        />
        <input
          type="text"
          class="inp"
          :placeholder="$t('m.请输入API名称')"
          v-model="apiName"
        />
        <div class="clickBtn screen-btn" @click="screen()">
          {{ $t("m.筛选") }}
        </div>
        <div class="Clear" @click="screen('clear')">{{ $t("m.清除筛选") }}</div>
      </div>

      <!-- 列表 -->
      <div class="dca-list">
        <DcaCard
          @childFn="parentFn"
          @IsRobot="IsRobot"
          ref="child"
          :isRobot="isRobot"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DcaCard from "./dcaCard.vue";
import {
  queryRobotList,
  deleteRobot,
  startRobot,
  suspendRobot,
  restartRobot,
  robotEnd,
} from "@/request/api";
export default {
  components: {
    DcaCard,
  },
  data() {
    return {
      activeName: "0",
      robotName: null, //机器人名称
      transactionPair: null,
      isRobot: true,
      isApi: null,
      apiName:null
    };
  },
  computed: {},
  watch: {},
  methods: {
    ToCreateStrategy() {
      this.$router.push("/robotList/createStrategy");
    },
    handleClick(tab, event) {
      this.screen();
    },
    parentFn(menuIndex, active, type, data) {
      if (data && type == "copy") {
        data.type = type;
        // if (type == "edit") {
        //   data.displayConfig.robotId = data.robotId;
        // }
        localStorage.setItem("copyStrategy", JSON.stringify(data));
      } else if (data && type == "datails") {
        localStorage.setItem("robotId", data.robotId);
      }
      this.$emit("childFn", menuIndex, active);
    },

    // 筛选
    screen(v) {
      if (v) {
        this.robotName = null;
        this.transactionPair = null;
        this.apiName =null
      }
      let num = Number(this.activeName);
      let payload = {
        robotName: this.robotName,
        transactionPair: this.transactionPair,
        apiName : this.apiName
      };
      switch (num) {
        case 0:
          break;
        case 1:
          payload.statesType = "open";
          break;
        case 2:
          payload.statesType = "close";
          break;
        case 3:
          payload.robotPattern = "1";
          break;
        case 4:
          payload.robotPattern = "0";
          break;
        case 5:
          payload.robotPattern = "2";
          break;
      }
      this.$refs.child.queryRobotList(payload);
    },

    //
    IsRobot(v) {
      this.isRobot = v;
    },

    // 创建机器人
    createBot() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning($t("m.请登录后再试"));
        this.Public.toLogin();
      } else if (!this.isApi) {
        this.$router.push({ path: "/myExchange" });
      } else {
        this.$router.push({ path: "/robotList/createStrategy" });
      }
    },
  },
  created() {},
  mounted() {
    if (localStorage.getItem("activeName")) {
      this.activeName = "1";
    }
    this.isApi = localStorage.getItem("isApi");
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.robotList {
  width: 100%;
  height: 100%;
  position: relative;

  .robotList-warp {
    min-width: 1150px;
    position: relative;
    ::v-deep.el-tabs__item {
      @include themeify {
        color: themed("secondaryColor");
      }
    }
    ::v-deep.is-active {
      @include themeify {
        color: themed("tabColor");
      }
    }

    ::v-deep.el-tabs__active-bar {
      @include themeify {
        background: themed("btnBackGreen");
      }
    }
    .back {
      width: 28px;
      height: 28px;
      background: #edeef4;
      border-radius: 8px;
      position: absolute;
      left: -20px;
      top: 12px;
      cursor: pointer;
      img {
        width: 28px;
        height: 28px;
      }
    }
    .add-dca {
      width: 140px;
      height: 26px;
      font-size: 10px;
      padding: 2px 6px;
      position: absolute;
      top: 7px;
      right: 0;
      z-index: 888;
      img {
        margin-right: 4px;
      }
    }
    ::v-deep.el-tabs__nav-wrap {
      margin-left: 20px;
    }
    ::v-deep.el-tabs__nav-wrap::after {
      background-color: rgba($color: #fff, $alpha: 0);
    }

    .screen {
      display: flex;
      align-items: center;
      margin: 20px 20px;
      .inp {
        width: 302px;
        height: 32px;
        margin-right: 20px;
        margin-top: 0;
        padding-left: 15px;
        @include themeify {
          border-color: themed("splitline") !important;
          background: themed("inpBack") !important;
        }
      }
      .screen-btn {
        width: 80px;
        height: 26px;
        font-size: 10px;
        margin-right: 20px;
      }
      .Clear {
        width: 80px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #d0d7df;
        font-size: 10px;
        cursor: pointer;
        @include themeify {
          color: themed("btnBackGreen") !important;
        }
      }
    }

    .dca-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 180px;
    }
    .text {
      margin: 25px 0;
      @include themeify {
        color: themed("secondaryColor");
      }
    }
  }
}
</style>