<template>
  <div class="download">
    <TopNavigation 
    seoMetaTitle="Download Vtrading App | Crypto iOS & Android App | Vtrading" 
    seoKeyWords="Vtrading DCA App, Grid trade app, Bitcoin, Altcoin trading tools" 
    seoDescription="Using the trading tools and strategies provided by vtrading app can better help traders continue to trade in the cryptocurrency market."/>
    
    <component v-if="current"
    :is="'Download'+current" 

    :one="one" :two="two" :three="three" 
    :four="four" :five="five"

    @tapClick="tapClick"/>

    <Bottom />
  </div>
</template>

<script>

import {getShowPage, isLogin} from "@/utils/function.js"

import TopNavigation from "@/components/TopNavigation";
import Bottom from "@/components/Bottom";
import DownloadPc from "./downloadPc.vue"
import DownloadH5 from "./downloadH5.vue"

export default {

  components: {
    TopNavigation: TopNavigation,
    Bottom: Bottom,
    DownloadPc: DownloadPc,
    DownloadH5: DownloadH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      one: {
        'span1': 'download_one_1',
        'span2': 'download_one_2',
        'span3': 'download_one_3',
        'src1': require('@/assets/image/bot/download_1.png'),
        'but1': {
          'txt':'download_one_but_1',
          'click':'toDownload'
        },
        'but2': {
          'txt':'download_one_but_2',
          'click':'toDownload'
        }
      },
      
      two: {
        'span1': 'download_two_1',
        'span2': 'download_two_2',
        'span3': 'download_two_3',
        'src1': require('@/assets/image/bot/download_2.png'),
      },

      three: {
        'span1':'download_three_1',
        'span2':'download_three_2',
        'span3':'download_three_3',
        'src1': require('@/assets/image/bot/download_3.png'),
      },

      four: {
        'src1': require('@/assets/image/bot/download_4.png'),
        'span1': 'download_four_1',
        'span2': 'download_four_2',
      },

      five: {
        'src1': require('@/assets/image/bot/download_5.png'),
        'span1':'download_five_1',
        'span2':'download_five_2',
        'but1': {
          'txt':'download_five_but_1',
          'click':'toDownload'
        }
      }
    };
  },

  created() {
    this.initView();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    /******************************************** 点击 */
    //点击事件(PC、H5)
    tapClick(name, param) {
      this[name](param);
    },

    //下载APP
    toDownload() {
      var url = 'https://wap.vtrading.com/H5/pages/download/download.html?time=' + new Date().valueOf();
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.download {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cbai60 {color: rgba($color: #ffffff, $alpha: 0.6);}
.but {cursor: pointer;}
</style>
