var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inputIcon"},[(_vm.item)?[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.item.name == "首次触发模式" ? "" : _vm.$store.state.lang == 'ch' ? _vm.item.name : _vm.item.enName)+" "),(_vm.item && _vm.item.explain)?_c('img',{staticClass:"explain",attrs:{"src":require("@/assets/icon/explain.svg"),"alt":""},on:{"click":function($event){return _vm.openAlert()}}}):_vm._e()]),_c('div',{staticClass:"inputBox"},[_c('div',{staticClass:"minBox"},[(_vm.minIcon)?_c('div',{staticClass:"box-smlBox",on:{"click":function($event){return _vm.minClick()}}},[_vm._v(" "+_vm._s(_vm.$t("m.最小值"))+" ")]):_vm._e(),(_vm.type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.value),expression:"item.value"}],staticClass:"slider-input",class:[
            _vm.disabled ? 'not-cursor' : '',
            _vm.item.redBorder ? 'redBorderStyle' : 'inpStyle',
          ],style:({
            'padding-left': _vm.leftIcon ? '22px' : '',
          }),attrs:{"placeholder":_vm.$store.state.lang == 'ch' ? _vm.item.placeholder : _vm.item.enPlaceholder,"disabled":_vm.disabled},domProps:{"value":(_vm.item.value)},on:{"blur":_vm.inputBlur,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "value", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.value),expression:"item.value"}],staticClass:"slider-input",class:[
            _vm.disabled ? 'not-cursor' : '',
            _vm.redBorderStyleShow
              ? 'redBorderStyle'
              : _vm.item.redBorderStyle
              ? 'redBorderStyle'
              : 'inpStyle',
          ],style:({
            'padding-left': _vm.leftIcon ? '22px' : '',
          }),attrs:{"placeholder":_vm.$store.state.lang == 'ch' ? _vm.item.placeholder : _vm.item.enPlaceholder,"disabled":_vm.disabled},domProps:{"value":(_vm.item.value)},on:{"blur":_vm.inputBlur,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "value", $event.target.value)}}}),(_vm.leftIcon)?_c('span',{staticClass:"input-leftIcon"},[_vm._v(_vm._s(_vm.leftIcon))]):_vm._e(),(_vm.item.inpUnit)?_c('span',{staticClass:"input-icon"},[(_vm.item.inpUnit == 'multiple')?_c('span',[_vm._v(_vm._s(_vm.$t("m.倍")))]):_vm._e(),(_vm.item.inpUnit == 'single')?_c('span',[_vm._v(_vm._s(_vm.$t("m.单")))]):_vm._e(),(_vm.item.inpUnit == 'percentage')?_c('span',[_vm._v("%")]):_vm._e(),(_vm.item.inpUnit == 'currency')?_c('span',[_vm._v(" "+_vm._s(_vm.pairCurrent ? _vm.pairCurrent.counterCoin : "USDT")+" ")]):_vm._e()]):_vm._e()]),(_vm.item.redBorderStyle)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.item.title == "网格间隔等差" && _vm.item.value == 0 && _vm.item.value ? _vm.$t("m.不能为零") : _vm.$t("m.不能为空"))+" ")]):_vm._e(),(_vm.equivalent)?_c('div',{staticStyle:{"margin":"5px"}},[_vm._v(" "+_vm._s("≈" + (_vm.result ? _vm.item.pairUsdtSize ? Number(Number(_vm.lastValue).toFixed(_vm.item.pairUsdtSize)) : Number(_vm.lastValue) : "999999") + (_vm.pairCurrent ? _vm.pairCurrent.baseCoin : "USDT"))+" ")]):_vm._e()])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }