<template>
  <div class="index">
    <TopNavigation :menu="0" />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'2'" />
      </el-aside>

      <el-container>
        <el-main ref="main">
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <template>
              <div class="exchanges-warp" id="exchanges_id" v-if="token">
                <div class="add-api">
                  <p class="label">
                    {{ isEdit ? $t("m.编辑交易所") : $t("m.连接交易所") }}
                  </p>
                  <div class="add-box">
                    <div class="box">
                      <span>{{ $t("m.交易所") }}</span>
                      <el-select
                        v-model="exchangeValue"
                        @change="exchange($event)"
                        :placeholder="$t('m.请选择交易所')"
                      >
                        <el-option
                          v-for="item in exchangeList"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div class="box">
                      <span>{{ $t("m.名称") }}</span>
                      <input
                        type="text"
                        :placeholder="$t('m.请输入API名称')"
                        :disabled="exchangeValue == 'Huobi'"
                        :class="
                          exchangeValue == 'Huobi' ? 'not-cursor' : 'inpStyle'
                        "
                        v-model="apiName"
                      />
                    </div>
                  </div>

                  <div class="add-box">
                    <div class="box">
                      <span>API Key</span>
                      <input
                        v-model="apiKey"
                        :placeholder="$t('m.请输入') + 'API Key'"
                        type="text"
                        :disabled="exchangeValue == 'Huobi'"
                        :class="
                          exchangeValue == 'Huobi' ? 'not-cursor' : 'inpStyle'
                        "
                      />
                    </div>
                    <div class="box">
                      <span>API Secret</span>
                      <input
                        v-model="apiSecret"
                        :placeholder="$t('m.请输入') + 'API Secret'"
                        :disabled="exchangeValue == 'Huobi'"
                        :class="
                          exchangeValue == 'Huobi' ? 'not-cursor' : 'inpStyle'
                        "
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="add-box" v-if="exchangeValue == 'OKX'">
                    <div class="box" style="width: 100%">
                      <span>{{ $t("m.密码") }}</span>
                      <input
                        v-model="passphrase"
                        :placeholder="$t('m.请输入')"
                        type="text"
                        class="inpStyle"
                      />
                    </div>
                  </div>

                  <div class="add-btn">
                    <div class="btn-warp" v-if="isEdit">
                      <span @click="cancelRenewal()">{{ $t("m.取消") }}</span>
                      <span @click="getApiAccountId()">{{ $t("m.保存") }}</span>
                    </div>
                    <span
                      v-else
                      @click="getApiAccountId()"
                      style="font-size: 16px"
                      :class="
                        exchangeValue == 'Huobi' ? 'not-btnCursor' : 'btn'
                      "
                    >
                      {{ $t("m.连接") }}
                    </span>

                    <div @click="toRegister()" v-if="!isEdit">
                      <span class="no-acc">{{ $t("m.还没有账户") + "?" }}</span>
                      <span class="res">{{
                        $t("m.去") +
                        " " +
                        (exchangeValue ? exchangeValue : "Binance") +
                        " " +
                        $t("m.注册")
                      }}</span>
                    </div>
                  </div>
                </div>

                <!-- <HomeAccount/> -->

                <div style="margin-bottom: 20px" v-if="isApi">
                  <!-- <AggregateAcc style="cursor: pointer" /> -->
                  <AggregateAcc ref="childAgg" />
                </div>

                <!--  -->

                <ApiList
                  @childFn="parentFn"
                  @IsApi="IsApi"
                  ref="child"
                  @editApi="editApi"
                />
              </div>
              <div class="no-accTwo" v-if="!token">
                <img src="@/assets/image/kong_big@2x.png" alt="" />
                <span>{{ $t("m.你还没有连接到交易所") }}</span>
                <span>{{ $t("m.连接交易所开始交易") }}</span>
                <span
                  class="clickBtn"
                  style="width: 232px; margin-top: 20px"
                  @click="toAddExchange()"
                >
                  {{ $t("m.连接交易所") }}</span
                >
              </div>
            </template>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 邮箱绑定/安全验证 -->
    <el-dialog
      :title="securityShow == '1' ? $t('m.邮箱验证') : $t('m.邮箱绑定')"
      :visible.sync="securityVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      class="emailStyle"
    >
      <!-- 邮箱绑定 -->
      <template>
        <div class="dialog-warp">
          <div class="inp-warp">
            <span class="inp-title">
              <span>
                {{ securityShow == "1" ? $t("m.邮箱验证") : $t("m.邮箱绑定") }}
              </span>
            </span>
          </div>
          <el-input
            class="inp-warp"
            style="margin-bottom: 10px; height: 36px"
            v-model="emailValue"
            :placeholder="$t('m.请输入邮箱')"
            v-if="securityShow == '0'"
          ></el-input>
          <div class="inp-warp">
            <el-input
              :placeholder="$t('m.请输入邮箱验证码')"
              v-model="emailCode"
            >
              <i slot="suffix">
                <div class="get-code" @click="getCode()" v-if="num == 0">
                  {{ $t("m.获取验证码") }}
                </div>
                <div class="get-code" v-else>
                  <span>{{ num }}</span>
                  <span>{{ " s " }}</span>
                </div>
              </i>
            </el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            style="width: 100%"
            @click="verificationEmail()"
            class="btn"
          >
            {{ $t("m.提交") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import HomeSkeleton from "../transaction/modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import AggregateAcc from "../transaction/modular/childModular/aggregateAcc.vue";
import ApiList from "../transaction/modular/childModular/apiList.vue";
import { setRSApass } from "@/utils/secret";
import {
  getPublicKey,
  addUserApi,
  getExchangeList,
  getApiAccountId,
  synchronizationSingelApi,
  queryExchangeOpenList,
  userDetail,
  getCode,
  getUserGAIsBind,
  sendEmail,
  isEmailCheck,
  checkGACode,
  checkCode,
  updateUser,
  apiRenewal,
  bindingEmail,
} from "@/request/api";
export default {
  components: {
    TopNavigation,
    Menu,
    HomeSkeleton,
    AggregateAcc,
    ApiList,
  },
  data() {
    return {
      loginShow: false,
      accInfo: null,
      //交易所option
      exchangeList: ["Binance", "OKX", "Huobi", "CoinTR_teacher"],
      exchangeValue: null,
      exchangeItem: null,
      exchangeData: null,

      // input输入框
      apiId: null,
      apiKey: null,
      apiSecret: null,

      apiList: null, //API列表
      apiAllData: null,
      exchangeUrl: null, //交易所连接
      isApi: false,
      token: null,
      securityShow: null, //是否安全验证
      securityVisibleShow: null,
      securityVisible: false,
      num: 0,
      isBandGa: null, //是否绑定了GA
      verification: "邮箱验证",
      emailCode: null, // 邮箱验证码
      emailValue: null,
      apiName: null,
      isEdit: false,
      user: null,
      scorll: 0,
      oldAccountId: null,
      StrublicKey: null, //策略公钥
      passphrase: null, // okx密码
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 是否续期Api
    editApi(exchangeName, apiKey, apiName, apiId, accountId) {
      let top = this.$refs.main.$el.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        this.$refs.main.$el.scrollTop =
          this.$refs.main.$el.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.apiName = apiName.split("-")[1];
      this.apiKey = apiKey;
      this.isEdit = true;
      this.apiId = apiId + "";
      this.oldAccountId = accountId;
      for (let key of this.exchangeData) {
        if (exchangeName == key.exchangeName) {
          this.exchangeValue = exchangeName == "OkexV5" ? "OKX" : exchangeName;
          this.exchangeItem = key;
        }
      }
    },

    // 获取当前选中的交易所信息
    exchange(item) {
      let exchangeName = item == "OKX" ? "OkexV5" : item;
      this.exchangeData.forEach((e) => {
        if (e.exchangeName == exchangeName) {
          this.exchangeItem = e;
          this.exchangeName = e.exchangeName;
        }
      });
    },
    handleClose() {
      this.securityVisible = false;
    },

    //续期
    renewalApi(payload) {
      apiRenewal(payload).then((res) => {
        if (res.status == 200) {
          this.cancelRenewal();
          this.$message.success(this.$t("m.操作成功"));
          this.$refs.child.queryApiListIconData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 取消续期
    cancelRenewal() {
      this.apiName = null;
      this.apiKey = null;
      this.apiSecret = null;
      this.isEdit = false;
      for (let key of this.exchangeData) {
        if (key.exchangeName == "Binance") {
          this.exchangeValue = key.exchangeName;
          this.exchangeItem = key;
        }
      }
      this.apiId = null;
      this.accountId = null;
    },

    //录入Api
    addUserApi() {
      let payload = {
        accountId: this.accountId,
        exchangeName: this.exchangeItem.exchangeName,
        exchangeId: this.exchangeItem.id,
        token: localStorage.getItem("token"),
        apiName: this.apiName,
      };
      addUserApi(payload).then((res) => {
        this.apiId = res.data.apiId.toString();
        let key = this.publicKey;
        let exchangeValue = {
          exchangeName: this.exchangeItem.exchangeName,
          apiId: this.apiId,
          secretKey: this.apiSecret,
        };
        let param = JSON.parse(this.exchangeItem.param);
        param.forEach((e) => {
          if (e.field == "apiKey") {
            exchangeValue.apiKey = this.apiKey;
          } else if (e.field == "accessKey") {
            exchangeValue.apiKey = this.apiKey;
          } else if (e.field == "passphrase") {
            exchangeValue.passphrase = this.passphrase;
          }
        });
        let apiData = { ...exchangeValue };
        let data = JSON.stringify(apiData);

        let partLength = 100;
        let i = 1;
        var temp = data.substring(0, partLength);
        var rsaStr = setRSApass(temp, key);
        while (data.length > i * partLength) {
          temp = data.substring(i * partLength, (i + 1) * partLength);
          rsaStr = rsaStr + "," + setRSApass(temp, key);
          i++;
        }

        let params = {
          accountId: this.accountId,
          token: localStorage.getItem("token"),
          apiData: rsaStr,
          apiId: this.apiId,
        };

        this.synchronizationSingelApi(params);
      });
    },

    //获取公钥
    getPublicKey(v) {
      let payload = {
        token: localStorage.getItem("token"),
        serverType: "accountServer",
      };
      getPublicKey(payload).then((res) => {
        this.publicKey = res.data.publicKey;
        console.log('公钥:', res, this.publicKey)
      });
    },
    // 策略公钥
    getStryPublicKey() {
      let payload = {
        token: localStorage.getItem("token"),
        serverType: "strategy",
      };
      getPublicKey(payload).then((res) => {
        this.StrublicKey = res.data.publicKey;
        console.log('策略公钥:', res, this.StrublicKey)
      });
    },

    //获取交易所列表
    getExchangeList() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      getExchangeList(payload).then((res) => {
        if (res.status == 200) {
          let list = JSON.parse(JSON.stringify(res.data.exchangeList));
          for (let key of list) {
            if (key.exchangeName == "Binance") {
              this.exchangeValue = key.exchangeName;
              this.exchangeItem = key;
            }
          }
          this.exchangeData = list;
        }
      });
    },

    //获取 accountId 后添加Api
    getApiAccountId() {
      if (this.exchangeValue == "Huobi") {
        this.$message.warning(
          this.$t("m.暂不支持") + "Huobi" + this.$t("m.交易所")
        );
        return;
      }
      if (!this.securityVisibleShow) {
        this.$message.warning(this.$t("m.为了您的账号安全，请进行安全验证"));
        this.securityVisible = true;
        return;
      }
      if (!this.apiKey) {
        this.$message.error(this.$t("m.请输入API key"));
        return;
      } else if (!this.apiSecret) {
        this.$message.error(this.$t("m.请输入API Secret"));
        return;
      } else if (!this.apiName) {
        this.$message.error(this.$t("m.请输入名称"));
        return;
      } else if (!this.exchangeValue) {
        this.$message.error(this.$t("m.请选择交易所"));
        return;
      } else if (!this.passphrase && this.exchangeValue == "OKX") {
        this.$message.error(this.$t("m.请输入密码"));
        return;
      } else if (this.Public.countCharacters(this.apiName) > 16) {
        return this.$message.error("API名称长度限制8个字符");
      }

      var Fn = (key) => {
        let exchangeValue = {
          exchangeName: this.exchangeItem.exchangeName,
          apiId: this.apiId,
          secretKey: this.apiSecret,
        };
        let param = JSON.parse(this.exchangeItem.param);
        param.forEach((e) => {
          if (e.field == "apiKey") {
            exchangeValue.apiKey = this.apiKey;
          } else if (e.field == "accessKey") {
            exchangeValue.apiKey = this.apiKey;
          } else if (e.field == "passphrase") {
            exchangeValue.passphrase = this.passphrase;
          }
        });
        let apiData = { ...exchangeValue };
        let data = JSON.stringify(apiData);

        let partLength = 100;
        let i = 1;
        var temp = data.substring(0, partLength);
        var rsaStr = setRSApass(temp, key);
        while (data.length > i * partLength) {
          temp = data.substring(i * partLength, (i + 1) * partLength);
          rsaStr = rsaStr + "," + setRSApass(temp, key);
          i++;
        }

        return rsaStr;
      };

      let payload = {
        token: localStorage.getItem("token"),
        apiData: Fn(this.publicKey), //新建 key
      };
      getApiAccountId(payload).then((res) => {
        if (res.status == 200) {
          var encryption = () => {
            this.isRenewal = true;
            let key = this.publicKey;
            let exchangeValue = {
              exchangeName: this.exchangeItem.exchangeName,
              apiId: res.data.apiId,
              secretKey: this.apiSecret,
            };
            let param = JSON.parse(this.exchangeItem.param);
            param.forEach((e) => {
              if (e.field == "apiKey") {
                exchangeValue.apiKey = this.apiKey;
              } else if (e.field == "accessKey") {
                exchangeValue.apiKey = this.apiKey;
              } else if (e.field == "passphrase") {
                exchangeValue.passphrase = this.passphrase;
              }
            });
            let apiData = { ...exchangeValue };
            let data = JSON.stringify(apiData);

            let partLength = 100;
            let i = 1;
            var temp = data.substring(0, partLength);
            var rsaStr = setRSApass(temp, key);
            while (data.length > i * partLength) {
              temp = data.substring(i * partLength, (i + 1) * partLength);
              rsaStr = rsaStr + "," + setRSApass(temp, key);
              i++;
            }
            let paramsData = {
              accountId: this.accountId,
              token: localStorage.getItem("token"),
              apiData: rsaStr,
              apiId: res.data.apiId,
            };
            synchronizationSingelApi(paramsData).then((resData) => {
              if (resData.status == 200) {
                let params = {
                  token: localStorage.getItem("token"),
                  apiData: rsaStr,
                  strategyApiData: Fn(this.StrublicKey),
                  apiId: res.data.apiId,
                  apiName: this.apiName,
                  exchangeName: this.exchangeItem.exchangeName,
                };
                this.renewalApi(params);
              } else {
                this.$message.error(res.msg);
              }
            });
          };
          let accountId = res.data.accountId;
          console.log(res)

          if (accountId.indexOf("Binance") != -1) {
            this.$confirm(
              this.$t("m.未知API，确认导入可能会导致运行中的交易错误"),
              this.$t("m.提示"),
              {
                confirmButtonText: this.$t("m.确定"),
                cancelButtonText: this.$t("m.取消"),
              }
            )
              .then(() => {
                encryption();
              })
              .catch(() => {});

            return;
          }

          if (
            this.oldAccountId != res.data.accountId &&
            res.data.states == "NORMAL" &&
            this.isEdit
          ) {
            this.$confirm(
              this.$t(
                "m.您录入的API与当前API来自不同账户，确认录入将创建新的连接"
              ),
              this.$t("m.提示"),
              {
                confirmButtonText: this.$t("m.确定"),
                cancelButtonText: this.$t("m.取消"),
              }
            )
              .then(() => {
                this.accountId = res.data.accountId;
                this.addUserApi();
              })
              .catch(() => {});
            return;
          } else if (res.data.states == "UNBIND") {
            this.$confirm(
              this.$t("m.您录入的API与名称为 ") +
                res.data.apiName +
                this.$t("m.的API来自同一账户") +
                this.$t("m.确认录入将更新原有API?"),
              this.$t("m.提示"),
              {
                confirmButtonText: this.$t("m.确定"),
                cancelButtonText: this.$t("m.取消"),
              }
            )
              .then(() => {
                encryption();
              })
              .catch(() => {});
            return;
          }

          this.accountId = res.data.accountId;
          this.addUserApi();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //同步单个Api
    synchronizationSingelApi(payload) {
      synchronizationSingelApi(payload).then((res) => {
        this.apiSecret = null;
        this.apiKey = null;
        this.apiName = null;
        for (let key of this.exchangeData) {
          if (key.exchangeName == "Binance") {
            this.exchangeValue = key.exchangeName;
            this.exchangeItem = key;
          }
        }
        this.$message.success(this.$t("m.录入成功"));
        this.$refs.child.queryApiListIconData();
        // this.$refs.childAgg.querySumApiData();
        // this.$router.go(0);
      });
    },

    parentFn(menuActive, active) {
      this.$emit("childFn", "2", "2-3");
    },

    //交易所连接
    queryExchangeOpenList() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      queryExchangeOpenList(payload).then((res) => {
        if (res.status == 200) {
          this.exchangeUrl = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    IsApi(v) {
      this.isApi = v;
      if (this.isApi && this.$refs.childAgg) {
        this.$refs.childAgg.querySumApiData();
      }
    },
    toAddExchange() {
      if (!localStorage.getItem("token")) {
        this.Public.toLogin();
      } else {
        this.$router.push({ path: "/myExchange" });
      }
    },
    toRegister() {
      let exchangeValue = this.exchangeValue ? this.exchangeValue : "Binance";
      this.exchangeUrl.forEach((e) => {
        if (e.exchangeName.toUpperCase() == exchangeValue.toUpperCase()) {
          window.open(e.openUrl);
        }
      });
    },
    // 查询用户信息
    userDetail(callback) {
      let payload = {
        token: localStorage.getItem("token"),
      };
      userDetail(payload).then((res) => {
        if (res.status == 200) {
          let user = res.data.user;
          this.user = user;
          if (user.isEmailCheck == "0") {
            if (user.email) {
              this.securityShow = "1";
            } else {
              this.securityShow = "0";
            }
            this.securityVisibleShow = false;
          } else {
            this.securityVisibleShow = true;
          }
          callback && callback();
        }
      });
    },
    // 判断是否绑定GA
    getUserGAIsBind() {
      if (!JSON.parse(localStorage.getItem("accInfo")).email) {
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        email: JSON.parse(localStorage.getItem("accInfo")).email,
      };

      getUserGAIsBind(payload).then((res) => {
        if (res.status == 200) {
          this.isBandGa = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 绑定邮箱/
    verificationEmail() {
      if (!this.emailCode) {
        this.$message.warning(this.$t("m.请输入邮箱验证码"));
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        valicode: this.emailCode,
      };
      //邮箱第一次绑定
      if (this.securityShow == "0") {
        payload.email = this.emailValue;
        checkCode(payload).then((res) => {
          if (res.status == 200) {
            payload.loginCheck = "1";
            updateUser(payload).then((res) => {
              if (res.status == 200) {
                let payload = {
                  email: this.emailValue,
                  token: localStorage.getItem("token"),
                  valicode: this.emailCode,
                };
                isEmailCheck(payload).then((res) => {});
                let payloadData = {
                  email: this.emailValue,
                  token: localStorage.getItem("token"),
                };
                bindingEmail(payloadData).then((res) => {});
                this.securityVisible = false;
                this.$message.success(
                  this.$t("m.连接成功,为了您的交易安全,已自动开启登录验证")
                );
                this.userDetail(() => {
                  this.getApiAccountId();
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        payload.email = this.user.email;
        isEmailCheck(payload).then((res) => {
          if (res.status == 200) {
            let payloadData = {
              loginCheck: "1",
              isEmailCheck: "1",
              token: localStorage.getItem("token"),
            };
            updateUser(payloadData).then((res) => {
              if (res.status == 200) {
                this.$message.success(
                  this.$t("m.安全验证成功,为了您的交易安全,已自动开启登录验证")
                );
                this.securityVisible = false;
                this.userDetail(() => {
                  this.getApiAccountId();
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 发送邮箱验证码
    getCode() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      if (this.securityShow == "1") {
        payload.email = this.user.email;
      } else {
        if (!this.emailValue) {
          return this.$message.warning("请输入邮箱");
        } else {
          var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
          if (this.emailValue.match(reg) == null) {
            this.$message.error(this.$t("m.邮箱格式不正确"));
            return;
          }
          payload.email = this.emailValue;
        }
      }
      sendEmail(payload).then((res) => {
        if (res.status == 200) {
          this.num = 60;
          this.countDownTwo();
          this.$message.success(this.$t("m.发送成功"));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 倒计时
    countDownTwo() {
      var down = () => {
        if (this.num > 0) {
          this.num--;
          setTimeout(down, 1000);
        }
      };
      setTimeout(down, 1000);
    },
  },
  created() {},
  mounted() {
    this.token = localStorage.getItem("token");
    let accInfo = JSON.parse(localStorage.getItem("accInfo"));
    this.token = localStorage.getItem("token");
    this.accInfo = accInfo;
    this.getExchangeList();
    this.getPublicKey();
    this.getStryPublicKey();
    this.queryExchangeOpenList();
    this.userDetail();

    let item = JSON.parse(localStorage.getItem("editItem"));

    if (item) {
      setTimeout(() => {
        this.editApi(item.exchangeName, item.apiKey, item.apiName, item.apiId);
      }, 1000);
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    localStorage.removeItem("editItem");
  },
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.index {
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      justify-content: space-between;
      overflow: auto;
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        // height: 100%;
        position: relative;
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
}
.not-cursor {
  cursor: not-allowed !important;
  background: #d0d7df;
}
.not-btnCursor {
  cursor: not-allowed !important;
  background: #d0d7df;
  width: 232px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 10px;
}
.exchanges-warp {
  .add-api {
    padding: 20px;
    border-radius: 8px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }

    .label {
      font-size: 20px;
      padding-bottom: 10px;
      @include themeify {
        color: themed("primaryColor") !important;
        border-bottom: 1px solid themed("splitline") !important;
        // box-shadow: themed("shadow") !important;
      }
    }
    .add-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      .box {
        width: 48%;
        display: flex;
        flex-direction: column;
        @include themeify {
          color: themed("primaryColor") !important;
        }
        span {
          margin: 20px 0 10px 0;
          @include themeify {
            color: themed("primaryColor") !important;
          }
        }
        ::v-deep.el-input__inner {
          border: none;
          background: none;
        }
      }
    }

    .add-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      .btn {
        width: 232px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-bottom: 10px;
        background: linear-gradient(90deg, #2670FA 0%, #0c5ffa 100%);
        cursor: pointer;
        @include themeify {
          color: themed("btnText") !important;
        }
      }
      .btn-warp {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 42px;
          border-radius: 4px;
          margin-bottom: 10px;
          background: linear-gradient(90deg, #2670FA 0%, #0c5ffa 100%);
          cursor: pointer;
          @include themeify {
            color: themed("btnText") !important;
          }
        }
        :first-child {
          margin-right: 50px;
          background: linear-gradient(90deg, #87a5cb 0%, #6782a4 100%);
        }
      }
      .no-acc {
        color: #6782a4;
        margin-right: 6px;
      }
      .res {
        cursor: pointer;
        @include themeify {
          color: themed("btnBackGreen") !important;
        }
      }
    }

    input {
      border-color: #e6e5f2;
    }
  }
}
.no-accTwo {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  @include themeify {
    color: #6782a4;
    background: themed("secondaryBackColor") !important;
  }
  img {
    width: 334px;
    height: 207px;
    margin-bottom: 20px;
  }
  .btn {
  }
}
.dialog-warp {
  .inp-warp {
    color: #6782a4;
    margin: 10px 0 10px 0;

    ::v-deep.el-input__inner {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
    ::v-deep.el-input__suffix {
      height: 32px;
      line-height: 32px;
    }

    .title-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .inp-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
    }
    .sel {
      color: #2670FA;
      cursor: pointer;
    }

    .get-code {
      height: 36px;
      line-height: 36px;
      color: #2670FA;
      cursor: pointer;
    }

    .inp {
      margin-top: 10px;
      .getcode {
        color: #2670FA;
        cursor: pointer;
      }
    }
  }
}
.emailStyle {
  /deep/.el-dialog__body {
    padding-top: 0;
  }
}
/deep/.acc-box {
  margin-bottom: 20px;
}
</style>