<template>
  <!-- 划转 -->
  <el-dialog
    :title="$t('m.划转')"
    :visible.sync="transferShow.value == 1"
    :before-close="handleClose"
    width="366px"
    class="transferBox"
    :close-on-click-modal="false"
  >
    <div class="transferItem">
      <span>{{ $t("m.从") }}</span>
      <el-select
        v-model="fromValue"
        @change="fromChange"
        :placeholder="$t('m.请选择')"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div>
      <img src="@/assets/icon/huazuan.svg" alt="" />
    </div>
    <div class="transferItem">
      <span>{{ $t("m.到") }}</span>
      <el-select
        v-model="toValue"
        @change="toChange"
        :placeholder="$t('m.请选择')"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="transferStyle">
      <div>{{ $t("m.币种") }}</div>
      <el-select v-model="coinValue" :placeholder="$t('m.请选择')">
        <el-option
          v-for="item in coinList"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div class="transferStyle">
      <div>{{ $t("m.数量") }}</div>
      <div class="input-box">
        <input
          class="inpStyle"
          type="number"
          :placeholder="$t('m.请输入划转数量')"
          @focus="inputNumberFocus($event)"
          @blur="blurNum($event)"
          v-model.number="numValue"
        />
        <div class="iconBtn">
          <div>{{ coinValue }}</div>
          <div style="margin: 0 6px 4px">|</div>
          <div class="allStyle" @click="allBtn()">{{ $t("m.全部") }}</div>
        </div>
      </div>
      <div class="numStyle">
        {{
          $t("m.可划转") +
          ": " +
          (coinCurrent ? Number(coinCurrent.available) : 0) +
          " " +
          coinValue
        }}
      </div>
    </div>
    <div slot="footer" class="open-footer">
      <div class="closeBtn" @click="handleClose()">{{ $t("m.取消") }}</div>
      <div class="clickBtn" @click="startRobotBtn('open')">
        {{ $t("m.确定") }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { synchronSingelCloudApi, accountTransfer } from "@/request/api";
export default {
  props: { transferShow: {}, apiCurrent: {} },
  data() {
    return {
      options: [], //从
      fromValue: null, //从
      toValue: null, //到
      changeShow: false,
      coinList: ["USDT"], // 币种列表
      coinValue: "USDT", // 当前币种
      coinCurrent: null, // 当前币种信息
      numValue: null, // 数量
      accountAssets: null, // 币币资产
      linerSwapAssets: null, // 合约资产
      spotCoin: 0, //币币
      moneyAssets: null, // 资金账户
    };
  },
  watch: {
    numValue() {
      if (this.numValue.toString().indexOf(".") > -1) {
        this.numValue = this.Public.toMathNum(this.numValue);
        if (isNaN(this.numValue)) {
          this.numValue = 0;
        }
      }
    },
    fromValue() {
      if (this.fromValue == 0) {
        this.coinCurrent = this.accountAssets;
      } else if (this.fromValue == 1) {
        if (
          this.apiCurrent.exchangeName == "OkexV5" ||
          this.apiCurrent.exchangeId == "29"
        ) {
          this.coinCurrent = this.moneyAssets;
        } else {
          this.coinCurrent = this.linerSwapAssets;
        }
      }
    },
  },
  methods: {
    fromChange(v) {
      if (v == 1) {
        this.toValue = 0;
      } else if (v == 0) {
        this.toValue = 1;
      } else if (v == 0) {
        this.toValue = 1;
      } else if (v == 1) {
        this.toValue = 0;
      }
      this.changeShow = true;
    },
    toChange(v) {
      if (v == 1) {
        this.fromValue = 0;
      } else if (v == 0) {
        this.fromValue = 1;
      } else if (v == 0) {
        this.fromValue = 1;
      } else if (v == 1) {
        this.fromValue = 0;
      }
      this.changeShow = true;
    },
    //关闭弹框
    handleClose() {
      this.transferShow.value = 0;
    },
    //全部按钮
    allBtn() {
      this.numValue = Number(this.coinCurrent.available) + "";
    },
    //获取可用币种
    synchronSingelCloudApi() {
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiCurrent.apiId,
      };
      synchronSingelCloudApi(payload).then((res) => {
        if (res.status == 200) {
          let dataList = [];
          for (let item in res.data) {
            dataList = res.data[item];
          }
          if (dataList.isExpire) {
            this.$message.warning(this.$t("m.API已过期,请重新录入"));
            return;
          }
          //币币资产列表
          let accountAssetsList = dataList.accountAssets;
          let accountAssets = null;
          if (accountAssetsList) {
            accountAssetsList.forEach((e) => {
              if (e.currency == "USDT") {
                e.available = this.Public.toMathNum(e.available);
                accountAssets = e;
              }
            });
          }

          this.accountAssets = accountAssets;
          //合约资产列表
          let linerSwapAssetsList = dataList.linerSwapAssets;
          let linerSwapAssets = null;
          if (linerSwapAssetsList) {
            if (
              this.apiCurrent.exchangeName == "OkexV5" ||
              this.apiCurrent.exchangeId == "29"
            ) {
              linerSwapAssetsList[0].available = this.Public.toMathNum(
                linerSwapAssetsList[0].available
              );
              linerSwapAssets = linerSwapAssetsList[0];
            } else {
              linerSwapAssetsList.forEach((e) => {
                if (e.currency == "USDT") {
                  e.available = this.Public.toMathNum(e.available);
                  linerSwapAssets = e;
                }
              });
            }
          }
          this.linerSwapAssets = linerSwapAssets;
          console.log(linerSwapAssets)

          let moneyAssetsList = dataList.moneyAssets;
          let moneyAssets = {};
          if (moneyAssetsList && moneyAssetsList.length !== 0) {
            moneyAssetsList.forEach((e) => {
              if (e.currency == "USDT") {
                e.available = this.Public.toMathNum(e.available);
                moneyAssets = e;
              }
            });
          } else {
            moneyAssets.available = 0;
          }
          this.moneyAssets = moneyAssets;
          this.coinCurrent = this.accountAssets;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //输入框失去焦点
    blurNum(e) {
      if (this.numValue > this.coinCurrent.available) {
        e.target.style.border = "1px solid red";
        this.$message.warning(this.$t("m.划转不能大于可划转金额"));
      } else if (this.numValue <= 1) {
        e.target.style.border = "1px solid red";
        this.$message.warning(this.$t("m.划转需要大于1") + "USDT");
      } else {
        e.target.style.border = "1px solid #DCDFE6";
      }
    },
    inputNumberFocus(e) {
      e.target.style.border = "1px solid #DCDFE6";
    },
    //划转按钮
    startRobotBtn() {
      if (this.numValue > this.coinCurrent.available) {
        return this.$message.warning(this.$t("m.划转不能大于可划转金额"));
      } else if (this.numValue <= 1) {
        return this.$message.warning(this.$t("m.划转需要大于1") + "USDT");
      } else if (isNaN(this.numValue)) {
        this.$message.warning(this.$t("m.请输入正确的数量"));
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        apiId: this.apiCurrent.apiId,
        param: {
          amount: this.numValue,
          currency: "USDT",
          "margin-account": "USDT",
        },
      };
      if (
        this.apiCurrent.exchangeName == "OkexV5" ||
        this.apiCurrent.exchangeId == "29"
      ) {
        payload.param.from = this.fromValue == 1 ? "spot" : "linear-swap";
        payload.param.to = this.fromValue == 1 ? "linear-swap" : "spot";
      } else {
        payload.param.from = this.fromValue == 0 ? "spot" : "linear-swap";
        payload.param.to = this.fromValue == 0 ? "linear-swap" : "spot";
      }

      accountTransfer(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.划转成功"));
          this.transferShow.value = 0;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  mounted() {},
  created() {
    if (
      this.apiCurrent.exchangeId == 4 ||
      this.apiCurrent.exchangeId == 30 ||
      this.apiCurrent.exchangeName == "Binance"
    ) {
      this.options = [
        { name: this.$t("m.现货账户"), value: 0 },
        { name: this.$t("m.合约账户-U本位合约"), value: 1 },
      ];
      this.fromValue = 0;
      this.toValue = 1;
    } else if (
      this.apiCurrent.exchangeId == 29 ||
      this.apiCurrent.exchangeName == "OkexV5"
    ) {
      this.options = [
        { name: this.$t("m.交易账户"), value: 0 },
        { name: this.$t("m.资金账户"), value: 1 },
      ];
      this.fromValue = 0;
      this.toValue = 1;
    }
    this.synchronSingelCloudApi();
  },
};
</script>

<style scoped lang='scss'>
.transferItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-select {
    width: 256px;
  }
}
.transferStyle {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .el-select,
  .el-input {
    margin-top: 10px;
  }
  .input-box {
    position: relative;
    input {
      margin-top: 10px;
    }
    .iconBtn {
      position: absolute;
      right: 18px;
      top: 16px;
      display: flex;
      align-items: center;
      div {
        height: 20px;
        display: flex;
        align-items: center;
      }
      .allStyle {
        color: #2670FA;
        cursor: pointer;
      }
    }
  }
  .numStyle {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: #2670FA;
  }
}
/deep/.el-dialog__body {
  padding: 10px 20px;
}
.open-footer {
  display: flex;
  justify-content: space-between;
  div {
    width: 143px;
    height: 40px;
  }
  .closeBtn {
    border: 1px solid #d0d7df;
    color: #999fb4;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>