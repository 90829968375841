<template>
  <div class="newssearchpc">

    <div class="headpc">
      <div class="row acenter jbetween">

        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/newsHome'}">
          {{$t('m.量化学院')}}
          </el-breadcrumb-item>

          <el-breadcrumb-item>
            {{$t('m.'+title)}}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <RichInput class="searchpc"
        v-model="search"
        :placeholder="$t('m.请输入搜索内容')"
        @enter="searchClick">
          <template #right="{select}">
            <img v-if="select" @click.stop="searchClick"
            class="img-searchpc but" src="@/assets/image/news/sousuo.png">
            
            <img v-else style="opacity:0.3"
            class="img-searchpc" src="@/assets/image/news/sousuo.png">
          </template>
        </RichInput>
      </div>
    </div>
    

    <template v-if="datas && datas.length">

      <div class="listpc">

        <CellNewsPc class="mb40" @click="cellClick"
        v-for="(item, index) in datas" :key="'cell'+index" 
        :item="item" />
        
        <CellNewsPc class="mb40" 
        v-for="(item, index) in kong" :key="'cellno'+index"  />
      </div>
      
      <el-pagination v-if="totalNum > pageSize"
      class="pagpc" 
      :current-page="showPage"
      :page-size="pageSize"
      :total="totalNum"
      layout="prev, pager, next"
      @current-change="pageClick"/>
    </template>

    <el-empty class="emptypc" v-else :description="$t('m.暂无内容')" />
    
  </div>
</template>

<script>
import {mapState} from 'vuex'

import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue";
import RichMenu from "@/components/RichMenuPc.vue";
import CellNewsPc from "./components/cellNewsPc.vue";

export default {
  components: {
    RichInput,
    RichBut,
    RichMenu,
    CellNewsPc
  },

  props: {
    title: String,
    keyWord: String,
    pageNo: Number,
    pageSize: Number,
    totalNum: Number,
    datas: Array,
  },

  watch: {
    keyWord() {
      this.search = this.keyWord;
    },
    pageNo() {
      this.showPage = this.pageNo;
    }
  },

  data() {
    return {
      search: this.keyWord, //搜索关键字
      menu: this.type, //当前菜单
      showPage: this.pageNo, //当前页码
    };
  },

  computed: {
    ...mapState(['lang']),  //语言

    kong() {
      if (!this.datas || !this.datas.length) return 0;

      var num = this.datas.length % 3;
      if (num ==0) return 0;

      return 3 - num;
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    
    /******************************************** 点击 */
    //搜索
    searchClick() {
      this.$emit('search', this.search);
    },
    
    //cell点击
    cellClick(item) {
      this.$emit('cell', item);
    },

    //页码点击
    pageClick(page) {
      this.showPage = page;
      this.$emit('page', page);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.newssearchpc {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 100%;
  
  .headpc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1200px;
    height: 60px;
    margin-bottom: 30px;
    .searchpc {
      width: 209px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 1px solid rgba(38,112,250,0.3);
      margin-left: 40px;
    }
    .img-searchpc {
      width: 18px;
      height: 18px;
      margin-left: 12px;
    }
  }
  
  .listpc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
  }

  
  .pagpc {    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;
    
    .el-pager {
      display: flex;
      align-items: center;
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;

      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
    }
    .active {
      background: #2670FA;
      color: #fff;
    }
  }
  .emptypc {
    margin: 160px 0;
  }
}
</style>
