const NEWS_LIST_TYPE = 'NEWS_LIST_TYPE' //新闻列表类型
const NEWS_LIST_PAGE = 'NEWS_LIST_PAGE' //新闻列表分页

const NEWS_SEARCH_KEY = 'NEWS_SEARCH_KEY' //新闻搜索关键字
const NEWS_SEARCH_PAGE = 'NEWS_SEARCH_PAGE' //新闻搜索分页

const NEWS_INFO_ID = 'NEWS_INFO_ID' //新闻详情ID
const NEWS_INFO_BACK= 'NEWS_INFO_BACK' //新闻详情返回页

export default {

  //******************新闻列表
  //分类
  setType(type) {
    localStorage.setItem(NEWS_LIST_TYPE, type);
  },
  getType() {
    var type = localStorage.getItem(NEWS_LIST_TYPE);
    type = type == undefined ? 0 : Number(type);
    return type;
  },
  //分页
  setPage(page, key='') {
    localStorage.setItem(NEWS_LIST_PAGE+key, page);
  },
  getPage(key='') {
    var page = localStorage.getItem(NEWS_LIST_PAGE+key);
    page = page == undefined ? 1 : Number(page);
    return page;
  },

  //******************新闻搜索
  //关键字
  setSearchKey(key) {
    this.setSearchPage(1, 'Pc');
    this.setSearchPage(1, 'H5');
    localStorage.setItem(NEWS_SEARCH_KEY, key);
  },
  getSearchKey() {
    var key = localStorage.getItem(NEWS_SEARCH_KEY);
    return key || '';
  },
  //分页
  setSearchPage(page, key='') {
    localStorage.setItem(NEWS_SEARCH_PAGE+key, page);
  },
  getSearchPage(key='') {
    var page = localStorage.getItem(NEWS_SEARCH_PAGE+key);
    page = page == undefined ? 1 : Number(page);
    return page;
  },

  //******************新闻详情
  //ID
  setInfoId(id) {
    localStorage.setItem(NEWS_INFO_ID, id);
  },
  getInfoId() {
    var id = localStorage.getItem(NEWS_INFO_ID);
    return Number(id) || '';
  },
  //面包屑返回
  setInfoBack(map) {
    var str = '';
    try {
      str = JSON.stringify(map);
    } catch (error) {}
    localStorage.setItem(NEWS_INFO_BACK, str);
  },
  getInfoBack() {
    var str = localStorage.getItem(NEWS_INFO_BACK);
    try {
      return JSON.parse(str);
    } catch (error) {
      return;
    }
  },
  
  //清空列表缓存
  delectList() {
    
    this.setType(0)

    this.setPage(1, 'Pc')
    this.setPage(1, 'H5')
  }
}