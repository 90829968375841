import Vue from 'vue'
import router from "../router/index";
import { Message } from 'element-ui'

const publicData = {

    // 机器人名称
    strategyInput: {
        value: "DCA Bot",
        placeholder: "DCA Bot",
        name: "机器人名称",
        enName: "Bot Name",
    },
    // 交易方向
    direction: {
        radioName: ["做多", "做空", "双向"],
        enRadioName: ["Long", "Short", "Two Side"],
        enName: "TradeDirection",
        value: 0,
        name: "交易方向",
    },
    // 保证金模式
    MarginMode: {
        radioName: ["全仓", "逐仓"],
        enRadioName: ["Cross", "Isolated"],
        name: "保证金模式",
        value: 0,
        enName: "MarginMode",
    },
    // 最大初始保证金
    maxBond: {
        value: null,
        name: "最大初始保证金",
        enName: "Maximun inicial margin",
        inpUnit: "currency",
        placeholder: "选填",
        enPlaceholder: "optional",
        valueMin: 1,
        valueMax: "Infinity",
        type: "number",
        enExplain:
            "Limit the max initial margin occupied by the robot in each round of trading. If it is expected that the total initial margin occupied after the next opening will exceed this value, the opening entrustment will be suspended and manual orders will not be limited.",
        explain:
            "限制机器人开仓最大占用的初始保证金。如预计下次开仓后总占用初始保证金将超过此值，则暂停开仓委托，不限制手动下单。如未设置，则不做占用限制，机器人将滚动投入资金。机器人在运行时不会冻结账户资产，您还可以进行其他交易，此时存在共用保证金的情况，请务必保留充足的保证金以防止爆仓。",
    },
    // 收益止盈
    profitSurplus: {
        value: null,
        placeholder: "选填",
        enPlaceholder: "optional",
        name: "收益止盈",
        enName: "TakeProfit",
        inpUnit: "currency",
        valueMin: 0,
        valueMax: "Infinity",
        explain: "执行实例的总收益大于等于此值时，机器人终止运行。",
        enExplain:
            "When the total revenue is greater than or equal to this value, the robot terminates.",
        type: "number",
        pairUsdtSize: null,
    },
    // 收益止损
    profitLoss: {
        value: null,
        placeholder: "选填",
        enPlaceholder: "optional",
        name: "收益止损",
        enName: "StopLoss",
        inpUnit: "currency",
        valueMin: 0,
        valueMax: "Infinity",
        explain: "执行实例的总收益小于等于此值时，机器人终止运行。",
        enExplain:
            "When the total revenue is less than or equal to this value, the robot terminates.",
        type: "number",
        pairUsdtSize: null,
    },

    // 机器人触发价
    triggerModel: {
        value: null,
        placeholder: "选填",
        enPlaceholder: "optional",
        name: "机器人触发价",
        enName: "Trigger price",
        inpUnit: "currency",
        valueMin: 0,
        valueMax: "Infinity",
        type: "number",
        explain:
            "当行情价穿过此价格时触发机器人运行，已触发的机器人修改此参数不生效。",
        enExplain:
            "When the price passes through this price, the robot will be triggered to run. The triggered robot will not modify this parameter.",
        pairUsdtSize: null,
    },

    transferShow: {
        value: 0,
    }, //划转页面显隐
    leverVisible: {
        value: 0,
    }, // 杠杆弹框
    checkboxCancel: true, // 终止时自动撤销委托
    checkboxClose: true, // 终止时自动平仓
}


export default publicData