<template>
  <div class="personal-box">
    <TopNavigation ref="child" />
    <div class="personal" v-if="accInfo">
      
      <div class="box">
        <div class="title">{{ $t("m.账户设置") }}</div>
        <div class="listItem">
          <div>UID</div>
          <div class="textColor">{{ uid }}</div>
        </div>
        <div class="listItem">
          <div>{{ $t("m.昵称") }}</div>
          <div class="item-name">
            <span class="textColor">{{
              nickName ? nickName : $t("m.未设置昵称")
            }}</span>
            <img src="../../assets/icon/bianji.svg" alt="" @click="open()" />
          </div>
        </div>

        <div class="listItem">
          <div style="display: flex; align-items: center">
            {{ $t("m.头像") }}
          </div>
          <!-- http://159.138.104.103:8797/VtradingPc http://192.168.2.36:8066/user/uploadAvatar -->
          <!-- http://159.138.104.103:8797/VtradingPc/user/uploadAvatar  测试 -->
          <!-- https://vtrading.com   正式 -->
          <el-upload
            class="avatar-uploader"
            action="https://vtrading.com/api/user/uploadAvatar"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="avatar" class="imgStyle" :src="avatar" alt="" />
            <img src="@/assets/image/avatar.svg" class="imgStyle" v-else />
            <!-- <div v-else class="imgStyle">
              {{ nickName ? nickName.slice(0, 1).toUpperCase() : null }}
            </div> -->
          </el-upload>
          
        </div>

        <div class="listItem">
          <span>{{ $t("m.邀请码") }}</span>
          <div style="display: flex; align-items: center">
            <span
              v-if="accInfo.isEmailCheck == '0' && email && inviteUserCode"
              style="margin-right: 5px"
            >
              {{ inviteUserCode }}
            </span>
            <div class="clickBtn whiteBackground green modifyBtn">
              <span v-if="!inviteUserCode" @click="bindCode()">
                {{ $t("m.绑定") }}
              </span>
              <span
                v-if="inviteUserCode"
                :class="
                  accInfo.isEmailCheck == '0' && email && inviteUserCode
                    ? 'green'
                    : 'textColor'
                "
              >
                {{
                  accInfo.isEmailCheck == "0" && email && inviteUserCode
                    ? $t("m.未生效")
                    : inviteUserCode
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="clickBtn mt30">
          <span @click="logout()">
            {{ $t("m.退出") }}
          </span>
        </div>
      </div>

      <div class="box">
        <div class="title">{{ $t("m.安全设置") }}</div>
        <div class="listItem">
          <div>{{ $t("m.密码") }}</div>
          <div
            class="clickBtn whiteBackground green modifyBtn"
            @click="updateIn('修改密码')"
          >
            {{ $t("m.修改") }}
          </div>
        </div>
        <div class="listItem">
          <div>{{ $t("m.谷歌验证器") }}</div>
          <div class="btn-warp">
            <!-- <span v-if="!isBandGa">{{ $t("m.未绑定") }}</span> -->
            <span
              class="clickBtn whiteBackground green modifyBtn"
              @click="toGa()"
              v-if="!isBandGa"
            >
              {{ $t("m.绑定") }}
            </span>
            <span
              v-if="isBandGa"
              class="clickBtn whiteBackground green modifyBtn"
              @click="updateIn('GA解绑')"
            >
              {{ $t("m.解绑") }}
            </span>
          </div>
        </div>
        <div class="listItem">
          <div>{{ $t("m.邮箱") }}</div>
          <div class="btn-warp">
            <span style="margin-right: 20px" class="textColor">
              {{ accInfo && accInfo.email ? accInfo.email : "--" }}
            </span>
            <span
              class="clickBtn whiteBackground green modifyBtn"
              @click="
                dialogVisible = true;
                verification = '邮箱验证';
              "
              v-if="accInfo.isEmailCheck == '0'"
            >
              {{ email ? $t("m.验证") : $t("m.绑定") }}
            </span>
            <span
              class="clickBtn whiteBackground green modifyBtn"
              @click="updateIn('邮箱换绑')"
              v-else
            >
              {{ $t("m.换绑") }}
            </span>
          </div>
        </div>
        <div class="listItem">
          <div>{{ $t("m.登录验证") }}</div>
          <el-switch
            v-model="loginCheck"
            active-color="#13ce66"
            @change="isCheck"
          >
          </el-switch>
        </div>

        <div class="listItem">
          <div>{{ $t("m.账号绑定") }}</div>
          <div class="band-warp">
            <!-- <div class="ga">
              <img src="@/assets/icon/google.svg" alt="" />
              <span class="btn">{{ $t("m.绑定") }}</span>
            </div> -->
            <div class="vt" @click="toVtrading()">
              <img src="@/assets/icon/vt.svg" alt="" />
              <span class="btn" v-if="!vtToken">{{ $t("m.绑定") }}</span>
              <span class="btn" v-else>{{ $t("m.解除绑定") }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="title">{{ $t("m.登录设备") }}</div>
        <div class="listBox">
          <div
          class="listItem"
          v-for="(item, idx) in loginEquipmentData"
          :key="idx">
            <template v-if="item">
              <div>
                <div>
                  <div class="flexStyle">
                    <span class="textColor">{{ item.osName }}</span>
                    <span v-if="token == item.token" style="margin-left: 4px">
                      {{ $t("m.当前设备") }}
                    </span>
                  </div>
                  <div class="flexStyle">
                    <span class="textColor">{{
                      item.ip
                        ? item.ip
                        : "2022年1月1日·中国香港 九龙湾"
                    }}</span>
                    <el-tooltip effect="light" placement="bottom-end">
                      <div slot="content" class="slotBox">
                        <div class="slotIcon"></div>
                        <div style="margin-right: 5px">
                          {{ $t("m.设备名") + "：" + item.browser }}
                        </div>
                        <div style="margin: 5px 5px 5px 0">
                          {{
                            $t("m.最近登录时间") +
                            "：" +
                            moment(Number(item.loginTime)).format(
                              "YYYY/MM/DD HH:mm"
                            )
                          }}
                        </div>
                        <div>{{ $t("m.IP地址") + "：" + item.ip }}</div>
                      </div>
                      <div>
                        <img class="icon" src="@/assets/icon/icon01.png" alt="" />
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div
                v-if="token !== item.token"
                @click="deleteLogin(item)"
                class="removeList">
                <img
                  style="width: 8px; height: 8px"
                  src="@/assets/icon/detele.png"
                  alt=""/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 邮箱绑定/安全验证 -->
    <el-dialog
      :title="
        accInfo && accInfo.isEmailCheck == '1'
          ? $t('m.安全验证')
          : email
          ? $t('m.邮箱验证')
          : $t('m.邮箱绑定')
      "
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <!-- 邮箱绑定 -->
      <template v-if="accInfo && accInfo.isEmailCheck == '0'">
        <div class="dialog-warp">
          <div class="inp-warp">
            <span class="inp-title">
              <span>{{ email ? $t("m.邮箱验证") : $t("m.邮箱绑定") }}</span>
            </span>
          </div>
          <el-input
            style="margin-bottom: 10px"
            v-if="!email"
            v-model="emailValue"
            :placeholder="$t('m.请输入邮箱')"
          ></el-input>
          <div class="inp-warp">
            <el-input
              :placeholder="$t('m.请输入邮箱验证码')"
              v-model="emailCode"
            >
              <i slot="suffix">
                <div class="get-code" @click="getCode()" v-if="num == 0">
                  {{ $t("m.获取验证码") }}
                </div>
                <div class="get-code" v-else>
                  <span>{{ num }}</span>
                  <span>{{ " s " }}</span>
                </div>
              </i>
            </el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            style="width: 100%"
            @click="verificationEmail('bindEmail')"
            class="btn"
          >
            {{ $t("m.提交") }}
          </el-button>
        </span>
      </template>

      <!-- 安全验证 -->
      <template v-if="accInfo && accInfo.isEmailCheck == '1'">
        <div class="dialog-warp">
          <div class="inp-warp">
            <span class="inp-title">
              <span>
                {{
                  verification == "谷歌验证"
                    ? $t("m.谷歌验证")
                    : $t("m.邮箱验证")
                }}
              </span>
              <el-dropdown trigger="click" v-if="isBandGa">
                <span class="el-dropdown-link" style="cursor: pointer">
                  {{ $t("m.切换验证方式") }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="verification = '谷歌验证'"
                    v-if="isBandGa"
                  >
                    {{ $t("m.谷歌验证") }}
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="verification = '邮箱验证'">
                    {{ $t("m.邮箱验证") }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
          <div class="inp-warp" v-if="verification == '邮箱验证'">
            <el-input
              :placeholder="$t('m.请输入邮箱验证码')"
              v-model="emailCode"
            >
              <i slot="suffix">
                <div class="get-code" @click="getCode()" v-if="num == 0">
                  {{ $t("m.获取验证码") }}
                </div>
                <div class="get-code" v-else>
                  <span>{{ num }}</span>
                  <span>{{ " s " }}</span>
                </div>
              </i>
            </el-input>
          </div>

          <div class="inp-warp" v-if="isBandGa && verification == '谷歌验证'">
            <el-input
              v-model="gaCode"
              :placeholder="$t('m.请输入谷歌验证码')"
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="verificationEmail()"
            style="width: 100%"
            class="btn"
          >
            {{ $t("m.提交") }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改密码/换绑邮箱 -->
    <el-dialog
      :title="clitext"
      :visible.sync="dialogVisible1"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <!-- 修改密码 -->
      <template v-if="clitext == '修改密码'">
        <div class="dialog-warp">
          <div class="inp-warp">
            <span class="inp-title">
              <span>{{ $t("m.新密码") }}</span>
            </span>
            <el-input
              type="password"
              show-password
              :placeholder="$t('m.请输入密码,字母加数字组合6-32位')"
              v-model="password"
            ></el-input>
          </div>
          <div class="inp-warp">
            <span class="inp-title">
              <span>{{ $t("m.重复新密码") }}</span>
            </span>
            <el-input
              :placeholder="$t('m.再次输入新密码')"
              v-model="againPassword"
              type="password"
              show-password
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            style="width: 100%"
            @click="updateUser('修改密码')"
            class="btn"
          >
            {{ $t("m.提交") }}
          </el-button>
        </span>
      </template>

      <!-- 换绑邮箱 -->
      <template v-if="clitext == '邮箱换绑'">
        <template>
          <div class="dialog-warp">
            <div class="inp-warp">
              <span class="inp-title">
                <span>{{ $t("m.新邮箱") }}</span>
              </span>
              <el-input
                :placeholder="$t('m.请输入新邮箱')"
                v-model="newEmail"
              ></el-input>
            </div>
            <!-- <div class="inp-warp">
              <span class="inp-title">
                <span>{{ $t("m.重复新邮箱") }}</span>
              </span>
              <el-input
                placeholder="再次输入邮箱"
                v-model="againNewEmail"
              ></el-input>
            </div> -->
            <div class="inp-warp">
              <span class="inp-title">
                <span> {{ $t("m.邮箱验证") }} </span>
              </span>
            </div>
            <div class="inp-warp">
              <el-input
                :placeholder="$t('m.请输入邮箱验证码')"
                v-model="emailCode"
              >
                <i slot="suffix">
                  <div class="get-code" @click="getCode('new')" v-if="num == 0">
                    {{ $t("m.获取验证码") }}
                  </div>
                  <div class="get-code" v-else>
                    <span>{{ num }}</span>
                    <span>{{ " s " }}</span>
                  </div>
                </i>
              </el-input>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="updateUser('换绑邮箱')"
              class="btn"
              style="width: 100%"
            >
              {{ $t("m.提交") }}
            </el-button>
          </span>
        </template>
      </template>
    </el-dialog>

    <!-- vtrading 二维码 -->
    <el-dialog
      :title="$t('m.绑定') + ' Vtrading'"
      :visible.sync="vtradingShow"
      :before-close="handleClose"
      width="366px"
      class="login-codeBox"
      :close-on-click-modal="false"
    >
      <div class="login-box">
        <div class="login-small-box">
          <div class="code-warp">
            <div class="qr-code">
              <div id="qrCode" ref="qrCodeDiv" class="code"></div>
              <div class="noCodeBox" v-if="codeShow">
                <div class="warp" @click="updateCode()">
                  <img src="@/assets/icon/shuaxin.svg" alt="" />
                  <span class="tips">{{ $t("m.刷新二维码") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="validity">
            <span>{{ $t("m.二维码有效期") }}</span>
            <span class="num">{{ num }}</span>
            <span>s</span>
          </div>
          <div class="tips">
            <span>{{ $t("m.打开 VtradingAPP 扫一扫登录") }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 解除绑定Vtrading -->
    <el-dialog
      :title="$t('m.解除绑定') + 'Vtrading'"
      :visible.sync="unboundVtShow"
      width="366px"
      class="openBox"
      :show-close="false"
    >
      <span>{{ $t("m.是否解除绑定Vtrading账户") + "？" }}</span>
      <div slot="footer" class="open-footer">
        <div class="closeBtn" @click="handleClose()">{{ $t("m.取消") }}</div>
        <div class="clickBtn" @click="unboundVt()">{{ $t("m.确定") }}</div>
      </div>
    </el-dialog>

    <!-- 双重验证 -->
    <el-dialog
      :title="doubleText == '1' && isBandGa ? $t('m.GA验证') : $t('m.邮箱验证')"
      :visible.sync="doubleVerification"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <template>
        <div class="dialog-warp">
          <div class="inp-warp">
            <span class="inp-title">
              <span>
                {{
                  doubleText == "1" && isBandGa
                    ? $t("m.GA验证")
                    : $t("m.邮箱验证")
                }}
              </span>
            </span>
          </div>
          <div class="inp-warp" v-if="doubleText == 0">
            <el-input
              :placeholder="$t('m.请输入邮箱验证码')"
              v-model="emailCode"
            >
              <i slot="suffix">
                <div class="get-code" @click="getCode()" v-if="num == 0">
                  {{ $t("m.获取验证码") }}
                </div>
                <div class="get-code" v-else>
                  <span>{{ num }}</span>
                  <span>{{ " s " }}</span>
                </div>
              </i>
            </el-input>
          </div>

          <div class="inp-warp" v-if="isBandGa && doubleText == 1">
            <el-input
              v-model="gaCode"
              :placeholder="$t('m.请输入谷歌验证码')"
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="dobeVerification()"
            style="width: 100%"
            class="btn"
          >
            {{ $t("m.提交") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
const $ = window.$;
import QRCode from "qrcodejs2";
import moment from "moment";
import TopNavigation from "@/components/TopNavigation.vue";
import InputIconBox from "@/components/InputIconBox.vue";

import {
  updateUser,
  loginEquipmentList,
  logout,
  userDetail,
  getUserGAIsBind,
  sendEmail,
  isEmailCheck,
  checkCode,
  checkGACode,
  getTemporaryToken,
  scanCodeLogin,
  unboundVt,
  unbindVt,
  userUnbindGA,
  deleteLogin,
  bindingEmail,
} from "@/request/api";
export default {
  components: {
    TopNavigation,
    InputIconBox,
  },
  data() {
    return {
      moment,
      loginCheck: false,
      accInfo: null,
      loginEquipmentData: null,
      token: localStorage.getItem("token"),

      uid: null,
      avatar: null,
      nickName: null,
      inviteUserCode: null, // 是否绑定邀请码

      isBandGa: false, //是否绑定GA

      dialogVisible: false, //邮箱绑定/安全验证

      clitext: null,
      dialogVisible1: false, //  修改密码/换绑邮箱
      input: null,
      verification: "谷歌验证",
      emailCode: null, // 邮箱验证码
      gaCode: null, //ga 验证码

      num: 0,

      password: null, //新密码
      againPassword: null, //二次密码

      isCheckEmail: false, //换绑邮箱验证通过
      newEmail: null, //新邮箱
      againNewEmail: null,
      vtradingShow: false, //vtrading二维码弹框
      codeShow: false,
      vtToken: null, //是否绑定了Vtrading
      unboundVtShow: false, //解除绑定Vtraidng
      isCheckShow: false, //判断是否验证了邮箱,验证失败不让关闭登录验证
      email: null,
      emailValue: null,
      doubleText: 0, // 邮箱验证
      doubleVerification: false,
      bindCodeShow: false, // 验证码验证
    };
  },
  methods: {
    //转跳到邀请页
    goInvite() {
      this.$router.push("/inviteFriend");
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.password = null;
      this.againPassword = null;
      this.newEmail = null;
      this.againNewEmail = null;
      this.emailCode = null;
      this.gaCode = null;
      this.newEmail = null;
      this.againNewEmail = null;
      this.vtradingShow = false;
      this.unboundVtShow = false;
      this.num = 0;
      this.isCheckEmail = false;
      this.clitext = null;
      this.doubleText = 0;
      this.doubleVerification = false;
      if (this.isCheckShow) {
        this.loginCheck = true;
        this.isCheckShow = false;
      }
    },

    // 修改昵称
    open() {
      let _this = this;
      this.$prompt(this.$t("m.修改昵称"), {
        confirmButtonText: this.$t("m.确定"),
        closeOnClickModal: false,
        inputValidator(value) {
          if (!value) {
            return _this.$t("m.请输入昵称");
          }
          value = value.trim();
          let strlenght = 0;
          var regexCh = /[u00-uff]/;
          for (var i = 0; i < value.length; i++) {
            if (!regexCh.test(value.charAt(i))) {
              strlenght = strlenght + 2; //中文为2个字符
            } else {
              strlenght = strlenght + 1; //英文一个字符
            }
          }
          if (strlenght > 16) {
            return _this.$t("m.请输入1-16位昵称");
          }
        },
        inputErrorMessage: _this.$t("m.请输入昵称"),
        inputValue: this.nickName,
      })
        .then(({ value }) => {
          let pay = {
            nickName: value,
          };
          this.updateUser(pay);
        })
        .catch(() => {});
    },

    //退出登录
    logout() {
      let payload = {
        token: localStorage.getItem("token"),
        userId: this.accInfo.userId,
      };
      logout(payload).then((res) => {
        this.$message.success(this.$t("m.退出成功"));
        this.$router.push({ path: "/login" });
        this.$store.commit("clearSession", null);
      });
    },

    // 绑定邀请码
    bindCode() {
      if (this.accInfo.isEmailCheck == "0" && this.email) {
        this.$message.warning(this.$t("m.请先进行邮箱验证,再绑定邀请码"));
        this.bindCodeShow = true;
        this.dialogVisible = true;
        this.verification = "邮箱验证";
        return;
      }
      this.$prompt(
        this.$t(
          "m.邀请码仅在注册后15天内可绑定, 且绑定后不可修改, 请确认填写的邀请码是否正确"
        ),
        this.$t("m.绑定邀请码"),
        {
          confirmButtonText: this.$t("m.确定"),
          inputValidator(value) {
            if (!value) {
              return "请输入邀请码";
            }
          },
          inputErrorMessage: this.$t("m.请输入邀请码"),
        }
      )
        .then(({ value }) => {
          if (value == this.accInfo.inviteCode) {
            this.$message.error(this.$t("m.不能绑定自己的邀请码"));
            return;
          }
          let pay = {
            inviteUserCode: value,
          };
          this.updateUser(pay);
        })
        .catch(() => {});
    },

    // 查询用户信息
    userDetail() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      userDetail(payload).then((res) => {
        if (res.status == 200) {
          let user = res.data.user;
          this.accInfo = res.data.user;
          this.nickName = user.nickName;
          this.avatar = user.avatar;
          let uuid = user.uuid;
          uuid = "00000000" + uuid;
          uuid = uuid.slice(uuid.length - 8, uuid.length);
          this.uid = uuid;
          this.loginCheck = user.loginCheck;
          let vtToken = user.vtToken;
          this.vtToken = vtToken;
          this.email = user.email;
          this.inviteUserCode = user.inviteUserCode;
          // localStorage.setItem("accInfo", JSON.stringify(user));
          localStorage.setItem("accInfo", JSON.stringify(this.accInfo));
          this.$refs.child.intiData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 上传图片成功
    handleAvatarSuccess(res, file) {
      let avatar = res.data.avatarUrl;
      let payload = {
        avatar: avatar,
      };
      this.updateUser(payload);
    },

    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isShow =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isShow) {
        this.$message.error(
          this.$t("m.上传头像图片只能是 JPG、PNG、JPEG 格式") + "!"
        );
      }
      if (!isLt2M && isShow) {
        this.$message.error(this.$t("m.上传头像图片大小不能超过 2MB") + "!");
      }
      // return isJPG && isLt2M;

      return isShow && isLt2M;
    },

    // 修改用户信息
    updateUser(payload) {
      if (payload == "修改密码") {
        // var reg = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
        var reg = /^(?=.*\d)(?=.*[A-Za-z])[\x20-\x7e]{6,32}$/;
        // var reg =/^(?=.*\d)(?=.*[A-Za-z])[\x20-\x7e]{8,16}$/
        // var reg=/^(?=.*?[a-zA-Z])(?=.*?[0-9])[!-~]{6,20}$/
        if (!this.password) {
          this.$message.error(this.$t("m.请输入新密码"));
          return;
        } else if (this.password.match(reg) == null) {
          this.$message.error(this.$t("m.密码必须包含字母和数字，长度6-32位"));
          return;
        } else if (!this.againPassword) {
          this.$message.error(this.$t("m.请再次输入新密码"));
        } else if (this.password != this.againPassword) {
          this.$message.error(this.$t("m.两次密码输入不一致"));
          return;
        } else {
          let payload = {
            password: this.$md5(this.password),
            token: localStorage.getItem("token"),
          };
          updateUser(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t("m.更新成功"));
              this.userDetail();
              this.handleClose();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      } else if (payload == "换绑邮箱") {
        var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
        if (!this.newEmail) {
          this.$message.error(this.$t("m.请输入新邮箱"));
          return;
        } else if (this.newEmail.match(reg) == null) {
          this.$message.error(this.$t("m.邮箱格式不正确"));
          return;
        }
        //  else if (!this.againNewEmail) {
        //   this.$message.error(this.$t("m.请再次输入新邮箱"));
        // } else if (this.newEmail != this.againNewEmail) {
        //   this.$message.error(this.$t("m.两次邮箱输入不一致"));
        //   return;
        // }
        else if (!this.emailCode) {
          this.$message.error(this.$t("m.请输入邮箱验证码"));
          return;
        } else {
          let payload = {
            email: this.newEmail,
            token: localStorage.getItem("token"),
          };
          let params = {
            token: localStorage.getItem("token"),
            email: this.newEmail,
            valicode: this.emailCode,
          };
          checkCode(params).then((res) => {
            if (res.status == 200) {
              bindingEmail(payload).then((res) => {
                if (res.status == 200) {
                  this.$message.success(this.$t("m.绑定成功"));
                  this.userDetail();
                  this.handleClose();
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      } else {
        payload.token = localStorage.getItem("token");
        updateUser(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t("m.更新成功"));
            if (this.bindCodeShow && !payload.inviteUserCode) {
              this.bindCode();
            }
            this.userDetail();
            this.handleClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    //获取登录设备
    loginEquipmentList() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      loginEquipmentList(payload).then((res) => {
        if (res.status == 200) {
          let list = JSON.parse(JSON.stringify(res.data.loginEquipmentList));
          let item;
          for (let i = 0; i < list.length; i++) {
            if (this.token == list[i].token) {
              item = list[i];
              list.splice(i, 1);
            }
          }

          this.loginEquipmentData = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //删除登录设备信息
    deleteLogin(item) {
      let payload = {
        token: localStorage.getItem("token"),
        delUserId: item.userId,
        delToken: item.token,
      };
      deleteLogin(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.删除成功"));
          this.loginEquipmentList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 判断是否绑定GA
    getUserGAIsBind() {
      if (!JSON.parse(localStorage.getItem("accInfo")).email) {
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        email: JSON.parse(localStorage.getItem("accInfo")).email,
      };
      getUserGAIsBind(payload).then((res) => {
        if (res.status == 200) {
          this.isBandGa = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //登录验证启用/禁用
    isCheck(v) {
      this.verification = "邮箱验证";
      if (this.accInfo.isEmailCheck == "0") {
        this.$message.warning(this.$t("m.邮箱验证未完成，请先完成邮箱验证"));
        this.loginCheck = false;
        this.dialogVisible = true;
        return;
      }
      if (!v) {
        this.dialogVisible = true;
        this.clitext = "关闭登录验证";
        this.isCheckShow = true;
      } else {
        if (this.isBandGa || this.accInfo.isEmailCheck) {
          let payload = {
            loginCheck: "1",
          };
          this.updateUser(payload);
        } else {
          this.$message.error(
            this.$t("m.账户未验证邮箱/绑定GA，请先验证/绑定")
          );
        }
      }
    },

    // 发送邮箱验证码
    getCode(v) {
      let payload = {
        token: localStorage.getItem("token"),
      };
      var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
      if (v) {
        if (!this.newEmail) {
          return this.$message.warning(this.$t("m.请输入新邮箱"));
        } else {
          var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
          if (this.newEmail.match(reg) == null) {
            this.$message.error(this.$t("m.邮箱格式不正确"));
            return;
          }
          payload.email = this.newEmail;
        }
      } else if (!this.email) {
        if (!this.emailValue) {
          return this.$message.warning(this.$t("m.请输入邮箱"));
        } else {
          if (this.emailValue.match(reg) == null) {
            this.$message.error(this.$t("m.邮箱格式不正确"));
            return;
          }
          payload.email = this.emailValue;
        }
      } else {
        payload.email = this.accInfo.email;
      }
      sendEmail(payload).then((res) => {
        if (res.status == 200) {
          this.num = 60;
          this.countDownTwo();
          this.$message.success(this.$t("m.发送成功"));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 倒计时
    countDownTwo() {
      var down = () => {
        if (this.num > 0) {
          this.num--;
          setTimeout(down, 1000);
        }
      };
      setTimeout(down, 1000);
    },
    // 绑定邮箱/
    verificationEmail(v) {
      //邮箱第一次绑定
      if (v == "bindEmail") {
        if (!this.emailCode) {
          this.$message.warning(this.$t("m.请输入邮箱验证码"));
          return;
        }
        let payload = {
          token: localStorage.getItem("token"),
          valicode: this.emailCode,
        };
        // 验证邮箱
        if (this.accInfo.email) {
          payload.email = this.accInfo.email;
          isEmailCheck(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t("m.验证成功"));
              this.userDetail();
              this.handleClose();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          // 绑定邮箱
          payload.email = this.emailValue;
          bindingEmail(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t("m.绑定成功"));

              let payload = {
                email: this.emailValue,
                token: localStorage.getItem("token"),
              };
              this.updateUser(payload);

              this.handleClose();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      } else {
        // 安全验证
        if (this.verification == "谷歌验证") {
          if (!this.gaCode) {
            this.$message.warning(this.$t("m.请输入谷歌验证码"));
            return;
          }
          let payload = {
            token: localStorage.getItem("token"),
            gaCode: this.gaCode,
          };
          checkGACode(payload).then((res) => {
            if (res.status == 200) {
              // this.$message.success("验证成功");
              if (this.clitext == "修改密码") {
                this.dialogVisible1 = true;
              } else if (this.clitext == "关闭登录验证") {
                let payload = {
                  loginCheck: "0",
                };
                this.updateUser(payload);
              } else if (this.clitext == "GA解绑") {
                this.unboundGa();
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          let payload = {
            token: localStorage.getItem("token"),
            email: this.accInfo.email,
            valicode: this.emailCode,
          };
          checkCode(payload).then((res) => {
            if (res.status == 200) {
              // this.$message.success("验证成功");
              if (this.clitext == "修改密码") {
                this.dialogVisible1 = true;
              } else if (this.clitext == "关闭登录验证") {
                let payload = {
                  loginCheck: "0",
                };
                this.updateUser(payload);
              } else if (this.clitext == "GA解绑") {
                this.unboundGa();
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }
    },

    toGa() {
      if (this.accInfo.isEmailCheck == "0") {
        this.$message.warning(this.$t("m.邮箱验证未完成，请先完成邮箱验证"));
        this.dialogVisible = true;
        return;
      }
      this.$router.push({ path: "GAVerification" });
    },

    //操作类型
    updateIn(v) {
      this.verification = "邮箱验证";
      if (this.accInfo.isEmailCheck == "0") {
        // this.$message.warning("邮箱验证未完成，请先完成邮箱验证");
        this.dialogVisible = true;
        return;
      }
      this.clitext = v;
      if (v == "邮箱换绑") {
        this.doubleVerification = true;
        return;
      }
      this.dialogVisible = true;
    },

    // 双重验证
    dobeVerification() {
      if (this.doubleText == 0) {
        let payload = {
          token: localStorage.getItem("token"),
          email: this.accInfo.email,
          valicode: this.emailCode,
        };
        checkCode(payload).then((res) => {
          if (res.status == 200) {
            this.num = 0;
            this.emailCode = null;
            this.$message.success(this.$t("m.验证成功"));
            if (!this.isBandGa) {
              this.doubleVerification = false;
              this.dialogVisible1 = true;
              this.clitext = "邮箱换绑";
            } else {
              this.doubleText = 1;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.doubleText == 1 && this.isBandGa) {
        let payload = {
          token: localStorage.getItem("token"),
          gaCode: this.gaCode,
        };
        checkGACode(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t("m.验证成功"));
            this.doubleVerification = false;
            this.dialogVisible1 = true;
            this.clitext = "邮箱换绑";
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    // 校验邮箱验证码
    // checkCode() {
    //   let payload = {
    //     token: localStorage.getItem("token"),
    //     email: this.accInfo.email,
    //     valicode: this.emailCode,
    //   };
    //   checkCode(payload).then((res) => {
    //     if (res.status == 200) {
    //       this.isCheckEmail = true;
    //       this.getUserGAIsBind();
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    // 解除绑定Vtrading
    unboundVt() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      unboundVt(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.操作成功"));
          this.userDetail();
          this.unboundVtShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // vtrading 登录
    toVtrading() {
      this.verification = "邮箱验证";
      if (this.accInfo.isEmailCheck == "0") {
        // this.$message.warning("邮箱验证未完成，请先完成邮箱验证");
        this.dialogVisible = true;
        return;
      }
      if (this.vtToken) {
        this.unboundVtShow = true;
      } else {
        this.vtradingShow = true;
        this.countDown();
        getTemporaryToken().then((res) => {
          if (res.status == 200) {
            this.temporaryToken = res.data.temporaryToken;
            localStorage.setItem("temporaryToken", this.temporaryToken);
            this.num = 90;
            if (this.qrcode) {
              this.qrcode.makeCode(JSON.stringify(res.data));
              this.qrcode._el.title = "";
            } else {
              this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
                text: JSON.stringify(res.data),
                width: 160,
                height: 160,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
              });
              this.qrcode._el.title = "";
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    // 刷新二维码
    updateCode() {
      this.token = null;
      this.account = null;
      this.temporaryToken = null;
      this.codeShow = false;
      this.toVtrading();
    },

    countDown() {
      var down = () => {
        if (this.num > 0 && this.vtradingShow) {
          this.num--;
          this.unbindVt();
          setTimeout(down, 2000);
        } else {
          // this.expired = true;
          if (!this.vtradingShow) {
            clearTimeout(down);
          } else {
            this.codeShow = true;
          }
        }
      };
      setTimeout(down, 2000);
    },
    unbindVt() {
      if (!this.temporaryToken) {
        return;
      }
      let payload = {
        temporaryToken: this.temporaryToken,
        token: localStorage.getItem("token"),
      };
      unbindVt(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.state == true) {
            this.num = 0;
            this.$message.success(this.$t("m.绑定成功"));
            this.vtradingShow = false;
            this.userDetail();
          }
        } else {
          if (res.state == false) {
            return;
          }
          this.$message.error(res.msg);
          this.vtradingShow = false;
        }
      });
    },

    // 解绑Ga
    unboundGa() {
      let payload = {
        token: localStorage.getItem("token"),
      };
      userUnbindGA(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.解绑成功"));
          this.getUserGAIsBind();
          this.userDetail();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });

      // this.$prompt("GA解绑", {
      //   confirmButtonText: this.$t("m.确定"),
      //   inputValidator(value) {
      //     if (!value) {
      //       return "请输入谷歌验证码";
      //     }
      //   },
      //   inputErrorMessage: "请输入谷歌验证码",
      // })
      //   .then(({ value }) => {
      //     let payload = {
      //       token: localStorage.getItem("token"),
      //     };
      //     userUnbindGA(payload).then((res) => {
      //       if (res.status == 200) {
      //         this.$message.success("解绑成功");
      //         this.getUserGAIsBind();
      //         this.userDetail();
      //       } else {
      //         this.$message.error(res.msg);
      //       }
      //     });
      //   })
      //   .catch(() => {});
    },
    // open() {
    //   this.$alert("为了您的账号安全,请进行安全验证", "标题名称", {
    //     confirmButtonText: "确定",
    //     callback: (action) => {
    //       this.$message({
    //         type: "info",
    //         message: `action: ${action}`,
    //       });
    //     },
    //   });
    // },
  },
  mounted() {
    // let accInfo = JSON.parse(localStorage.getItem("accInfo"));
    // this.accInfo = accInfo;
    this.loginEquipmentList();
    this.userDetail();
    this.getUserGAIsBind();
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.personal-box {
  width: 100%;
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
}
.personal {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .box {
    width: 380px;
    height: 390px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
      box-shadow: themed("shadow") !important;
    }
    border-radius: 8px;
    margin-right: 20px;
    padding: 20px;

    .title {
      padding: 10px 0;
      border-bottom: 1px solid #dee2e6;
      @include themeify {
        color: themed("primaryColor") !important;
      }
    }
    .listItem {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
      .item-name {
        display: flex;
        align-items: center;
        img {
          margin-left: 4px;
          cursor: pointer;
        }
      }
      .imgStyle {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include themeify {
          color: themed("btnText") !important;
          background: themed("btnBackGreen") !important;
        }
        font-size: 18px;
      }
      .btn-warp {
        display: flex;
        align-items: center;
        :first-child {
          // margin-right: 20px;
        }
      }
      .band-warp {
        display: flex;
        align-items: center;
        @include themeify {
          color: themed("btnBackGreen") !important;
        }
        .ga,
        .vt {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            margin-right: 4px;
          }
          .btn {
            cursor: pointer;
          }
        }
        .vt {
          margin-left: 30px;
        }
      }
    }
    .listBox {
      height: 300px;
      overflow-y: scroll;
      .listItem {
        display: flex;
        align-items: center;
        padding-right: 20px;
        height: 30px;

        .removeList {
          cursor: pointer;
        }
      }
    }
    .modifyBtn {
      min-width: 46px;
      font-size: 12px;
      height: 22px;
    }
  }
}

.slotBox {
  position: relative;
  display: flex;
  align-items: center;
  .slotIcon {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid rgba(246, 245, 245, 1);
    font-size: 20px;
    top: -19px;
    right: 0;
  }
}
.icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-left: 5px;
  cursor: pointer;
}
</style>

<style lang="scss" >
@import "@/assets/sass/_themeify.scss";
.el-message-box {
  padding-bottom: 20px;
}
.el-message-box__btns {
  .el-button {
    display: none;
  }
  .el-button--primary {
    display: inline-block;
    width: 100%;
    @include themeify {
      background: themed("btnBackGreen") !important;
    }
    border-radius: 8px;
    border: none;
    margin-left: 0 !important;
  }
}

.el-dialog__body {
  padding-top: 0px;
}
.dialog-warp {
  .inp-warp {
    color: #6782a4;
    margin: 10px 0 10px 0;

    ::v-deep.el-input__inner {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      height: 30px;
      line-height: 30px;
      padding: 0;
    }
    ::v-deep.el-input__suffix {
      height: 30px;
      line-height: 30px;
    }

    .title-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .inp-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
    }
    .sel {
      color: #2670FA;
      cursor: pointer;
    }

    .get-code {
      height: 36px;
      line-height: 36px;
      color: #2670FA;
      cursor: pointer;
    }

    .inp {
      margin-top: 10px;
      .getcode {
        color: #2670FA;
        cursor: pointer;
      }
    }
  }
}
.login-codeBox {
  /deep/.el-dialog__body {
    padding-top: 15px;
  }
  .login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .code-warp {
      height: 180px;
      margin-bottom: 10px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .validity {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .noCodeBox {
      position: absolute;
      margin-bottom: 10px;
      background: url("../../assets/image/img@2x.png") no-repeat;
      background-size: 100% 100%;
      width: 180px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
    .qr-code {
      margin: 10px 0;
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      display: inline-block;
      width: 100%;
      text-align: center;
      border-radius: 8px;
      @include themeify {
        background-color: themed("btnBackGreen") !important;
      }
      .el-button--primary {
        background: none;
        border: none;
      }
    }
  }
}
.mt30 {
  margin-top: 30px;
}
.openBox {
  /deep/.el-dialog__body {
    padding: 20px 20px 35px;
  }
  span {
    color: #999fb4;
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.textColor {
  @include themeify {
    color: themed("primaryColor") !important;
  }
}
</style>
