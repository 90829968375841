<template>
  <div class="switch">
    <div
      class="title"
      v-if="dataList.name"
      :style="dataList.name ? 'height:18px' : ''"
    >
      {{ $store.state.lang == 'ch' ? dataList.name : dataList.enName }}
      <img
        v-if="dataList && dataList.explain"
        src="@/assets/icon/explain.svg"
        class="explain"
        @click="openAlert()"
        alt=""
      />
    </div>
    <div class="switch-box">
      <div
        v-if="$store.state.lang == 'ch'"
        class="switch-item"
        v-for="(item, index) in dataList.radioName"
        @click="switchEmit(index)"
        :class="[
          index == dataIndex ? 'switchStyle' : 'dufBack',
          disabled ? 'not-cursor' : '',
        ]"
      >
        {{ item }}
      </div>
      <div
        v-if="$store.state.lang == 'en'"
        class="switch-item"
        v-for="(item, index) in dataList.enRadioName"
        @click="switchEmit(index)"
        :class="[
          index == dataIndex ? 'switchStyle' : 'dufBack',
          disabled ? 'not-cursor' : '',
        ]"
      >
        {{ item }}
      </div>
    </div>
    <div v-if="inputType && inputList" class="switch-input">
      <InputIconBox
        :item="inputList"
        style="margin: 0"
        v-model="inputList.number"
      />
    </div>
  </div>
</template>

<script>
import InputIconBox from "./InputIconBox";
export default {
  name: "tabSwitchClick",
  props: ["dataList", "inputType", "disabled", "icon"],
  components: {
    InputIconBox,
  },
  watch: {
    "dataList.value"() {
      this.dataIndex = this.dataList.value;
    },
    dataList() {
      if (this.dataList.enRadioName) {
        this.dataList.enRadioName = eval(this.dataList.enRadioName);
      }
      if (this.dataList.radioName) {
        this.dataList.radioName = eval(this.dataList.radioName);
      }
    },
  },
  data() {
    return {
      dataIndex: this.dataList.value ? this.dataList.value : "0",
      inputList: null,
    };
  },
  methods: {
    switchEmit(index) {
      //禁止点击
      if (this.disabled) {
        if (this.dataList.name == "保证金模式") {
          this.$message.warning(this.$t("m.暂不支持"));
        }
        return;
      }
      var dataIndex = this.dataIndex;
      this.dataIndex = index;
      this.dataList.value = index;
      this.$emit("update:switchClick", index);
      this.$emit("change", index, ()=>{
        this.dataIndex = dataIndex;
        this.dataList.value = dataIndex;
      });
    },

    //解释文案
    openAlert() {
      this.$alert(
        this.$store.state.lang == 'ch'
          ? this.dataList.explain
          : this.dataList.enExplain,
        "",
        {
          confirmButtonText: this.$t("m.确定"),
          callback: (action) => {},
        }
      );
    },
  },
  mounted() {
    if (this.inputType) {
      this.inputList = JSON.parse(JSON.stringify(this.dataList));
      this.inputList.name = null;
      this.inputList.explain = null;
    }
  },
  created() {
    if (this.dataList.enRadioName) {
      this.dataList.enRadioName = eval(this.dataList.enRadioName);
    }
    if (this.dataList.radioName) {
      this.dataList.radioName = eval(this.dataList.radioName);
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.switch {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex: 1;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include themeify {
      color: themed("tabColor") !important;
    }
    .explain {
      cursor: pointer;
      margin: 0 0 -2px 5px;
    }
  }
  .switch-box {
    display: flex;
    border-radius: 4px;
    background: #f6f6f6;
    :first-child {
      border-radius: 4px 0 0 4px;
    }
    :last-child {
      border-radius: 0 4px 4px 0;
    }
    .switch-item {
      height: 36px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @include themeify {
        color: themed("primaryColor") !important;
      }
    }
    .switchStyle {
      @include themeify {
        background: themed("btnBackGreen") !important;
        color: themed("btnText") !important;
      }
    }

    .dufBack {
      @include themeify {
        background: themed("tabBackColor") !important;
        color: themed("tabColor") !important;
      }
    }
  }
}
//禁止点击图标
.not-cursor {
  cursor: not-allowed !important;
  background: #d0d7df !important;
}
</style>