<template>
  <div class="cellnewspc" :class="[item ? 'but' : '']" @click="cellClick()">
    <template v-if="item">
      <el-image class="img-cellnewspc" :src="item.artPath" fit="cover"  />
      <div class="textspc">
        <span class="font18 fontc ch1 mb10 text-line2">{{item.artTitle}}</span>
        <span class="font16 ch3 text-line3 mb8">{{item.artIntroduce}}</span>
        <span class="font12 ch5">{{(lang == 'en' ? item.timeEn  : item.timeCn)}}</span>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: {
    item: [Object, String],
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState(['lang']),  //语言
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {

    /******************************************** 点击 */
    //cell点击
    cellClick() {
      if (!this.item) return;
      this.$emit('click', this.item);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.cellnewspc {
  display: flex;
  flex-direction: column;
  width: 372px;
  height: 318px;
  .img-cellnewspc {
    overflow: hidden;
    width: 372px;
    height: 206px;
    border-radius: 4px;
    border: 1px solid #F3F7FF;
    margin-bottom: 10px;
  }
  .textspc {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;
  }
}
</style>
