var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("m.参数")))]),_c('div',{staticClass:"createFlex"},[_vm._l((_vm.dataList),function(item){return [(item.id == 1)?_c('div',{staticClass:"create-item"},[_c('div',{staticClass:"flex-average",staticStyle:{"margin":"20px 0 0 0"}},[_c('InputIconBox',{staticStyle:{"margin":"0"},attrs:{"minIcon":"true","item":item,"equivalent":"true","result":_vm.result,"pairCurrent":_vm.pairCurrent}})],1)]):_vm._e(),(item.id == 2)?_c('div',{staticClass:"create-item"},[_c('TabSwitchClick',{attrs:{"title":"DealStartTrigger","dataList":item,"inputType":"true"}})],1):_vm._e(),(item.id == 3)?_c('div',{staticClass:"create-item"},[_c('TabSwitchClick',{staticClass:"create-mrg",attrs:{"title":item.name,"dataList":item,"switchClick":_vm.replenishmentShow},on:{"update:switchClick":function($event){_vm.replenishmentShow=$event},"update:switch-click":function($event){_vm.replenishmentShow=$event}}})],1):_vm._e(),(
          (item.id == 5 && _vm.replenishmentShow == 0) ||
          (item.id == 6 && _vm.replenishmentShow == 0) ||
          item.id == 10 ||
          item.id == 12 ||
          (item.id == 13 && _vm.orderModelShow == 0) ||
          (item.id == 14 && _vm.directionShow !== 1) ||
          (item.id == 15 && _vm.directionShow !== 0)
        )?_c('div',{staticClass:"create-item"},[_c('Slider',{attrs:{"dataList":item}})],1):_vm._e(),(item.id == 7 && _vm.replenishmentShow == 1)?_c('div',{staticClass:"create-item"},[_c('InputIconBox',{attrs:{"item":item,"result":_vm.result,"pairCurrent":_vm.pairCurrent}})],1):_vm._e(),(item.id == 8)?_c('div',{staticClass:"create-item"},[_c('TabSwitchClick',{staticClass:"create-mrg",attrs:{"title":item.name,"dataList":item,"switchClick":_vm.orderModelShow},on:{"update:switchClick":function($event){_vm.orderModelShow=$event},"update:switch-click":function($event){_vm.orderModelShow=$event},"change":_vm.directionChange}})],1):_vm._e(),(item.id == 16)?_c('div',{staticClass:"create-item"},[_c('InputIconBox',{attrs:{"item":item}})],1):_vm._e(),(
          item.id == 1 ||
          item.id == 3 ||
          item.id == 8 ||
          item.id == 10 ||
          (item.id == 7 && _vm.replenishmentShow == 1) ||
          (item.id == 13 && _vm.orderModelShow !== 0) ||
          (item.id == 14 && _vm.directionShow == 0) ||
          (item.id == 15 && _vm.directionShow == 1)
        )?_c('div',{staticClass:"create-item"}):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }