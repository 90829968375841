<template>
  <div class="index">
    <TopNavigation  :menu="1" />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'3'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <template>
              <RobotList />
            </template>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HomeSkeleton from "../transaction/modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import RobotList from "../transaction/modular/robotList.vue";
export default {
  components: {
    TopNavigation,
    Menu,
    HomeSkeleton,
    RobotList,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.index {
  width: 100%;
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      justify-content: space-between;
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        // height: 100%;
        position: relative;
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
}
</style>