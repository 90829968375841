<template>
  <div class="search">
    <TopNavigation />
    <div class="content">
      <div class="cont-warp">
        <!-- 搜索 -->
        <div class="search-warp">
          <el-input
            :placeholder="$t('m.请输入关键词搜索')"
            v-model="keyword"
            @input="searchChange"
          >
            <div slot="suffix" class="search-right" style="cursor: pointer">
              <span></span>
              <img src="../../assets/icon/souso.png" alt="" />
              <span @click="getArticleList()">{{ $t("m.搜索") }}</span>
            </div>
          </el-input>
        </div>

        <!-- 内容 -->
        <div class="cont-box">
          <div class="box">
            <div class="title">{{ $t("m.搜索结果") }}</div>
            <template v-if="total != 0 && articleList.length != 0">
              <div class="article">
                <div
                  class="article-box"
                  v-for="(item, idx) in articleList"
                  :key="idx"
                  @click="getArticleDetails(item.id)"
                >
                  <span class="article-box-title">
                    <span>{{ idx + 1 + "." }}</span>
                    <span id="title" v-html="item.title"></span>
                  </span>
                  <div class="article-box-text">
                    <span class="article-cont" v-html="item.outline"></span>
                    <span class="article-time">{{
                      moment(item.createTime).format("YYYY/MM/DD HH:mm")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="pagination-warp" v-if="total > 10">
                <Pagination
                  :total="total"
                  :size="pageSize"
                  :current="pageNo"
                  :sizeList="[10, 20, 50, 100, 500]"
                  @sizeChange="handleSizeChange"
                  @currentChange="handleCurrentChange"
                />
              </div>
            </template>

            <div class="no-data" v-else>
              <img src="@/assets/icon/quesheng.svg" alt="" />
              <span>{{ $t("m.抱歉，没有找到相关内容") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TopNavigation from "@/components/TopNavigation.vue";
import Pagination from "@/components/pagination.vue";
import { getArticleList } from "@/request/api";
export default {
  components: {
    TopNavigation,
    Pagination,
  },
  data() {
    return {
      moment,
      keyword: null,
      articleList: null,
      total: 0,
      pageSize: 10,
      pageNo: 1,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 搜索
    searchChange(v) {
      this.getArticleList();
    },

    // 查询文章列表
    getArticleList() {
      if (!this.keyword) {
        return;
      }
      let payload = {
        token: localStorage.getItem("token"),
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        keyword: this.keyword,
      };
      getArticleList(payload).then((res) => {
        if (res.status == 200) {
          // this.total = Number(res.data.count);
          this.total = res.data.articleList.length;
          this.articleList = res.data.articleList;

          // setTimeout(()=>{
          this.changeColor(this.articleList);
          // },500)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getArticleList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getArticleList();
    },

    // 单独跳详情
    getArticleDetails(id) {
      // window.open("http://localhost:8080/#/helpCenter?id=" + id);
      // window.open("http://159.138.104.103:8797/#/helpCenter?id=" + id);
      window.open("https://vtrading.com/helpCenter?id=" + id);
    },
    // 搜索高亮
    changeColor(result) {
      //result为接口返回的数据
      result.map((item, index) => {
        if (this.keyword) {
          /**
           * 使用正则表达式进行全文匹配关键词
           * ig : 表示 全文查找 ,忽略大小写
           *  i : 忽略大小写
           *  g : 全文查找
           *
           * 使用字符串的replace方法进行替换
           * stringObject.replace('被替换的值',替换的值)
           */
          let replaceReg = new RegExp(this.keyword, "ig");
          let replaceString = `<span style="color: #02C8A8;margin:0">${this.keyword}</span>`;
          result[index].title = item.title.replace(replaceReg, replaceString);
          result[index].outline = item.outline.replace(
            replaceReg,
            replaceString
          );
        }
      });
      this.articleList = result;
    },
  },
  created() {},
  mounted() {
    // this.keyword = this.$route.query.searchText;
    let url = window.location.href;
    let text = decodeURIComponent(url.split("=")[1]);
    this.keyword = text;
    // this.keyword = this.$route.query.searchText;
    this.getArticleList();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 100%;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .content {
    width: 1440px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .cont-warp {
      width: 1080px;
      padding-top: 20px;
      .search-warp {
        height: 104px;
        background: url("../../assets/icon/back.png") no-repeat;
        background-size: 100% 100%;
        ::v-deep.el-input {
          width: 459px;
          margin: 30px 0 30px 40px;
        }
        ::v-deep.el-input__inner {
          border-radius: 10px !important;
        }
        .search-right {
          display: flex;
          align-items: center;
          font-size: 14px;
          height: 40px;

          :first-child {
            height: 16px;
            border-left: 1px solid #dee2e6;
          }
          img {
            width: 17px;
            height: 18px;
            margin: 0 6px 0 20px;
          }
          :last-child {
            margin-right: 24px;
          }
        }
      }
      .cont-box {
        width: 100%;
        height: calc(100vh - 266px);
        margin-top: 20px;
        display: flex;
        .box {
          width: 100%;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          @include themeify {
            background: themed("secondaryBackColor") !important;
          }
          .title {
            font-size: 16px;
            border-bottom: 1px solid #dee2e6;
            padding: 10px 0;
            margin-bottom: 20px;
            @include themeify {
              color: themed("primaryColor") !important;
            }
          }
          .article {
            width: 100%;
            flex: 1;
            overflow: auto;
            .article-box {
              line-height: 22px;
              margin-bottom: 14px;
              cursor: pointer;
              .article-box-title {
                font-size: 14px;
                display: inline-block;
                width: 570px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include themeify {
                  color: themed("primaryColor") !important;
                }
              }
              .article-box-text {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                .article-cont {
                  display: inline-block;
                  text-indent: 14px;
                  width: 570px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .article-time {
                  margin-right: 20px;
                }
                @include themeify {
                  color: themed("secondaryColor") !important;
                }
              }
            }
          }
          .pagination-warp {
            width: 100%;
            height: 50px;
            text-align: right;
          }
          .no-data {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 180px;
              margin-bottom: 10px;
            }
            span {
              margin: 25px 0;
              @include themeify {
                color: themed("secondaryColor");
              }
            }
          }
        }
        .details-warp {
          display: flex;
          .details {
            width: 599px;
            padding: 20px;
            @include themeify {
              background: themed("secondaryBackColor") !important;
              color: themed("secondaryColor") !important;
              border-right: 1px solid themed("splitline") !important;
            }
            .details-title {
              width: 100%;
              padding-bottom: 10px;
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              :first-child {
                @include themeify {
                  color: themed("primaryColor") !important;
                }
              }
              @include themeify {
                border-bottom: 1px solid themed("splitline") !important;
              }
            }
          }
          .right-list {
            width: 239px;
            padding: 20px;
            font-size: 14px;
            @include themeify {
              background: themed("secondaryBackColor") !important;
              color: themed("primaryColor") !important;
            }
            .right-list-box {
              margin-top: 20px;
              cursor: pointer;
              .right-list-box-text {
                @include themeify {
                  color: themed("secondaryColor") !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>