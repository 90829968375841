<template>
  <div class="strategyDatails">
    <TopNavigation />
    <el-container>
      <el-aside width="240px">
        <Menu :idx="'3'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <!-- 渲染内容 -->
            <template>
              <div class="breadcrumb-warp">
                <span @click="$router.push({ path: '/transac' })">{{
                  $t("m.首页") + " / "
                }}</span>
                <span @click="$router.push({ path: '/robotList' })">
                  {{ $t("m.U本位合约DCA") + " / " }}
                </span>
                <span>{{ $t("m.机器人详情") }}</span>
              </div>
              <div class="strategyDatails-warp">
                <div
                  class="strategyDatails-left"
                  v-if="robotData"
                  :style="activeName == '参数' ? 'padding-bottom:0px' : ''"
                >
                  <div class="strategy-warp">
                    <div class="strategy">
                      <span class="strategy-name">{{
                        robotData.robotName
                      }}</span>
                      <span class="id">{{
                        "(" + $t("m.机器人") + "ID:" + robotData.robotId + ")"
                      }}</span>
                      <span
                        :class="
                          robotData.robotPattern == 0
                            ? 'redBack'
                            : robotData.robotPattern == 1
                            ? 'greenBack'
                            : 'cross'
                        "
                        class="lever"
                      >
                        <span v-if="robotData.robotPattern == 1">
                          {{ $t("m.做多") }}
                        </span>
                        <span v-if="robotData.robotPattern == 0">
                          {{ $t("m.做空") }}
                        </span>
                        <span v-if="robotData.robotPattern == 2">{{
                          $t("m.双向")
                        }}</span>
                        <span style="margin-top: 2px">
                          {{ robotData.leverRate + "X" }}
                        </span>
                      </span>
                      <span class="cross">{{
                        robotData.bondPattern == "0"
                          ? $t("m.全仓")
                          : $t("m.逐仓")
                      }}</span>
                    </div>
                    <div class="strategy-time">
                      {{
                        $t("m.执行时长") +
                        " " +
                        formatDuring(robotData.createTime)
                      }}
                    </div>
                    <div class="exchange-pair">
                      <span class="exchangeName">
                        <img
                          v-if="robotData.exchangeName == 'OkexV5'"
                          src="@/assets/icon/OKX@2x.png"
                          alt=""
                          style="width: 20px; height: 20px; margin-right: 5px"
                        />
                        <img
                          v-if="robotData.exchangeName == 'Binance'"
                          src="@/assets/icon/bianlogo@2x.png"
                          style="width: 20px; height: 20px; margin-right: 5px"
                          alt=""
                        />
                        {{
                          robotData.apiName
                            ? robotData.apiName.split("-")[1]
                            : "--"
                        }}
                      </span>
                      <span>{{ $t("m.U本位合约账户") }}</span>
                      <span>{{ counterCoin + " / " + baseCoin }}</span>
                    </div>

                    <div class="ops">
                      <span
                        class="green greenBorder btnClickStyle"
                        @click="editRobot(displayConfig)"
                      >
                        {{ $t("m.编辑") }}
                      </span>
                      <span
                        class="green greenBorder btnClickStyle"
                        @click="copyRobot(displayConfig)"
                      >
                        {{ $t("m.复制") }}
                      </span>
                      <span
                        v-if="robotData.states == 'EXECUTE'"
                        class="blueBackground btnClickStyle"
                        @click.stop="suspendRobot()"
                      >
                        {{ $t("m.暂停") }}
                      </span>
                      <span
                        v-if="robotData.states == 'SUSPEND'"
                        class="green greenBorder btnClickStyle"
                        @click.stop="restartRobot()"
                      >
                        {{ $t("m.重启") }}
                      </span>
                      <span
                        v-if="
                          robotData.states == 'STOPING' || !robotData.states
                        "
                        class="blueBackground btnClickStyle"
                        @click.stop="openVisible = true"
                      >
                        {{ $t("m.启动") }}
                      </span>
                      <span
                        v-if="
                          robotData.states && robotData.states !== 'STOPING'
                        "
                        class="red redBorder btnClickStyle"
                        @click.self="endVisible = true"
                      >
                        {{ $t("m.终止") }}
                      </span>
                    </div>
                  </div>

                  <!-- tabs -->
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="$t('m.数据')" name="数据">
                    </el-tab-pane>
                    <el-tab-pane :label="$t('m.信息')" name="信息">
                      <span slot="label">
                        <span class="nameStyle">
                          {{ $t("m.信息") }}
                          <span
                            class="identification"
                            v-if="robotData.errorInfo"
                          >
                          </span>
                        </span>
                      </span>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('m.参数')" name="参数">
                    </el-tab-pane>
                    <el-tab-pane :label="$t('m.成交记录')" name="成交记录">
                    </el-tab-pane>
                  </el-tabs>
                  <StrategyStates
                    v-if="activeName == '数据'"
                    :chartsData="chartsData"
                    :robotPattern="robotData.robotPattern"
                    :pairDataList="pairDataList"
                  />
                  <StrategyEvents
                    :robotId="pairDataList.robotId"
                    v-if="activeName == '信息'"
                  />
                  <StrategyParameter
                    v-if="activeName == '参数'"
                    :params="params"
                  />
                  <StartegyTradeHistory
                    :executeId="pairDataList.executeId"
                    :contractSize="pairDataList.contractSize"
                    v-if="activeName == '成交记录'"
                  />
                </div>

                <!-- 右侧数据 -->

                <div class="strategyDatails-right">
                  <div class="top">
                    <p class="title">{{ $t("m.账户资产") }}</p>
                    <div class="WalletAssets-warp">
                      <span class="exchangeName">{{ $t("m.可用权益") }}</span>
                      <div>
                        <span>
                          {{
                            (exampleData ? exampleData.marginAvailable : 0) +
                            " USDT"
                          }}
                        </span>
                        <span class="Transfer" @click="transferShowBtn()">
                          {{ $t("m.划转") }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="bottom" v-if="exampleData">
                    <p>{{ $t("m.执行实例") }}</p>
                    <div class="Instances-warp">
                      <div class="Instances-left">
                        <span>{{ $t("m.总收益") + ":" }}</span>
                        <span>{{ $t("m.完成的实例数") + ":" }}</span>
                        <span>{{ $t("m.活跃的实例数") + ":" }}</span>
                        <span>{{ $t("m.当前做单数") + ":" }}</span>
                        <span>{{ $t("m.当前初始保证金占用") + ":" }}</span>
                      </div>
                      <div class="Instances-right">
                        <span
                          :class="{
                            green:
                              Public.toMathNum(
                                exampleData.robotEarningRate,
                                100
                              ) > 0,
                            red:
                              Public.toMathNum(
                                exampleData.robotEarningRate,
                                100
                              ) < 0,
                          }"
                        >
                          {{
                            exampleData.robotSumEarnings +
                            "(" +
                            Public.toMathNum(
                              exampleData.robotEarningRate,
                              100
                            ) +
                            "%)"
                          }}
                        </span>
                        <span>{{ exampleData.historyStrategyCount }}</span>
                        <span>{{ exampleData.executeStrategyCount }}</span>
                        <span>{{
                          (maxShortCount || maxShortCount == 0
                            ? (exampleData.shortOrderNum ||
                              exampleData.shortOrderNum == "0"
                                ? exampleData.shortOrderNum
                                : "0") +
                              "/" +
                              maxShortCount +
                              "(" +
                              $t("m.做空") +
                              ")"
                            : "") +
                          " " +
                          (maxLongCount || maxLongCount == 0
                            ? (exampleData.longOrderNum ||
                              exampleData.longOrderNum == "0"
                                ? exampleData.longOrderNum
                                : "0") +
                              "/" +
                              maxLongCount +
                              "(" +
                              $t("m.做多") +
                              ")"
                            : "")
                        }}</span>
                        <span>{{ exampleData.marginPosition + " USDT" }} </span>
                      </div>
                    </div>

                    <div
                      class="box"
                      v-for="(item, idx) in exampleData.executeStrategyList"
                      :class="idx % 2 == 1 ? 'boxColorStyle' : ''" :key="idx"
                    >
                      <div class="box-one">
                        <div class="id-warp">
                          <span>{{ "ID:" + item.executeId.slice(0, 10) }}</span>
                          <span class="type">{{ $t("m.活跃") }}</span>
                        </div>
                        <span
                          :class="{
                            green: Public.toMathNum(item.incomeUsdtNum) > 0,
                            red: Public.toMathNum(item.incomeUsdtNum) < 0,
                          }"
                          >{{ Public.toMathNum(item.incomeUsdtNum) }}</span
                        >
                      </div>
                      <div class="box-two">
                        <span class="time">{{
                          moment(item.startTime).format("YYYY/MM/DD HH:mm") +
                          " - " +
                          "( " +
                          formatDuring(item.startTime) +
                          " )"
                        }}</span>
                        <span
                          :class="{
                            green: Public.toMathNum(item.realEarningsRate) > 0,
                            red: Public.toMathNum(item.realEarningsRate) < 0,
                          }"
                          >{{
                            Public.toMathNum(item.realEarningsRate) + "%"
                          }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="box"
                      v-for="(item, idx) in exampleData.historyStrategyList"
                      :class="idx % 2 == 1 ? 'boxColorStyle' : ''" :key="idx"
                    >
                      <div class="box-one">
                        <div class="id-warp">
                          <span>{{ "ID:" + item.executeId.slice(0, 10) }}</span>
                          <span class="type gray">{{ $t("m.已终止") }}</span>
                        </div>
                        <span
                          :class="{
                            green: Public.toMathNum(item.incomeUsdtNum) > 0,
                            red: Public.toMathNum(item.incomeUsdtNum) < 0,
                          }"
                          >{{ Public.toMathNum(item.incomeUsdtNum) }}</span
                        >
                      </div>

                      <div class="box-two">
                        <span class="time">
                          {{
                            moment(item.startTime).format("YYYY/MM/DD HH:mm") +
                            " - " +
                            moment(item.endTime).format("YYYY/MM/DD HH:mm") +
                            "( " +
                            formatDuring(item.startTime, item.endTime) +
                            " )"
                          }}
                        </span>
                        <span
                          :class="{
                            green: Public.toMathNum(item.realEarningsRate) > 0,
                            red: Public.toMathNum(item.realEarningsRate) < 0,
                          }"
                          >{{
                            Public.toMathNum(item.realEarningsRate) + "%"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Transfer
                v-if="transferShow.value == 1"
                :transferShow="transferShow"
                :apiCurrent="apiCurrent"
              />
              <!-- 创建完成是否开启机器人 -->
              <el-dialog
                :title="$t('m.启动机器人')"
                :visible.sync="openVisible"
                width="366px"
                class="openBox"
              >
                <span>{{ $t("m.确定要启动当前机器人") + "？" }}</span>
                <div slot="footer" class="open-footer">
                  <div class="closeBtn" @click="openVisible = false">
                    {{ $t("m.取消") }}
                  </div>
                  <div class="clickBtn" @click="startRobotBtn()">
                    {{ $t("m.确定") }}
                  </div>
                </div>
              </el-dialog>
              <!-- 终止机器人 -->
              <el-dialog
                :title="$t('m.终止机器人')"
                :visible.sync="endVisible"
                width="366px"
                class="openBox"
                :show-close="false"
              >
                <span>{{ $t("m.确定要终止当前机器人") + "？" }}</span>
                <div slot="footer" class="open-footer">
                  <div class="closeBtn" @click="endVisible = false">
                    {{ $t("m.取消") }}
                  </div>
                  <div class="clickBtn" @click="endRobotBtn()">
                    {{ $t("m.确定") }}
                  </div>
                </div>
              </el-dialog>
            </template>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import StrategyStates from "./childModular/strategyStates.vue";
import StrategyEvents from "./childModular/strategyEvents.vue";
import StrategyParameter from "./childModular/strategyParameter.vue";
import StartegyTradeHistory from "./childModular/startegyTradeHistory.vue";
import Transfer from "@/components/Transfer.vue";
import HomeSkeleton from "./homeSkeleton.vue";
import Menu from "@/components/menu.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import moment from "moment";
import {
  queryRobotDetails,
  qureyRobotStrategyData,
  suspendRobot,
  robotEnd,
  startRobot,
  restartRobot,
} from "@/request/api";
export default {
  components: {
    StrategyStates,
    StrategyEvents,
    StrategyParameter,
    StartegyTradeHistory,
    Transfer,
    TopNavigation,
    Menu,
    HomeSkeleton,
  },
  data() {
    return {
      moment,
      activeName: "数据",
      robotData: null, // 执行数据
      exampleData: null, // 右侧实例数据
      chartsData: null,
      robotId: null,
      params: null,
      transferShow: {
        value: 0,
      }, //划转页面显隐
      displayConfig: null, //回显参数
      maxShortCount: null,
      maxLongCount: null,
      counterCoin: null,
      baseCoin: null,
      pairDataList: null, //交易对
      openVisible: false,
      endVisible: false,
    };
  },
  computed: {},
  watch: {
    "transferShow.value"() {
      if (this.transferShow.value == 0) {
        setTimeout(() => {
          this.qureyRobotStrategyData();
        }, 1000);
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      if (this.activeName == "信息") {
        this.robotData.errorInfo = false;
      }
    },

    // 查询机器人详情
    queryRobotDetails() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
      };
      queryRobotDetails(payload).then((res) => {
        if (res.status == 200) {
          this.robotData = res.data.robot;
          let displayConfig = JSON.parse(res.data.robot.displayConfig);
          this.displayConfig = displayConfig;
          this.displayConfig.robotId = res.data.robot.robotId;
          this.baseCoin = displayConfig.baseCoin;
          this.counterCoin = displayConfig.counterCoin;
          this.pairDataList = {
            baseCoin: displayConfig.baseCoin,
            counterCoin: displayConfig.counterCoin,
            apiId: this.robotData.apiId,
            exchangeName: this.robotData.exchangeName,
            executeId: this.robotData.executeId,
            robotId: this.robotData.robotId,
            contractSize: this.displayConfig.contractSize,
            transactionPair: this.robotData.transactionPair,
          };
          if (
            displayConfig.strategyPayload.strategy.params.maxLongCount ||
            displayConfig.strategyPayload.strategy.params.maxLongCount == "0"
          ) {
            this.maxLongCount =
              displayConfig.strategyPayload.strategy.params.maxLongCount;
          }
          if (
            displayConfig.strategyPayload.strategy.params.maxShortCount ||
            displayConfig.strategyPayload.strategy.params.maxShortCount == "0"
          ) {
            this.maxShortCount =
              displayConfig.strategyPayload.strategy.params.maxShortCount;
          }
          if (this.robotData.executeId) {
            localStorage.setItem("executeId", this.robotData.executeId);
          } else {
            localStorage.removeItem("executeId");
          }
          this.params = displayConfig.strategyConfig;
          let list = JSON.parse(JSON.stringify(res.data.robot.robotList));

          let eventProfit = [];
          if (list.length != 0) {
            for (let key of list) {
              key.value = key.sumEarnings;
            }
            list.sort(function (a, b) {
              return a.time - b.time;
            });
            if (list.length == 1) {
              eventProfit = list;
            } else {
              eventProfit[0] = JSON.parse(JSON.stringify(list[0]));

              for (let i = 1; i < list.length; i++) {
                let num = list[i].value - list[i - 1].value;
                let item = {
                  time: list[i].time,
                  value: Math.floor(num * 1000) / 1000,
                };
                eventProfit.push(item);
              }
            }
          }

          let chartsData = {
            performanceList: list, //机器人表现
            everyList: eventProfit, //每日利润
          };

          this.chartsData = chartsData;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询机器人详情右侧数据
    qureyRobotStrategyData() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotId,
      };
      qureyRobotStrategyData(payload).then((res) => {
        if (res.status == 200) {
          let exampleData = res.data;
          // exampleData.robotSumEarnings =
          //   Math.floor(exampleData.robotSumEarnings * 1 0000) / 1 0000;
          // exampleData.marginPosition =
          //   Math.floor(exampleData.marginPosition * 1 0000) / 1 0000;
          // exampleData.marginAvailable =
          //   Math.floor(exampleData.marginAvailable * 1 0000) / 1 0000;
          exampleData.robotSumEarnings = this.Public.toMathNum(
            exampleData.robotSumEarnings
          );
          exampleData.marginPosition = this.Public.toMathNum(
            exampleData.marginPosition
          );
          exampleData.marginAvailable = this.Public.toMathNum(
            exampleData.marginAvailable
          );
          if (exampleData.executeStrategyList) {
            exampleData.executeStrategyList.forEach((e) => {
              e.incomeUsdtNum = this.Public.toMathNum(e.incomeUsdtNum);
              // e.incomeUsdtNum = Math.floor(e.incomeUsdtNum * 1 0000) / 1 0000;
            });
          }
          if (exampleData.historyStrategyList) {
            exampleData.historyStrategyList.forEach((e) => {
              e.incomeUsdtNum = this.Public.toMathNum(e.incomeUsdtNum);
              // e.incomeUsdtNum = Math.floor(e.incomeUsdtNum * 1 0000) / 1 0000;
              this.$store.commit("date_format", e.endTime - e.startTime);
              e.durationTime = this.$store.state.timeNow;
            });
          }

          this.exampleData = exampleData;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 暂停机器人
    suspendRobot() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
      };
      suspendRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.暂停成功"));
          this.queryRobotDetails();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 终止机器人按钮
    robotEnd(item) {
      this.stopWithCancel = item.displayConfig.stopWithCancel;
      this.stopWithClose = item.displayConfig.stopWithClose;
      this.endVisible = true;
    },
    // 终止机器人
    endRobotBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
      };
      robotEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.终止成功"));
          this.endVisible = false;
          this.queryRobotDetails();
        } else {
          this.$message.error(res.msg);
        }
      });
      return;
      let payloadData = {
        ...this.robotItem,
        stopWithCancel: this.stopWithCancel ? 1 : 0,
        stopWithClose: this.stopWithClose ? 1 : 0,
      };
      updataRobot(payload).then((res) => {
        if (res.status == 200) {
        } else {
          this.$message.error(res.msg);
        }
      });
      return;
    },

    // 计算执行时间
    formatDuring(start, end) {
      let New = new Date().getTime();
      let mss;
      if (end) {
        mss = end - start;
      } else {
        mss = New - start;
      }
      var day = parseInt(mss / (1000 * 24 * 60 * 60));
      var hours = parseInt((mss % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      var str =
        day +
        this.$t("m.天") +
        " " +
        hours +
        this.$t("m.时") +
        " " +
        minutes +
        this.$t("m.分");
      return str;
    },

    // 划转按钮
    transferShowBtn() {
      this.transferShow.value = 1;
      this.apiCurrent = this.robotData;
    },
    //重启机器人
    restartRobot() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
      };
      restartRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.重启成功"));
          this.queryRobotDetails();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 复制机器人
    copyRobot(item) {
      localStorage.setItem("copyStrategy", JSON.stringify(item));
      this.$router.push({ path: "/robotList/createStrategy" });
    },
    // 编辑机器人
    editRobot(item) {
      item.type = "edit";
      localStorage.setItem("dcaType", "edit");
      localStorage.setItem("copyStrategy", JSON.stringify(item));
      this.$router.push({ path: "/robotList/createStrategy" });
    },

    // 启动机器人
    startRobotBtn() {
      let payload = {
        token: localStorage.getItem("token"),
        robotId: this.robotData.robotId,
      };
      startRobot(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.启动成功"));
          this.openVisible = false;
          this.queryRobotDetails();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    let activeName = localStorage.getItem("activeName");
    if (activeName) {
      this.activeName = activeName;
      localStorage.removeItem("activeName");
    }
  },
  mounted() {
    this.robotId = localStorage.getItem("robotId");
    this.queryRobotDetails();
    this.qureyRobotStrategyData();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    // localStorage.removeItem('robotId')
  },
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.strategyDatails {
  height: 100%;
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      justify-content: space-between;
      overflow: auto;
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        position: relative;
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
  .breadcrumb-warp {
    margin-bottom: 20px;
    span {
      cursor: pointer;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
    }
    :last-child {
      cursor: auto;
      @include themeify {
        color: themed("primaryColor") !important;
      }
    }
  }
  .strategyDatails-warp {
    display: flex;
    min-width: 1120px;
    width: 100%;
    .strategyDatails-left {
      min-width: 682px;
      flex: 1;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;
      @include themeify {
        background: themed("secondaryBackColor") !important;
        box-shadow: themed("shadow") !important;
      }
      .strategy-warp {
        margin-bottom: 30px;
        .strategy {
          display: flex;
          align-items: center;
          font-size: 12px;
          .strategy-name {
            font-size: 18px;
            @include themeify {
              color: themed("primaryColor") !important;
            }
          }
          .id {
            margin: 0 10px;
            @include themeify {
              color: themed("secondaryColor") !important;
            }
          }
          .lever {
            width: 68px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(90deg, #0c5ffa 0%, #2670FA 100%);
            border-radius: 0px 8px 0px 8px;
            margin: 0 10px;
            @include themeify {
              color: themed("btnText") !important;
            }
          }
          .cross {
            width: 68px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(90deg, #87a5cb 0%, #6782a4 100%);
            border-radius: 0px 8px 0px 8px;
            @include themeify {
              color: themed("btnText") !important;
            }
          }
        }

        .strategy-time {
          font-size: 12px;
          margin: 4px 0 10px 0;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
        }
        .exchange-pair {
          font-size: 16px;
          color: #6782a4;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          .exchangeName {
            display: flex;
          }

          span {
            margin-right: 20px;
            height: 20px;
            line-height: 20px;
          }
        }
        .ops {
          display: flex;
          align-items: center;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 28px;
            font-size: 12px;
            border-radius: 4px;
            margin-right: 20px;
            cursor: pointer;
            color: #fff;
          }
          .greenBack {
            @include themeify {
              background-color: themed("btnBackGreen") !important;
            }
          }
          .redBack {
            @include themeify {
              background-color: themed("upRed") !important;
            }
          }
        }
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: rgba($color: #fff, $alpha: 0);
      }
      //默认字体颜色
      /deep/.el-tabs__item {
        color: #999fb4;
      }
      //选中
      /deep/.is-active {
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
      //border
      /deep/.el-tabs__active-bar {
        background: #2670FA;
        height: 3px;
        border-radius: 3px;
      }
    }

    .strategyDatails-right {
      margin-left: 10px;
      .top {
        width: 427px;
        height: 104px;
        border-radius: 8px;
        font-size: 16px;
        padding: 20px;
        margin-bottom: 10px;
        @include themeify {
          color: themed("primaryColor") !important;
          background: themed("secondaryBackColor") !important;
          box-shadow: themed("shadow") !important;
        }
        p {
          margin-bottom: 20px;
        }
        .WalletAssets-warp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .exchangeName {
            @include themeify {
              color: themed("secondaryColor") !important;
            }
          }
          .Transfer {
            margin-left: 10px;
            color: #0895f7;
            cursor: pointer;
          }
        }
      }
      .bottom {
        padding: 20px;
        font-size: 16px;
        width: 427px;
        min-height: 235px;
        border-radius: 8px;
        @include themeify {
          color: themed("primaryColor") !important;
          background: themed("secondaryBackColor") !important;
          box-shadow: themed("shadow") !important;
        }
        p {
          margin-bottom: 20px;
        }
        .Instances-warp {
          display: flex;
          justify-content: space-between;
          line-height: 30px;
          .Instances-left {
            display: flex;
            flex-direction: column;
            @include themeify {
              color: themed("secondaryColor") !important;
            }
          }
          .Instances-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }

        .box {
          width: 387px;
          height: 80px;
          background: #fcfcfc;
          border-radius: 8px;
          border: 1px solid #e6e5f2;
          font-size: 16px;
          padding: 14px;
          margin: 20px 0;

          @include themeify {
            color: themed("primaryColor") !important;
            background: themed("secondaryBackColor") !important;
          }
          .box-one {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .id-warp {
              display: flex;
              align-items: center;
              .type {
                padding: 2px 6px;
                background: linear-gradient(90deg, #0c5ffa 0%, #2670FA 100%);
                border-radius: 2px;
                margin-left: 6px;
                font-size: 10px;
                @include themeify {
                  color: themed("btnText") !important;
                }
              }

              .gray {
                background: linear-gradient(90deg, #87a5cb 0%, #6782a4 100%);
              }
            }
          }

          .box-two {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time {
              display: inline-block;
              font-size: 12px;
              max-width: 260px;
              line-height: 16px;
              @include themeify {
                color: themed("secondaryColor") !important;
              }
            }
          }
        }
        .boxColorStyle {
          background: #e6e5f2;
        }
      }
    }
  }
}
.openBox {
  /deep/.el-dialog {
    margin-top: 34vh !important;
  }
  /deep/.el-dialog__header {
    padding: 20px 30px 10px;
  }
  /deep/.el-dialog__body {
    padding: 20px 30px 35px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 30px 30px;
  }
  span {
    color: #999fb4;
  }
  .open-footer {
    display: flex;
    justify-content: space-between;
    div {
      width: 143px;
      height: 40px;
    }
    .closeBtn {
      border: 1px solid #d0d7df;
      color: #999fb4;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
/deep/.positions-cont-warp {
  background: #fcfcfc;
}
/deep/.positions-cont {
  background: #fcfcfc;
}
.nameStyle {
  position: relative;
  .identification {
    width: 8px;
    height: 8px;
    background: #fa5c5d;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -10px;
  }
}
</style>