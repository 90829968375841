<template>
  <div class="GA">
    <TopNavigation />
    <div class="breadcrumb-warp">
      <span @click="goSetUp()">{{ $t("m.设置") + " / " }}</span>
      <span>{{ $t("m.绑定谷歌验证器") }}</span>
    </div>
    <div class="bigBox">
      <div class="box">
        <div class="title">
          <span>{{ $t("m.绑定谷歌验证器") }}</span>
        </div>
        <div class="nav">
          <div class="nav-title" style="margin-top: 0">
            {{ "1 " + $t("m.下载谷歌验证器") + "APP" }}
          </div>
          <div>
            {{
              "1. iOS" +
              $t("m.用户登录") +
              "App Store" +
              $t("m.搜索") +
              "Authenticator" +
              $t("m.下载")
            }}
          </div>

          <div>
            {{ "2. " + $t("m.安卓用户登录应用商店,或使用手机浏览器搜索下载") }}
          </div>
          <div class="flexStyle" style="margin: 10px 0 30px">
            <div
              class="clickBtn whiteBackground download"
              style="margin-right: 20px"
              @click="AppStore()"
            >
              App Store
            </div>
            <div class="clickBtn whiteBackground download" @click="PlayStore()">
              Google Play
            </div>
          </div>
          <div class="nav-title">
            {{ "2 " + $t("m.在谷歌验证器中添加密钥并备份") }}
          </div>
          <div>
            {{
              $t(
                "m.打开谷歌验证器,扫描下方二维码或手动输入下述密钥添加验证令牌。"
              )
            }}
          </div>
          <div class="flexStyle" style="height: 100px">
            <div id="qrCode" ref="qrCodeDiv"></div>
            <div class="key">
              <div class="keySpan">{{ $t("m.密钥") + "：" + secret }}</div>
              <div
                class="clickBtn whiteBackground green copyBtn"
                style="width: 90px; height: 28px"
                @click="copyKey(secret)"
              >
                {{ $t("m.复制密钥") }}
              </div>
            </div>
          </div>
          <div class="nav-title">
            {{ "3 " + $t("m.在谷歌验证器中获取验证码并输入") }}
          </div>
          <div class="gaCode">
            <input type="text" v-model="gaCode" class="inpStyle" />
            <div class="gaSpan">
              <span style="margin-top: 2px">{{ $t("m.验证码") + " " }}</span>
              <div style="margin: 1px 0 0 5px">|</div>
            </div>
          </div>
        </div>
        <div class="flexStyle">
          <div class="clickBtn whiteBackground removeBtn" @click="goSetUp()">
            {{ $t("m.取消") }}
          </div>
          <div class="clickBtn" style="width: 260px" @click="bindGa()">
            {{ $t("m.启用双重认证") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation.vue";
import { getGASecret, checkGACode, bindGA } from "@/request/api";
const $ = window.$;
import QRCode from "qrcodejs2";
export default {
  components: {
    TopNavigation,
  },
  data() {
    return {
      input1: "11",
      barcodeUrl: null,
      secret: null,
      gaCode: null,
      qrcode: null,
    };
  },
  methods: {
    //转跳到个人中心
    goSetUp() {
      this.$router.push("./personal");
    },

    // 获取GA 秘钥/二维码
    getGASecret() {
      let payload = {
        token: localStorage.getItem("token"),
      };

      getGASecret(payload).then((res) => {
        if (res.status == 200) {
          this.secret = res.data.secret;
          this.barcodeUrl = res.data.barcodeUrl;
          if (this.qrcode) {
            this.qrcode.makeCode(JSON.stringify(res.data.secret));
            this.qrcode._el.title = "";
          } else {
            let url =
              "otpauth://totp/" +
              res.data.gaName +
              "?secret=" +
              res.data.secret;
            this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
              text: url,
              width: 100,
              height: 100,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
            this.qrcode._el.title = "";
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 复制秘钥
    copyKey(text) {
      // 数字没有 .length 不能执行selectText 需要转化成字符串
      const textString = text.toString();
      let input = document.querySelector("#copy-input");
      if (!input) {
        input = document.createElement("input");
        input.id = "copy-input";
        input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
        input.style.position = "absolute";
        input.style.left = "-1000px";
        input.style.zIndex = "-1000";
        document.body.appendChild(input);
      }

      input.value = textString;
      // ios必须先选中文字且不支持 input.select();
      selectText(input, 0, textString.length);
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      } else {
      }
      input.blur();

      // input自带的select()方法在苹果端无法进行选择，需要自己去写一个类似的方法
      // 选择文本。createTextRange(setSelectionRange)是input方法
      function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {
          //ie
          const range = textbox.createTextRange();
          range.collapse(true);
          range.moveStart("character", startIndex); //起始光标
          range.moveEnd("character", stopIndex - startIndex); //结束光标
          range.select(); //不兼容苹果
        } else {
          //firefox/chrome
          textbox.setSelectionRange(startIndex, stopIndex);
          textbox.focus();
        }
      }

      this.$message.success(this.$t("m.复制成功"));
    },

    // 校验/绑定GA
    bindGa() {
      if (!this.gaCode) {
        this.$message.error("请输入GA码");
        return;
      }
      let payload = {
        gaCode: this.gaCode,
        secret: this.secret,
        token: localStorage.getItem("token"),
      };
      // return;
      bindGA(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.绑定成功"));
          this.$router.push("./personal");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    AppStore() {
      window.open(
        "https://apps.apple.com/cn/app/google-authenticator/id388497605"
      );
    },
    PlayStore() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
      );
    },
  },
  mounted() {
    this.getGASecret();
  },
};
</script>

<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.GA {
  @include themeify {
    background: themed("primaryBackColor") !important;
    box-shadow: themed("shadow") !important;
  }
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .breadcrumb-warp {
    width: 1080px;
    padding: 20px 0;
    :first-child {
      cursor: pointer;
      @include themeify {
        color: themed("secondaryColor") !important;
      }
    }
    :last-child {
      @include themeify {
        color: themed("primaryColor") !important;
      }
    }
  }
  .bigBox {
    width: 1080px;
    height: 659px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
      box-shadow: themed("shadow") !important;
    }
    border-radius: 8px;
    display: flex;
    justify-content: center;
    .box {
      width: 420px;
      height: 659px;
      .title {
        font-size: 16px;
        padding: 20px 0;
        border-bottom: 1px solid #dee2e6;
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
      .nav {
        margin: 20px 0;
        border-radius: 4px;
        padding: 20px;
        font-size: 12px;
        @include themeify {
          background: themed("GABackColor") !important;
          color: themed("secondaryColor") !important;
        }
        .nav-title {
          margin: 30px 0 10px;
          font-size: 14px;
          @include themeify {
            color: themed("tabColor") !important;
          }
        }
        .gaCode {
          position: relative;
          .inpStyle {
            text-indent: 65px;
          }
          .gaSpan {
            position: absolute;
            display: flex;
            top: 9px;
            left: 20px;
            font-size: 12px;
          }
        }
      }
      .download {
        color: #6782a4;
        border: 1px solid #6782a4;
        width: 128px;
      }
      .flexStyle {
        margin-top: 10px;
        .key {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px;
          .keySpan {
            margin-bottom: 30px;
          }
          .copyBtn {
            @include themeify {
              border: 1px solid themed("btnBackGreen") !important;
            }
          }
        }
      }
      .removeBtn {
        font-size: 14px;
        @include themeify {
          color: themed("primaryColor") !important;
        }
        width: 140px;
        margin-right: 20px;
      }
    }
  }
}
</style>