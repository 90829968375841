<template>
  <div class="menu">
    <el-menu
      :default-active="idx"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#fff"
      :text-color="$store.state.theme == 'light' ? '#162447' : '#fff'"
      active-text-color="#fff"
      :default-openeds="['3']"
    >
      <el-menu-item index="1" @click="toPath(1)" style="margin-top: 24px">
        <img
          src="@/assets/icon/home_1.svg"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-if="idx == 1"
        />
        <img
          src="@/assets/icon/home.svg"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-else
        />

        <span slot="title">{{ $t("m.首页") }}</span>
      </el-menu-item>

      <el-menu-item index="2" @click="toPath(2)">
        <img
          src="@/assets/icon/My Exchanges_1.svg"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-if="idx == 2"
        />
        <img
          src="@/assets/icon/My Exchanges.svg"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-else
        />

        <span slot="title">{{ $t("m.我的交易所") }}</span>
      </el-menu-item>

      <el-submenu index="3" :class="idx == 3 ? 'redClass' : 'greenClass'">
        <template slot="title" @click="toPath('3')">
          <img
            src="@/assets/icon/DCA Bot_1.svg"
            alt=""
            style="margin-right: 10px; width: 18px"
            v-if="idx == 3"
          />
          <img
            src="@/assets/icon/DCA Bot.svg"
            alt=""
            style="margin-right: 10px; width: 18px"
            v-else
          />
          <span>{{ $t("m.U本位合约DCA") }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            index="3-1"
            style="padding-left: 30px; height: 0px !important"
            @click="toPath('3-1')"
          >
            <!-- {{ $t("m.机器人列表") }} -->
          </el-menu-item>
          <el-menu-item
            index="3-2"
            style="padding-left: 30px"
            @click="toPath('3-2')"
          >
            {{ $t("m.活跃实例") }}
          </el-menu-item>
          <el-menu-item
            index="3-3"
            style="padding-left: 30px"
            @click="toPath('3-3')"
          >
            {{ $t("m.历史") }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item index="4" @click="toPath(4)">
        <img
          src="@/assets/icon/wangge-bai@2x.png"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-if="idx == 4 || idx == 30"
        />
        <img
          src="@/assets/icon/wangge@2x.png"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-else
        />

        <span slot="title">{{ $t("m.U本位合约网格-m") }}</span>
      </el-menu-item>
      <el-menu-item index="5" @click="toPath(5)">
        <img
          src="@/assets/icon/yaoqing-bai@2x.png"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-if="idx == 5"
        />
        <img
          src="@/assets/icon/yaoqing@2x.png"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-else
        />

        <span slot="title">{{ $t("m.邀请好友") }}</span>
      </el-menu-item>

      
      <!-- <el-menu-item index="6" @click="toPath(6)">
        <img
          src="@/assets/icon/yaoqing-bai@2x.png"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-if="idx == 6"
        />
        <img
          src="@/assets/icon/yaoqing@2x.png"
          alt=""
          style="margin-right: 10px; width: 18px"
          v-else
        />

        <span slot="title">早鸟计划</span>
      </el-menu-item> -->
    </el-menu>
    <!-- <div class="customer-service">
      <img src="@/assets/icon/kefu.svg" alt="" />
      <span>{{ $t("m.在线客服") }}</span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["idx"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleOpen(key, keyPath) {
      if (key == 3) {
        this.toPath(3);
      }
    },
    handleClose(key, keyPath) {
      if (key == 3) {
        this.toPath(3);
      }
    },
    // 跳转
    toPath(v) {
      let url = window.location.href.split("/#")[1];
      if (v == 1 && url != "/transac") {
        this.$router.push({ path: "/transac" });
      } else if (v == 2 && url != "/myExchange") {
        this.$router.push({ path: "/myExchange" });
      } else if (v == "3-1" && url != "/robotList") {
        this.$router.push({ path: "/robotList" });
      } else if (v == "3-2" && url != "/robotList/exampleList") {
        this.$router.push({ path: "/robotList/exampleList" });
      } else if (v == "3-3" && url != "/robotList/historyExample") {
        this.$router.push({ path: "/robotList/historyExample" });
      } else if (v == "4" && url != "/contractStrategy") {
        this.$router.push({ path: "/contractStrategy" });
      } else if (v == "3" && url != "/robotList") {
        this.$router.push({ path: "/robotList" });
      } else if (v == "5" && url != "/inviteFriend") {
        this.$router.push({ path: "/inviteFriend" });
      } else if (v == "6" && url != "/earlyBird") {
        this.$router.push({ path: "/earlyBird" });
      }


    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.menu {
  width: 100%;
  height: 100%;
  //   height: calc(100% - 65px);
  //   @include themeify {
  //     background: themed("primaryBackColor") !important;
  //   }
  @include themeify {
    background: themed("secondaryBackColor") !important;
  }
  ::v-deep.el-menu {
    padding: 0 20px;
    border-right: 0;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
  }
  .el-menu-item.is-active {
    background: themed("btnBackGreen") !important;
  }
  ::v-deep.el-menu-item {
    min-width: 150px;
    height: 44px;
    line-height: 44px;
    margin-bottom: 16px;
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
  }
  ::v-deep.el-menu-item-group__title {
    padding: 0;
  }
  ::v-deep.el-submenu__title {
    padding: 0 0 0 19px !important;
    display: flex;
    align-items: center;
    height: 44px;
    line-height: 44px;
    @include themeify {
      color: menucolor;
      // background: themed("secondaryBackColor") !important;
    }
  }
  ::v-deep.is-active {
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px #3b70d3;
    @include themeify {
      background: themed("btnBackGreen") !important;
    }
  }

  .customer-service {
    position: fixed;
    left: 20px;
    bottom: 40px;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    @include themeify {
      color: themed("primaryColor") !important;
      border: themed("kefuborder") !important;
      background: themed("kefuBack") !important;
    }
    img {
      margin-right: 6px;
    }
  }
}
.redClass {
  box-shadow: 0px 0px 0px 0px !important;
  /deep/.el-submenu__title {
    background: #2670FA !important;
    color: #fff !important;
    border-radius: 8px;
    i {
      color: #fff;
    }
  }
}
.greenClass {
  box-shadow: 0px 0px 0px 0px !important;
  /deep/.el-submenu__title {
    @include themeify {
      background: themed("secondaryBackColor") !important;
    }
  }
}
</style>