<template>
  <div class="index">
    <TopNavigation />

    <div class="earlyBird-warp" v-if="isHomeTo">
      <EarlyBird />
    </div>  

    <el-container >
      <el-aside width="240px">
        <Menu :idx="'6'" />
      </el-aside>

      <el-container>
        <el-main>
          <div class="default-page">
            <template v-if="$store.state.isSkeleton">
              <HomeSkeleton class="skeleton" />
            </template>
            <template>
                <EarlyBird/>
            </template>
          </div>
        </el-main>
      </el-container>
    </el-container>
    
  </div>
</template>

<script>
import HomeSkeleton from "../transaction/modular/homeSkeleton.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import Menu from "@/components/menu.vue";
import EarlyBird from "@/components/earlyBird.vue";
export default {
  components: {
    TopNavigation,
    Menu,
    HomeSkeleton,
    EarlyBird
  },
  data() {
    return {
      isHomeTo:false
    };
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created() {},
  mounted() {
    let type = this.$route.query.isHomeTo
    if(type){
      this.isHomeTo =true
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.index {
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
  @include themeify {
    background: themed("primaryBackColor") !important;
  }
  .earlyBird-warp{
    height: calc(100vh - 65px);
    overflow-y: scroll;
  }
  .el-container {
    height: 100%;
    .el-main {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      justify-content: space-between;
      // 骨架
      .default-page {
        min-width: 1120px;
        width: 100%;
        // height: 100%;
        position: relative;
        .skeleton {
          position: absolute;
          z-index: 8000;
        }
      }
    }
  }
}
</style>