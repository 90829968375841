<template>
  <div class="newsh5">

    <RichInput class="search5"
    v-model="keyWord"
    :placeholder="$t('m.请输入搜索内容')"
    @enter="searchClick">
      <template #right="{select}">
        <img v-if="select" @click.stop="searchClick"
        class="img-search5 but" src="@/assets/image/news/sousuo.png">
        
        <img v-else style="opacity:0.3"
        class="img-search5" src="@/assets/image/news/sousuo.png">
      </template>
    </RichInput>

    <RichMenu v-if="types" class="menush5" 
    v-model="menu" 
    :menus="types" menuKey="artType" 
    @click="typeClick" />

    <template v-if="datas && datas.length">
      
      <CellNewsH5 class="mb30" @click="cellClick"
      v-for="(item, j) in datas" :key="'cellR'+j" 
      :item="item"/>
      
      <el-pagination v-if="totalNum > pageSize"
      class="pageh5"
      :current-page="showPage"
      :page-size="pageSize"
      :total="totalNum"
      layout="prev, pager, next"
      @current-change="pageClick"/>
    </template>
    <el-empty class="emptyh5" v-else :description="$t('m.暂无内容')" />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import RichInput from "@/components/RichInput.vue";
import RichBut from "@/components/RichBut.vue";
import RichMenu from "@/components/RichMenuH5.vue";
import CellNewsH5 from "./components/cellNewsH5.vue";

export default {
  components: {
    RichInput,
    RichBut,
    RichMenu,
    CellNewsH5
  },

  props: {
    type: Number,
    types: Array,
    typeMap: Object,

    pageNo: Number,
    pageSize: Number,
    totalNum: Number,
    datas: Array,
  },

  watch: {
    type() {
      this.menu = this.type;
    },
    pageNo() {
      this.showPage = this.pageNo;
    }
  },

  data() {
    return {
      keyWord: '', //搜索关键字
      menu: this.type, //当前菜单
      showPage: this.pageNo, //当前页码
    };
  },

  computed: {
    ...mapState(['lang']),  //语言
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    
    /******************************************** 点击 */
    //搜索
    searchClick() {
      this.$emit('search', this.keyWord);
    },

    //文章类型点击
    typeClick() {
      this.$emit('type', this.menu);
    },

    //cell点击
    cellClick(item) {
      this.$emit('cell', item);
    },

    //页码点击
    pageClick(page) {
      this.showPage = page;
      this.$emit('page', page);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.newsh5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 16px;

  .search5 {
    height: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid rgba(38,112,250,0.3);
    margin-bottom: 8px;
  }
  .img-search5 {
    width: 18px;
    height: 18px;
    margin-left: 12px;
  }
  
  .menush5 {
    z-index: 10;
    position: sticky; 
    top: 60px;
    margin-bottom: 20px;
  }

  .pageh5 {    
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pager {
      display: flex;
      align-items: center;
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 30px;

      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
    }
    .active {
      background: #2670FA;
      color: #fff;
    }
  }

  .emptyh5 {
    margin: 120px 0;
  }
}
</style>
