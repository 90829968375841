<template>
  <div class="homeAccount">
    <div class="homeAccount-warp">
      <!-- 聚合账户 -->

      <div v-if="isApi">
        <!-- <AggregateAcc style="cursor: pointer" /> -->
        <AggregateAcc />
      </div>

      <!-- 我的策略 -->
      <div class="my-strategy">
        <p class="label">{{ $t("m.我的机器人") }}</p>

        <div class="strategy-warp">
          <div class="strategy-list">
            <div
              class="strategy"
              v-for="(item, idx) in robotList"
              :key="idx"
              @click="toDatails(item)"
              style="cursor: pointer"
            >
              <!-- <div class="strategy-label">{{ item.robotName }}</div>
              <div class="cont">
                <div class="cont-left">
                 
                  <div class="left-two">
                    <span class="title">{{ $t("m.今日收益") }}</span>
                    <span
                      :class="{
                        green: Public.toMathNum(item.todoyEarnings) > 0,
                        red: Public.toMathNum(item.todoyEarnings) < 0,
                      }"
                      >{{
                        Public.toMathNum(item.todoyEarnings) + " USDT"
                      }}</span
                    >
                  </div>
                  <div class="left-three">
                    <span class="title">{{ $t("m.总收益") }}</span>
                    <span
                      :class="{
                        green: Public.toMathNum(item.sumEarnings) > 0,
                        red: Public.toMathNum(item.sumEarnings) < 0,
                      }"
                      >{{ Public.toMathNum(item.sumEarnings) + " USDT" }}</span
                    >
                  </div>
                </div>
                <img :src="item.imagesUrl" alt="" v-if="item.imagesUrl" />
                <div class="no-line-data" v-else>{{ $t("m.暂无数据") }}</div>
              </div> -->
              <div class="strategy-box">
                <div class="strategy-title">
                  <div class="title-name">
                    {{
                      item.strategyId == "WL20210624182847777"
                        ? $t("m.U本位合约DCA")
                        : $t("m.U本位合约网格")
                    }}
                  </div>
                  <img
                    class="title-img"
                    @click.stop="creatBtn(item)"
                    src="@/assets/icon/chuangjian@2x(1).png"
                    alt=""
                  />
                </div>
                <div class="strategy-nav" v-if="item.sumExecuteNum !== 0">
                  <div
                    class="nav-box"
                    @click.stop="toDatails(item, 'activeName')"
                  >
                    <div class="nav-title">{{ $t("m.活跃机器人") }}</div>
                    <div>
                      <span :class="item.activeExecuteNum > 0 ? 'blue' : ''">
                        {{ item.activeExecuteNum }}
                      </span>
                      {{ "/ " + item.sumExecuteNum }}
                    </div>
                  </div>
                  <div class="nav-box">
                    <div class="nav-title">{{ $t("m.今日收益") }}</div>
                    <div
                      :class="
                        item.todoyEarnings > 0
                          ? 'green'
                          : item.todoyEarnings < 0
                          ? 'red'
                          : ''
                      "
                    >
                      {{
                        (item.todoyEarnings > 0
                          ? "+" + item.todoyEarnings
                          : item.todoyEarnings) + " USDT"
                      }}
                    </div>
                  </div>
                  <div class="nav-box nav-boxStyle">
                    <div class="nav-title">{{ $t("m.总收益") }}</div>
                    <div>
                      <span
                        :class="
                          item.activeExecuteSumEarnings > 0
                            ? 'green'
                            : item.activeExecuteSumEarnings < 0
                            ? 'red'
                            : ''
                        "
                      >
                        {{
                          (item.activeExecuteSumEarnings > 0
                            ? "+" + item.activeExecuteSumEarnings
                            : item.activeExecuteSumEarnings) + "USDT / "
                        }}
                      </span>
                      <span
                        :class="
                          item.sumEarnings > 0
                            ? 'green'
                            : item.sumEarnings < 0
                            ? 'red'
                            : ''
                        "
                      >
                        {{
                          (item.sumEarnings > 0
                            ? "+" + item.sumEarnings
                            : item.sumEarnings) + " USDT"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else style="font-size: 14px">
                  <div>
                    {{
                      item.strategyId == "WL20210624182847777"
                        ? $t(
                            "m.U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活"
                          )
                        : $t(
                            "m.U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利"
                          )
                    }}
                  </div>
                  <div class="strategy-course" @click="courseUrl(item)">
                    {{ $t("m.查看教程") + ">>" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 我的账户 -->
      <div class="apiListLabel">
        <p class="label">{{ $t("m.我的账户") }}</p>
        <div class="add clickBtn" @click="$router.push({ path: 'myExchange' })">
          <img src="@/assets/icon/tianjia.svg" alt="" />
          <span>{{ $t("m.添加账户") }}</span>
        </div>
      </div>
      <ApiList ref="child" @IsApi="IsApi" />
    </div>
    <div class="no-acc" v-if="!isApi">
      <img src="@/assets/image/kong_big@2x.png" alt="" />
      <span>{{ $t("m.你还没有连接到交易所") }}</span>
      <span>{{ $t("m.连接交易所开始交易") }}</span>
      <span
        class="clickBtn"
        style="width: 232px; margin-top: 20px"
        @click="toAddExchange()"
        >{{ $t("m.连接交易所") }}</span
      >
    </div>
  </div>
</template>

<script>
import AggregateAcc from "./childModular/aggregateAcc.vue";
import ApiList from "./childModular/apiList.vue";

import { queryRobotCardData } from "@/request/api";
export default {
  components: {
    AggregateAcc,
    ApiList,
  },
  data() {
    return {
      isApi: false,
      robotList: [],
      isRobotList: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    toAddExchange() {
      if (!localStorage.getItem("token")) {
        this.Public.toLogin();
        return;
      }
      this.$router.push({ path: "myExchange" });
    },
    // 机器人列表
    queryRobotCardData(val) {
      let payload = {
        token: localStorage.getItem("token"),
      };
      payload = { ...payload, ...val };
      queryRobotCardData(payload).then((res) => {
        if (res.status == 200) {
          let listName = [
            { strategyId: "WL20210624182847777", sumExecuteNum: 0 },
            { strategyId: "WL202207311426369634", sumExecuteNum: 0 },
          ];

          let list = res.data.robotCardData;
          listName.forEach((e) => {
            list.forEach((item) => {
              if (item.strategyId == e.strategyId) {
                e.activeExecuteNum = item.activeExecuteNum;
                e.activeExecuteSumEarnings = this.Public.toMathNum(
                  item.activeExecuteSumEarnings
                );
                e.sumEarnings = this.Public.toMathNum(item.sumEarnings);
                e.sumExecuteNum = item.sumExecuteNum;
                e.todoyEarnings = this.Public.toMathNum(item.todoyEarnings);
              }
            });
          });
          this.robotList = listName;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳转详情
    toDatails(item, type) {
      if (item.strategyId == "WL20210624182847777") {
        this.$router.push({ path: "/robotList" });
        if (type == "activeName") {
          localStorage.setItem("activeName", true);
        }
      } else if (item.strategyId == "WL202207311426369634") {
        this.$router.push({ path: "/contractStrategy" });
      }
    },
    creatBtn(item) {
      if (item.strategyId == "WL20210624182847777") {
        this.$router.push({ path: "/createStrategy" });
      } else if (item.strategyId == "WL202207311426369634") {
        this.$router.push({ path: "/contractStrategy" });
      }
    },
    parentFn(menuActive, active) {
      this.$emit("childFn", "2", "2-3");
    },

    IsApi(v) {
      this.isApi = v;
    },
    courseUrl(item) {
      if (item.strategyId == "WL20210624182847777") {
        window.open(
          "https://intercom.help/vtrading/zh-CN/articles/6161085-u%E6%9C%AC%E4%BD%8D%E5%90%88%E7%BA%A6dca%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C"
        );
      } else {
        window.open(
          "https://intercom.help/vtrading/zh-CN/articles/6161067-%E7%BD%91%E6%A0%BC%E5%A7%94%E6%89%98%E5%B7%A5%E5%85%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C"
        );
      }
    },
  },
  created() {},
  mounted() {
    this.queryRobotCardData();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.homeAccount {
  min-width: 1140px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // padding-bottom:40px ;
  .homeAccount-warp {
    // max-width: 1120px;
    width: 100%;

    @include themeify {
      color: themed("primaryColor") !important;
    }

    // 我的策略
    .my-strategy {
      padding: 20px 0;
      // width: 1140px;
      .label {
        margin-bottom: 10px;
        padding-left: 20px;
        font-size: 20px;
        @include themeify {
          color: themed("primaryColor") !important;
        }
      }
      .strategy-warp {
        display: flex;
        justify-content: space-between;
        .strategy-list {
          display: flex;
          justify-content: space-between;
          flex: 1;
          .strategy-width {
            width: 100% !important;
          }
          .strategy {
            width: 49%;
            border-radius: 8px;
            font-size: 12px;
            @include themeify {
              color: themed("primaryColor") !important;
              background: themed("secondaryBackColor") !important;
              box-shadow: themed("shadow") !important;
            }

            .strategy-label {
              font-size: 14px;
              padding-bottom: 10px;
              margin-bottom: 3px;
              @include themeify {
                border-bottom: 1px solid themed("splitline") !important;
              }
            }
            .cont {
              display: flex;
              justify-content: space-between;
              .cont-left {
                width: 150px;
                line-height: 40px;
                .left-one {
                  display: flex;
                  align-items: center;
                  div {
                    :first-child {
                      @include themeify {
                        color: themed("btnBackGreen") !important;
                      }
                    }
                  }
                }
                .left-two {
                  display: flex;
                  flex-direction: column;
                  line-height: 24px;
                  margin-top: 20px;
                }
                .left-three {
                  display: flex;
                  flex-direction: column;
                  line-height: 24px;
                  margin-top: 15px;
                }
                .title {
                  margin-right: 10px;
                }
              }
              img {
                // width: 248px;
                height: 131px;
                flex: 1;
                border-radius: 4px;
              }
              .no-line-data {
                // width: 248px;
                height: 131px;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                @include themeify {
                  color: themed("secondaryColor") !important;
                }
              }
            }
            .strategy-box {
              padding: 16px 20px 20px;
              .strategy-title {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                padding-bottom: 10px;
                margin-bottom: 20px;
                @include themeify {
                  border-bottom: 1px solid themed("splitline") !important;
                }
                .title-img {
                  width: 38px;
                  height: 28px;
                }
              }
              .strategy-nav {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                .nav-title {
                  margin-bottom: 6px;
                  color: #162447;
                  font-size: 12px;
                }
              }
              .strategy-course {
                margin-top: 6px;
                color: #0895f7;
                cursor: pointer;
              }
              .nav-boxStyle {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              }
            }
          }
        }
        .more {
          width: 120px;
          height: 190px;
          border-radius: 8px;
          margin-left: 20px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @include themeify {
            color: themed("primaryColor") !important;
            background: themed("secondaryBackColor") !important;
            box-shadow: themed("shadow") !important;
          }
          .img-warp {
            margin-top: 20px;
            img {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
      .no-robot {
        width: 100%;
        min-height: 190px;

        border-radius: 8px;
        font-size: 14px;
        color: #6782a4;
        @include themeify {
          background: themed("secondaryBackColor") !important;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 209px;
          height: 130px;
          margin-right: 40px;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .apiListLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 20px;
    .label {
      font-size: 20px;
      margin-right: 20px;
      @include themeify {
        color: themed("primaryColor") !important;
      }
    }
    .add {
      img {
        margin-right: 5px;
      }
    }
  }
  .no-acc {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    @include themeify {
      color: #6782a4;
      background: themed("secondaryBackColor") !important;
    }
    img {
      width: 334px;
      height: 207px;
      margin-bottom: 20px;
    }
    .btn {
    }
  }
}
/deep/.acc-box {
  margin-bottom: 20px;
}
</style>