<template>
  <div class="gridDelegation">
    <div class="warp">
      <div class="content">
        <div class="title">
          <span></span>
          <span>{{ $t("m.网格机器人委托工具") }}</span>
          <span class="btnStyle" @click="$emit('cancelGrld')">
            <img style="width: 16px" src="@/assets/icon/detele.png" alt="" />
          </span>
        </div>

        <template v-if="dflt">
          <Default />
        </template>

        <template v-else>
          <Short
            v-if="direction == 0"
            ref="child"
            :price="price"
            :marketPrice="marketPrice"
            :minPrice="minPrice"
            :maxPrice="maxPrice"
            :leftData="shortLeft"
            :rightData="shortRight"
            :priceDecimal="priceDecimal"
            :baseCoin="baseCoin"
            :contractSize="contractSize"
            :counterCoin="counterCoin"
            @updateData="updateData"
            class="childStyle"
          />
          <Long
            v-if="direction == 1"
            ref="child"
            :price="price"
            :marketPrice="marketPrice"
            :minPrice="minPrice"
            :maxPrice="maxPrice"
            :leftData="longLeft"
            :rightData="longRight"
            :priceDecimal="priceDecimal"
            :baseCoin="baseCoin"
            :contractSize="contractSize"
            :counterCoin="counterCoin"
            @updateData="updateData"
            class="childStyle"
          />
          <Neutral
            v-if="direction == 2"
            ref="child"
            :price="price"
            :marketPrice="marketPrice"
            :minPrice="minPrice"
            :maxPrice="maxPrice"
            :shortLeft="shortLeft"
            :shortRight="shortRight"
            :longLeft="longLeft"
            :longRight="longRight"
            :baseCoin="baseCoin"
            :contractSize="contractSize"
            :counterCoin="counterCoin"
            :priceDecimal="priceDecimal"
            @updateData="updateData"
            class="childStyle"
          />
        </template>

        <div v-if="!marketPrice" class="childStyle"></div>

        <div class="bottom">
          <div class="left">
            <div v-if="!dflt">
              <span>{{ $t("m.实例仓位") + ":" }}</span>
              <span class="green" v-if="longNum != 0">{{
                $t("m.做多") +
                " " +
                longNum * contractSize +
                " " +
                counterCoin +
                ","
              }}</span>
              <span class="red" v-if="shortNum != 0">{{
                $t("m.做空") +
                " " +
                shortNum * contractSize +
                " " +
                counterCoin +
                ","
              }}</span>
              <span>{{
                $t("m.强平价") +
                " :" +
                Math.floor(Number(liquidationPrice) * 10000) / 10000
              }}</span>
            </div>
            <div v-else>
              <span>{{ $t("m.实例仓位") + ":" }}</span>
              <span class="green">{{ $t("m.做多") + " 0 BTC ," }}</span>
              <span class="red">{{ $t("m.做空") + " 0 BTC ," }}</span>
              <span>{{ $t("m.强平价") + " : --" }}</span>
            </div>
            <span class="user-manual" @click="toGridCourse()">
              {{ $t("m.网格委托工具使用手册") }}
            </span>
          </div>
          <div class="right">
            <span class="cancel" @click="cancelUpdateGrid()">{{
              $t("m.取消")
            }}</span>
            <span class="update" @click="updateGridDialog()">{{
              $t("m.修改")
            }}</span>
          </div>
        </div>
        <div class="alignment" @click="$refs.child.alignment()" v-if="!dflt">
          <img src="@/assets/icon/location.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- 提交修改 -->
    <el-dialog
      :title="$t('m.请确认修改的内容')"
      :visible.sync="updateShow"
      :close-on-click-modal="false"
      class="dialogUpStyle"
    >
      <div class="dia-cont">
        <div class="cont-title">
          <span class="fir">{{ $t("m.方向") }}</span>
          <span>{{ $t("m.数量") + "（" + counterCoin + "）" }}</span>
          <span>{{ $t("m.价格") + "（" + baseCoin + "）" }}</span>
          <span class="cen"></span>
          <span>{{ $t("m.数量") + "（" + counterCoin + "）" }}</span>
          <span>{{ $t("m.价格") + "（" + baseCoin + "）" }}</span>
        </div>
        <div class="cont-text" v-for="(item, idx) of updateList" :key="idx">
          <span
            class="fir"
            :class="
              item.type == '平多' || item.type == '开空'
                ? 'red'
                : item.type == '开多' || item.type == '平空'
                ? 'green'
                : ''
            "
          >
            {{
              item.type == "平多"
                ? $t("m.平多")
                : item.type == "平空"
                ? $t("m.平空")
                : item.type == "开多"
                ? $t("m.开多")
                : item.type == "开空"
                ? $t("m.开空")
                : $t("m.开多")
            }}
          </span>
          <span>{{ item.value * contractSize }}</span>
          <span>{{ Number(item.oldPrice.toFixed(4)) }}</span>
          <span class="cen">>></span>
          <span>{{ item.value * contractSize }}</span>
          <span>{{ Number(item.newPrice.toFixed(4)) }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-warp">
          <span @click="cancelUpdate()" class="cancel">{{ $t("m.取消") }}</span>
          <span @click="confirmUpdate()" class="confirm">
            {{ $t("m.提交修改") }}
          </span>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Short from "./child/short.vue";
import Long from "./child/long.vue";
import Neutral from "./child/neutral.vue";
import Default from "./child/default.vue";
import { qureyCellsData, updataGrid } from "@/request/api";
export default {
  components: {
    Short,
    Long,
    Neutral,
    Default,
  },
  data() {
    return {
      executeId: null,
      direction: null, //方向 ， 0 做空 ，1做多 ，2双向
      minPrice: null, //价格下限
      maxPrice: null, //价格上限
      marketPrice: null, //市场价
      firsetPrice: null, //首单价
      price: null,
      locationNum: null,

      updateShow: false,

      longLeft: null,
      longRight: null,

      shortLeft: null,
      shortRight: null,
      priceDecimal: null, //精度
      baseCoin: null,
      counterCoin: null,
      updateList: null, //修改数据
      liquidationPrice: null,
      longNum: 0,
      shortNum: 0,
      contractSize: null,

      dflt: true, //
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询网格数据
    qureyCellsData(item) {
      this.executeId = item.executeId;
      this.contractSize = item.contractSize;
      let payload = {
        token: localStorage.getItem("token"),
        executeId: item.executeId,
      };
      qureyCellsData(payload).then((res) => {
        if (res.status == 200) {
          if (!res.data.st_data) {
            return;
          }
          let longData = res.data.st_data.longData;
          let shortData = res.data.st_data.shortData;
          let spaceRate = null;
          let spaceDiff = null;
          let spaceModel = null; // 0 等比 ，1 等差
          let priceDecimal = null; //币对精度
          this.counterCoin = res.data.contract.counterCoin;
          this.baseCoin = res.data.contract.baseCoin;
          this.liquidationPrice = res.data.liquidationPrice;
          if (longData && shortData) {
            this.direction = 2;
            longData.cells.length == 0 && shortData.cells.length == 0
              ? (this.dflt = true)
              : (this.dflt = false);
            this.marketPrice = res.data.st_data.longData.curPrice;
            this.maxPrice = res.data.st_data.longData.maxPrice;
            this.minPrice = res.data.st_data.longData.minPirce;
            this.firsetPrice = res.data.st_data.longData.firstPrice;
            if(res.data.st_data.longData.firstPrice){
              this.firsetPrice = res.data.st_data.longData.firstPrice;
            }else{
              this.firsetPrice = res.data.st_data.shortData.firstPrice;
            }
            spaceRate = res.data.st_data.longData.spaceRate;
            spaceDiff = res.data.st_data.longData.spaceDiff;
            spaceModel = res.data.st_data.longData.spaceModel;
            this.priceDecimal = res.data.contract.priceDecimal;
          } else if (longData && !shortData) {
            this.direction = 1;
            longData.cells.length == 0
              ? (this.dflt = true)
              : (this.dflt = false);
            this.marketPrice = res.data.st_data.longData.curPrice;
            this.maxPrice = res.data.st_data.longData.maxPrice;
            this.minPrice = res.data.st_data.longData.minPirce;
            this.firsetPrice = res.data.st_data.longData.firstPrice;
            spaceRate = res.data.st_data.longData.spaceRate;
            spaceDiff = res.data.st_data.longData.spaceDiff;
            spaceModel = res.data.st_data.longData.spaceModel;
            this.priceDecimal = res.data.contract.priceDecimal;
          } else if (!longData && shortData) {
            this.direction = 0;
            shortData.cells.length == 0
              ? (this.dflt = true)
              : (this.dflt = false);
            this.marketPrice = res.data.st_data.shortData.curPrice;
            this.maxPrice = res.data.st_data.shortData.maxPrice;
            this.minPrice = res.data.st_data.shortData.minPirce;
            this.firsetPrice = res.data.st_data.shortData.firstPrice;
            spaceRate = res.data.st_data.shortData.spaceRate;
            spaceDiff = res.data.st_data.shortData.spaceDiff;
            spaceModel = res.data.st_data.shortData.spaceModel;
            this.priceDecimal = res.data.contract.priceDecimal;
          }
          if (!this.firsetPrice) {
            return;
          }
          let price = [
            {
              id: 0,
              price: this.firsetPrice,
            },
          ];

          let min = JSON.parse(JSON.stringify(this.firsetPrice));
          let max = 0;

          if (longData && longData.cells) {
            for (let key of longData.cells) {
              if (key.closePrice && key.closePrice > max) {
                max =
                  key.closePrice > key.openPrice
                    ? key.closePrice
                    : key.openPrice;
              }
              if (key.openPrice > max) {
                max = key.openPrice;
              }
              if (key.closePrice && key.closePrice < min) {
                min =
                  key.closePrice < key.openPrice
                    ? key.closePrice
                    : key.openPrice;
              }
              if (key.openPrice < min) {
                min = key.openPrice;
              }
            }
          }

          if (shortData) {
            for (let key of shortData.cells) {
              if (key.closePrice && key.closePrice > max) {
                max =
                  key.closePrice > key.openPrice
                    ? key.closePrice
                    : key.openPrice;
              }
              if (key.openPrice > max) {
                max = key.openPrice;
              }
              if (key.closePrice && key.closePrice < min) {
                min =
                  key.closePrice < key.openPrice
                    ? key.closePrice
                    : key.openPrice;
              }
              if (key.openPrice < min) {
                min = key.openPrice;
              }
            }
          }

          let a = this.marketPrice > max ? this.marketPrice : max;
          let b = a - this.firsetPrice;

          let minNum = 50;
          let maxNum = 50;

          //  ******别删******

          // if (b > 0) {
          //   minNum = Math.ceil(b / spaceRate) + 51;
          // } else {
          //   minNum = 51;
          // }

          // if (maxNum < this.firsetPrice) {
          //   maxNum = Math.ceil((this.firsetPrice - maxNum) / spaceRate) + 51;
          // } else {
          //   maxNum = 51;
          // }

          // 计算价格
          if (spaceModel == 0) {
            // 等比
            for (let i = 2; i < maxNum + 1; i++) {
              let item = {
                id: i - 1,
                // price: Math.floor((this.firsetPrice + i * Number(spaceRate))*100000)/100000,
                // price: Number((Math.pow(1 + spaceRate, i) * this.firsetPrice).toFixed(priceDecimal)),
                price: Math.pow(1 + spaceRate, i-1) * this.firsetPrice,
              };
              price.unshift(item);
            }

            for (let j = 1; j < minNum; j++) {
              let item = {
                id: j * -1,
                // price: Number((Math.pow(1 - spaceRate, j) * this.firsetPrice).toFixed(priceDecimal))
                price: Math.pow(1 - spaceRate, j) * this.firsetPrice,
              };
              if (item.price < 0) {
                break;
              }
              price.push(item);
            }
          } else {
            // 等差
            for (let i = 1; i < maxNum + 1; i++) {
              let item = {
                id: i,
                // price: Number((this.firsetPrice + i * spaceRate).toFixed(priceDecimal)),
                price: this.firsetPrice + i * spaceDiff,
              };
              price.unshift(item);
            }
            for (let j = 1; j < minNum; j++) {
              let item = {
                id: j * -1,
                // price: Number((this.firsetPrice - i * spaceRate).toFixed(priceDecimal)),
                price: this.firsetPrice - j * spaceDiff,
              };
              if (item.price < 0) {
                break;
              }
              price.push(item);
            }
          }

          this.price = price;
                    
          // 计算格子

          // 做多
          if (this.direction == 1) {
            let list = JSON.parse(
              JSON.stringify(res.data.st_data.longData.cells)
            );
            let priceList = JSON.parse(JSON.stringify(price));
            let leftData = JSON.parse(JSON.stringify(price));
            let rightData = JSON.parse(JSON.stringify(price));

            for (let key of list) {
              if (key.status == 0 || key.status == 1) {
                for (let item of leftData) {
                  if (item.id == key.index) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              } else {
                this.longNum += key.openAmount;
                for (let item of rightData) {
                  if (item.id == key.index + 1) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              }
            }

            this.longLeft = leftData;
            this.longRight = rightData;
            setTimeout(() => {
              this.$refs.child.location();
            }, 300);
          } else if (this.direction == 0) {
            let list = JSON.parse(
              JSON.stringify(res.data.st_data.shortData.cells)
            );
            let priceList = JSON.parse(JSON.stringify(price));
            let leftData = JSON.parse(JSON.stringify(price));
            let rightData = JSON.parse(JSON.stringify(price));

            for (let key of list) {
              if (key.status == 0 || key.status == 1) {
                for (let item of leftData) {
                  if (item.id == key.index) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              } else {
                this.shortNum += key.openAmount;
                for (let item of rightData) {
                  if (item.id == key.index - 1) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              }
            }

            this.shortLeft = leftData;
            this.shortRight = rightData;

            setTimeout(() => {
              this.$refs.child.location();
            }, 300);
          } else if (this.direction == 2) {
            let shortData = JSON.parse(
              JSON.stringify(res.data.st_data.shortData.cells)
            );
            let longData = JSON.parse(
              JSON.stringify(res.data.st_data.longData.cells)
            );

            let priceList = JSON.parse(JSON.stringify(price));
            let longLeft = JSON.parse(JSON.stringify(price));
            let longRight = JSON.parse(JSON.stringify(price));
            let shortLeft = JSON.parse(JSON.stringify(price));
            let shortRight = JSON.parse(JSON.stringify(price));

            // 多
            for (let key of longData) {
              if (key.status == 0 || key.status == 1) {
                for (let item of longLeft) {
                  if (item.id == key.index) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              } else {
                this.longNum += key.openAmount;
                for (let item of longRight) {
                  if (item.id == key.index + 1) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              }
            }
            this.longLeft = longLeft;
            this.longRight = longRight;
            // 空
            for (let key of shortData) {
              if (key.status == 0 || key.status == 1) {
                for (let item of shortLeft) {
                  if (item.id == key.index) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              } else {
                this.shortNum += key.openAmount;
                for (let item of shortRight) {
                  if (item.id == key.index - 1) {
                    if (!item.gridList) {
                      item.gridList = [];
                    }
                    item.gridList.push(key);
                  }
                }
              }
            }

            this.shortLeft = shortLeft;
            this.shortRight = shortRight;
          }

          let center, top, bottom;
          for (let i = 0; i < price.length; i++) {
            if (this.marketPrice < price[i].price) {
              center = i;
            }
            if (this.maxPrice < price[i].price) {
              top = i;
            }
            if (this.minPrice < price[i].price) {
              bottom = i;
            }
          }
          setTimeout(() => {
            // 市场价，上限，下限定位
            this.$refs.child.location(center, top, bottom);
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 更新数据
    updateData(v, type) {
      this.longLeft = v;
    },
    // 取消弹窗

    cancelUpdateGrid() {
      this.$emit("cancelGrld");
      this.direction = null;
    },

    // 修改弹窗获取数据
    updateGridDialog() {
      if (this.dflt) {
        this.$emit("cancelGrld");
        return;
      }

      let list = JSON.parse(JSON.stringify(this.$refs.child.logList));
      if (list.length == 0) {
        this.$message.error("没有修改数据");
        return;
      }
      for (var i = 0; i < list.length; i++) {
        for (var j = i + 1; j < list.length; j++) {
          if (
            list[i].type == list[j].type &&
            list[i].GridId == list[j].GridId
          ) {
            list[i].newIndex = list[j].newIndex;
            list[i].newPrice = list[j].newPrice;
            list.splice(j, 1);
            j--;
          }
        }
      }
      this.updateList = list;
      this.updateShow = true;
    },

    // 取消修改
    cancelUpdate() {
      this.updateShow = false;
    },
    // 修改
    confirmUpdate() {
      let payload = {
        token: localStorage.getItem("token"),
        executeId: this.executeId,
        gridParam: {},
      };
      let list = JSON.parse(JSON.stringify(this.updateList));
      let longChanges = [];
      let shortChanges = [];
      for (let key of list) {
        let item = {};
        if (key.type == "开多") {
          item.id = key.GridId;
          item.index = key.newIndex;
          longChanges.push(item);
        } else if (key.type == "平多") {
          item.id = key.GridId;
          longChanges.push(item);
          item.index = key.newIndex - 1;
        } else if (key.type == "开空") {
          item.id = key.GridId;
          item.index = key.newIndex;
          shortChanges.push(item);
        } else if (key.type == "平空") {
          item.id = key.GridId;
          item.index = key.newIndex + 1;
          shortChanges.push(item);
        }
      }
      payload.gridParam.longChanges = longChanges;
      payload.gridParam.shortChanges = shortChanges;

      updataGrid(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("m.修改成功"));
          this.updateShow = false;
          this.direction = null;
          this.$emit("cancelGrld");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toGridCourse() {
      window.open(
        "https://intercom.help/vtrading/zh-CN/articles/6161105-u%E6%9C%AC%E4%BD%8D%E5%90%88%E7%BA%A6%E7%BD%91%E6%A0%BC%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C"
      );
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    this.$destroy();
  },
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@import "@/assets/sass/_themeify.scss";
.gridDelegation {
  height: 100%;
  width: 100%;
  .warp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: rgba(7, 25, 53, 0.5);
    .content {
      position: relative;
      width: 1120px;
      background: #fff !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // overflow: hidden;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        height: 60px;
        .btnStyle {
          cursor: pointer;
        }
      }
      .childStyle {
        flex: 1;
        overflow-y: scroll;
        background: #fcfcfc;
      }
      .bottom {
        // position: absolute;
        // left: 0;
        // bottom: 0;
        height: 96px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 24px 30px;
        font-size: 14px;
        @include themeify {
          color: themed("primaryColor") !important;
        }
        .left {
          div {
            margin-bottom: 10px;
          }
          .user-manual {
            color: #2670FA;
          }
        }
        .right {
          display: flex;
          align-items: center;
          @include themeify {
            color: themed("secondaryColor") !important;
          }
          .cancel {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 143px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #d0d7df;
            margin-right: 20px;
            cursor: pointer;
          }
          .update {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 143px;
            height: 40px;
            background: #2670FA;
            border-radius: 8px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .alignment {
        position: absolute;
        top: calc(50% - 40px);
        left: 10px;
        width: 18px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 0px 0px 8px;
        color: #fff;
        padding-left: 4px;
        cursor: pointer;
      }
    }
  }
}

.dia-cont {
  .cont-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0px 2px 4px 0px #eff2f7;
    padding-bottom: 10px;
    margin-bottom: 10px;
    @include themeify {
      border-bottom: 1px solid themed("splitline");
    }
    span {
      width: 20%;
    }
  }
  .cont-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    span {
      width: 20%;
    }
  }
  .fir {
    width: 15% !important;
  }
  .cen {
    width: 10% !important;
  }
}

.btn-warp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 10px;
  @include themeify {
    border-top: 1px solid themed("splitline");
    color: themed("secondaryColor") !important;
  }
  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d0d7df;
    margin-right: 20px;
    cursor: pointer;
  }
  .confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 40px;
    background: #2670FA;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
  }
}
.dialogUpStyle {
  min-width: 600px !important;
}
</style>