<template>
  <div class="inputIcon">
    <template v-if="item">
      <div class="title">
        {{
          item.name == "首次触发模式"
            ? ""
            : $store.state.lang == 'ch'
            ? item.name
            : item.enName
        }}
        <img
          v-if="item && item.explain"
          src="@/assets/icon/explain.svg"
          class="explain"
          @click="openAlert()"
          alt=""
        />
      </div>
      <div class="inputBox">
        <div class="minBox">
          <div class="box-smlBox" v-if="minIcon" @click="minClick()">
            {{ $t("m.最小值") }}
          </div>
          <input
            :style="{
              'padding-left': leftIcon ? '22px' : '',
            }"
            class="slider-input"
            :placeholder="
              $store.state.lang == 'ch' ? item.placeholder : item.enPlaceholder
            "
            v-model="item.value"
            :disabled="disabled"
            @blur="inputBlur"
            v-if="type"
            :class="[
              disabled ? 'not-cursor' : '',
              item.redBorder ? 'redBorderStyle' : 'inpStyle',
            ]"
          />
          <input
            v-else
            :style="{
              'padding-left': leftIcon ? '22px' : '',
            }"
            class="slider-input"
            :class="[
              disabled ? 'not-cursor' : '',
              redBorderStyleShow
                ? 'redBorderStyle'
                : item.redBorderStyle
                ? 'redBorderStyle'
                : 'inpStyle',
            ]"
            :placeholder="
              $store.state.lang == 'ch' ? item.placeholder : item.enPlaceholder
            "
            v-model="item.value"
            :disabled="disabled"
            @blur="inputBlur"
          />

          <!-- 输入框左符号 -->
          <span v-if="leftIcon" class="input-leftIcon">{{ leftIcon }}</span>

          <!-- 输入框右单位 -->
          <span v-if="item.inpUnit" class="input-icon">
            <span v-if="item.inpUnit == 'multiple'">{{ $t("m.倍") }}</span>
            <span v-if="item.inpUnit == 'single'">{{ $t("m.单") }}</span>
            <span v-if="item.inpUnit == 'percentage'">%</span>
            <span v-if="item.inpUnit == 'currency'">
              {{ pairCurrent ? pairCurrent.counterCoin : "USDT" }}
            </span>
          </span>
        </div>
        <div v-if="item.redBorderStyle" style="color: red">
          {{
            item.title == "网格间隔等差" && item.value == 0 && item.value
              ? $t("m.不能为零")
              : $t("m.不能为空")
          }}
        </div>

        <!-- 输入框下折合 -->
        <div style="margin: 5px" v-if="equivalent">
          {{
            "≈" +
            (result
              ? item.pairUsdtSize
                ? Number(Number(lastValue).toFixed(item.pairUsdtSize))
                : Number(lastValue)
              : "999999") +
            (pairCurrent ? pairCurrent.baseCoin : "USDT")
          }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    leftIcon: {},
    minIcon: {},
    equivalent: {},
    disabled: {},
    pairCurrent: {},
    result: {},
    type: {},
  },
  data() {
    return {
      lastValue: 0,
      index: null, //小数位
      redBorderStyleShow: false,
    };
  },
  watch: {
    "item.value"() {
      if (this.item.value == null || this.item.value.length == 0) {
        if (
          (!this.item.value || this.item.value.length == 0) &&
          (this.item.name == "首单投入" ||
            this.item.name == "补仓金额增量" ||
            this.item.name == "单格投入" ||
            this.item.inputType == "required")
        ) {
          this.item.redBorderStyle = true;
          this.lastValue = 0;
        }
        return;
      }
      this.item.redBorderStyle = false;

      if (this.item.inpUnit) {
        if (this.index) {
          if (isNaN(Number(this.item.value))) {
            if (this.item.name == "首单投入" || this.item.name == "单格投入") {
              this.minClick();
            } else {
              this.item.value = this.item.valueMin.toString();
              return;
            }
          }
          let value = this.item.value.toString();
          if (value.indexOf(".") > -1) {
            if (this.index - 1 < value.length - value.indexOf(".")) {
              value = value.slice(0, value.indexOf(".") + this.index);
              this.item.value = value;
            }
          }
        } else {
          if (this.item.pairUsdtSize) {
            let value = this.item.value.toString();
            if (value.indexOf(".") > -1) {
              this.item.value = value.slice(
                0,
                value.indexOf(".") + Number(this.item.pairUsdtSize) + 1
              );
            }
          } else {
            if (this.item.value.toString().indexOf(".") > -1) {
              if (
                this.item.value.toString().length -
                  this.item.value.toString().indexOf(".") >
                4
              )
                this.item.value = this.item.value
                  .toString()
                  .slice(0, this.item.value.toString().indexOf(".") + 5);
            }
          }
        }
        if (this.result) {
          let lastValue = (this.item.value * this.result.last).toString();
          if (lastValue.indexOf(".") > -1) {
            this.lastValue = lastValue.slice(0, lastValue.indexOf(".") + 7);
          } else {
            this.lastValue = lastValue;
          }
        }
      }
    },
    "result.last"() {
      let lastValue = (this.item.value * this.result.last).toString();
      if (lastValue.indexOf(".") > -1) {
        this.lastValue = lastValue.slice(0, lastValue.indexOf(".") + 7);
      } else {
        this.lastValue = lastValue;
      }
      setTimeout(() => {
        this.minClick();
      }, 1000);
    },
    pairCurrent() {
      if (this.pairCurrent.contractSize.indexOf(".") > -1) {
        this.index =
          this.pairCurrent.contractSize.length -
          this.pairCurrent.contractSize.indexOf(".");
      } else {
        this.index = 1;
      }
    },
  },
  methods: {
    //解释文案
    openAlert() {
      this.$alert(
        this.$store.state.lang == 'ch'
          ? this.item.explain
          : this.item.enExplain,
        "",
        {
          confirmButtonText: this.$t("m.确定"),
          callback: (action) => {
            console.log(action)
          },
        }
      );
    },
    //输入框失去焦点
    inputBlur() {
      if (this.item.inpUnit) {
        if (
          this.item.name == "首单投入" ||
          this.item.name == "补仓金额增量" ||
          this.item.name == "单格投入"
        ) {
          if (this.item.name == "补仓金额增量") {
            if (this.item.value && this.item.value.indexOf(".") > -1) {
              this.item.value = Number(this.item.value).toString();
            }
            return;
          }
          if (this.pairCurrent.exchangeName == "binance") {
            if (Number(this.lastValue) < 10) {
              this.redBorderStyleShow = true;
              this.$message.warning("输入的数量需要大于10USDT");
            } else {
              this.redBorderStyleShow = false;
            }
          } else {
            if (
              this.lastValue <
              this.Public.toMathNum(
                this.result.last * this.pairCurrent.contractSize
              )
            ) {
              this.redBorderStyleShow = true;
              this.$message.warning(
                "输入的数量需要大于" +
                  this.Public.toMathNum(
                    this.result.last * this.pairCurrent.contractSize
                  ) +
                  "USDT"
              );
            } else {
              this.redBorderStyleShow = false;
            }
          }
        } else {
          if (this.item.value == null || this.item.value.length == 0) {
            if (this.item.inputType == "required") {
              this.item.redBorderStyle = true;
            }

            return;
          }
          if (this.item.value == 0 && this.item.title == "网格间隔等差") {
            this.item.redBorderStyle = true;
            return;
          }
          if (this.item.value.toString().indexOf(".") > -1) {
            if (this.item.accuracy) {
            } else {
              this.item.value =
                this.item.value.split(".")[0] +
                "." +
                this.item.value.split(".")[1];
            }
          }
          if (
            this.item.valueMin &&
            Number(this.item.value) < Number(this.item.valueMin)
          ) {
            this.item.value = this.item.valueMin;
          } else if (this.item.valueMax) {
            if (this.item.valueMax == "Infinity") {
              if (this.item.value > 999999) {
                this.item.value = 999999;
              }
            } else {
              if (Number(this.item.value) > Number(this.item.valueMax)) {
                this.item.value = this.item.valueMax;
              }
            }
          }
        }
      }
    },
    //最小值按钮
    minClick() {
      if (!this.result) {
        return;
      }
      if (this.pairCurrent.exchangeName == "binance") {
        let value = 10 / this.result.last;
        if (this.index == 1) {
          value = Math.ceil(value);
        } else {
          value =
            Math.ceil(value * Math.pow(10, this.index - 1)) /
            Math.pow(10, this.index - 1);
        }
        if (Number(value) == 0) {
          this.item.value = this.pairCurrent.contractSize;
        } else {
          this.item.value = value.toString();
        }
      } else {
        this.item.value = this.pairCurrent.contractSize;
      }
      this.item.value = Number(this.item.value);
      let lastValue = (this.item.value * this.result.last).toString();
      if (lastValue.indexOf(".") > -1) {
        this.lastValue = lastValue.slice(0, lastValue.indexOf(".") + 7);
      } else {
        this.lastValue = lastValue;
      }
    },
  },
  mounted() {
    if (this.pairCurrent) {
      if (this.pairCurrent.contractSize.indexOf(".") > -1) {
        this.index =
          this.pairCurrent.contractSize.length -
          this.pairCurrent.contractSize.indexOf(".");
      } else {
        this.index = 1;
      }
      if (this.item.name == "补仓金额增量") {
        return;
      }
      if (
        this.item.value &&
        this.item.value > 0 &&
        this.item.value !== this.item.valueMin
      ) {
        let lastValue = (this.item.value * this.result.last).toString();
        if (lastValue.indexOf(".") > -1) {
          this.lastValue = lastValue.slice(0, lastValue.indexOf(".") + 7);
        } else {
          this.lastValue = lastValue;
        }
        return;
      }
      this.minClick();
    } else if (this.item && this.item.accuracy) {
      this.index = this.item.accuracy;
    }
  },
  created() {
    if (this.item && this.item.name == "首次触发模式") {
      this.item.inpUnit = "currency";
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.inputIcon {
  margin-top: 20px;
  @include themeify {
    color: themed("secondaryColor") !important;
  }
  flex: 1;
  .title {
    display: flex;
    align-items: center;
    @include themeify {
      color: themed("tabColor") !important;
    }
    .explain {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .inputBox {
    position: relative;
    .slider-input {
      margin-top: 10px;
      // @include themeify {
      //   border: themed("InpBorder") !important;
      //   background: themed("InpBack") !important;
      //   color: themed("primaryColor") !important;
      //   background: themed("secondaryBackColor") !important;
      // }
    }
    .input-icon {
      position: absolute;
      right: 8px;
      top: 10px;
    }
    .input-leftIcon {
      position: absolute;
      left: 8px;
      top: 7px;
      font-size: 19px;
    }
  }
}
.minBox {
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-top: 10px;
  .box-smlBox {
    width: 70px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2670FA;
    border-radius: 4px;
    cursor: pointer;
    @include themeify {
      color: themed("btnText") !important;
    }
  }
  /deep/.slider-input {
    margin-top: 0 !important;
  }

  /deep/.input-icon {
    top: 10px;
  }
} //禁止点击图标
.not-cursor {
  cursor: not-allowed !important;
  background: #f0f0f0;
  color: #d0d7df;
}
.redBorderStyle {
  border: 1px solid red !important;
  padding: 0 10px;
  :focus {
    border: 1px solid red !important;
  }
}
</style>